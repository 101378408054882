import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chatId: null,
  nft: null,
  receiver: null,
  mode: null,
  holdFlag: null,
  receiverProfile: null,
};

export const ChatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setChat: (state, action) => {
      state.chatId = action.payload;
    },
    setChatNft: (state, action) => {
      state.nft = action.payload;
    },
    setChatReceiver: (state, action) => {
      state.receiver = action.payload;
    },
    setChatMode: (state, action) => {
      state.mode = action.payload;
    },
    setHoldFlag: (state, action) => {
      state.holdFlag = action.payload;
    },
    setReceiverProfile: (state, action) => {
      state.receiverProfile = action.payload;
    },
  },
});

export const {
  setChat,
  setChatNft,
  setChatReceiver,
  setChatMode,
  setHoldFlag,
  setReceiverProfile,
} = ChatSlice.actions;

export default ChatSlice.reducer;
