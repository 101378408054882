import NUSD from "../../assets/images/NUSD.svg";
import LocationPin from "../../assets/images/PropertyAddress/Pin, Location.svg";
// import icon from "../../assets/images/icon.svg";
// import icon1 from "../../assets/images/icon (1).svg";
// import icon2 from "../../assets/images/icon (2).svg";
import heart from "../../assets/images/Frame 1000005004.png";

import btnnext from "../../assets/images/carousel/Frame 1000005236.svg";
import btnprev from "../../assets/images/carousel/Frame 1000005237.svg";
import NUSD1 from "../../assets/images/carousel/Frame.png";
import Guests from "../../assets/images/dashboard/GuestsSocial.svg";
import Bedrooms from "../../assets/images/dashboard/BedroomsSocial.svg";
import Bathrooms from "../../assets/images/dashboard/BathroomsSocial.svg";
import Beds from "../../assets/images/dashboard/BedsSocial.svg";
import Carousel from "react-bootstrap/Carousel";

import { useDispatch } from "react-redux";
import { setId } from "../../Actions/PropertySlice";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

import { setNFT } from "../../Actions/NFTSlice";

export const CarouselCompo = ({
  mode,
  nftInfo,
  metaData,
  price,
  onlyImages,
  topRightIcon,
  imageHeight,
  btn,
  totalPriceHide,
  emptyImage,
  PriceHide,
  onClick = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  stopPropagation,
  padding = 6,
  controls = true,
}) => {
  const [images, setImages] = useState([]);
  const [totalPrice, setTotalPrice] = useState();
  const [location, setLocation] = useState();
  // const [metaData, setMetaData] = useState();

  useEffect(() => {
    const imagesHash = metaData?.Images;
    if (imagesHash) setImages(imagesHash);
    setLocation(metaData?.Address);
    setTotalPrice(Number(nftInfo?.auction?.price) * 12);
  }, [metaData]);

  const handleClick = () => {
    // dispatch(
    //   setNFT({
    //     NftInfo: nftInfo,
    //     metaData: metaData,
    //   })
    // );
    // navigate("/property");
  };

  return (
    <div
      className={`p-[${padding}px] w-full min-w-[250px] shadow-md rounded-[10px] relative h-max cursor-pointer group`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Carousel
        className="w-full"
        interval={null}
        fade={true}
        onClick={(e) => {
          if (!stopPropagation) e.stopPropagation();
        }}
        prevIcon={<img alt="" src={btnprev}></img>}
        nextIcon={<img alt="" src={btnnext}></img>}
        controls={controls}
      >
        {images.length === 0 ? (
          <Carousel.Item>
            <div
              className={`rounded-[10px] cursor-auto w-full h-[${imageHeight}] flex items-center`}
            >
              <img alt="" src={emptyImage} className="m-auto"></img>
            </div>
          </Carousel.Item>
        ) : (
          images.map((image) => (
            <Carousel.Item>
              <img
                alt=""
                src={image}
                className={`rounded-[10px] cursor-auto shadow-md w-full h-[${imageHeight}] group-hover:scale-125 transition duration-500`}
              ></img>
            </Carousel.Item>
          ))
        )}
      </Carousel>

      {topRightIcon ? (
        <div className="absolute top-[20px] right-[20px] z-[10]">
          {topRightIcon}
        </div>
      ) : (
        <></>
      )}

      {!onlyImages ? (
        <>
          {PriceHide ? (
            <></>
          ) : (
            <div className="flex items-center ml-[10px] my-[10px] font-semibold text-[14px] gap-[10px]">
              <img alt="" src={NUSD}></img>
              <div className="shadow-md px-[4px] py-[2px] rounded-[8px] text-[#5B1DEE] font-semibold text-[16px]">
                {price}
              </div>
              <div className="font-semibold text-[16px]">NUSD</div>
              <div className="text-[#959595]">
                {mode === "short" ? "/day" : "/month"}
              </div>
            </div>
          )}

          {totalPriceHide ? (
            <></>
          ) : (
            <div className="flex items-center ml-[10px] my-[10px] gap-[10px]">
              <img alt="" src={NUSD}></img>
              <div className="text-[#959595]">{totalPrice} NUSD Total</div>
            </div>
          )}

          <div className="flex items-center text-[#959595] ml-[10px] gap-[10px] mt-[8px]">
            <img alt="" src={LocationPin}></img>
            <div className="truncate w-full">{location}, Dubai</div>
          </div>
          <div className="flex items-center gap-[10px] text-[#959595] w-full">
            <img alt="" src={Bedrooms}></img>
            <div>{metaData?.Amenities?.Bedrooms}</div>

            <div className="w-[2px] h-[15px] bg-[#D9D9D9]"></div>
            <img alt="" src={Guests}></img>
            <div>{metaData?.Amenities?.Guests}</div>
            <div className="w-[2px] h-[15px] bg-[#D9D9D9]"></div>

            <img alt="" src={Bathrooms}></img>
            <div>{metaData?.Amenities?.Bathrooms}</div>
          </div>
        </>
      ) : (
        <></>
      )}

      {btn ? btn : <></>}
    </div>
  );
};
