import { useSelector } from "react-redux";

import "react-multi-carousel/lib/styles.css";
import { LandlordDashboard } from "../components/LandlordDashboard/LandlordDashboard";
import { LandlordTenantOverview } from "../components/LandlordDashboard/LandlordTenantOverview";
import { LandlordNFTs } from "../components/LandlordDashboard/LandlordNFTs";
import { LandlordCalendar } from "../components/LandlordDashboard/LandlordCalendar";
import { TenantDashboard } from "../components/TenantDashboard/TenantDashboard";
import { TenantMyRental } from "../components/TenantDashboard/TenantMyRental";
import { TenantTransaction } from "../components/TenantDashboard/TenantTransaction";
import { LandlordTransaction } from "../components/LandlordDashboard/LandlordTransaction";
import { LandlordInbox } from "../components/LandlordDashboard/LandlordInbox";
import { TenantInbox } from "../components/TenantDashboard/TenantInbox";
import { Properties } from "../components/YieldEstateDashboard/Properties";
import { Portfolio } from "../components/YieldEstateDashboard/Portfolio";
import { TravelerDashboard } from "../components/TravelerDashboard/TravelerDashboard";
import { Trips } from "../components/TravelerDashboard/Trips";
import { WriteReview } from "../components/TravelerDashboard/WriteReview";
import { HostReservations } from "../components/HostDashboard/HostReservations";
import { HostNFTs } from "../components/HostDashboard/HostNFTs";
import { HostInbox } from "../components/HostDashboard/HostInbox";
import { HostDashboard } from "../components/HostDashboard/HostDashboard";
import { TravelerInbox } from "../components/TravelerDashboard/TravelerInbox";
import { TravelerTrips } from "../components/TravelerDashboard/TravelerTrips";
import { useEffect, useState } from "react";

export const Dashboard = () => {
  const headerMode = useSelector((state) => state.header.mode);
  const headerSubmode = useSelector((state) => state.header.submode);
  const dashboardMode = useSelector((state) => state.dashboard.mode);

  const account = useSelector((state) => state.auth.account);
  const allNFTs = useSelector((state) => state.nft.allNFTs);

  const [myNFTs, setMyNFTs] = useState([]);
  const [myNFTs_Rent, setMyNFTs_Rent] = useState([]);

  useEffect(() => {
    const myNFTs = [];
    const temp = Object.values(allNFTs);
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].nft_info.access.owner === account) myNFTs.push(temp[i]);
    }
    setMyNFTs(myNFTs);
  }, [allNFTs, account]);

  useEffect(() => {
    const myNFTs_Rent = [];
    for (let i = 0; i < myNFTs.length; i++) {
      if (
        myNFTs[i].shorttermrental_info.travelers.length &&
        myNFTs[i].shorttermrental_info.islisted
      )
        myNFTs_Rent.push(myNFTs[i]);
    }
    setMyNFTs_Rent(myNFTs_Rent);
  }, [myNFTs]);

  return (
    <div className="bg-white w-full rounded-[8px] shadow-md flex flex-col h-[calc(100vh-70px)] overflow-auto">
      {headerSubmode === 1 && headerMode === 4 ? (
        <>
          {dashboardMode === 0 ? <LandlordDashboard /> : <></>}
          {dashboardMode === 1 ? <LandlordTenantOverview /> : <></>}
          {dashboardMode === 2 ? <LandlordNFTs /> : <></>}
          {dashboardMode === 3 ? <LandlordTransaction /> : <></>}
          {dashboardMode === 4 ? <LandlordInbox /> : <></>}
        </>
      ) : (
        <></>
      )}

      {headerSubmode === 2 && headerMode === 4 ? (
        <>
          {dashboardMode === 0 ? <TenantDashboard /> : <></>}
          {dashboardMode === 1 ? <TenantMyRental /> : <></>}
          {dashboardMode === 2 ? <TenantTransaction /> : <></>}
          {dashboardMode === 3 ? <TenantInbox /> : <></>}
        </>
      ) : (
        <></>
      )}
      {headerSubmode === 3 && headerMode === 4 ? (
        <>
          {dashboardMode === 0 && <HostDashboard assets={myNFTs} />}
          {dashboardMode === 1 && <HostReservations assets={myNFTs_Rent} />}
          {dashboardMode === 2 && <HostNFTs assets={myNFTs} />}
          {dashboardMode === 3 && <HostInbox />}
        </>
      ) : (
        <></>
      )}
      {headerSubmode === 4 && headerMode === 4 ? (
        <>
          {dashboardMode === 0 && <TravelerDashboard />}
          {dashboardMode === 1 && <TravelerTrips />}
          {dashboardMode === 3 && <TravelerInbox />}
        </>
      ) : (
        <></>
      )}
      {headerSubmode === 0 && headerMode === 4 ? <Portfolio /> : <></>}
    </div>
  );
};
