import imgBack from "../../assets/images/dashboard/luke-van-zyl-koH7IVuwRLw-unsplash 1.png";
import colorarrow from "../../assets/images/Arrow.png";
import imageCard from "../../assets/images/image.png";
import heart from "../../assets/images/dashboard/Frame 1000005004.png";
import mapBack from "../../assets/images/Traveler/map.svg";
import home from "../../assets/images/Traveler/home.svg";
import emptyIcon from "../../assets/images/Traveler/emptyIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderMode } from "../../Actions/HeaderSlice";
import { setPage } from "../../Actions/PageSlice";
import { CarouselCompo } from "../Global/CarouselCompo";
import { useEffect, useState } from "react";

export const TravelerDashboard = () => {
  const dispatch = useDispatch();
  const handleExplore = () => {
    dispatch(setPage(null));
    dispatch(setHeaderMode({ mode: 1, submode: 1 }));
  };
  const [recommended, setRecommended] = useState([]);
  const allNFTs = useSelector((state) => state.nft.allNFTs);

  function selectRandomItemsFromArray(array, numItems) {
    const randomItems = [];

    // Create a copy of the original array to avoid modifying it
    const remainingItems = array.slice();

    for (let i = 0; i < numItems; i++) {
      const randomIndex = Math.floor(Math.random() * remainingItems.length);
      const selectedItem = remainingItems.splice(randomIndex, 1)[0];
      randomItems.push(selectedItem);
    }

    return randomItems;
  }

  useEffect(() => {
    const listedNFTs = [];
    for (let i = 0; i < Object.values(allNFTs).length; i++) {
      if (Object.values(allNFTs)[i].shorttermrental_info.islisted)
        listedNFTs.push(Object.values(allNFTs)[i]);
    }
    setRecommended(selectRandomItemsFromArray(listedNFTs, 2));
  }, []);

  return (
    <div className="p-[16px] w-full h-full flex flex-col">
      <div className="shadow-md relative rounded-[8px]">
        <div className="flex justify-between">
          <img alt="" src={mapBack} className="max-w-[40vw]" />
          <img alt="" src={home} className="rounded-[8px] max-w-[40vw]" />
        </div>

        <div className="absolute top-[20%] left-[2%] space-y-[20px] w-[50%]">
          <div className="text-[40px]">Find your dream stay</div>
          <div>
            Explore the best of the best in real estate with our recommended
            properties.
          </div>
          <div
            onClick={handleExplore}
            className="bg-[#5B1DEE] text-white px-[14px] py-[8px] w-max rounded-[16px] cursor-pointer hover:shadow-[-1px_6px_10px_0_rgba(93,0,207,0.5)] hover:-translate-y-[3px]"
          >
            Explore NFTs
          </div>
        </div>
      </div>

      <div className="flex w-full justify-between my-[10px]">
        <div>Recommended properties</div>
        <div className="flex items-center py-[4px] px-[12px] rounded-[100px] shadow-md">
          <div className="text-[#4C37C3]">See all</div>
          <img alt="" src={colorarrow} className="" />
        </div>
      </div>

      <div className="flex gap-[20px]">
        {recommended.map((item) => {
          return (
            <div
              className="w-[250px]"
              onClick={() => {
                // dispatch(setNFTsSection(0));
                // dispatch(setDashboardMode(2));
              }}
            >
              <CarouselCompo
                nftInfo={item?.nft_info}
                metaData={item?.metaData}
                onlyImages={true}
                imageHeight={"146px"}
                stopPropagation={true}
              />
            </div>
          );
        })}
      </div>

      <div className="flex w-full justify-between my-[10px]">
        <div>Wishlist</div>
        <div className="flex items-center py-[4px] px-[12px] rounded-[100px] shadow-md">
          <div className="text-[#4C37C3]">See all</div>
          <img alt="" src={colorarrow} className="" />
        </div>
      </div>

      {/* <div className="flex gap-[20px]">
        <div className="p-[8px] rounded-[16px] shadow-md relative">
          <img alt=""  className="rounded-[16px] w-[240px] h-[180px]" src={imageCard} />
          <div className="absolute top-[20px] right-[20px] z-[10]">
            <img alt=""  src={heart}></img>
          </div>
        </div>
        <div className="p-[8px] rounded-[16px] shadow-md">
          <img alt=""  className="rounded-[16px] w-[240px] h-[180px]" src={imageCard} />
        </div>
      </div> */}
      <div className="shadow-md h-full rounded-[8px] flex flex-col items-center justify-center">
        <img alt="" src={emptyIcon} />
        <div className="text-[18px]">Not Found</div>
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        </div>
        <div
          onClick={handleExplore}
          className="bg-[#5B1DEE] mt-[10px] text-white px-[14px] py-[8px] w-max rounded-[16px] cursor-pointer hover:shadow-[-1px_6px_10px_0_rgba(93,0,207,0.5)] hover:-translate-y-[3px]"
        >
          Explore NFTs
        </div>
      </div>
    </div>
  );
};
