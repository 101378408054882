// import arrow from "../assets/images/PropertyAddress/Group.png";
// import arrow1 from "../assets/images/Arrow1.svg";
import radio from "../../assets/images/tick-circle.png";
import radio1 from "../../assets/images/tick-circle1.png";
import NUSD from "../../assets/images/NUSD.svg";
// import avatar from "../../assets/images/Ellipse 868.png";
import ProfileIcon from "../../assets/images/Profile.svg";

// import calendar from "../assets/images/Frame 10000014601.png";
// import calendarsm from "../assets/images/Calendar,Schedule.png";

// import image from "../assets/images/image.png";
import progress from "../../assets/images/progress.png";
// import checked from "../assets/images/Frame 1000005306 (1).png";
// import unchecked from "../assets/images/Frame 1000005306.png";
// import close from "../assets/images/Close1.png";
// import processing from "../assets/images/Frame 10000052511.png";
// import warning from "../assets/images/Group 1000001279.png";

// import icon from "../assets/images/Frame 1000005306 (2).png";

// import closemini from "../assets/images/close-mini.png";

// import StarRatings from "react-star-ratings";
import { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PaymentTimeline } from "../../components/Rental/PaymentTimeline";
import { PropertyAddress } from "../../components/Rental/PropertyAddress";
import {
  SelectionGroup,
  SelectionItem,
} from "../../components/Global/Selection";
import { ImageView } from "../../components/Global/ImageView";
import { PropertyReserve } from "../../components/Rental/PropertyReserve";
import { useDispatch } from "react-redux";
import { setPage } from "../../Actions/PageSlice";
import { setAmountToPay } from "../../Actions/RentSlice";
import { executeContract } from "../../components/functional/InteractToContract";
import { setSomeoneToContact } from "../../Actions/MessageSlice";
import { setHeaderMode } from "../../Actions/HeaderSlice";
import { setDashboardMode } from "../../Actions/DashboardSlice";
import {
  goToInbox,
  truncateWalletAddress,
} from "../../components/functional/Control";
import { setChatNft, setChatReceiver } from "../../Actions/ChatSlice";
import { setEventToEmit } from "../../Actions/NotificationSlice";
import { diffToUTC } from "../../components/functional/GetCurrentUTCTime";
import { Testnet } from "@nibiruchain/nibijs";
import { setChat } from "../../Actions/ChatSlice";
import { getChatId } from "../../components/functional/Api";
import { addDays } from "date-fns";

const testNet = Testnet(1);

export const DetailPayment = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const mode = useSelector((state) => state.header.mode);
  const submode = useSelector((state) => state.header.submode);

  const dates = useSelector((state) => state.rent.period);
  const totalPrice = useSelector((state) => state.rent.totalPrice);
  const [receiverAvatar, setReceiverAvatar] = useState();
  const receiverProfile = useSelector((state) => state.chat.receiverProfile);

  const nft = useSelector((state) => state.nft.currentNFT);
  const pricePerMonth = useSelector(
    (state) =>
      state.nft.allNFTs[nft]?.longtermrental_info?.landlord?.price_per_month
  );

  const metaData = useSelector((state) => state.nft.allNFTs[nft]?.metaData);
  const owner = useSelector((state) => state.chat.receiver);

  const currentNFT = useSelector((state) => state.nft.allNFTs[nft]);
  const token_id = useSelector((state) => state.nft.allNFTs[nft]?.token_id);

  const pricePerDay = useSelector(
    (state) => state.nft.allNFTs[nft]?.shorttermrental_info?.price_per_day
  );

  const deposit_amount = useSelector((state) => state.rent.amountToPay);
  const renting_period = useSelector((state) => state.rent.period);
  const walletEx = useSelector((state) => state.auth.wallet);
  const account = useSelector((state) => state.auth.account);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const guests = useSelector((state) => state.search.guests);
  const guests = 1;

  useEffect(() => {
    setReceiverAvatar(receiverProfile?.Avatar);
  }, [receiverProfile]);

  const handleReserveNow = async () => {
    let message;
    let res;
    if (mode === 1 && submode === 0)
      message = {
        set_reservation_for_long_term: {
          token_id: token_id,
          isreserved: true,
          deposit_amount: deposit_amount,
          deposit_denom: "unibi",
          renting_period: [
            renting_period[0].toString(),
            renting_period[1].toString(),
          ],
        },
      };
    if (mode === 1 && submode === 1) {
      // console.log(
      //   Math.floor(new Date(renting_period[0]).getTime() / 1000).toString()
      // );
      const diffsec = await diffToUTC();
      const checkin =
        Math.floor(new Date(renting_period[0]).getTime() / 1000) + diffsec;
      const checkout =
        Math.floor(new Date(renting_period[1]).getTime() / 1000) + diffsec;
      message = {
        set_reservation_for_short_term: {
          token_id: token_id,
          renting_period: [checkin.toString(), checkout.toString()],
        },
      };

      res = await executeContract(
        testNet.chainId,
        testNet.endptTm,
        process.env.REACT_APP_RENTAL_SMART_CONTRACT,
        dispatch,
        token_id,
        owner,
        message,
        account,
        walletEx,
        [
          {
            amount: Math.round(deposit_amount).toString(),
            denom: "unibi",
          },
        ]
      );
    }

    if (res) {
      dispatch(setHeaderMode({ mode: 4, submode: 4 }));
      dispatch(setDashboardMode(1));
      // dispatch(setChatReceiver(owner));
      // dispatch(setChatNft(currentNFT));
      // goToInbox(owner, mode, submode, dispatch, token_id);
    }

    // dispatch(setPage("confirmed"));
  };

  // useEffect(() => {
  //   dispatch(setAmountToPay(totalPrice));
  // }, []);

  return (
    <div className="pt-[20px] bg-white w-full space-y-[20px]">
      <div className="w-[1200px] mx-auto">
        <PropertyAddress />
      </div>

      <div className="w-[1200px] mx-auto grid grid-cols-2 gap-[20px]">
        <div className="shadow-md p-[16px] space-y-[24px]">
          <div className="space-y-[8px]">
            <div className="font-semibold text-[24px]">Your Stay</div>
            <div className="flex justify-between items-center">
              <div>
                <div className="text-[18px]">Dates</div>
                <div className="text-[#5A5A5A]">
                  {new Date(dates[0])?.toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}{" "}
                  -{" "}
                  {new Date(dates[1])?.toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
                </div>
              </div>
              {/* <div className="underline">Edit</div> */}
            </div>
            <div className="flex justify-between items-center">
              <div>
                <div className="text-[18px]">Guests</div>
                <div className="text-[#5A5A5A]">
                  {guests}
                  {/* {guests.adults + guests.children} */}
                </div>
              </div>
              {/* <div className="underline">Edit</div> */}
            </div>
          </div>

          {/* <div className="space-y-[8px]">
            <div className="font-semibold text-[24px]">
              Documents need to be completed after payment
            </div>
            <div className="flex gap-[10px]">
              <div className="w-max">
                <img alt=""  src={checked}></img>
                <div className="w-[4px] h-[32px] bg-[#EFE8FD] mx-auto"></div>
                <img alt=""  src={unchecked}></img>
                <div className="w-[4px] h-[32px] bg-[#EFE8FD] mx-auto"></div>
                <img alt=""  src={unchecked}></img>
                <div className="w-[4px] h-[32px] bg-[#EFE8FD] mx-auto"></div>
                <img alt=""  src={unchecked}></img>
              </div>
              <div className="space-y-[36px]">
                <div>1. ID</div>
                <div className="text-[#959595]">2. Ejari</div>
                <div className="text-[#959595]">3. DLD</div>
                <div className="text-[#959595]">4. Mint NFT Module </div>
              </div>
            </div>
          </div> */}

          {mode === 1 && submode === 0 && (
            <>
              <div className="bg-[#D9D9D9] w-full h-[1px]"></div>
              <div className="space-y-[8px]">
                <div className="font-semibold text-[24px]">
                  Choose how to pay
                </div>
                <SelectionGroup defaultItem={0} SelectedItemMask="shadow-md">
                  <SelectionItem
                    SelectedItem={
                      <div className="flex items-center gap-[16px] p-[12px] rounded-[16px]">
                        <img alt="" src={radio1}></img>
                        <div>
                          <div className="text-[18px]">Pay in full</div>
                          <div className="text-[#5A5A5A]">
                            Pay the total ({totalPrice} NUSD) now and you’re all
                            set.
                          </div>
                        </div>
                      </div>
                    }
                    UnselectedItem={
                      <div
                        className="flex items-center gap-[16px] p-[12px] rounded-[16px]"
                        onClick={() => {
                          dispatch(setAmountToPay(totalPrice));
                        }}
                      >
                        <img alt="" src={radio}></img>
                        <div>
                          <div className="text-[18px]">Pay in full</div>
                          <div className="text-[#5A5A5A]">
                            Pay the total ({totalPrice} NUSD) now and you’re all
                            set.
                          </div>
                        </div>
                      </div>
                    }
                  />
                  <SelectionItem
                    SelectedItem={
                      <div className="flex items-center gap-[16px] p-[12px] rounded-[16px]">
                        <img alt="" src={radio1}></img>
                        <div>
                          <div className="text-[18px]">Pay Monthly</div>
                          <div className="text-[#5A5A5A]">
                            Pay for one month ({pricePerMonth} NUSD) now and you
                            can rent.
                          </div>
                        </div>
                      </div>
                    }
                    UnselectedItem={
                      <div
                        className="flex items-center gap-[16px] p-[12px] rounded-[16px]"
                        onClick={() => {
                          dispatch(setAmountToPay(pricePerMonth));
                        }}
                      >
                        <img alt="" src={radio}></img>
                        <div>
                          <div className="text-[18px]">Pay Monthly</div>
                          <div className="text-[#5A5A5A]">
                            Pay for one month ({pricePerMonth} NUSD) now and you
                            can rent.
                          </div>
                        </div>
                      </div>
                    }
                  />
                </SelectionGroup>
              </div>
            </>
          )}

          {/* <div className="p-[24px] rounded-[16px] shadow-md space-y-[8px]">
            <div>Bid price</div>
            <div className="globalInputForm rounded-[8px] flex w-full px-[12px] py-[9px] gap-[6px] items-center text-[18px]">
              <div className="text-[#B6B6B6]">NUSD</div>
              <input className="w-full text-[24px]"></input>
              <div>($49.83)</div>
            </div>
          </div> */}

          <div className="bg-[#D9D9D9] w-full h-[1px]"></div>
          <div className="space-y-[16px]">
            <div className="flex items-center justify-between">
              <div className="font-semibold text-[24px]">Pay with</div>
              <img alt="" src={NUSD}></img>
            </div>
            <div className="px-[20px] py-[10px] flex items-center justify-between shadow-md rounded-[6px]">
              <div className="text-[#AAAAAA]">Pay with</div>
              <div className="flex items-center gap-[6px]">
                <img alt="" src={NUSD}></img>
                <div className="text-[#AAAAAA]">NUSD</div>
              </div>
            </div>
          </div>
          <div className="bg-[#D9D9D9] w-full h-[1px]"></div>
          <div className="space-y-[16px]">
            {/* <div className="font-semibold text-[24px]">Required for your trip</div> */}
            {/* <div className="text-[18px]">Message the host</div> */}
            <div className="text-[18px]">
              Message the {mode === 1 && submode === 0 ? "Landlord" : "Host"}
            </div>
            <div className="text-[#5A5A5A]">
              Feel free to add a comment or questions to your reservation.
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-[10px]">
                <div className="my-[10px] ml-[10px] p-[4px] rounded-full border-[1px] border-[#cdcdcd]">
                  <img
                    alt=""
                    src={receiverAvatar || ProfileIcon}
                    className="rounded-full w-[50px]"
                  />
                </div>
                <div>
                  <div className="font-semibold">
                    {truncateWalletAddress(owner, 5, 4)}
                  </div>
                  {/* <div className="text-[#5A5A5A]">Joined in 2023</div> */}
                </div>
              </div>

              <div
                className="px-[24px] py-[8px] cursor-pointer bg-[#5B1DEE] text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] hover:shadow-[-1px_6px_10px_0_rgba(93,0,207,0.5)] hover:-translate-y-[3px]"
                onClick={async () => {
                  dispatch(setChatReceiver(owner));
                  dispatch(setChatNft(nft));
                  dispatch(
                    setChat(await getChatId(account, owner, nft, "short"))
                  );
                  goToInbox(owner, mode, submode, dispatch, token_id);
                }}
              >
                Go to Inbox
              </div>
            </div>
            {/* <div className="globalInputForm rounded-[8px] px-[20px] py-[10px]">
              <textarea className="w-full"></textarea>
            </div> */}
          </div>
          <div className="bg-[#D9D9D9] w-full h-[1px]"></div>
          <div className="space-y-[16px]">
            <div className="font-semibold text-[24px]">Cancellation policy</div>
            <p className="text-[#5A5A5A]">
              <b className="text-[#202020] mr-[10px]">
                Free cancellation before Approval
              </b>
              Cancel before check-in date for a partial refund.
            </p>

            <div>
              {currentNFT?.shorttermrental_info?.cancellation.map((item) => {
                return (
                  <div>
                    {item.percentage} % refundable until{" "}
                    <b>
                      {addDays(
                        new Date(dates[0]),
                        -item.deadline
                      ).toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })}
                    </b>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="bg-[#D9D9D9] w-full h-[1px]"></div>
          <div className="space-y-[16px]">
            <div className="font-semibold text-[24px]">Ground rules</div>
            <div className="text-[#5A5A5A]">
              We ask every guest to remember a few simple things about what
              makes a great guest.
            </div>
            <div>{metaData?.GroundRules}</div>
            {/* <ol>
              <li>Follow the house rules.</li>
              <li>Treat your Host’s home like your own.</li>
            </ol> */}
          </div>
          <div className="bg-[#D9D9D9] w-full h-[1px]"></div>
          {/* <div className="flex items-start gap-[20px]">
            <img alt=""  src={calendar}></img>
            <div>
              <div className="font-semibold text-[18px]">
                Your reservation won’t be confirmed until the Host accepts your
                request (within 24 hours)
              </div>
              <div className="text-[#5A5A5A]">
                You won’t be charged until then.
              </div>
            </div>
          </div>
          <div className="bg-[#D9D9D9] w-full h-[1px]"></div> */}
          {/* <p className="text-[#5A5A5A]">
            By selecting the button below, I agree to the
            <b className="text-[#202020] font-semibold">
              Host’s House Rules, Ground rules for guests, Airbnb’s Rebooking
              and Refund Policy,
            </b>
            and that
            <b className="text-[#202020] font-semibold">
              Coded Estate can charge my payment method
            </b>
            if I’m responsible for damage. I agree to pay the total amount shown
            if the Host accepts my booking request. Payment Terms between you
            and Airbnb Payments Luxembourg S.A.
          </p> */}
        </div>

        <div className="flex flex-col">
          <div className="shadow-md p-[24px] h-max rounded-[8px] space-y-[24px]">
            <div className="p-[16px] space-y-[40px] rounded-[8px] shadow-md">
              <div className="flex items-center gap-[20px]">
                {/* <img alt="" 
                  src={image}
                  className="w-[166px] h-[166px] rounded-[8px]"
                ></img> */}
                <div className="w-[50%]">
                  <ImageView counts={1} />
                </div>
                <div className="space-y-[8px]">
                  <div className="text-[#5a5a5a] text-[20px]">
                    {metaData?.Name}
                  </div>
                  <div className="">{metaData?.Address}</div>
                  {/* <div className="flex gap-[10px]">
                    <StarRatings
                      numberOfStars={1}
                      rating={5}
                      starRatedColor="#F8BC30"
                      starDimension="20"
                    ></StarRatings>
                    <div>4.95</div>
                    <div className="text-[#5a5a5a]">(41 reviews)</div>
                  </div>
                  <div className="font-semibold">Super Host</div> */}
                </div>
              </div>
              <div className="bg-[#D9D9D9] w-full h-[1px]"></div>
              <PropertyReserve mode={"short"} />
            </div>
            <div
              className="px-[20px] py-[12px] text-white text-center bg-[#5B1DEE] rounded-[16px] shadow-md cursor-pointer"
              onClick={() => {
                handleReserveNow();
              }}
            >
              Reserve Now
            </div>
            <div className="text-center text-[#B6B6B6] mt-[30px]">
              You will not be charged yet. You will be required to sign a
              message from your wallet to confirm the reservation
            </div>
            <Modal show={show} onHide={handleClose} centered>
              <Modal.Body>
                <div className="w-[400px]">
                  <div className="bg-[#F6F6F6] flex items-center justify-between rounded-t-[8px] px-[20px] py-[20px]">
                    <div className="font-semibold text-[18px]">
                      Confirmation Bid
                    </div>
                  </div>
                  <div className="px-[20px] py-[20px] space-y-[10px] border-b-[1px] border-[#D5D5D5]">
                    <div className="flex items-center justify-between">
                      <div className="font-semibold">Checking your ID</div>
                      <Spinner animation="border" role="status" size="sm">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                    <div className="text-[#959595]">
                      Please wait for several minutes while system check your ID
                      for your verified profile.
                    </div>
                  </div>
                  <div className="px-[20px] py-[20px] space-y-[10px] border-b-[1px] border-[#D5D5D5]">
                    <div className="flex items-center justify-between">
                      <div className="font-semibold">Checking your ID</div>
                      <img alt="" src={progress}></img>
                    </div>
                  </div>
                  <div className="px-[20px] py-[20px] space-y-[10px] border-b-[1px] border-[#D5D5D5]">
                    <div className="flex items-center justify-between">
                      <div className="font-semibold">DLD</div>
                      <img alt="" src={progress}></img>
                    </div>
                  </div>
                  <div className="px-[20px] py-[20px] space-y-[10px] border-b-[1px] border-[#D5D5D5]">
                    <div className="flex items-center justify-between">
                      <div className="font-semibold">Mint NFT Module </div>
                      <img alt="" src={progress}></img>
                    </div>
                  </div>
                </div>
              </Modal.Body>

              {/* <Modal.Body>
              <div className="w-[400px]">
                <div className="bg-[#F6F6F6] flex items-center justify-between rounded-t-[8px] px-[20px] py-[10px]">
                  <div className="font-semibold text-[18px]">Confirmation Bid</div>
                  <img alt=""  src={close} onClick={handleClose}></img>
                </div>
                <img alt=""  src={processing} className="mx-auto my-[30px]"></img>
                <div className="text-center w-[70%] mx-auto ">
                  Bid is confirmedF
                </div>
                <div className="w-full px-[20px]">
                  <div className="bg-[#5B1DEE] py-[12px] rounded-[16px] text-white my-[30px] w-full text-center">
                    Done
                  </div>
                </div>
              </div>
            </Modal.Body> */}
            </Modal>
          </div>
          {mode === 1 && submode === 0 && <PaymentTimeline />}
        </div>
      </div>
    </div>
  );
};
