import VerifiedUserIcon from "../../assets/images/Profile/VerifiedUserIcon.svg";
import { useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import close from "../../assets/images/Close.png";
import { useNavigate } from "react-router-dom";
import { Reviews } from "../../components/Reviews";

export const User = () => {
  const [preview, setPreview] = useState(null);
  const inputRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [src, setSrc] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [bio, setBio] = useState("");
  const [name, setName] = useState({
    firstName: "",
    lastName: "",
  });
  const [company, setCompany] = useState(null);
  const [emiratesId, setEmiratesId] = useState();
  const account = useSelector((state) => state.auth.account);
  const [file, setFile] = useState(null);

  const profile = useSelector((state) => state.chat.receiverProfile);
  const wallet = useSelector((state) => state.chat.receiver);
  const [reviewData, setReviewData] = useState();

  useEffect(() => {
    if (profile) {
      setPreview(profile.Avatar);
      const name = profile.Name.split("/");
      setName({
        firstName: name[0],
        lastName: name[1],
      });
      setBio(profile.Bio);
      setReviewData(profile.Reviews);
    }
  }, [profile]);

  return (
    <div className="pt-[6px] pb-[6px] bg-[#F2F2F2] w-full h-max">
      <div className="w-[60%] max-w-[1200px] mx-auto bg-white shadow-md min-h-[calc(100vh-72px)] rounded-[6px] p-[24px] ">
        <div className="flex gap-[20px] items-center">
          <img
            alt=""
            src={close}
            onClick={() => navigate(-1)}
            className="cursor-pointer"
          ></img>

          <div className="text-[24px]">Profile</div>
        </div>
        <div className="flex gap-[40px]">
          <div className="min-w-[30%] flex flex-col justify-center items-center h-max">
            <img
              alt=""
              src={
                preview ||
                "https://www.signivis.com/img/custom/avatars/member-avatar-01.png"
              }
              className="cursor-pointer mt-[50px] rounded-full w-[160px] border-[2px] border-[#dddddd] shadow-md"
            />
            <div className="shadow-lg mt-[-20px] flex gap-[6px] items-center text-white px-[12px] bg-[#5B1DEE] rounded-[400px] py-[2px] w-max mx-auto">
              <img alt="" src={VerifiedUserIcon}></img>
              <div className="text-[14px]">Verified</div>
            </div>
          </div>

          <div className="space-y-[20px] bg-blur mt-[50px]">
            <div className="flex items-start gap-[20px] w-[400px]">
              <div className="w-full space-y-[20px]">
                <div className="font-semibold text-[18px]">First name</div>
                <div className="globalInputForm rounded-[8px] p-[10px] ">
                  <input
                    type="text"
                    className="w-full"
                    value={name.firstName}
                    onChange={(e) =>
                      setName({ ...name, firstName: e.target.value })
                    }
                    disabled
                    placeholder="First Name"
                  />
                </div>
              </div>
              <div className="w-full space-y-[20px]">
                <div className="font-semibold text-[18px]">Last name</div>
                <div className="globalInputForm rounded-[8px] p-[10px]">
                  <input
                    type="text"
                    className="w-full"
                    value={name.lastName}
                    onChange={(e) =>
                      setName({ ...name, lastName: e.target.value })
                    }
                    disabled
                    placeholder="Last Name"
                  />
                </div>
              </div>
            </div>

            <div className="w-[400px] space-y-[20px]">
              <div className="font-semibold text-[18px]">Wallet</div>
              <div className="globalInputForm rounded-[8px] p-[10px] ">
                <input
                  type="text"
                  className="w-full"
                  value={wallet}
                  onChange={(e) => setCompany(e.target.value)}
                  disabled
                  placeholder=""
                />
              </div>
            </div>
            <div className="w-[400px] space-y-[20px]">
              <div className="font-semibold text-[18px]">Company</div>
              <div className="globalInputForm rounded-[8px] p-[10px] ">
                <input
                  type="text"
                  className="w-full"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  disabled
                  placeholder=""
                />
              </div>
            </div>

            <div className="font-semibold text-[18px]">Bio</div>
            <div className="globalInputForm rounded-[8px] p-[10px] w-[400px]">
              <textarea
                className="w-full min-h-[100px]"
                placeholder=""
                disabled
                value={bio}
                onChange={(e) => setBio(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
        <Reviews reviewData={reviewData} />
      </div>
    </div>
  );
};
