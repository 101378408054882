import logo from "../assets/images/Logo Coded-Estate.svg";

import logowhite from "../assets/images/landingpage/logo.svg";
import plus from "../assets/images/ConnectWallet/Close.svg";

import map from "../assets/images/landingpage/map.png";

import home from "../assets/images/landingpage/house-search.svg";
import home1 from "../assets/images/landingpage/house-search.1.svg";

import homeCanada from "../assets/images/landingpage/image 2134.svg";
import homeGreenland from "../assets/images/landingpage/image 2138.svg";
import homeUS from "../assets/images/landingpage/image 2135.svg";
import homeBrazil from "../assets/images/landingpage/image 2133.svg";
import homeFinland from "../assets/images/landingpage/image 2137.svg";
import homeRussia from "../assets/images/landingpage/image 2132.svg";
import homeIndonesia from "../assets/images/landingpage/image 2136.svg";

import Card from "../assets/images/landingpage/Card.png";
import imageSVG from "../assets/images/landingpage/Group 1000004874.svg";
import imagePNG from "../assets/images/landingpage/asset-2 1.png";

import icon from "../assets/images/landingpage/icon.svg";
import icon1 from "../assets/images/landingpage/icon (1).svg";
import icon2 from "../assets/images/landingpage/icon (2).svg";
import icon3 from "../assets/images/landingpage/icon (3).svg";
import perpsIcon from "../assets/images/landingpage/iconPerps.svg";

import real from "../assets/images/landingpage/home-house-real-estate-plan.svg";
import hidd from "../assets/images/landingpage/Group.svg";
import remo from "../assets/images/landingpage/Crypto, Currency, Bitcoin, Ethereum.svg";
import frict from "../assets/images/landingpage/store-certificate-checkmark.svg";
import fully from "../assets/images/landingpage/home-delivery.svg";

// import email from "../assets/images/landingpage/email-mail-style-4-big.png";
// import send from "../assets/images/landingpage/send-letters-mail-fast.png";

import discord from "../assets/images/landingpage/discord-big.svg";
import twitter from "../assets/images/landingpage/Twitter.svg";
import mobileIcon from "../assets/images/landingpage/Close.svg";
import downArrow from "../assets/images/landingpage/down-arrow.svg";

import { ConnectWallet } from "../components/Page/ConnectWallet";

import { Modal } from "react-bootstrap";
import {
  useState,
  useEffect,
  Suspense,
  useRef,
  useSyncExternalStore,
} from "react";

import { useSelector, useDispatch } from "react-redux";
import { connected } from "../Actions/AuthSlice";
import { useNavigate } from "react-router-dom";
import NetworkManager from "@xdefi/wallets-connector";

import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import { setAllNFTs, updateNFT } from "../Actions/NFTSlice";
// import {
//   getAllTokenIds,
//   getAllInfo,
// } from "../components/functional/InteractToIPFS";
import {
  getAllTokenIds,
  getAllInfo,
} from "../components/functional/InteractToContract";

import "../LandingPage.css";
import { updateProperty } from "../Actions/PropertySlice";

import { queryContract } from "../components/functional/InteractToContract";
import Lottie from "lottie-react";
import codedEstateLottie from "../assets/animations/codedEstateLottie.json";
import MapLottie from "../assets/animations/maps.json";
// import MapLottie from "../assets/animations/detail page/progress-bar(investment).json";
import { setModal } from "../Actions/AuthSlice";
import {
  Fade,
  Bounce,
  Zoom,
  AttentionSeeker,
  Flip,
  Roll,
  JackInTheBox,
  Slide,
} from "react-awesome-reveal";
import { TransitionCompo } from "../components/TransitionCompo";
import { setPage } from "../Actions/PageSlice";
import { getMetadata } from "../components/functional/InteractToIPFS";
import { Join } from "../components/Global/Join";
import { Mainnet } from "@nibiruchain/nibijs";

const mainNet = Mainnet();

export const LandingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const show = useSelector((state) => state.auth.connectModal);

  const [flag, setFlag] = useState([1, 0, 0]);
  const [headlineText, setHeadlineText] = useState();

  const [mobileFlag, setMobileFlag] = useState(false);

  const getAllAssets = async () => {
    const tokens = await getAllTokenIds();
    for (let i = 0; i < tokens.length; i++) {
      const item = await getAllInfo(
        process.env.REACT_APP_RENTAL_SMART_CONTRACT,
        tokens[i]
      );
      dispatch(updateNFT(item));
    }
  };

  const getFractionalizedNFTs = async () => {
    const tokenIds = [
      "14",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
    ];

    for (let i = 0; i < tokenIds.length; i++) {
      const messageForItem = {
        all_nft_info: {
          token_id: tokenIds[i],
        },
      };

      const res = await queryContract(
        process.env.REACT_APP_FRACTIONALIZED_SMART_CONTRACT,
        messageForItem,
        mainNet.endptTm
      );
      let totalCounts = 0;

      for (let j = 0; j < res.investors.length; j++) {
        totalCounts += res.investors[j].allocations;
      }

      const metaData = await getMetadata(res.token_uri);
      if (res.investors.length > 1750)
        dispatch(
          updateProperty({
            token_id: tokenIds[i],
            ...res,
            value: totalCounts * 100000000,
            metaData: metaData,
          })
        );
      else
        dispatch(
          updateProperty({
            token_id: tokenIds[i],
            ...res,
            metaData: metaData,
          })
        );
    }
  };

  const [loading, setLoading] = useState(true);
  const connecting = useSelector((state) => state.auth.connecting);

  const [texts, setTexts] = useState(["Rent", "Buy", "Sell", "Own"]);

  useEffect(() => {
    getFractionalizedNFTs();
    // getAllAssets();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const temp = texts;
      temp.push(temp.shift());
      setTexts(temp);
      setHeadlineText(temp[0]);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setJoinModal(true);
    }, 10000);
  }, []);

  const [joinModal, setJoinModal] = useState(false);

  return (
    <div className="font-semibold">
      <div className="w-full h-[100vh] flex" hidden={!loading}>
        <Lottie
          className="w-[300px] m-auto"
          animationData={codedEstateLottie}
          loop={false}
          onComplete={() => setLoading(false)}
        />
      </div>

      <Join show={joinModal} handleClose={() => setJoinModal(false)} />

      <div className="w-full bg-white" hidden={loading || connecting}>
        <div className="flex items-center justify-between py-[14px] sm:px-[70px] px-[10px] fixed top-0 w-full z-[1000] h-max bg-white">
          <img alt="" src={logo} width={140}></img>
          <div className="sm:flex hidden items-center gap-[32px]">
            <div
              onClick={() => {
                document.getElementById("home").scrollIntoView();
              }}
              className="cursor-pointer hover:text-[#5D00CF]"
            >
              Home
            </div>
            {/* <div
              onClick={() => {
                document.getElementById("howitworks").scrollIntoView();
              }}
              className="cursor-pointer hover:text-[#5D00CF]"
            >
              How it Works
            </div>
            <div>
              <a
                href="https://coded-estate.gitbook.io/coded-estate/"
                target="blank"
                className="cursor-pointer hover:text-[#5D00CF]"
              >
                Documents
              </a>
            </div> */}

            <div
              className="cursor-pointer hover:text-[#5D00CF]"
              onClick={() => {
                // dispatch(setPage("join"));
                // navigate("/join");
                setJoinModal(true);
              }}
            >
              Join the Waitlist
            </div>

            <div
              className="cursor-pointer hover:text-[#5D00CF]"
              onClick={() => {
                navigate("/claim");
              }}
            >
              Claim Testate
            </div>

            {/* <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip className="margin">coming soon</Tooltip>}
            > */}
            <div
              className="cursor-pointer px-[18px] truncate py-[10px] bg-[#5B1DEE] active:scale-[0.95] text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] text-center font-semibold hover:shadow-[-1px_6px_10px_0_rgba(93,0,207,0.5)] hover:-translate-y-[3px]"
              onClick={() => {
                dispatch(setModal());
              }}
            >
              Connect Wallet
            </div>
            {/* </OverlayTrigger> */}
          </div>
          <div className="sm:hidden block">
            <img alt="" src={mobileIcon} onClick={() => setMobileFlag(true)} />
            <Modal show={mobileFlag} onHide={() => setMobileFlag(false)}>
              <Modal.Body>
                <div
                  onClick={() => setMobileFlag(false)}
                  className="p-[20px] h-[98vh] flex flex-col"
                >
                  <div className="flex items-center w-full justify-between">
                    <img alt="" src={logo} />
                    <img
                      alt=""
                      src={plus}
                      className="w-[40px]"
                      onClick={() => setMobileFlag(false)}
                    />
                  </div>
                  <div className="h-full flex flex-col w-full justify-between">
                    <div className="text-center mt-[20px] space-y-[10px]">
                      <div
                        onClick={() => {
                          document.getElementById("home").scrollIntoView();
                        }}
                      >
                        Home
                      </div>
                      <hr />
                      <div
                        onClick={() => {
                          document
                            .getElementById("howitworks")
                            .scrollIntoView();
                        }}
                      >
                        How it Works
                      </div>
                      <hr />
                      <div>
                        <a
                          href="https://coded-estate.gitbook.io/coded-estate/"
                          target="blank"
                        >
                          Documents
                        </a>
                      </div>
                    </div>
                    <div
                      className="cursor-pointer px-[18px] py-[10px] bg-[#5B1DEE] text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] w-full text-center rounded-[16px] font-semibold hover:shadow-[-1px_6px_10px_0_rgba(93,0,207,0.5)] hover:-translate-y-[3px]"
                      onClick={() => {
                        dispatch(setModal());
                      }}
                    >
                      Connect Wallet
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>

        <div className="relative">
          <div id="home" className="mt-[70px] relative w-full flex">
            <Lottie
              className="sm:mt-[-10vw] mt-[27vh]"
              animationData={MapLottie}
              onComplete={() => {
                setTimeout(() => {
                  setLoading(false);
                }, 1000);
              }}
            />
            {/* <>
              <img alt="" 
                src={homeCanada}
                className="absolute top-[8vw] left-[10vw] rounded-[50%] z-[100] transition ease-in-out hover:scale-150 duration-300 hover:-translate-y-1"
                
              ></img>
              <img alt="" 
                src={homeUS}
                className="absolute top-[16vw] left-[20vw] rounded-[50%] z-[100] transition ease-in-out hover:scale-150 duration-300 hover:-translate-y-1"
                
              ></img>
              <img alt="" 
                src={homeGreenland}
                className="absolute top-[4vw] left-[35vw] rounded-[50%] z-[100] transition ease-in-out hover:scale-150 duration-300 hover:-translate-y-1"
                
              ></img>
              <img alt="" 
                src={homeBrazil}
                className="absolute top-[32vw] left-[30vw] rounded-[50%] z-[100] transition ease-in-out hover:scale-150 duration-300 hover:-translate-y-1"
                
              ></img>
              <img alt="" 
                src={homeFinland}
                className="absolute top-[8vw] left-[62vw] rounded-[50%] z-[100] transition ease-in-out hover:scale-150 duration-300 hover:-translate-y-1"
                
              ></img>
              <img alt="" 
                src={homeRussia}
                className="absolute top-[7vw] left-[75vw] rounded-[50%] z-[100] transition ease-in-out hover:scale-150 duration-300 hover:-translate-y-1"
                
              ></img>
              <img alt="" 
                src={Card}
                className="absolute rounded-[16px] top-[10vw] left-[77vw] z-[90] transition ease-in-out duration-300 hover:-translate-y-[20px] hover:z-[110]"
                
              ></img>
              <img alt="" 
                src={homeIndonesia}
                className="absolute top-[30vw] left-[74vw] rounded-[50%] z-[100] transition ease-in-out hover:scale-150 duration-300 hover:-translate-y-1"
                
              ></img>
            </> */}

            <div className="absolute top-0 w-full h-[calc(100vh-70px)] flex flex-col justify-between z-[10]">
              <div className="mx-auto mt-[12vh] sm:mt-[30vh]" hidden={loading}>
                <div className="tracking-wide flex flex-col items-center sm:flex-row w-full text-center sm:text-[54px] justify-center text-[32px] mx-auto text-[#5B1DEE] rounded-[12px]">
                  <div>Easiest way to</div>

                  <div className="flex items-center">
                    <div
                      className="sm:w-[120px] sm:mr-[10px] w-[80px] text-center"
                      style={{
                        // opacity: opacity[0],
                        // transition: "opacity 1s",
                        animation: "flow 3s infinite",
                      }}
                    >
                      {headlineText}
                    </div>
                    <div className="flex">
                      <Zoom
                        cascade
                        // direction="up"
                        delay={2000}
                        duration={1000}
                        damping={0.2}
                      >
                        {"Real".split("").map((char, i) => {
                          return (
                            <div className={char === "l" ? "mr-1" : ""}>
                              {char}
                            </div>
                          );
                        })}
                      </Zoom>
                    </div>
                  </div>

                  <div className="flex">
                    <Zoom
                      cascade
                      // direction="up"
                      duration={1000}
                      delay={2000}
                      damping={0.2}
                    >
                      {"Estate".split("").map((char, i) => {
                        return (
                          <div className={char === "l" ? "mr-1" : ""}>
                            {char}
                          </div>
                        );
                      })}
                    </Zoom>
                  </div>
                </div>

                {/* <Fade cascade>
                  <div className="globalInputForm rounded-[8px] p-[4px] pl-[16px] flex items-center sm:w-[40vw] w-[80vw] mx-auto gap-[12px] radius40 mt-[20px]">
                    <img alt=""  src={home} ></img>
                    <input
                      placeholder="Search properties"
                      className="w-full"
                    ></input>
                    <OverlayTrigger
                      placement={"bottom"}
                      overlay={<Tooltip>coming soon</Tooltip>}
                    >
                      <div className="bg-[#202020] p-[10px] rounded-[40px] cursor-pointer">
                        <img alt=""  src={home1} ></img>
                      </div>
                    </OverlayTrigger>
                  </div>

                  <div className="p-[24px] rounded-[12px] shadow-md sm:w-[40vw] w-[80vw] bg-white mt-[16px] ">
                    <div className="flex gap-[12px] justify-center">
                      <OverlayTrigger
                        placement={"bottom"}
                        overlay={<Tooltip>coming soon</Tooltip>}
                      >
                        <div className="cursor-pointer px-[18px] py-[10px] bg-[#5B1DEE] text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-full font-semibold hover:shadow-[-1px_6px_10px_0_rgba(120,60,20,0.5)] hover:-translate-y-[3px]">
                          I'm Buyer
                        </div>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement={"bottom"}
                        overlay={<Tooltip>coming soon</Tooltip>}
                      >
                        <div className="cursor-pointer px-[18px] py-[10px] bg-[#5B1DEE] text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-full font-semibold hover:shadow-[-1px_6px_10px_0_rgba(120,60,20,0.5)] hover:-translate-y-[3px]">
                          I'm Owner
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement={"bottom"}
                        overlay={<Tooltip>coming soon</Tooltip>}
                      >
                        <div className="cursor-pointer px-[18px] py-[10px] bg-[#5B1DEE] text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-full font-semibold hover:shadow-[-1px_6px_10px_0_rgba(120,60,20,0.5)] hover:-translate-y-[3px]">
                          I'm Renting
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement={"bottom"}
                        overlay={<Tooltip>coming soon</Tooltip>}
                      >
                        <div className="cursor-pointer px-[18px] py-[10px] bg-[#202020] text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-full font-semibold hover:shadow-[-1px_6px_10px_0_rgba(120,60,20,0.5)] hover:-translate-y-[3px]">
                          Just Looking
                        </div>
                      </OverlayTrigger>
                    </div>
                    <OverlayTrigger
                      placement={"bottom"}
                      overlay={
                        <Tooltip className="mt-[0px]">coming soon</Tooltip>
                      }
                    >
                      <div
                        className="cursor-pointer px-[18px] py-[10px] mt-[20px] w-max bg-[#202020] sm:w-[60%] mx-auto active:scale-[0.95] text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] text-center font-semibold hover:shadow-[-1px_6px_10px_0_rgba(80,80,80,0.5)] hover:-translate-y-[3px]"
                        onClick={() => {
                          dispatch(setModal());
                        }}
                      >
                        Connect Wallet
                      </div>
                    </OverlayTrigger>

                    <div
                      className="cursor-pointer px-[18px] py-[10px] mt-[20px] bg-[#5B1DEE] sm:w-[60%] w-max mx-auto active:scale-[0.95] text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] text-center font-semibold hover:shadow-[-1px_6px_10px_0_rgba(93,0,207,0.5)] hover:-translate-y-[3px]"
                      onClick={() => {
                        // dispatch(setPage("join"));
                        // navigate("/join");
                        setJoinModal(true);
                      }}
                    >
                      Join The Waitlist
                    </div>
                  </div>
                </Fade> */}
              </div>
              {/* <img alt=""  src={image}  className="mb-[30vh] sm:hidden" /> */}

              <img
                alt=""
                src={imagePNG}
                className="sm:hidden sm:absolute mb-[12vh] sm:w-[900px] bottom-0 right-0 transition ease-in-out duration-300"
              ></img>
              <div className="sm:hidden absolute w-full bottom-0">
                <img
                  alt=""
                  src={downArrow}
                  onClick={() => {
                    document.getElementById("howitworks").scrollIntoView();
                  }}
                  className="cursor-pointer w-[50px] mx-auto"
                />
              </div>
            </div>
          </div>

          <div className="w-full absolute top-[100vh]">
            <div
              id="howitworks"
              className="mx-auto text-center sm:w-[50%] w-[90vw] pt-[10vh]"
            >
              <Fade direction="down">
                <div className="sm:text-[48px] text-[32px]">How it Works</div>
              </Fade>
              <Fade direction="up">
                <div className="text-[#5A5A5A]">
                  Sign in and verify ownership in a few simply clicks and
                  experience the first decentralized platform that serves as an
                  all in one hub for travelers, investors, future home owners
                  and home owners
                </div>
              </Fade>
              <Fade cascade duration={1000} damping={0.1}>
                <div className="flex my-[50px] items-center gap-[10px]">
                  <img
                    alt=""
                    src={icon}
                    className="sm:mx-[80px] transition ease-in-out hover:-translate-y-[20px] duration-300 sm:w-max sm:h-max w-[100px] h-[100px]"
                  ></img>

                  <div className="m-auto space-y-[20px]">
                    <div className="flex items-center gap-[20px] text-[#5B1DEE]">
                      <div className="shadow-md w-[40px] h-[40px] rounded-full text-center text-[22px]">
                        1
                      </div>
                      <div className="sm:text-[32px] text-[20px]">Own</div>
                    </div>
                    <div className="sm:text-center text-left text-[#5A5A5A]">
                      Own premium Dubai real estate from as low as $50 when you
                      participate in a YieldEstate auction and earn up to 8%
                      Yield* on your fraction of ownership.
                    </div>
                  </div>
                </div>

                <div className="flex my-[50px] items-center gap-[10px]">
                  <div className="flex">
                    <div className="m-auto space-y-[20px]">
                      <div className="flex items-center gap-[20px] text-[#5B1DEE]">
                        <div className="shadow-md w-[40px] h-[40px] rounded-full text-center text-[22px]">
                          2
                        </div>
                        <div className="sm:text-[32px] text-[20px]">Travel</div>
                      </div>
                      <div className="sm:text-center text-left text-[#5A5A5A]">
                        Unlock travel experiences on the blockchain, find your
                        next home away from home and use cryptocurrency or
                        stable coins to visit unique destinations in the UAE.
                      </div>
                    </div>
                  </div>
                  <img
                    alt=""
                    src={icon1}
                    className="sm:mx-[80px] transition ease-in-out hover:-translate-y-[20px] duration-300 sm:w-max sm:h-max w-[100px] h-[100px]"
                  ></img>
                </div>
                <div className="flex my-[50px] items-center gap-[10px]">
                  <img
                    alt=""
                    src={icon2}
                    className="sm:mx-[80px] transition ease-in-out hover:-translate-y-[20px] duration-300 sm:w-max sm:h-max w-[100px] h-[100px]"
                  ></img>
                  <div className="flex">
                    <div className="m-auto space-y-[20px]">
                      <div className="flex items-center gap-[20px] text-[#5B1DEE]">
                        <div className="shadow-md w-[40px] h-[40px] rounded-full text-center text-[22px]">
                          3
                        </div>
                        <div className="sm:text-[32px] text-[20px]">
                          Buy & Sell
                        </div>
                      </div>
                      <div className="sm:text-center text-left text-[#5A5A5A]">
                        Verifying & Transferring ownership made easier than ever
                        before leveraging zero-knowledge proofs and smart
                        contracts. Sign in and complete verification to purchase
                        or sell homes in Dubai.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex my-[50px] items-center gap-[10px]">
                  <div className="flex">
                    <div className="m-auto space-y-[20px]">
                      <div className="flex items-center gap-[20px] text-[#5B1DEE]">
                        <div className="shadow-md w-[40px] h-[40px] rounded-full text-center text-[22px]">
                          4
                        </div>
                        <div className="sm:text-[32px] text-[20px]">Rental</div>
                      </div>
                      <div className="sm:text-center text-left text-[#5A5A5A]">
                        Extend your travel into home away from home by
                        leveraging on-chain real estate to rent homes more
                        seamless than ever before
                      </div>
                    </div>
                  </div>
                  <img
                    alt=""
                    src={icon3}
                    className="sm:mx-[80px] transition ease-in-out hover:-translate-y-[20px] duration-300 sm:w-max sm:h-max w-[100px] h-[100px]"
                  ></img>
                </div>

                <div className="flex my-[50px] items-center gap-[10px]">
                  <img
                    alt=""
                    src={perpsIcon}
                    className="sm:mx-[80px] transition ease-in-out hover:-translate-y-[20px] duration-300 sm:w-max sm:h-max w-[100px] h-[100px]"
                  ></img>
                  <div className="flex">
                    <div className="m-auto space-y-[20px]">
                      <div className="flex items-center sm:gap-[20px] gap-[0px] text-[#5B1DEE]">
                        <div className="shadow-md min-w-[40px] h-[40px] rounded-full text-center text-[22px]">
                          5
                        </div>
                        <div className="sm:text-[32px] text-[20px]">
                          RWA Perpetutal Trading
                        </div>
                      </div>
                      <div className="sm:text-center text-left text-[#5A5A5A]">
                        Coded Estate RWA Perps, designed for synthetic real
                        estate trading, facilitates cross-margin perp trading
                        across diverse real estate markets. It integrates Real
                        World Asset (RWA) perpetuals with decentralized
                        Perpetual Automated Market Maker (Perp AMM) pools.
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
            <div id="document" className="flex w-full">
              <div className="relative min-h-[60vh] mx-auto sm:flex w-[90vw] max-w-[1200px] justify-start sm:space-y-0 space-y-[40px]">
                <div className="flex gap-[30px] py-[20px]">
                  <div className="w-[8px] rounded-[10px] h-full shadow-md"></div>

                  <div className="flex flex-col space-y-[10px] justify-between cursor-default">
                    <Fade
                      cascade
                      direction="left"
                      duration={1000}
                      damping={0.1}
                    >
                      <div
                        onMouseEnter={() => setFlag([1, 0, 0, 0])}
                        className="transition drop-shadow-2xl cursor-pointer"
                      >
                        {flag[0] ? (
                          <div className="sm:text-[40px] text-[32px]">
                            Marketplace
                          </div>
                        ) : (
                          <div className="text-[#959595] sm:text-[32px] text-[24px]">
                            Marketplace
                          </div>
                        )}
                        <div
                          className="text-[#5A5A5A] max-w-[450px]"
                          hidden={!flag[0]}
                        >
                          Making buying and selling real estate simpler than
                          ever combining traditional property transactions with
                          smart contracts, leveraging the blockchain. Coded
                          Estate removes intermediaries, empowers buyers and
                          removes friction from property ownership
                        </div>
                      </div>

                      <div
                        className="transition drop-shadow-2xl cursor-pointer"
                        onMouseEnter={() => setFlag([0, 1, 0, 0])}
                      >
                        {flag[1] ? (
                          <div className="sm:text-[40px] text-[32px]">
                            Rental
                          </div>
                        ) : (
                          <div className="text-[#959595] sm:text-[32px] text-[24px]">
                            Rental
                          </div>
                        )}
                        <div
                          className="text-[#5A5A5A] max-w-[450px]"
                          hidden={!flag[1]}
                        >
                          Your Key to Unforgettable Rentals. Unlock a world of
                          adventure and discovery with blockchain-powered
                          rentals. Explore unique spaces, create lasting
                          memories, and embark on extraordinary journeys with
                          us, on-chain
                        </div>
                      </div>
                      <div
                        className="transition drop-shadow-2xl cursor-pointer"
                        onMouseEnter={() => setFlag([0, 0, 1, 0])}
                      >
                        {flag[2] ? (
                          <div className="sm:text-[40px] text-[32px]">
                            YieldEstate
                          </div>
                        ) : (
                          <div className="text-[#959595] sm:text-[32px] text-[24px]">
                            YieldEstate
                          </div>
                        )}
                        <div
                          className="text-[#5A5A5A] max-w-[450px]"
                          hidden={!flag[2]}
                        >
                          Coded Estate redefines property ownership with
                          fractionalized real estate investments. Join us to
                          stake your claim in prime properties and experience
                          the future of real estate. Explore flexible ownership
                          options and embark on a new era in real estate
                          investment.
                        </div>
                      </div>
                      <div
                        className="transition drop-shadow-2xl cursor-pointer"
                        onMouseEnter={() => setFlag([0, 0, 0, 1])}
                      >
                        {flag[3] ? (
                          <div className="sm:text-[40px] text-[32px]">
                            RWA Perps
                          </div>
                        ) : (
                          <div className="text-[#959595] sm:text-[32px] text-[24px]">
                            RWA Perps
                          </div>
                        )}
                        <div
                          className="text-[#5A5A5A] max-w-[450px]"
                          hidden={!flag[3]}
                        >
                          Coded Estate's native RWA perps protocol enables users
                          to speculate on global real estate markets, offering
                          synthetic index tokens for city indexes. With up to
                          10x leverage, traders can position themselves in both
                          rising and falling markets. The platform's 100%
                          decentralized infrastructure ensures accurate and
                          secure trading. This precision is bolstered by our
                          native Index API and reliable oracles. Additionally,
                          the trading environment benefits users by
                          redistributing trading fees, enhancing both
                          profitability and user engagement.
                        </div>
                      </div>
                    </Fade>
                  </div>
                </div>
                {/* <Fade> */}
                <img
                  alt=""
                  src={imageSVG}
                  className="sm:block hidden sm:absolute sm:w-[900px] bottom-0 right-0 transition ease-in-out duration-300"
                ></img>
                <img
                  alt=""
                  src={imagePNG}
                  className="sm:hidden block sm:absolute sm:w-[900px] bottom-0 right-0 transition ease-in-out duration-300"
                ></img>
                {/* </Fade> */}
              </div>
            </div>
            <div className="flex w-full">
              <div className="mx-auto sm:w-[60%] w-[80vw] my-[100px] grid sm:grid-cols-5 grid-cols-2">
                <div className="sm:col-span-4 col-span-2">
                  <Zoom>
                    <div className="sm:text-[36px] text-[24px] sm:w-[80%] w-full">
                      The Five Key Features of Our Cutting-Edge NFTs Marketplace
                    </div>
                  </Zoom>
                </div>
                <AttentionSeeker>
                  <div className="p-[20px] shadow-md space-y-[16px] rounded-[8px] transition ease-in-out hover:-translate-y-[10px] duration-100">
                    <img alt="" src={real}></img>
                    <div>Real world assets in NFTs</div>
                  </div>
                  <div className="sm:hidden block" />
                  <div></div>
                  <div className="p-[20px] shadow-md space-y-[16px] rounded-[8px] transition ease-in-out hover:-translate-y-[10px] duration-100">
                    <img alt="" src={hidd}></img>
                    <div>No hidden or extra fees</div>
                  </div>
                  <div className="sm:hidden block" />
                  <div></div>
                  <div className="p-[20px] shadow-md space-y-[16px] rounded-[8px] transition ease-in-out hover:-translate-y-[10px] duration-100">
                    <img alt="" src={remo}></img>
                    <div>Remove barriers to real estate investments</div>
                  </div>
                  <div className="sm:hidden block" />
                  <div></div>
                  <div className="p-[20px] shadow-md space-y-[16px] rounded-[8px] transition ease-in-out hover:-translate-y-[10px] duration-100">
                    <img alt="" src={frict}></img>
                    <div>Frictionless buying and selling</div>
                  </div>
                  <div className="sm:hidden block" />
                  <div></div>
                  <div className="p-[20px] shadow-md space-y-[16px] rounded-[8px] transition ease-in-out hover:-translate-y-[10px] duration-100">
                    <img alt="" src={fully}></img>
                    <div>Fully decentralised process</div>
                  </div>
                </AttentionSeeker>
              </div>
            </div>

            <div className="gradbg sm:px-[120px] sm:pt-[56px] sm:pb-[24px] p-[10px]">
              <div className="flex justify-between">
                <div className="flex flex-col justify-between items-center">
                  <div className="w-full my-[20px]">
                    <img alt="" src={logowhite}></img>
                  </div>
                  <div className="flex text-white gap-[24px]">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        document.getElementById("home").scrollIntoView();
                      }}
                    >
                      Home
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        document.getElementById("howitworks").scrollIntoView();
                      }}
                    >
                      How it Works
                    </div>
                    <div>
                      <a
                        href="https://coded-estate.gitbook.io/coded-estate/"
                        target="blank"
                      >
                        Documents
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full bg-[#959595] h-[1px] my-[24px]"></div>
              <div className="flex justify-between">
                <div className="flex gap-[10px] text-white">
                  <div>Copyright © 2024 CodedEstate</div>
                </div>
                <div className="flex gap-[10px]">
                  <div>
                    <a href="https://discord.gg/codedestate" target="blank">
                      <img alt="" src={discord}></img>
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://x.com/codedestate?s=21&t=5OdxYhHLF31Z8VwzP3lI0g"
                      target="blank"
                    >
                      <img alt="" src={twitter}></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
