import testate from "../assets/images/YieldEstate/image 2235.svg";
import time from "../assets/images/YieldEstate/Time Circle.svg";
import { useSelector } from "react-redux";
import { executeContract } from "../components/functional/InteractToContract";
import { useDispatch } from "react-redux";

import logo from "../assets/images/Logo Coded-Estate.svg";
import discord from "../assets/images/claim/iconoir_discord.svg";
import twitter from "../assets/images/claim/iconoir_twitter.svg";
import medium from "../assets/images/claim/iconoir_medium.svg";
import { setModal } from "../Actions/AuthSlice";
import Confetti from "react-confetti";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setHeaderMode } from "../Actions/HeaderSlice";
import { queryBalance } from "../components/functional/InteractToContract";
import BackIcon from "../assets/images/claim/Back.svg";
import emailjs from "emailjs-com";
import axios from "axios";
import { toast } from "react-toastify";
import { whiteList } from "../whiteList";
import { Mainnet } from "@nibiruchain/nibijs";
import { api } from "../components/functional/Api";

const mainNet = Mainnet();

export const Claim = () => {
  const account = useSelector((state) => state.auth.account);
  const walletEx = useSelector((state) => state.auth.wallet);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [claimed, setClaimed] = useState(false);
  // const [isWhiteList, setIsWhiteList] = useState(false);

  const [claimableAmount, setClaimableAmount] = useState(1500);
  const [unlockedAmount, setUnlockedAmount] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [tokenBalance, setTokenBalance] = useState(0);

  const getBalance = async () => {
    const res = await queryBalance(
      account,
      process.env.REACT_APP_TESTATE_DENOM,
      mainNet.endptTm
    );
    setTokenBalance((res.amount / 1000000).toFixed(2));
  };

  const claim = async (token_uri) => {
    // const tokenToSend = [
    //   {
    //     amount: "4000000",
    //     denom: "unibi",
    //   },
    // ];

    if (claimed) return;

    const Message = {
      // mint: {
      //   token_id: "14",
      //   owner: account,
      //   token_uri:
      //     "https://copper-patient-guanaco-983.mypinata.cloud/ipfs/QmRdMzZZrvb8jpeNkxo7qh379Kcn3B84pT1CT2RoMcvmeL",
      //   extension: {},
      //   value: 1000000000000,
      //   nft_price: 100000000,
      // },
      // burn: {
      //   token_id: "10261289",
      // },
      // buy_n_f_ts: {
      //   token_id: "1",
      //   counts: 2,
      // },
      // withdraw_funds: {
      //   amount: {
      //     denom: "unibi",
      //     amount: "24000000",
      //   },
      // },
      // create_denom: { subdenom: "utestate" },
      // mint: {
      //   coin: {
      //     amount: (claimableAmount * 1000000).toString(),
      //     denom:
      //       process.env.REACT_APP_TESTATE_DENOM,
      //   },
      //   mint_to: account,
      // },
      // set_deposit_denom: {
      //   denom:
      //     process.env.REACT_APP_TESTATE_DENOM,
      // },
      // burn: {
      //   amount: "1000000000",
      //   burn_from: "nibi14ny7n8n8srweacurdyrkw588ct5g9e06cj6csq",
      // },
      // set_claimable_amount: {
      //   amount: "10000000000",
      // },
      // mint: {
      //   amount: "250000000000000",
      //   mint_to: account,
      // },

      claim: {
        amount: (claimableAmount * 1000000).toString(),
      },
    };

    const res = await executeContract(
      mainNet.chainId,
      mainNet.endptTm,
      process.env.REACT_APP_TESTATE_SMART_CONTRACT,
      // process.env.REACT_APP_FRACTIONALIZED_SMART_CONTRACT,
      dispatch,
      false,
      "",
      Message,
      account,
      walletEx
      // tokenToSend
    );
    if (res) {
      setClaimed(true);
      getBalance();

      await api("claimedlist/add", {
        address: account,
      });
    }
  };

  const checkIfClaimable = async () => {
    if (
      (
        await api("claimedlist/getall", {
          address: account,
        })
      ).length
    ) {
      setClaimed(true);
      toast.error(`You claimed already!`, {
        autoClose: 1000,
      });
    }
  };

  const checkIfWhiteList = () => {
    for (let i = whiteList.length - 1; i >= 0; i--) {
      if (whiteList[i] === account) {
        // setIsWhiteList(true);
        // if (i > 35069) {
        //   setClaimableAmount(10000);
        // } else {
        setClaimableAmount(5000);
        // }
        break;
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getBalance();
      checkIfWhiteList();
      checkIfClaimable();
    }
  }, []);

  useEffect(() => {
    if (claimed) {
      setUnlockedAmount(claimableAmount);
      setClaimableAmount(0);
    }
  }, [claimed]);

  return (
    <div className="w-full absolute top-0">
      <div className="h-[60px] bg-white flex items-center justify-between px-[20px] z-[1300] absolute w-full">
        <img
          alt=""
          src={logo}
          className="w-[130px]"
          // onClick={() => {
          //   dispatch(setHeaderMode({ mode: 0, submode: null }));
          //   navigate("/yieldestate");
          // }}
        />
        <div className="flex items-center gap-[10px]">
          <a href="https://discord.gg/codedestate" target="blank">
            <div className="flex items-center gap-[4px] bg-[#5B1DEE] px-[12px] py-[8px] rounded-full cursor-pointer hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)] hover:-translate-y-[3px]">
              <img alt="" src={discord} />
              <div className="text-white">Discord</div>
            </div>
          </a>
          <a
            href="https://x.com/codedestate?s=21&t=5OdxYhHLF31Z8VwzP3lI0g"
            target="blank"
          >
            <div className="flex items-center gap-[4px] bg-[#5B1DEE] px-[12px] py-[8px] rounded-full cursor-pointer hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)] hover:-translate-y-[3px]">
              <img alt="" src={twitter} />
              <div className="text-white">Twitter</div>
            </div>
          </a>
          <a href="https://medium.com/@codedestate" target="blank">
            <div className="flex items-center gap-[4px] bg-[#5B1DEE] px-[12px] py-[8px] rounded-full cursor-pointer hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)] hover:-translate-y-[3px]">
              <img alt="" src={medium} />
              <div className="text-white">Medium</div>
            </div>
          </a>
        </div>
      </div>

      <div className="mt-[60px] p-[8px] h-[calc(100vh-60px)] w-full relative overflow-auto">
        <img
          alt=""
          src={BackIcon}
          className="absolute top-[40px] left-[40px] cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <Confetti
          className="mx-auto w-full h-[calc(100vh-60px)]"
          run={claimed}
          hidden={!claimed}
        />
        <div className="w-full h-full bg-white flex flex-col items-center rounded-[8px]">
          <div className="text-[40px] font-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#6B349A] to-[#4C37C3] mt-[5vh]">
            CLAIM $TESTATE
          </div>
          <img alt="" src={testate} className="my-[20px]" />
          {/* <div className="flex items-center gap-1">
            <img alt=""  src={time} />
            <div className="text-[40px] mt-[-10px]">22</div>
            <div className="text-[28px]">Days:</div>
            <div className="text-[40px] mt-[-10px]">23</div>
            <div className="text-[28px]">Hours:</div>
            <div className="text-[40px] mt-[-10px]">59</div>
            <div className="text-[28px]">Min</div>
          </div> */}
          <div className="py-[24px] rounded-[8px] flex flex-col items-center bg-white shadow-md w-[500px] my-[30px]">
            <>
              {isAuthenticated ? (
                <>
                  <div className="text-center">
                    <div>Available to Claim</div>
                    <div className="text-[#5B1DEE] text-[24px]">
                      {claimableAmount} $TESTATE
                    </div>
                  </div>

                  <div className="flex items-center justify-around w-full my-[20px]">
                    <div className="text-center">
                      <div>Total Unlocked</div>
                      <div className="text-[24px]">
                        {unlockedAmount} $TESTATE
                      </div>
                    </div>
                    <div className="text-center">
                      <div>Your Holdings</div>
                      <div className="text-[24px]">{tokenBalance} $TESTATE</div>
                    </div>
                  </div>

                  {claimed ? (
                    <div className="text-white text-center bg-[#E3E3E3] rounded-[16px] py-[12px] px-[60px] cursor-not-allowed">
                      Claimed
                    </div>
                  ) : (
                    <div
                      className="text-white text-center bg-[#5B1DEE] rounded-[16px] py-[12px] px-[60px] cursor-pointer hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)] hover:-translate-y-[3px]"
                      onClick={() => {
                        claim();
                        // toast.error(`Claim period opens at 16:00pm UTC`, {
                        //   autoClose: 1000,
                        // });
                      }}
                    >
                      Claim Now
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="text-[24px]">CLAIM YOUR $TESTATE</div>
                  <div className="text-[14px] font-normal">
                    Connect Wallet to See Your Current Airdrop Allocation
                  </div>
                  <div
                    className="text-white text-center bg-[#5B1DEE] rounded-[16px] py-[12px] px-[32px] mt-[40px] cursor-pointer hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)] hover:-translate-y-[3px]"
                    onClick={() => {
                      dispatch(setModal());
                    }}
                  >
                    Connect Wallet
                  </div>
                </>
              )}
            </>
          </div>

          <div className="max-w-[40%] text-center text-[#202020]">
            Claimable $TESTATE tokens will begin showing availability in late
            March, this drop is for the early Coders, Nibiruins, Degens and
            believers. $TESTATE will be launched on mainnet as a test to Coded
            Estate, this token will not be valuable but serves future benefit
            for early Coders.
          </div>

          {/* <div className="flex gap-[20px]">
        <div className="p-[16px] rounded-[8px] flex flex-col items-center bg-white shadow-md w-[500px] my-[30px] justify-between">
          <div className="text-[18px] font-semibold">Percentage Pie</div>
          <div className="text-[14px] font-normal">
            This is showing how many tokens that are left to claim.
          </div>
          <PieChartComponent size={100} percentage={40} />
        </div>
        <div className="p-[16px] rounded-[8px] flex flex-col items-center bg-white shadow-md w-[500px] my-[30px] justify-between">
          <div className="text-[18px] font-semibold">Your Airdrop Allocation</div>
          <div className="text-[40px] font-semibold text-[#5B1DEE] my-[20px]">
            $Testate
          </div>
          <div className="border-[1px] w-full text-center border-[#5D00CF] text-[#5D00CF] rounded-[16px] py-[12px] px-[32px]">
            Not Claimable Yet
          </div>
        </div>
      </div> */}
        </div>
      </div>
    </div>
  );
};
