import { useState, useEffect } from "react";
import { Reclaim } from "@reclaimprotocol/js-sdk";
import QRCode from "react-qr-code";
import { Modal } from "react-bootstrap";
import closeIcon from "../../assets/images/close-mini.png";
import { toast } from "react-toastify";

export const ReclaimProtocol = ({
  providerId = 0,
  setReturn = () => {},
  txt,
}) => {
  const [requestUrl, setRequestUrl] = useState();
  const [proofs, setProofs] = useState();
  const [show, setShow] = useState(false);

  const APP_ID = "0x6CB1eBb098847b314Da9f157de692743bd9A6d8b";

  const reclaimProofRequest = new Reclaim.ProofRequest(APP_ID);

  const providerIds = [
    "330309b6-2c86-4071-a96c-e3da218fcbc7", // Dubai Land Department - Emirates Id
    "a6496655-08dc-43a2-8f41-97915aaa2f3c", // Dubailand_TitleDeed
    "285a345c-c6a6-4b9f-9e1e-23432082c0a8", // Coinbase Completed KYC
    "2b22db5c-78d9-4d82-84f0-a9e0a4ed0470", // Binance KYC Level
    "61560bd9-db21-409e-97d5-435914446f67", // OKX KYC
    "4f3b-8a4d-5ffca0e57c66", // Dubai Land Department - Metadata
    "19af-4f3b-8a4d-5ffca0e57c66", // Dubai Land Department - Tenant Verification V2
  ];

  function findValue(obj, key) {
    for (var property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (property === key) {
          return obj[property];
        } else if (typeof obj[property] === "object") {
          var result = findValue(obj[property], key);
          if (result !== undefined) {
            return result;
          }
        }
      }
    }
  }

  async function createVerificationRequest() {
    toast.loading("Generating proofs..");
    // id of the provider you want to generate the proof for
    await reclaimProofRequest.buildProofRequest(providerIds[providerId]);

    reclaimProofRequest.setSignature(
      await reclaimProofRequest.generateSignature(
        "0x122404352cd04e4deb6bccf66c967f50cbe3467e6e1587916d2b03e02bf0a42b" // Handle securely for production
      )
    );

    const { requestUrl, statusUrl } =
      await reclaimProofRequest.createVerificationRequest();

    await reclaimProofRequest.startSession({
      onSuccessCallback: (proofs) => {
        setShow(false);
        toast.dismiss();
        console.log("Verification success", proofs);
        if (providerId === 0)
          setReturn(
            findValue(
              JSON.parse(proofs[0]?.claimData.context).extractedParameters,
              "Emirates_ID"
            ).toString()
          );
        if (providerId === 1)
          setReturn(
            findValue(
              JSON.parse(proofs[0]?.claimData.context).extractedParameters,
              "Titledeed"
            ).toString()
            // JSON.parse(proofs[0]?.claimData.context).extractedParameters.Titledeed
          );
      },
      onFailureCallback: (error) => {
        setShow(false);
        toast.error("ZKP verification request has failed");
        console.error("Verification failed", error);
      },
    });

    setRequestUrl(requestUrl);
  }

  useEffect(() => {
    if (requestUrl) {
      setShow(true);
    }
  }, [requestUrl]);

  return (
    <div className="">
      <div
        onClick={createVerificationRequest}
        className="bg-[#202020] px-[40px] py-[10px] rounded-[16px] text-white text-center w-max mx-auto cursor-pointer hover:shadow-[-1px_6px_10px_0_rgba(93,0,207,0.5)] hover:-translate-y-[3px]"
      >
        {txt}
      </div>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          toast.dismiss();
        }}
        centered
        style={{ fontFamily: "General Sans" }}
      >
        <Modal.Body>
          <div className="p-[20px] space-y-[10px] relative">
            <img
              alt=""
              src={closeIcon}
              className="absolute right-[16px] top-[16px] cursor-pointer"
              onClick={() => {
                setShow(false);
                toast.dismiss();
              }}
            />
            <div className="text-center drop-shadow-lg text-[24px] text-[#5B1DEE]">
              Scan your code
            </div>
            <QRCode value={requestUrl} fgColor="#5B1DEE" />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
