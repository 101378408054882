import verifiedCheckMark from "../../assets/images/verifiedCheckMark.svg";
import unverifiedCheckMark from "../../assets/images/unverifiedCheckMark.svg";
import checkMark from "../../assets/images/CheckMark.svg";

export const VerifiedCheckMark = () => {
  return <img alt="" src={verifiedCheckMark} />;
};

export const ApprovedCheckMark = () => {
  return (
    <div className="p-[2px] text-[#38A569] bg-white rounded-full border-[1px] border-[#38A569] flex items-center pr-[8px]">
      <img alt="" src={checkMark} className="h-[30px]" />
      <div className="text-[15px] font-light">Approved</div>
    </div>
  );
};

export const UnApprovedCheckMark = () => {
  return (
    <div className="p-[2px] text-[#EB4245] bg-white rounded-full border-[1px] border-[#EB4245] flex items-center pr-[8px]">
      <img alt="" src={unverifiedCheckMark} className="h-[30px]" />
      <div className="text-[15px] font-light">Unapproved</div>
    </div>
  );
};

export const CancelledCheckMark = () => {
  return (
    <div className="p-[2px] text-[#EB4245] bg-white rounded-full border-[1px] border-[#EB4245] flex items-center pr-[8px]">
      <img alt="" src={unverifiedCheckMark} className="h-[30px]" />
      <div className="text-[15px] font-light">Canceled</div>
    </div>
  );
};
