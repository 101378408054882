import NUSD from "../../assets/images/NUSD.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { executeContract } from "../functional/InteractToContract";
import { Testnet } from "@nibiruchain/nibijs";
import { useState, useEffect } from "react";
import { diffToUTC } from "../functional/GetCurrentUTCTime";
import { setDashboardMode } from "../../Actions/DashboardSlice";

export const TravelerAction = ({
  approved,
  cancelled,
  rentingPeriod,
  fundedAmount,
  currentToken,
  setViewDetailFlag = () => {},
}) => {
  const account = useSelector((state) => state.auth.account);
  const walletEx = useSelector((state) => state.auth.wallet);
  const dispatch = useDispatch();
  const [refundable, setRefundable] = useState(0);
  const testNet = Testnet(1);
  const [diff, setDiffToUTC] = useState(0);

  const getDiffToUTC = async () => {
    setDiffToUTC(await diffToUTC());
  };
  useEffect(() => {
    getDiffToUTC();
  }, []);
  const [currentTime, setCurrentTime] = useState(new Date());

  const handleCancelReservation = async () => {
    let message;
    if (!approved)
      message = {
        cancel_reservation_for_shortterm: {
          token_id: currentToken.token_id,
          renting_period: [
            rentingPeriod[0].toString(),
            rentingPeriod[1].toString(),
          ],
        },
      };
    else {
      message = {
        cancel_rental_for_shortterm: {
          token_id: currentToken.token_id,
          renting_period: [
            rentingPeriod[0].toString(),
            rentingPeriod[1].toString(),
          ],
        },
      };
    }
    const res = await executeContract(
      testNet.chainId,
      testNet.endptTm,
      process.env.REACT_APP_RENTAL_SMART_CONTRACT,
      dispatch,
      currentToken.token_id,
      currentToken.nft_info?.access?.owner,
      message,
      account,
      walletEx
    );
    // if (res) {
    //   setViewDetailFlag(null);
    // }
  };

  useEffect(() => {
    if (!approved) {
      setRefundable(fundedAmount);
      return;
    }
    let cancellation = currentToken?.shorttermrental_info?.cancellation;
    if (!cancellation || cancellation?.length === 0) return;
    cancellation.sort((a, b) => {
      return b.percentage - a.percentage;
    });
    const currentTimeSec = Math.floor(currentTime?.getTime() / 1000);
    const diffDays = (rentingPeriod[0] - diff - currentTimeSec) / 86400;
    for (let i = 0; i < cancellation.length; i++) {
      if (cancellation[i].deadline < diffDays) {
        setRefundable((fundedAmount * cancellation[i].percentage) / 100);
        break;
      }
    }
  }, [currentToken]);

  return (
    <div className="space-y-[10px] border-b-[1px] border-[#B6B6B6] py-[10px]">
      {approved &&
        !cancelled &&
        new Date((rentingPeriod[1] - diff) * 1000) > currentTime && (
          <div className="text-[18px] font-semibold text-[#38A569]">
            Approved
          </div>
        )}
      {approved &&
        !cancelled &&
        new Date((rentingPeriod[1] - diff) * 1000) < currentTime && (
          <div className="text-[18px] font-semibold text-[#38A569]">
            Completed
          </div>
        )}

      {cancelled && (
        <div className="text-[18px] font-semibold text-[#EB4245]">Canceled</div>
      )}

      {!approved &&
        new Date((rentingPeriod[0] - diff) * 1000) > currentTime && (
          <div className="text-[18px] font-semibold text-[#F1B71C]">
            Waiting for approval
          </div>
        )}
      {!approved &&
        new Date((rentingPeriod[0] - diff) * 1000) < currentTime && (
          <div className="text-[18px] font-semibold text-[#EB4245]">
            Expired
          </div>
        )}

      <div className="flex justify-between items-center">
        <div>Period</div>
        <div className="">
          {rentingPeriod?.length &&
            rentingPeriod[0] - diff !== "" &&
            rentingPeriod[1] - diff !== "" &&
            new Intl.DateTimeFormat("en-US", {
              month: "short",
              day: "numeric",
            }).format(new Date((rentingPeriod[0] - diff) * 1000)) +
              "~" +
              new Intl.DateTimeFormat("en-US", {
                month: "short",
                day: "numeric",
              }).format(new Date((rentingPeriod[1] - diff) * 1000))}
        </div>
      </div>
      <div className="flex justify-between">
        <div>Funded</div>
        <div className="flex items-center gap-[10px]">
          <img alt="" src={NUSD} />
          <div className="py-[2px] px-[4px] rounded-[4px] text-[#5b1dee] shadow-md">
            {fundedAmount}
          </div>
          <div>NUSD</div>
        </div>
      </div>
      {(new Date((rentingPeriod[0] - diff) * 1000) > currentTime ||
        (new Date((rentingPeriod[0] - diff) * 1000) < currentTime &&
          !approved)) &&
        !cancelled && (
          <>
            <div className="flex justify-between">
              <div>Refundable</div>
              <div className="flex items-center gap-[10px]">
                <img alt="" src={NUSD} />
                <div className="py-[2px] px-[4px] rounded-[4px] text-[#5b1dee] shadow-md">
                  {refundable}
                </div>
                <div>NUSD</div>
              </div>
            </div>
            <div
              className="bg-[#202020] py-[10px] text-center w-full text-white cursor-pointer shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] hover:shadow-[-1px_6px_10px_0_rgba(80,80,80,0.5)] hover:-translate-y-[3px]"
              onClick={() => {
                handleCancelReservation();
              }}
            >
              Cancel
            </div>
          </>
        )}

      {new Date((rentingPeriod[0] - diff) * 1000) < currentTime &&
        approved &&
        !cancelled && (
          <div
            className="bg-[#5b1dee] py-[10px] text-center w-full text-white cursor-pointer shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] hover:shadow-[-1px_6px_10px_0_rgba(80,80,80,0.5)] hover:-translate-y-[3px]"
            onClick={() => {
              setViewDetailFlag(true);
              dispatch(setDashboardMode(1));
            }}
          >
            Write a Review
          </div>
        )}
    </div>
  );
};
