import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentNFT: null,
  myNFTs: [],
  allNFTs: {},
  filteredNFTs: {},
  // NFTsSection: 0,
  // uploadingData: {
  //   description: null,
  //   offers: null,
  // },
};

export const NFTSlice = createSlice({
  name: "nft",
  initialState,
  reducers: {
    setNFT: (state, action) => {
      state.currentNFT = action.payload;
    },
    setAllNFTs: (state, action) => {
      state.allNFTs = action.payload;
    },
    setMyNFTs: (state, action) => {
      state.myNFTs = action.payload;
    },
    updateNFT: (state, action) => {
      state.allNFTs[action.payload.token_id] = action.payload;
    },
    setNFTsSection: (state, action) => {
      state.NFTsSection = action.payload;
    },
    setFilteredNFTs: (state, action) => {
      const tokenIds = Object.keys(action.payload);
      for (let i = 0; i < tokenIds.length; i++) {
        state.filteredNFTs[tokenIds[i]] = {
          ...state.filteredNFTs[tokenIds[i]],
          ...action.payload[tokenIds[i]],
        };
      }
    },
  },
});

export const {
  setNFT,
  setAllNFTs,
  setMyNFTs,
  updateNFT,
  setNFTsSection,
  setFilteredNFTs,
} = NFTSlice.actions;

export default NFTSlice.reducer;
