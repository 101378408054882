import { useDispatch, useSelector } from "react-redux";
import { CarouselCompo } from "../Global/CarouselCompo";
import { Inbox } from "../PartialPage/Inbox/Inbox";
import NUSD from "../../assets/images/NUSD.png";
import { setPage } from "../../Actions/PageSlice";
import { setHeaderMode } from "../../Actions/HeaderSlice";
import { ImageView } from "../Global/ImageView";

import ProfileIcon from "../../assets/images/Profile.svg";
import UserIcon from "../../assets/images/User,Profile.svg";
import NUSDIcon from "../../assets/images/NUSD.svg";
import { useEffect, useState } from "react";
import { executeContract } from "../functional/InteractToContract";
import { checkIfApproved } from "../functional/InteractToContract";
import { setNFT } from "../../Actions/NFTSlice";

export const TenantInbox = () => {
  const currentNFT = useSelector((state) => state.nft.currentNFT);
  const rent = useSelector(
    (state) =>
      state.nft?.currentNFT?.longtermrental_info?.tenant?.renting_period
  );
  const account = useSelector((state) => state.auth.account);

  const [reserved, setReserved] = useState(false);
  const walletEx = useSelector((state) => state.auth.wallet);

  const [approved, setApproved] = useState(false);
  const dispatch = useDispatch();

  const handleProceed = async () => {
    const message = {
      proceed_longterm_rental: {
        token_id: currentNFT.token_id,
      },
    };
    const res = await executeContract(
      process.env.REACT_APP_RENTAL_SMART_CONTRACT,
      dispatch,
      currentNFT.token_id,
      message,
      account,
      walletEx
    );
    if (res) {
      dispatch(setHeaderMode({ mode: 2, submode: 0 }));
      dispatch(setPage("confirmed"));
    }
  };

  const handleCancelReservation = async () => {
    const message = {
      cancel_reservation_for_longterm: {
        token_id: currentNFT.token_id,
      },
    };
    const res = await executeContract(
      process.env.REACT_APP_RENTAL_SMART_CONTRACT,
      dispatch,
      currentNFT.token_id,
      message,
      account,
      walletEx
    );
    if (res) {
      // dispatch(setSomeoneToContact(landlord));
      // dispatch(
      //   setHeaderMode({
      //     mode: 3,
      //     submode: 3,
      //   })
      // );
      // dispatch(setDashboardMode(3));
      // navigate("/dashboard");
    }

    // dispatch(setPage("confirmed"));
  };

  const checkApproved = async () => {
    if (currentNFT) {
      const res = await checkIfApproved(currentNFT?.token_id, account);
      setApproved(res);
    }
  };

  useEffect(() => {
    checkApproved();
  }, [currentNFT]);

  useEffect(() => {
    if (
      currentNFT?.longtermrental_info?.isreserved &&
      account === currentNFT?.longtermrental_info?.tenant_address
    )
      setReserved(true);
    else setReserved(false);
  }, [currentNFT?.longtermrental_info?.isreserved]);

  // useEffect(() => {
  //   dispatch(setNFT(null));
  // }, []);

  return (
    <>
      <div className="bg-[#F6F6F6] rounded-[8px] w-full h-full flex gap-[5px]">
        <div className="w-full rounded-[8px] bg-white">
          <Inbox mode={"tenant"} />
        </div>
        <div className="w-[400px] rounded-[8px] bg-white p-[12px] space-y-[16px] overflow-auto sidebarheight hiddenscrollbar">
          {/* {currentNFT ? (
            <>
              <div className="text-[20px]">Rental Details</div>
              <div className="shadow-md space-y-[6px] p-[12px]">
                <ImageView counts={1} />
                <div className="text-[#B6B6B6]">Rental</div>
                <div>
                  You sent an offer to
                  {" " +
                    currentNFT.NftInfo?.access.owner.substring(0, 5) +
                    "..." +
                    currentNFT.NftInfo?.access.owner.substring(
                      currentNFT.NftInfo?.access.owner.length - 4
                    )}
                </div>
                <div className="text-[#B6B6B6]">
                  {currentNFT.metaData["Building Name"].buildingNameEn}
                </div>
                <div className="text-[#B6B6B6]">
                  {currentNFT.longtermrentalInfo?.tenant?.renting_period[0] +
                    "~" +
                    currentNFT.longtermrentalInfo?.tenant?.renting_period[1]}
                </div>
                <div className="flex gap-[8px] items-center">
                  <img alt=""  src={NUSD} />
                  <div>
                    {currentNFT.longtermrentalInfo?.landlord?.price_per_month}
                  </div>
                  <div>NUSD</div>
                </div>
                <div className="flex w-full justify-around">
                  <div
                    className="text-white px-[20px] py-[8px] bg-[#5B1DEE] rounded-[16px] cursor-pointer"
                    onClick={() => {
                      dispatch(
                        setHeaderMode({
                          mode: 1,
                          submode: 0,
                        })
                      );
                      dispatch(setPage("confirmed"));
                    }}
                  >
                    Confirm
                  </div>
                  <div className="text-white px-[20px] py-[8px] bg-[#202020] rounded-[16px] cursor-pointer">
                    Cancel
                  </div>
                </div>
              </div>
              <div className="shadow-md space-y-[8px] p-[12px]">
                <div className="flex justify-between">
                  <div>Landlord</div>
                  <div className="text-[#B6B6B6]">
                    {currentNFT.NftInfo?.access.owner.substring(0, 5) +
                      "..." +
                      currentNFT.NftInfo?.access.owner.substring(
                        currentNFT.NftInfo?.access.owner.length - 4
                      )}
                  </div>
                </div>
                <div className="w-full h-[1px] bg-[#B6B6B6]"></div>
                <div className="flex justify-between items-center">
                  <div>Deposit Amount</div>
                  <div className="flex items-center gap-[4px]">
                    <img alt=""  src={NUSD} />
                    <div className="text-[#5B1DEE] p-[6px] rounded-[4px]">
                      {currentNFT.longtermrentalInfo?.tenant?.deposit_amount}
                    </div>
                    <div className="text-[#B6B6B6]">NUSD</div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )} */}

          {currentNFT ? (
            <>
              {reserved && (
                <div className="text-[20px]">Your request for rental</div>
              )}

              <ImageView counts={1} />

              <div className="p-[8px] rounded-[8px] shadow-md space-y-[8px] text-[#606060]">
                <div className="flex justify-between">
                  <div>Tower Name</div>
                  <div className="text-[#B6B6B6]">
                    {currentNFT?.metaData?.Name}
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div>Address</div>
                  <div className="text-[#B6B6B6] text-[14px]">
                    {currentNFT?.metaData?.Address}
                  </div>
                </div>
                {reserved ? (
                  <div className="flex justify-between items-center">
                    <div>Rental Period</div>
                    <div className="text-[#B6B6B6] text-[14px]">
                      {rent?.length &&
                        rent[0] !== "" &&
                        rent[1] !== "" &&
                        new Intl.DateTimeFormat("en-US", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        }).format(new Date(rent[0])) +
                          "~" +
                          new Intl.DateTimeFormat("en-US", {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          }).format(new Date(rent[1]))}
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="flex justify-between items-center">
                  <div>Monthly Cost</div>
                  <div className="flex gap-[8px] items-center">
                    <img alt="" src={NUSDIcon} />
                    <div className="text-[#B6B6B6]">
                      {
                        currentNFT?.longtermrental_info?.landlord
                          ?.price_per_month
                      }
                    </div>

                    <div>NUSD</div>
                  </div>
                </div>

                {reserved && (
                  <div className="flex justify-between items-center">
                    <div className="">Refundable deposit</div>
                    <div className="flex gap-[8px] items-center">
                      <img alt="" src={NUSDIcon} />
                      <div className="text-[#B6B6B6]">
                        {
                          currentNFT?.longtermrental_info?.landlord
                            ?.refundable_deposit
                        }
                      </div>

                      <div>NUSD</div>
                    </div>
                  </div>
                )}
                {reserved && (
                  <div className="flex justify-between items-center">
                    <div className="">Total price</div>
                    <div className="flex gap-[8px] items-center">
                      <img alt="" src={NUSDIcon} />
                      <div className="text-[#5b1dee] px-[6px] py-[2px] rounded-[4px] shadow-md">
                        {currentNFT.longtermrental_info?.tenant?.deposit_amount}
                      </div>

                      <div>NUSD</div>
                    </div>
                  </div>
                )}
              </div>

              {reserved ? (
                <div>Waiting for approval...</div>
              ) : (
                <div>Not reserved yet</div>
              )}
              <div className="p-[8px] rounded-[8px] shadow-md space-y-[8px]">
                <div className="flex items-center gap-[10px]">
                  <img alt="" src={ProfileIcon} />
                  <div className="space-y-[4px] flex flex-col items-start">
                    <div className="text-[#B6B6B6]">
                      {currentNFT?.nft_info?.access.owner.substring(0, 5) +
                        "..." +
                        currentNFT?.nft_info?.access.owner.substring(
                          currentNFT?.nft_info?.access.owner.length - 15
                        )}
                    </div>
                    <div className="bg-[#5b1dee] px-[12px] py-[4px] text-white cursor-pointer shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)] hover:-translate-y-[3px] flex items-center gap-[4px]">
                      <img alt="" src={UserIcon} />
                      <div>View Profile</div>
                    </div>
                  </div>
                </div>
              </div>
              {reserved && (
                <div className="flex items-center gap-[20px] w-full">
                  {approved ? (
                    <>
                      <div
                        className="bg-[#202020] py-[10px] text-center w-full text-white cursor-pointer shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] hover:shadow-[-1px_6px_10px_0_rgba(80,80,80,0.5)] hover:-translate-y-[3px]"
                        onClick={() => {
                          handleProceed();
                        }}
                      >
                        Proceed
                      </div>
                      <div
                        className="bg-[#dfdfdf] py-[10px] text-center w-full text-white cursor-not-allowed shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px]"
                        onClick={() => {
                          // handleCancelReservation_temp();
                        }}
                      >
                        Cancel
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="bg-[#dfdfdf] cursor-not-allowed py-[10px] text-center w-full text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px]">
                        Proceed
                      </div>
                      <div
                        className="bg-[#202020] py-[10px] text-center w-full text-white cursor-pointer shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] hover:shadow-[-1px_6px_10px_0_rgba(80,80,80,0.5)] hover:-translate-y-[3px]"
                        onClick={() => {
                          handleCancelReservation();
                        }}
                      >
                        Cancel
                      </div>
                    </>
                  )}
                </div>
              )}

              <div
                className="bg-[#5b1dee] py-[10px] text-center mt-[10px] text-white cursor-pointer shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)] hover:-translate-y-[3px]"
                onClick={() => {
                  dispatch(
                    setHeaderMode({
                      mode: 2,
                      submode: 0,
                    })
                  );
                  dispatch(setPage("detail"));
                }}
              >
                View Details
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
