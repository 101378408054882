import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  waiting: false,
  txState: null,
};

export const ModalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModal: (state, action) => {
      state[action.payload.modal] = action.payload.data;
    },
  },
});

export const { setModal } = ModalSlice.actions;

export default ModalSlice.reducer;
