import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: null,
  account: null,
  wallet: null,
  connectModal: null,
  connecting: false,
  profile: null,
};

export const AuthSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    connected: (state, action) => {
      state.isAuthenticated = true;
      state.account = action.payload.account;
      state.wallet = action.payload.wallet;
    },
    disconnect: (state) => {
      state.account = null;
      state.isAuthenticated = null;
      state.wallet = null;
    },
    setModal: (state) => {
      state.connectModal = true;
    },
    setHideModal: (state) => {
      state.connectModal = false;
    },
    setConnecting: (state, action) => {
      state.connecting = action.payload;
    },
    setMyProfile: (state, action) => {
      state.profile = action.payload;
    },
  },
});

export const {
  connected,
  disconnect,
  setModal,
  setHideModal,
  setConnecting,
  setMyProfile,
} = AuthSlice.actions;

export default AuthSlice.reducer;
