import { Modal } from "react-bootstrap";
import plus from "../../assets/images/ConnectWallet/Close.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setModal } from "../../Actions/ModalSlice";
import waitingLottie from "../../assets/animations/hourglass.json";
import successLottie from "../../assets/animations/XA9XVQ3i9v.json";
import failedLottie from "../../assets/animations/failedLottie.json";
import Lottie from "lottie-react";
import { toast } from "react-toastify";

export const Loading = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.modal.waiting);

  const txState = useSelector((state) => state.modal.txState);

  const handleClose = () => {
    dispatch(
      setModal({
        modal: "waiting",
        show: null,
      })
    );
    dispatch(
      setModal({
        modal: "txState",
        show: null,
      })
    );
    toast.dismiss();
  };

  return (
    <Modal show={show} centered onHide={handleClose}>
      <Modal.Body>
        <div className="sm:w-[400px] w-full min-h-[400px] flex flex-col relative bg-white rounded-[16px]">
          <img
            alt=""
            src={plus}
            className="w-[40px] cursor-pointer absolute top-[10px] right-[10px]"
            onClick={handleClose}
          ></img>
          {txState === null ? (
            <Lottie
              className="w-[200px] m-auto"
              animationData={waitingLottie}
              loop={true}
            />
          ) : (
            <></>
          )}

          {txState === true ? (
            <Lottie
              className="w-[200px] m-auto"
              animationData={successLottie}
              loop={false}
              onComplete={handleClose}
            />
          ) : (
            <></>
          )}

          {txState === false ? (
            <Lottie
              className="w-[200px] m-auto"
              animationData={failedLottie}
              loop={false}
              onComplete={handleClose}
            />
          ) : (
            <></>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
