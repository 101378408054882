import { setHeaderMode } from "../../Actions/HeaderSlice";
import { setDashboardMode } from "../../Actions/DashboardSlice";
import { setChatMode, setHoldFlag } from "../../Actions/ChatSlice";

export const goToInbox = (
  someOneToContact,
  headerMode,
  headerSubmode,
  dispatch,
  nftId
) => {
  // dispatch(setMaintainSomeoneToContact(nftId));
  // dispatch(setSomeoneToContact(someOneToContact));
  dispatch(setHoldFlag(true));
  if (headerMode === 1 && headerSubmode === 1) {
    dispatch(setChatMode("traveler"));
    dispatch(
      setHeaderMode({
        mode: 4,
        submode: 4,
      })
    );
    dispatch(setDashboardMode(3));
  }
  if (headerMode === 1 && headerSubmode === 0) {
    dispatch(setChatMode("tenant"));
    dispatch(
      setHeaderMode({
        mode: 4,
        submode: 2,
      })
    );
    dispatch(setDashboardMode(3));
  }
};

export const truncateWalletAddress = (address, forward = 5, backward = 4) => {
  if (address)
    return (
      address.toString().substring(0, forward) +
      "..." +
      address.toString().substring(address.toString().length - backward)
    );
};
