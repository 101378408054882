import { Transition } from "react-transition-group";
import { useRef, useState, useEffect } from "react";

export const TransitionCompo = ({
  Component,
  timeout = 0,
  className = "",
  //   start,
  cycle,
}) => {
  const nodeRef = useRef(null);
  const [inProp, setInProp] = useState(false);
  const duration = 300;
  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 0, transform: "translateY(40px)" },
    entered: { opacity: 1, transform: "translateY(0%)" },
    exiting: { opacity: 1, transform: "translateY(-20px)" },
    exited: { opacity: 0, transform: "translateY(0%)" },
  };

  useEffect(() => {
    // if (start)
    setTimeout(() => {
      setInProp(true);
    }, timeout);
    if (cycle) {
      setTimeout(() => {
        setInProp(!inProp);
      }, cycle);
    }
  }, []);

  return (
    <Transition nodeRef={nodeRef} in={inProp} timeout={duration}>
      {(state) => (
        <div
          ref={nodeRef}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          className={className}
        >
          {Component}
        </div>
      )}
    </Transition>
  );
};
