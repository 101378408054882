import search from "../../assets/images/Frame 1000001522.png";
import searchsm from "../../assets/images/search-normal.png";
import recent from "../../assets/images/Group 1000004834.png";

import AbuDhabi from "../../assets/images/SearchBar/vector-maps.svg";
import Ajman from "../../assets/images/SearchBar/vector-maps (1).svg";
import Dubai from "../../assets/images/SearchBar/vector-maps (2).svg";
import Fujairah from "../../assets/images/SearchBar/vector-maps (3).svg";
import RasAlKhaimah from "../../assets/images/SearchBar/vector-maps (4).svg";
import Sharjah from "../../assets/images/SearchBar/vector-maps (5).svg";
import UmmAlQuwain from "../../assets/images/SearchBar/vector-maps (6).svg";

import { Calendar } from "react-multi-date-picker";
import light from "../../assets/images/light.svg";
// import minus from "../../assets/images/Group 1000004840.png";
// import plus from "../../assets/images/Group 1000004839.png";
import uncheck from "../../assets/images/checkbox/unchecked.png";
import check from "../../assets/images/checkbox/checked.png";

import { Modal } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { NumberSpin } from "../Global/NumberSpin";
import { Checkbox } from "../Global/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import cross from "../../assets/images/cross.svg";
import locationPinSVG from "../../assets/images/LocationPin.svg";
import AbuDhabiMap from "../../assets/mapData/AbuDhabi.json";
import DubaiMap from "../../assets/mapData/Dubai.json";
import AjmanMap from "../../assets/mapData/Ajman.json";
import FujairahMap from "../../assets/mapData/Fujairah.json";
import RasAlKhaimahMap from "../../assets/mapData/RasAlKhaimah.json";
import SharjahMap from "../../assets/mapData/Sharjah.json";
import UmmMap from "../../assets/mapData/Umm Al-Quwain.json";

import {
  setAmenities,
  setCheckIn,
  setCheckOut,
  setGuests,
  setRegion,
  setSearchAddress,
} from "../../Actions/SearchSlice";
import { SelectionGroup, SelectionItem } from "../Global/Selection";
import { setRentPeriod } from "../../Actions/RentSlice";
import { Popover } from "react-tiny-popover";
import { setFilteredNFTs } from "../../Actions/NFTSlice";
import { Fade } from "react-awesome-reveal";

export const SearchOptionBar = () => {
  const assets = useSelector((state) => state.nft.allNFTs);

  // const [filteredAssets, setFilteredAssets] = useState([]);

  const [abuDhabi, setAbuDhabi] = useState([]);
  const [dubai, setDubai] = useState([]);
  const [ajman, setAjman] = useState([]);
  const [fujairah, setFujairah] = useState([]);
  const [rasAlKhaimah, setRasAlKhaimah] = useState([]);
  const [sharjah, setSharjah] = useState([]);
  const [umm, setUmm] = useState([]);

  const isLocationInArea = (location, areaCoordinates) => {
    if (getArrayDimensions(areaCoordinates).length === 1) {
      const polygon = new window.google.maps.Polygon({
        paths: areaCoordinates,
      });
      const point = new window.google.maps.LatLng(location.lat, location.lng);
      return window.google.maps.geometry.poly.containsLocation(point, polygon);
    }
    if (getArrayDimensions(areaCoordinates).length === 2) {
      for (let i = 0; i < areaCoordinates.length; i++) {
        const polygon = new window.google.maps.Polygon({
          paths: areaCoordinates[i],
        });
        const point = new window.google.maps.LatLng(location.lat, location.lng);
        if (window.google.maps.geometry.poly.containsLocation(point, polygon))
          return true;
      }
      return false;
    }
  };

  const getArrayDimensions = (arr) => {
    if (!Array.isArray(arr)) {
      alert("!");
      return "Not an array";
    }

    let dimensions = [arr.length];
    if (Array.isArray(arr[0])) {
      let innerDimensions = getArrayDimensions(arr[0]);
      dimensions = dimensions.concat(innerDimensions);
    }

    return dimensions;
  };

  const calcDistance = (loc1, loc2) => {
    const earthRadius = 6371; // Earth's radius in kilometers

    const dLat = ((loc2.lat - loc1.lat) * Math.PI) / 180;
    const dLon = ((loc2.lng - loc1.lng) * Math.PI) / 180;

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((loc1.lat * Math.PI) / 180) *
        Math.cos((loc2.lat * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = earthRadius * c; // Distance in kilometers

    return distance;
  };

  const setCoordinates = (map, setCoords = () => {}) => {
    if (getArrayDimensions(map.coordinates).length === 2) {
      const coordinates = [];
      for (let i = 0; i < map.coordinates.length; i++) {
        coordinates.push({
          lat: map.coordinates[i][1],
          lng: map.coordinates[i][0],
        });
      }
      setCoords(coordinates);
    }
    if (getArrayDimensions(map.coordinates).length === 3) {
      const coordinates = [];
      for (let i = 0; i < map.coordinates.length; i++) {
        const subArea = [];
        for (let j = 0; j < map.coordinates[i].length; j++) {
          subArea.push({
            lat: map.coordinates[i][j][1],
            lng: map.coordinates[i][j][0],
          });
        }
        coordinates.push(subArea);
      }
      setCoords(coordinates);
    }
  };

  const initializeRegions = () => {
    const regions = [
      {
        map: AbuDhabiMap,
        func: setAbuDhabi,
      },
      {
        map: DubaiMap,
        func: setDubai,
      },
      {
        map: AjmanMap,
        func: setAjman,
      },
      {
        map: FujairahMap,
        func: setFujairah,
      },
      {
        map: RasAlKhaimahMap,
        func: setRasAlKhaimah,
      },
      {
        map: SharjahMap,
        func: setSharjah,
      },
      {
        map: UmmMap,
        func: setUmm,
      },
    ];
    for (let i = 0; i < regions.length; i++) {
      setCoordinates(regions[i].map, regions[i].func);
    }
  };

  useEffect(() => {
    initializeRegions();
  }, []);

  const [show, setShow] = useState(false);

  const [active, setActive] = useState(false);

  const [activeHover, setActiveHover] = useState(null);

  const handleClose = () => {
    setShow(false);
    setActive(false);
    setSearchMode(0);
  };
  const handleShow = (e) => {
    setActive(true);
    setAddresses([]);
    setShow(true);
  };

  const period = useSelector((state) => state.rent.period);

  const region = useSelector((state) => state.search.region);
  const formattedAddress = useSelector(
    (state) => state.search.address?.formatted_address
  );
  const searchLocation = useSelector(
    (state) => state.search.address?.geometry?.location
  );

  const guestsAdults = useSelector((state) => state.search.guests.adults);

  const guestsChildren = useSelector((state) => state.search.guests.children);

  const guestsInfants = useSelector((state) => state.search.guests.infants);

  const guestsPets = useSelector((state) => state.search.guests.pets);

  const squareFeet = useSelector((state) => state.search.amenities.squareFeet);

  const bathroom = useSelector((state) => state.search.amenities.bathroom);

  const bedroom = useSelector((state) => state.search.amenities.bedroom);

  const [searchMode, setSearchMode] = useState(0);
  const [checked, setChecked] = useState(false);
  const [onlyMonthPicker, setOnlyMonthPicker] = useState(false);

  const [datePickerIndex, setDatePickerIndex] = useState(0);
  // const [currentSearch, setCurrentSearch] = useState(0);
  const dispatch = useDispatch();

  // let service;
  // let geocoder;

  const [service, setService] = useState();
  const [geocoder, SetGeocoder] = useState();
  useEffect(() => {
    setService(new window.google.maps.places.AutocompleteService());
    SetGeocoder(new window.google.maps.Geocoder());
  }, []);

  const [addresses, setAddresses] = useState([]);
  const [inputAddress, setInputAddress] = useState();
  const [address, setAddress] = useState();

  const getAddressList = async (input) => {
    service.getPlacePredictions(
      { input: input },
      function (predictions, status) {
        if (
          status !== window.google.maps.places.PlacesServiceStatus.OK ||
          !predictions
        ) {
          return;
        }
        setAddresses(predictions);
      }
    );
  };

  const getLocationFromAddress = async (place_id) => {
    const res = await geocoder.geocode({ placeId: place_id });
    dispatch(setSearchAddress(res.results[0]));
    setInputAddress(res.results[0].formatted_address);
  };

  const handleSetSearchRegion = (input) => {
    dispatch(setSearchAddress(null));
    dispatch(setRegion(input));
  };

  const handleSetSearchAddress = (input) => {
    dispatch(setRegion(null));
    setAddress(input);
  };

  const handleSetNull = () => {
    dispatch(setRegion(null));
    dispatch(setSearchAddress(null));
  };

  const handleSearch = (e) => {
    e.stopPropagation();
    let filteredAssets = {};
    const assetsArray = Object.values(assets);
    for (let i = 0; i < assetsArray.length; i++) {
      //location
      let where;
      if (region) {
        if (region === "Abu Dhabi")
          where = isLocationInArea(assetsArray[i].metaData.Location, abuDhabi);
        if (region === "Dubai")
          where = isLocationInArea(assetsArray[i].metaData.Location, dubai);
        if (region === "Ajman")
          where = isLocationInArea(assetsArray[i].metaData.Location, ajman);
        if (region === "Fujairah")
          where = isLocationInArea(assetsArray[i].metaData.Location, fujairah);
        if (region === "Ras Al-Khaimah")
          where = isLocationInArea(
            assetsArray[i].metaData.Location,
            rasAlKhaimah
          );
        if (region === "Sharjah")
          where = isLocationInArea(assetsArray[i].metaData.Location, sharjah);
        if (region === "Umm Al-Quwain")
          where = isLocationInArea(assetsArray[i].metaData.Location, umm);
      } else if (searchLocation) {
        where = calcDistance(assetsArray[i].metaData.Location, {
          lat: searchLocation.lat(),
          lng: searchLocation.lng(),
        });
      } else {
        where = true;
      }
      //rental period
      let possible = false;
      let flag = false;
      if (period.length) {
        let searchCheckIn = Math.floor(period[0] / 1000);
        let searchCheckOut = Math.floor(period[1] / 1000);
        if (!searchCheckIn) searchCheckIn = searchCheckOut;
        if (!searchCheckOut) searchCheckOut = searchCheckIn;

        for (
          let j = 0;
          j < assetsArray[i].shorttermrental_info.travelers.length;
          j++
        ) {
          let checkIn =
            assetsArray[i].shorttermrental_info.travelers[j].renting_period[0];
          let checkOut =
            assetsArray[i].shorttermrental_info.travelers[j].renting_period[1];

          if (searchCheckOut < checkIn) {
            if (j === 0) {
              possible = true;
              break;
            } else if (flag) {
              possible = true;
              break;
            }
          } else if (checkOut < searchCheckIn) {
            flag = true;
            if (
              j ===
              assetsArray[i].shorttermrental_info.travelers.length - 1
            ) {
              possible = true;
              break;
            }
          } else {
            flag = false;
          }
        }
        if (!possible) {
          if (assetsArray[i].shorttermrental_info.travelers.length === 0) {
            possible = true;
          }
        }
      }
      if (period.length === 0) {
        possible = true;
      }
      //guests
      let guests = true;
      if (guestsAdults + guestsChildren)
        if (
          assetsArray[i].metaData.Amenities.Guests <
          guestsAdults + guestsChildren
        )
          guests = false;

      filteredAssets[assetsArray[i].token_id] = {
        where: where,
        period: possible,
        guests: guests,
      };
      // tempArray.push(searchedItem);
    }
    // filteredAssets.sort((a, b) => a.where - b.where);

    handleClose();
    dispatch(setFilteredNFTs(filteredAssets));
    // console.log(filteredAssets);
  };

  useEffect(() => {
    if (address) getLocationFromAddress(address.place_id);
  }, [address]);

  useEffect(() => {
    if (inputAddress) {
      getAddressList(inputAddress);
    }
    if (inputAddress === "") setAddresses([]);
  }, [inputAddress]);

  useEffect(() => {
    if (period[0]) {
      setSearchMode(3);
    }
  }, [period[0]]);

  useEffect(() => {
    if (period[1]) {
      setSearchMode(4);
    }
  }, [period[1]]);

  useEffect(() => {
    setInputAddress(region || formattedAddress);
    setSearchMode(2);
  }, [region, formattedAddress]);

  return (
    <>
      <Popover
        isOpen={show}
        positions={["bottom"]}
        onClickOutside={handleClose}
        content={
          <Fade>
            <div className="bg-white shadow-lg rounded-[20px] mt-[10px]">
              {searchMode === 1 ? (
                <div>
                  <div
                    className={
                      addresses.length
                        ? "p-[16px] font-semibold"
                        : "font-semibold"
                    }
                  >
                    <div className="space-y-[10px]">
                      {/* <div className="text-[18px]">Recent searches</div>
                    <div className="flex items-center">
                      <img alt="" src={recent}></img>
                      <div>
                        <div className="text-[16px]">Dubai Stays</div>
                        <div className="text-[#959595]">
                          Nov 6 - Nov 25 (3 guests)
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <img alt="" src={recent}></img>
                      <div>
                        <div className="text-[16px]">Dubai Stays</div>
                        <div className="text-[#959595]">
                          Nov 6 - Nov 25 (3 guests)
                        </div>
                      </div>
                    </div> */}
                      {addresses.map((address) => {
                        return (
                          <div
                            onClick={() => handleSetSearchAddress(address)}
                            className="flex gap-[10px] items-center cursor-pointer w-[400px] overflow-hidden h-[50px] p-[8px] rounded-[8px] hover:bg-gray-300"
                          >
                            <img alt="" src={locationPinSVG} width={20} />
                            <div className="w-full truncate">
                              {address.description}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div
                      hidden
                      // hidden={addresses.length > 0}
                    >
                      <div className="text-[18px]">Search by region</div>
                      <div className="grid grid-cols-4 gap-[8px] mt-[10px]">
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSetSearchRegion("Abu Dhabi")}
                        >
                          <div
                            className={
                              region === "Abu Dhabi"
                                ? "w-[120px] h-[120px] flex border-[2px] border-[#666666] rounded-[8px] bg-[#f7f7f7] hover:bg-[#dfdfdf]"
                                : "w-[120px] h-[120px] flex border-[1px] border-[#aaaaaa] rounded-[8px] bg-[#f7f7f7] hover:bg-[#dfdfdf]"
                            }
                          >
                            <img alt="" src={AbuDhabi} className="m-auto"></img>
                          </div>
                          <div className="text-center">Abu Dhabi</div>
                        </div>
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSetSearchRegion("Ajman")}
                        >
                          <div
                            className={
                              region === "Ajman"
                                ? "w-[120px] h-[120px] flex border-[2px] border-[#666666] rounded-[8px] bg-[#f7f7f7] hover:bg-[#dfdfdf]"
                                : "w-[120px] h-[120px] flex border-[1px] border-[#aaaaaa] rounded-[8px] bg-[#f7f7f7] hover:bg-[#dfdfdf]"
                            }
                          >
                            <img alt="" src={Ajman} className="m-auto"></img>
                          </div>
                          <div className="text-center">Ajman</div>
                        </div>
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSetSearchRegion("Dubai")}
                        >
                          <div
                            className={
                              region === "Dubai"
                                ? "w-[120px] h-[120px] flex border-[2px] border-[#666666] rounded-[8px] bg-[#f7f7f7] hover:bg-[#dfdfdf]"
                                : "w-[120px] h-[120px] flex border-[1px] border-[#aaaaaa] rounded-[8px] bg-[#f7f7f7] hover:bg-[#dfdfdf]"
                            }
                          >
                            <img alt="" src={Dubai} className="m-auto"></img>
                          </div>
                          <div className="text-center">Dubai</div>
                        </div>
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSetSearchRegion("Fujairah")}
                        >
                          <div
                            className={
                              region === "Fujairah"
                                ? "w-[120px] h-[120px] flex border-[2px] border-[#666666] rounded-[8px] bg-[#f7f7f7] hover:bg-[#dfdfdf]"
                                : "w-[120px] h-[120px] flex border-[1px] border-[#aaaaaa] rounded-[8px] bg-[#f7f7f7] hover:bg-[#dfdfdf]"
                            }
                          >
                            <img alt="" src={Fujairah} className="m-auto"></img>
                          </div>
                          <div className="text-center">Fujairah</div>
                        </div>
                        <div
                          className="cursor-pointer"
                          onClick={() =>
                            handleSetSearchRegion("Ras Al-Khaimah")
                          }
                        >
                          <div
                            className={
                              region === "Ras Al-Khaimah"
                                ? "w-[120px] h-[120px] flex border-[2px] border-[#666666] rounded-[8px] bg-[#f7f7f7] hover:bg-[#dfdfdf]"
                                : "w-[120px] h-[120px] flex border-[1px] border-[#aaaaaa] rounded-[8px] bg-[#f7f7f7] hover:bg-[#dfdfdf]"
                            }
                          >
                            <img
                              alt=""
                              src={RasAlKhaimah}
                              className="m-auto"
                            ></img>
                          </div>
                          <div className="text-center">Ras Al-Khaimah</div>
                        </div>
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSetSearchRegion("Sharjah")}
                        >
                          <div
                            className={
                              region === "Sharjah"
                                ? "w-[120px] h-[120px] flex border-[2px] border-[#666666] rounded-[8px] bg-[#f7f7f7] hover:bg-[#dfdfdf]"
                                : "w-[120px] h-[120px] flex border-[1px] border-[#aaaaaa] rounded-[8px] bg-[#f7f7f7] hover:bg-[#dfdfdf]"
                            }
                          >
                            <img alt="" src={Sharjah} className="m-auto"></img>
                          </div>
                          <div className="text-center">Sharjah</div>
                        </div>
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSetSearchRegion("Umm Al-Quwain")}
                        >
                          <div
                            className={
                              region === "Umm Al-Quwain"
                                ? "w-[120px] h-[120px] flex border-[2px] border-[#666666] rounded-[8px] bg-[#f7f7f7] hover:bg-[#dfdfdf]"
                                : "w-[120px] h-[120px] flex border-[1px] border-[#aaaaaa] rounded-[8px] bg-[#f7f7f7] hover:bg-[#dfdfdf]"
                            }
                          >
                            <img
                              alt=""
                              src={UmmAlQuwain}
                              className="m-auto"
                            ></img>
                          </div>
                          <div className="text-center">Umm Al-Quwain</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {searchMode === 2 || searchMode === 3 ? (
                <div>
                  <div className="pt-[20px]">
                    <SelectionGroup
                      defaultItem={datePickerIndex}
                      className="p-[6px] shadow-md gap-[8px] rounded-[16px] flex w-max mx-auto text-[#959595]"
                      SelectedItemMask="shadow-md font-semibold text-[#202020] rounded-[10px]"
                    >
                      <SelectionItem
                        SelectedItem={
                          <div className="w-[120px] rounded-[10px] flex items-center justify-center gap-[10px]">
                            <div>Dates</div>
                            <img alt="" src={light}></img>
                          </div>
                        }
                        UnselectedItem={
                          <div
                            className="w-[120px] rounded-[10px] flex items-center justify-center gap-[10px]"
                            onClick={() => {
                              setOnlyMonthPicker(false);
                              setDatePickerIndex(0);
                            }}
                          >
                            <div>Dates</div>
                          </div>
                        }
                      />
                      <SelectionItem
                        SelectedItem={
                          <div className="w-[120px] rounded-[10px] flex items-center justify-center gap-[10px]">
                            <div>Months</div>
                            <img alt="" src={light}></img>
                          </div>
                        }
                        UnselectedItem={
                          <div
                            className="w-[120px] rounded-[10px] flex items-center justify-center gap-[10px]"
                            onClick={() => {
                              setOnlyMonthPicker(true);
                              setDatePickerIndex(1);
                            }}
                          >
                            <div>Months</div>
                          </div>
                        }
                      />
                      {/* <SelectionItem
                  SelectedItem={
                    <div className="w-[120px] rounded-[10px] flex items-center justify-center gap-[10px] shadow-md font-semibold text-[#202020]">
                      <div>Flexible</div>
                      <img alt=""  src={light}></img>
                    </div>
                  }
                  UnselectedItem={
                    <div
                      className="w-[120px] rounded-[10px] flex items-center justify-center gap-[10px]"
                      onClick={() => {
                        setOnlyMonthPicker(false);
                        setDatePickerIndex(2);
                      }}
                    >
                      <div>Flexible</div>
                    </div>
                  }
                /> */}
                    </SelectionGroup>
                  </div>

                  <Calendar
                    highlightToday={false}
                    range
                    numberOfMonths={2}
                    minDate={new Date()}
                    onChange={(dates) => dispatch(setRentPeriod(dates))}
                    value={period}
                    onlyMonthPicker={onlyMonthPicker}
                  />

                  {/* <SelectionGroup
              defaultItem={0}
              className="m-[10px] flex gap-[8px] text-[#D5D5D5]"
            >
              <SelectionItem
                SelectedItem={
                  <div className="px-[14px] py-[8px] border-[1px] rounded-[16px] w-max border-[#5D00CF] text-[#5D00CF]">
                    Exact dates
                  </div>
                }
                UnselectedItem={
                  <div className="px-[14px] py-[8px] border-[1px] rounded-[16px] w-max">
                    Exact dates
                  </div>
                }
              />
              <SelectionItem
                SelectedItem={
                  <div className="px-[14px] py-[8px] border-[1px] rounded-[16px] w-max border-[#5D00CF] text-[#5D00CF]">
                    +-1day
                  </div>
                }
                UnselectedItem={
                  <div className="px-[14px] py-[8px] border-[1px] rounded-[16px] w-max">
                    +-1day
                  </div>
                }
              />
              <SelectionItem
                SelectedItem={
                  <div className="px-[14px] py-[8px] border-[1px] rounded-[16px] w-max border-[#5D00CF] text-[#5D00CF]">
                    +-2day
                  </div>
                }
                UnselectedItem={
                  <div className="px-[14px] py-[8px] border-[1px] rounded-[16px] w-max">
                    +-2day
                  </div>
                }
              />
              <SelectionItem
                SelectedItem={
                  <div className="px-[14px] py-[8px] border-[1px] rounded-[16px] w-max border-[#5D00CF] text-[#5D00CF]">
                    +-3day
                  </div>
                }
                UnselectedItem={
                  <div className="px-[14px] py-[8px] border-[1px] rounded-[16px] w-max">
                    +-3day
                  </div>
                }
              />
              <SelectionItem
                SelectedItem={
                  <div className="px-[14px] py-[8px] border-[1px] rounded-[16px] w-max border-[#5D00CF] text-[#5D00CF]">
                    +-7day
                  </div>
                }
                UnselectedItem={
                  <div className="px-[14px] py-[8px] border-[1px] rounded-[16px] w-max">
                    +-7day
                  </div>
                }
              />
            </SelectionGroup> */}
                </div>
              ) : (
                <></>
              )}
              {searchMode === 4 ? (
                <div>
                  <div className="w-[340px] p-[20px] space-y-[10px]">
                    <div className="flex items-center justify-between ">
                      <div>
                        <div className="font-semibold">Adults</div>
                        <div className="text-[#959595]">Age 13 or above</div>
                      </div>
                      <div className="flex items-center">
                        <NumberSpin
                          value={guestsAdults}
                          onChange={(value) =>
                            dispatch(
                              setGuests({
                                adults: value,
                                children: guestsChildren,
                                infants: guestsInfants,
                                pets: guestsPets,
                              })
                            )
                          }
                          min={1}
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-between ">
                      <div>
                        <div className="font-semibold">Children</div>
                        <div className="text-[#959595]">Age 2-12</div>
                      </div>
                      <div className="flex items-center">
                        <NumberSpin
                          value={guestsChildren}
                          onChange={(value) =>
                            dispatch(
                              setGuests({
                                adults: guestsAdults,
                                children: value,
                                infants: guestsInfants,
                                pets: guestsPets,
                              })
                            )
                          }
                          min={0}
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-between ">
                      <div>
                        <div className="font-semibold">Infants</div>
                        <div className="text-[#959595]">Under 2</div>
                      </div>
                      <div className="flex items-center">
                        <NumberSpin
                          value={guestsInfants}
                          onChange={(value) =>
                            dispatch(
                              setGuests({
                                adults: guestsAdults,
                                children: guestsChildren,
                                infants: value,
                                pets: guestsPets,
                              })
                            )
                          }
                          min={0}
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-between ">
                      <div>
                        <div className="font-semibold">Pets</div>
                        <div className="text-[#959595] underline">
                          Bringing a service animal?
                        </div>
                      </div>
                      <div className="flex items-center">
                        <NumberSpin
                          value={guestsPets}
                          onChange={(value) =>
                            dispatch(
                              setGuests({
                                adults: guestsAdults,
                                children: guestsChildren,
                                infants: guestsInfants,
                                pets: value,
                              })
                            )
                          }
                          min={0}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {searchMode === 5 ? (
                <div>
                  <div className="w-[340px] p-[20px] space-y-[10px]">
                    <div className="flex items-center justify-between ">
                      <div>
                        <div className="font-semibold">Bathroom</div>
                        <div className="text-[#959595]">Bathroom</div>
                      </div>
                      <div className="flex items-center">
                        {/* <img alt=""  src={minus}></img>
                  <div className="font-semibold">1</div>
                  <img alt=""  src={plus}></img> */}
                        <NumberSpin
                          value={bathroom}
                          onChange={(value) =>
                            dispatch(
                              setAmenities({
                                bathroom: value,
                                bedroom: bedroom,
                                squareFeet: squareFeet,
                              })
                            )
                          }
                          min={0}
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-between ">
                      <div>
                        <div className="font-semibold">Bedroom</div>
                        <div className="text-[#959595]">Bedroom</div>
                      </div>
                      <div className="flex items-center">
                        {/* <img alt=""  src={minus}></img>
                  <div className="font-semibold">1</div>
                  <img alt=""  src={plus}></img> */}
                        <NumberSpin
                          value={bedroom}
                          onChange={(value) =>
                            dispatch(
                              setAmenities({
                                bathroom: bathroom,
                                bedroom: value,
                                squareFeet: squareFeet,
                              })
                            )
                          }
                          min={0}
                        />
                      </div>
                    </div>

                    <div className="font-semibold">Square Feet</div>

                    <div className="grid grid-cols-2">
                      <div
                        className="flex items-center cursor-pointer"
                        onClick={() =>
                          dispatch(
                            setAmenities({
                              bathroom: bathroom,
                              bedroom: bedroom,
                              squareFeet: "<100m",
                            })
                          )
                        }
                      >
                        <Checkbox checked={squareFeet === "<100m"} />
                        <div>&lt;100m</div>
                      </div>
                      <div
                        className="flex items-center cursor-pointer"
                        onClick={() =>
                          dispatch(
                            setAmenities({
                              bathroom: bathroom,
                              bedroom: bedroom,
                              squareFeet: "500-1000m",
                            })
                          )
                        }
                      >
                        <Checkbox checked={squareFeet === "500-1000m"} />
                        <div>500-1000</div>
                      </div>
                      <div
                        className="flex items-center cursor-pointer"
                        onClick={() =>
                          dispatch(
                            setAmenities({
                              bathroom: bathroom,
                              bedroom: bedroom,
                              squareFeet: "100-500m",
                            })
                          )
                        }
                      >
                        <Checkbox checked={squareFeet === "100-500m"} />
                        <div>100-500</div>
                      </div>
                      <div
                        className="flex items-center cursor-pointer"
                        onClick={() =>
                          dispatch(
                            setAmenities({
                              bathroom: bathroom,
                              bedroom: bedroom,
                              squareFeet: "<1000m",
                            })
                          )
                        }
                      >
                        <Checkbox checked={squareFeet === "<1000m"} />
                        <div>&lt;1000m</div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Fade>
        }
      >
        <div>
          <SelectionGroup
            className={
              active
                ? "font-normal flex rounded-[40px] bg-[#efefef] shadow-[2px_0px_8px_3px_#eeeeee]"
                : "font-normal flex rounded-[40px] bg-white shadow-[2px_0px_8px_3px_#eeeeee]"
            }
            id={"searchbar"}
            SelectedItemMask={active ? "bg-white rounded-full" : ""}
            defaultItem={searchMode - 1}
          >
            <SelectionItem
              SelectedItem={
                <div className="flex items-center group">
                  <div
                    className={
                      searchMode === 1 && active
                        ? "bg-white truncate px-[24px] py-[12px] hover:bg-[#efefef] rounded-full shadow-md relative"
                        : "px-[24px] trucate py-[12px] hover:bg-[#efefef] rounded-full relative"
                    }
                    onClick={() => {
                      setSearchMode(1);
                      handleShow();
                    }}
                    onMouseEnter={() => setActiveHover(1)}
                    onMouseLeave={() => setActiveHover(null)}
                  >
                    <div className="text-[12px] font-semibold">Where</div>
                    <input
                      className="text-[14px] text-[#959595] w-[160px] mr-[20px]"
                      value={inputAddress}
                      onChange={(e) => setInputAddress(e.target.value)}
                      placeholder="Search Destination"
                    />
                    <>
                      {active && (region || formattedAddress) ? (
                        <div
                          className="absolute top-[22px] left-[190px] p-[4px] rounded-full hover:bg-[#c7c7c7]"
                          onClick={() => handleSetNull()}
                        >
                          <img alt="" src={cross} width={10} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  </div>
                  {searchMode === 1 || searchMode === 2 ? (
                    <div className="w-[1px] h-[30px] bg-[#efefef]" />
                  ) : (
                    <>
                      {activeHover === 1 || activeHover === 2 ? (
                        <div className="w-[1px] h-[30px] bg-white" />
                      ) : (
                        <div className="w-[1px] h-[30px] bg-[#cacaca]" />
                      )}
                    </>
                  )}
                </div>
              }
              UnselectedItem={
                <div className="flex items-center group">
                  <div
                    className={
                      searchMode === 1 && active
                        ? "bg-[#efefef] truncate px-[24px] py-[12px] cursor-pointer hover:bg-[#efefef] rounded-full shadow-md"
                        : "px-[24px] trucate py-[12px] cursor-pointer hover:bg-[#e0e0e0] rounded-full"
                    }
                    onClick={() => {
                      setSearchMode(1);
                      handleShow();
                    }}
                    onMouseEnter={() => setActiveHover(1)}
                    onMouseLeave={() => setActiveHover(null)}
                  >
                    <div className="text-[12px] font-semibold">Where</div>
                    <div className="text-[14px] text-[#959595] w-[180px] truncate overflow-hidden">
                      {inputAddress ? inputAddress : "Search Destination"}
                    </div>
                  </div>
                  {searchMode === 1 || searchMode === 2 ? (
                    <div className="w-[1px] h-[30px] bg-[#efefef]" />
                  ) : (
                    <>
                      {activeHover === 1 || activeHover === 2 ? (
                        <div className="w-[1px] h-[30px] bg-[#efefef]" />
                      ) : (
                        <div className="w-[1px] h-[30px] bg-[#cacaca]" />
                      )}
                    </>
                  )}
                </div>
              }
              selected={searchMode === 1 && active ? true : false}
            />
            <SelectionItem
              SelectedItem={
                <div className="flex items-center group">
                  <div
                    className={
                      searchMode === 2 && active
                        ? "bg-white truncate px-[24px] py-[12px] cursor-pointer hover:bg-[#efefef] rounded-full shadow-md relative"
                        : "px-[24px] trucate py-[12px] cursor-pointer hover:bg-[#efefef] rounded-full relative"
                    }
                    onClick={() => {
                      setSearchMode(2);
                      handleShow();
                    }}
                    onMouseEnter={() => setActiveHover(2)}
                    onMouseLeave={() => setActiveHover(null)}
                  >
                    <div className="text-[12px] truncate font-semibold">
                      Check In
                    </div>
                    <div className="text-[14px] text-[#959595] truncate w-[90px]">
                      {period[0] ? period[0].toString() : "Add Dates"}
                    </div>
                    <>
                      {active && period[0] ? (
                        <div
                          className="absolute top-[22px] left-[100px] p-[4px] rounded-full hover:bg-[#c7c7c7]"
                          onClick={() =>
                            dispatch(setRentPeriod([null, period[1]]))
                          }
                        >
                          <img alt="" src={cross} width={10} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  </div>
                  {searchMode === 3 || searchMode === 2 ? (
                    <div className="w-[1px] h-[30px] bg-[#efefef]" />
                  ) : (
                    <>
                      {activeHover === 3 || activeHover === 2 ? (
                        <div className="w-[1px] h-[30px] bg-white" />
                      ) : (
                        <div className="w-[1px] h-[30px] bg-[#cacaca]" />
                      )}
                    </>
                  )}
                </div>
              }
              UnselectedItem={
                <div className="flex items-center group">
                  <div
                    className={
                      searchMode === 2 && active
                        ? "bg-[#efefef] truncate px-[24px] py-[12px] cursor-pointer hover:bg-[#efefef] rounded-full shadow-md"
                        : "px-[24px] trucate py-[12px] cursor-pointer hover:bg-[#e0e0e0] rounded-full"
                    }
                    onClick={() => {
                      setSearchMode(2);
                      handleShow();
                    }}
                    onMouseEnter={() => setActiveHover(2)}
                    onMouseLeave={() => setActiveHover(null)}
                  >
                    <div className="text-[12px] truncate font-semibold">
                      Check In
                    </div>
                    <div className="text-[14px] text-[#959595] truncate w-[90px]">
                      {period[0] ? period[0].toString() : "Add Dates"}
                    </div>
                  </div>
                  {searchMode === 3 || searchMode === 2 ? (
                    <div className="w-[1px] h-[30px] bg-[#efefef]" />
                  ) : (
                    <>
                      {activeHover === 3 || activeHover === 2 ? (
                        <div className="w-[1px] h-[30px] bg-[#efefef]" />
                      ) : (
                        <div className="w-[1px] h-[30px] bg-[#cacaca]" />
                      )}
                    </>
                  )}
                </div>
              }
              selected={searchMode === 2 && active ? true : false}
            />
            <SelectionItem
              SelectedItem={
                <div className="flex items-center group">
                  <div
                    className={
                      searchMode === 3 && active
                        ? "bg-white truncate px-[24px] py-[12px] cursor-pointer hover:bg-[#efefef] rounded-full shadow-md relative"
                        : "px-[24px] trucate py-[12px] cursor-pointer hover:bg-[#efefef] rounded-full relative"
                    }
                    onClick={() => {
                      setSearchMode(3);
                      handleShow();
                    }}
                    onMouseEnter={() => setActiveHover(3)}
                    onMouseLeave={() => setActiveHover(null)}
                  >
                    <div className="text-[12px] truncate font-semibold">
                      Check Out
                    </div>
                    <div className="text-[14px] text-[#959595] truncate w-[90px]">
                      {period[1] ? period[1].toString() : "Add Dates"}
                    </div>
                    <>
                      {active && period[1] ? (
                        <div
                          className="absolute top-[22px] left-[100px] p-[4px] rounded-full hover:bg-[#c7c7c7]"
                          onClick={() =>
                            dispatch(setRentPeriod([period[0], null]))
                          }
                        >
                          <img alt="" src={cross} width={10} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  </div>
                  {searchMode === 3 || searchMode === 4 ? (
                    <div className="w-[1px] h-[30px] bg-[#efefef]" />
                  ) : (
                    <>
                      {activeHover === 3 || activeHover === 4 ? (
                        <div className="w-[1px] h-[30px] bg-white" />
                      ) : (
                        <div className="w-[1px] h-[30px] bg-[#cacaca]" />
                      )}
                    </>
                  )}
                </div>
              }
              UnselectedItem={
                <div className="flex items-center group">
                  <div
                    className={
                      searchMode === 3 && active
                        ? "bg-[#efefef] truncate px-[24px] py-[12px] cursor-pointer hover:bg-[#efefef] rounded-full shadow-md"
                        : "px-[24px] trucate py-[12px] cursor-pointer hover:bg-[#e0e0e0] rounded-full"
                    }
                    onClick={() => {
                      setSearchMode(3);
                      handleShow();
                    }}
                    onMouseEnter={() => setActiveHover(3)}
                    onMouseLeave={() => setActiveHover(null)}
                  >
                    <div className="text-[12px] truncate font-semibold">
                      Check Out
                    </div>
                    <div className="text-[14px] text-[#959595] truncate w-[90px]">
                      {period[1] ? period[1].toString() : "Add Dates"}
                    </div>
                  </div>
                  {searchMode === 3 || searchMode === 4 ? (
                    <div className="w-[1px] h-[30px] bg-[#efefef]" />
                  ) : (
                    <>
                      {activeHover === 3 || activeHover === 4 ? (
                        <div className="w-[1px] h-[30px] bg-[#efefef]" />
                      ) : (
                        <div className="w-[1px] h-[30px] bg-[#cacaca]" />
                      )}
                    </>
                  )}
                </div>
              }
              selected={searchMode === 3 && active ? true : false}
            />

            <SelectionItem
              SelectedItem={
                <div className="flex items-center group">
                  <div
                    className={
                      searchMode === 4 && active
                        ? "flex items-center w-[250px] justify-between cursor-pointer rounded-full relative shadow-md"
                        : "flex items-center w-[250px] justify-between cursor-pointer rounded-full relative hover:bg-[#e0e0e0]"
                    }
                    onClick={() => {
                      setSearchMode(4);
                      handleShow();
                    }}
                    onMouseEnter={() => setActiveHover(4)}
                    onMouseLeave={() => setActiveHover(null)}
                  >
                    <div className="px-[24px] py-[12px] rounded-full">
                      <div className="text-[12px] font-semibold">Who</div>
                      <div className="text-[14px] text-[#959595] w-[100px] truncate overflow-hidden">
                        {guestsAdults +
                          guestsChildren +
                          " guest," +
                          guestsInfants +
                          " infant," +
                          guestsPets +
                          " pet"}
                      </div>
                      <>
                        {active &&
                        (guestsAdults ||
                          guestsChildren ||
                          guestsInfants ||
                          guestsPets) ? (
                          <div
                            className="absolute top-[22px] left-[110px] p-[4px] rounded-full hover:bg-[#c7c7c7]"
                            onClick={() =>
                              dispatch(
                                setGuests({
                                  adults: 1,
                                  children: 0,
                                  infants: 0,
                                  pets: 0,
                                })
                              )
                            }
                          >
                            <img alt="" src={cross} width={10} />
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    </div>
                    <div
                      className="px-[14px] h-[52px] bg-[#5B1DEE] rounded-full text-white flex gap-[4px] items-center justify-center mr-[6px] cursor-pointer hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)] hover:bg-[#5B1DEE]"
                      onClick={handleSearch}
                    >
                      <img alt="" src={searchsm}></img>
                      {active ? (
                        <div className="transition-all ease-in-out">Search</div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              }
              UnselectedItem={
                <div className="flex items-center group">
                  <div
                    className={
                      searchMode === 4 && active
                        ? "flex items-center w-[250px] justify-between cursor-pointer rounded-full hover:bg-[#efefef] shadow-md"
                        : "flex items-center w-[250px] justify-between cursor-pointer rounded-full hover:bg-[#e0e0e0]"
                    }
                    onClick={() => {
                      setSearchMode(4);
                      handleShow();
                    }}
                    onMouseEnter={() => setActiveHover(4)}
                    onMouseLeave={() => setActiveHover(null)}
                  >
                    <div className="px-[24px] py-[12px]  rounded-full">
                      <div className="text-[12px] font-semibold">Who</div>
                      <div className="text-[14px] text-[#959595] w-[100px] truncate overflow-hidden">
                        {guestsAdults +
                          guestsChildren +
                          " guest," +
                          guestsInfants +
                          " infant," +
                          guestsPets +
                          " pet"}
                      </div>
                    </div>
                    <div
                      className="px-[14px] h-[52px] bg-[#5B1DEE] rounded-full text-white flex gap-[4px] items-center justify-center mr-[6px] cursor-pointer hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)] hover:bg-[#5B1DEE]"
                      onClick={handleSearch}
                    >
                      <img alt="" src={searchsm}></img>
                      {active ? (
                        <div className="transition-all ease-in-out">Search</div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              }
              selected={searchMode === 4 && active ? true : false}
            />

            {/* <SelectionItem
          SelectedItem={
            <div
              className={
                searchMode === 5 && active
                  ? "flex items-center w-[250px] justify-between cursor-pointer rounded-full relative shadow-md"
                  : "flex items-center w-[250px] justify-between cursor-pointer rounded-full relative hover:bg-[#e0e0e0]"
              }
              onClick={() => {
                setSearchMode(5);
                handleShow();
              }}
              onMouseEnter={() => setActiveHover(5)}
              onMouseLeave={() => setActiveHover(null)}
            >
              <div className="px-[24px] py-[12px] rounded-full">
                <div className="text-[12px] font-semibold">Amenities</div>
                <div className="text-[14px] text-[#959595] w-[80px] truncate overflow-hidden">
                  {bathroom +
                    " bathroom," +
                    bedroom +
                    " bedroom" +
                    squareFeet +
                    "square feet"}
                </div>

                <>
                  {active && (bathroom || bedroom || squareFeet !== "") ? (
                    <div
                      className="absolute top-[22px] left-[110px] p-[4px] rounded-full hover:bg-[#c7c7c7]"
                      onClick={() =>
                        dispatch(
                          setAmenities({
                            bathroom: 0,
                            bedroom: 0,
                            squareFeet: "",
                          })
                        )
                      }
                    >
                      <img alt="" src={cross} width={10} />
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              </div>

              <div
                className="px-[14px] h-[52px] bg-[#5B1DEE] rounded-full text-white flex gap-[4px] items-center justify-center mr-[6px] cursor-pointer hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)] hover:bg-[#5B1DEE]"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <img alt="" src={searchsm}></img>
                {active ? (
                  <div className="transition-all ease-in-out">Search</div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          }
          UnselectedItem={
            <div
              className={
                searchMode === 5 && active
                  ? "flex items-center w-[250px] justify-between cursor-pointer rounded-full hover:bg-[#efefef] shadow-md"
                  : "flex items-center w-[250px] justify-between cursor-pointer rounded-full hover:bg-[#e0e0e0]"
              }
              onClick={() => {
                setSearchMode(5);
                handleShow();
              }}
              onMouseEnter={() => setActiveHover(5)}
              onMouseLeave={() => setActiveHover(null)}
            >
              <div className="px-[24px] py-[12px]  rounded-full">
                <div className="text-[12px] font-semibold">Amenities</div>
                <div className="text-[14px] text-[#959595] w-[80px] truncate overflow-hidden">
                  {bathroom +
                    " bathroom," +
                    bedroom +
                    " bedroom" +
                    squareFeet +
                    "square feet"}
                </div>
              </div>

              <div
                className="px-[14px] h-[52px] bg-[#5B1DEE] rounded-full text-white flex gap-[4px] items-center justify-center mr-[6px] cursor-pointer hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)] hover:bg-[#5B1DEE]"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <img alt="" src={searchsm}></img>
                {active ? (
                  <div className="transition-all ease-in-out">Search</div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          }
        /> */}
          </SelectionGroup>
        </div>
      </Popover>
    </>
  );
};
