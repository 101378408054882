import { ReclaimClient } from "@reclaimprotocol/js-sdk";
import { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import QRCode from "react-qr-code";
import closeIcon from "../assets/images/close-mini.png";
import { toast } from "react-toastify";

export const Reclaim = ({ mode = 0, setProofs = () => {} }) => {
  const [url, setUrl] = useState();

  const [show, setShow] = useState(false);
  const APP_ID = "0xA7D39749b65f7CAd6Dc659807562eB65C0eebfd9";
  const APP_SECRET =
    "0x443abebcc159c8307b70e3d799ef464048c5d0e961b64517afdabda20f787288";
  const reclaimClient = new ReclaimClient(APP_ID); //TODO: replace with your applicationId

  async function generateVerificationRequest() {
    let providerIds = [];

    if (mode === 0) providerIds = ["19af-4f3b-8a4d-5ffca0e57c66"]; // Dubai Land Department - Tenant Verification V2
    if (mode === 1) providerIds = ["4f3b-8a4d-5ffca0e57c66"]; // Dubai Land Department - Metadata
    if (mode === 2) providerIds = ["330309b6-2c86-4071-a96c-e3da218fcbc7"]; // Dubai Land Department - Emirates Id
    const providers = await reclaimClient.buildHttpProviderV2ByID(providerIds);

    await reclaimClient.addContext({
      address: `user's address`,
      message: "for acmecorp.com on 1st january",
    });

    const requestProofs = reclaimClient.buildRequestedProofs(
      providers,
      reclaimClient.getAppCallbackUrl()
    );

    reclaimClient.setSignature(
      await reclaimClient.getSignature(
        requestProofs,
        APP_SECRET //TODO : replace with your APP_SECRET
      )
    );

    const reclaimReq = await reclaimClient.createVerificationRequest(
      providerIds
    );
    const requestUrl = await reclaimReq.start();

    setUrl(requestUrl);

    reclaimReq.on("success", function (proofs) {
      //TODO: your business logic
      setProofs(proofs?.profile?.emiratesId);
      setShow(false);
      toast.dismiss();
    });

    reclaimReq.on("error", function (errors) {
      //TODO: your business logic
      alert("Verification Failed!");
      setShow(false);
      toast.dismiss();
    });
  }

  useEffect(() => {
    if (url) {
      alert(url);
      setShow(true);
    }
  }, [url]);

  return (
    <div className="flex gap-[20px]">
      <div
        className="bg-[#202020] px-[40px] py-[10px] rounded-[16px] text-white text-center w-max mx-auto cursor-pointer hover:shadow-[-1px_6px_10px_0_rgba(93,0,207,0.5)] hover:-translate-y-[3px]"
        onClick={() => {
          toast.loading("Generating proofs..");
          generateVerificationRequest();
        }}
      >
        {mode === 0 ? "Tenant Verification V2" : ""}
        {mode === 1 ? "Metadata" : ""}
        {mode === 2 ? "Verify Emirates ID" : ""}
      </div>
      {/* <div
        className="bg-[#5B1DEE] px-[40px] py-[10px] rounded-[16px] text-white text-center w-max mx-auto cursor-pointer"
        onClick={() => window.open(url, "_blank")}
      >
        Open link
      </div> */}

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          toast.dismiss();
        }}
        centered
        style={{ fontFamily: "General Sans" }}
      >
        <Modal.Body>
          <div className="p-[20px] space-y-[10px] relative">
            <img
              alt=""
              src={closeIcon}
              className="absolute right-[16px] top-[16px] cursor-pointer"
              onClick={() => {
                setShow(false);
                toast.dismiss();
              }}
            />
            <div className="text-center font-serif drop-shadow-lg text-[24px] text-[#5B1DEE]">
              Scan your code
            </div>
            <QRCode value={url} fgColor="#5B1DEE" />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
