import image from "../../assets/images/image.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { showGallery } from "../../Actions/GallerySlice";

export const ImageView = ({ counts = 5, imagesArray, imageHeight = 200 }) => {
  const nft = useSelector((state) => state.nft.currentNFT);

  const images = useSelector(
    (state) => state.nft.allNFTs[nft]?.metaData?.Images
  );

  const dispatch = useDispatch();

  // const imagesURL = [];

  // const pushImages = () => {
  //   for (let i = 0; i < images?.length; i++) {
  //     imagesURL.push(gateWay + images[i]);
  //   }
  // };

  // pushImages();

  return (
    <>
      {!imagesArray ? (
        <>
          {images ? (
            <div
              className={`shadow-md bg-white p-[2px] rounded-[10px] cursor-pointer ${
                counts > 2 ? "grid grid-cols-2" : ""
              } gap-[4px]`}
              onClick={() => dispatch(showGallery(images))}
            >
              {counts == 1 ? (
                <img
                  alt=""
                  src={images[0]}
                  className={`w-full max-h-[${imageHeight}px] rounded-[10px]`}
                ></img>
              ) : (
                <></>
              )}
              {counts == 3 ? (
                <>
                  <img alt="" src={images[0]} className="w-full h-full"></img>
                  <div className="space-y-[10px]">
                    {images.map((item, i) => {
                      if (i > 0 && i < 3)
                        return <img alt="" src={item} className="w-full"></img>;
                    })}
                  </div>
                </>
              ) : (
                <></>
              )}
              {counts == 5 ? (
                <>
                  <img
                    alt=""
                    src={images[0]}
                    className="w-full max-h-[400px]"
                  ></img>
                  <div className="grid grid-cols-2 gap-[4px]">
                    {images.map((item, i) => {
                      if (i > 0 && i < 5)
                        return <img alt="" src={item} className="w-full"></img>;
                    })}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <div
            className={`shadow-md bg-white p-[8px] rounded-[10px] cursor-pointer h-max ${
              counts > 2 ? "grid grid-cols-2" : ""
            } gap-[8px]`}
            onClick={() => dispatch(showGallery(imagesArray))}
          >
            {counts == 1 ? (
              <img
                alt=""
                src={imagesArray[0]}
                className={`w-full max-h-[${imageHeight}px] rounded-[10px]`}
              ></img>
            ) : (
              <></>
            )}
            {counts == 3 ? (
              <>
                <img
                  alt=""
                  src={imagesArray[0]}
                  className="w-full h-full"
                ></img>
                <div className="space-y-[10px]">
                  {imagesArray.map((item, i) => {
                    if (i > 0 && i < 3)
                      return <img alt="" src={item} className="w-full"></img>;
                  })}
                </div>
              </>
            ) : (
              <></>
            )}
            {counts == 5 ? (
              <>
                <div class="relative w-full group overflow-hidden bg-cover bg-[50%] bg-no-repeat">
                  <img
                    alt=""
                    src={imagesArray[0]}
                    className="w-full h-[calc(50vh-16px)] transition duration-500 rounded-[8px]"
                  ></img>
                  <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden rounded-[8px] group-hover:bg-[#2c2c2c88] bg-fixed opacity-50"></div>
                </div>

                <div className="grid grid-cols-2 gap-[8px] h-full">
                  {imagesArray.map((item, i) => {
                    if (i > 0 && i < 5)
                      return (
                        <div class="relative w-full group overflow-hidden bg-cover bg-[50%] bg-no-repeat">
                          <img
                            alt=""
                            src={item}
                            className="hover:bg-blend-darken w-full h-[calc(25vh-12px)] transition duration-500 rounded-[8px]"
                          ></img>
                          <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden rounded-[8px] group-hover:bg-[#2c2c2c88] bg-fixed opacity-50"></div>
                        </div>
                      );
                  })}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </>
  );
};
