import listed from "../../assets/images/Frame 1000001303.png";
import unverified from "../../assets/images/Frame 1000001303 (1).png";
import upcoming from "../../assets/images/Frame 1000001303 (2).png";
import rent from "../../assets/images/Frame 1000001303 (3).png";
import { SelectionGroup, SelectionItem } from "../Global/Selection";
import { useDispatch } from "react-redux";
import { setDashboardMode } from "../../Actions/DashboardSlice";
import { CarouselCompo } from "../Global/CarouselCompo";
import light from "../../assets/images/light.svg";
import search from "../../assets/images/tabler-icon-search.png";
import slash from "../../assets/images/slash.png";
import windowIcon from "../../assets/images/window.png";
import list from "../../assets/images/list.png";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { executeContract } from "../functional/InteractToContract";
import uploadimage from "../../assets/images/dashboardListing/uploadimage.png";
import BackIcon from "../../assets/images/claim/Back.svg";
import backArrow from "../../assets/images/Arrow1.svg";

import { setNFT, setNFTsSection } from "../../Actions/NFTSlice";
import { getMyNFTsInfo } from "../functional/InteractToIPFS";
import axios from "axios";
import { getMetadata, pinMetadata } from "../functional/InteractToIPFS";
import circlechecked from "../../assets/images/Frame 1000005306 (1).png";
import ImageUploading from "react-images-uploading";
import deleteIcon from "../../assets/images/dashboard/deleteIcon.svg";

import { PropertyDetail } from "../Rental/PropertyDetail";
import { PropertyAddress } from "../Rental/PropertyAddress";
import { ImageView } from "../Global/ImageView";
import { PropertyListingDetail } from "../Rental/PropertyListingDetail";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { deletePinFromIPFS } from "../functional/InteractToIPFS";
import { Modal } from "react-bootstrap";
import cross from "../../assets/images/Header/Group 1000004790.svg";
import { pinFileToIPFS } from "../functional/InteractToIPFS";
import { Testnet } from "@nibiruchain/nibijs";
import { Reviews } from "../Reviews";
import { getCurrentUTCTime } from "../functional/GetCurrentUTCTime";

const testNet = Testnet(1);

export const HostNFTs = ({ assets = [] }) => {
  const [totalListedNFTsCount, setTotalListedNFTsCount] = useState(0);
  const [totalUnverifedNFTsCount, setTotalUnverifiedNFTsCount] = useState(0);
  const [totalUpcomingCount, setTotalUpcomingCount] = useState(0);
  const [totalCompletedCount, setTotalCompletedCount] = useState(0);
  const dispatch = useDispatch();
  const [NFTsFlag, setNFTsFlag] = useState(0);
  // const NFTsFlag = useSelector((state) => state.nft.NFTsSection);
  const account = useSelector((state) => state.auth.account);
  const walletEx = useSelector((state) => state.auth.wallet);

  const nft = useSelector((state) => state.nft.currentNFT);
  const uploadedImages = useSelector(
    (state) => state.nft.allNFTs[nft]?.metaData?.Images
  );
  const [currentImages, setCurrentImages] = useState([]);
  useEffect(() => {
    setCurrentImages(uploadedImages);
  }, [uploadedImages]);

  const onImageRemoveFromCurrent = (index) => {
    let tempArray = [...currentImages];

    const toDelete = tempArray.splice(index, 1);
    deletePinFromIPFS(
      toDelete.toString().replace(process.env.REACT_APP_PINATA_GATEWAY_2, ""),
      process.env.REACT_APP_PINATA_JWT_2
    );
    setCurrentImages(tempArray);
  };

  const [currentToken, setCurrentToken] = useState();
  const [actionMode, setActionMode] = useState(null);
  const [images, setImages] = useState([]);
  const maxNumber = 69;

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalValue, setModalValue] = useState();
  // const [propertyID, setPropertyID] = useState("10261289");
  const [propertyName, setName] = useState();
  const [description, setDescription] = useState();
  const [groundRule, setGroundRule] = useState();
  const [wifiPass, setWifiPass] = useState();
  const [currentTime, setCurrentTime] = useState();

  const setTime = async () => {
    setCurrentTime(new Date(await getCurrentUTCTime()));
  };

  useEffect(() => {
    setTime();
  }, []);
  // const assets = useSelector((state) => state.nft.allNFTs);
  // const description = useSelector(
  //   (state) => state.nft.uploadingData.description
  // );
  // const offers = useSelector((state) => state.nft.uploadingData.offers);

  const updateMetadata = async (hash, key_values) => {
    const metaData = {
      ...(await getMetadata(process.env.REACT_APP_PINATA_GATEWAY_2 + hash)),
    };
    // console.log(Object.isExtensible(metaData), metaData);

    await deletePinFromIPFS(hash, process.env.REACT_APP_PINATA_JWT_2);
    for (let i = 0; i < key_values.length; i++) {
      metaData[key_values[i].key] = key_values[i].value;
    }
    console.log(metaData);
    const newHash = await pinMetadata(
      metaData,
      metaData.Name,
      process.env.REACT_APP_PINATA_JWT_2
    );
    return newHash;
  };
  const onUploadingImagesChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  const updateTokenURI = async (token_id, newTokenURI) => {
    const updateMessage = {
      set_metadata: {
        token_id: token_id,
        token_uri: newTokenURI,
      },
    };
    await executeContract(
      testNet.chainId,
      testNet.endptTm,
      process.env.REACT_APP_RENTAL_SMART_CONTRACT,
      dispatch,
      token_id,
      account,
      updateMessage,
      account,
      walletEx
    );
    // setUpdateFlag(!updateFlag);
    // dispatch(setDashboardMode(2));
  };
  useEffect(() => {
    let countlisted = 0,
      countunverifed = 0,
      upcoming = 0,
      completed = 0;
    for (let i = 0; i < assets.length; i++) {
      if (assets[i].shorttermrental_info.islisted) countlisted++;
      if (!assets[i].metaData?.Images) countunverifed++;
      for (
        let j = 0;
        j < assets[i].shorttermrental_info.travelers.length;
        j++
      ) {
        if (
          currentTime <
          new Date(
            assets[i].shorttermrental_info.travelers[j].renting_period[0] * 1000
          )
        ) {
          upcoming++;
        }
        // if (
        //   currentTime >
        //   new Date(
        //     assets[i].shorttermrental_info.travelers[j].renting_period[1] * 1000
        //   )
        // )
        else {
          completed++;
        }
      }
    }
    setTotalListedNFTsCount(countlisted);
    setTotalUnverifiedNFTsCount(countunverifed);
    setTotalUpcomingCount(upcoming);
    setTotalCompletedCount(completed);
  }, [assets, currentTime]);

  const setListNFT = async (
    token_id,
    denom,
    price_per_day,
    auto_approve,
    available_period,
    minimum_stay,
    cancellation
  ) => {
    const listingMessage = {
      set_list_for_short_term_rental: {
        token_id: token_id,
        denom: denom,
        price_per_day: price_per_day,
        auto_approve: auto_approve,
        available_period: available_period,
        minimum_stay: minimum_stay,
        cancellation: cancellation,
      },
    };

    await executeContract(
      testNet.chainId,
      testNet.endptTm,
      process.env.REACT_APP_RENTAL_SMART_CONTRACT,
      dispatch,
      token_id,
      account,
      listingMessage,
      account,
      walletEx
    );

    setActionMode(null);
    dispatch(setNFTsSection(2));
  };

  const setUnlistNFT = async (token_id) => {
    const message = {
      set_unlist_for_shortterm_rental: {
        token_id: token_id,
      },
    };
    await executeContract(
      testNet.chainId,
      testNet.endptTm,
      process.env.REACT_APP_RENTAL_SMART_CONTRACT,
      dispatch,
      token_id,
      account,
      message,
      account,
      walletEx
    );

    setActionMode(null);
    dispatch(setNFTsSection(0));
  };

  useEffect(() => {
    setName(currentToken?.metaData.Name);
    setDescription(currentToken?.metaData.Description);
    setGroundRule(currentToken?.metaData.GroundRules);
    setWifiPass(currentToken?.metaData.Wifi);
  }, [currentToken]);

  const editMetaData = () => {
    setActionMode(false);
  };

  // const pinFileToIPFS = async () => {
  //   if (images.length) toast.info("Uploading images..");
  //   const hashes = [];
  //   for (let i = 0; i < images.length; i++) {
  //     const formData = new FormData();

  //     formData.append("file", images[i].file);

  //     try {
  //       const res = await axios.post(
  //         "https://api.pinata.cloud/pinning/pinFileToIPFS",
  //         formData,
  //         {
  //           maxBodyLength: "Infinity",
  //           headers: {
  //             "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
  //             Authorization: `Bearer ${jwt}`,
  //           },
  //         }
  //       );
  //       console.log(res.data);
  //       hashes.push(res.data.IpfsHash);
  //     } catch (error) {
  //       toast.dismiss();
  //       toast.error(
  //         `Uploading failed with following errors ${error.toString()}`,
  //         {
  //           autoClose: 1000,
  //         }
  //       );
  //       console.log(error);
  //     }
  //   }
  //   if (images.length) {
  //     toast.dismiss();
  //     toast.success("Images uploaded.", { autoClose: 1000 });
  //   }
  //   return hashes;
  // };

  const handleUpdateMetadata = async () => {
    let currentURI = currentToken.nft_info.info.token_uri.replace(
      process.env.REACT_APP_PINATA_GATEWAY_2,
      ""
    );
    let imageHashes = [
      ...currentImages,
      ...(await pinFileToIPFS(
        images,
        process.env.REACT_APP_PINATA_JWT_2,
        process.env.REACT_APP_PINATA_GATEWAY_2
      )),
    ];

    const newHash = await updateMetadata(currentURI, [
      {
        key: "Images",
        value: imageHashes,
      },
      {
        key: "Name",
        value: propertyName,
      },
      {
        key: "Description",
        value: description,
      },
      {
        key: "GroundRules",
        value: groundRule,
      },
      {
        key: "Wifi",
        value: wifiPass,
      },
    ]);

    await updateTokenURI(
      currentToken.token_id,
      process.env.REACT_APP_PINATA_GATEWAY_2 + newHash
    );
    setImages([]);
    setActionMode(true);
  };

  return (
    <>
      {actionMode == null ? (
        <>
          <div className="p-[20px]">
            <div className="text-[#8C8C8C]">My Real Estate NFTs</div>
            <div className="my-[20px] text-[18px] font-semibold">
              Your Real Estate Status
            </div>
            <div className="grid grid-cols-4 gap-[16px]">
              <div className="p-[16px] rounded-[8px] shadow-md flex items-center justify-between">
                <div className="flex items-center gap-[10px]">
                  <img alt="" src={listed}></img>
                  <div className="text-[#8C8C8C]">Total NFTs Listed</div>
                </div>
                <div className="text-[24px]">{totalListedNFTsCount}</div>
              </div>
              <div className="p-[16px] rounded-[8px] shadow-md flex items-center justify-between">
                <div className="flex items-center gap-[10px]">
                  <img alt="" src={unverified}></img>
                  <div className="text-[#8C8C8C]">Total NFTs Unverified</div>
                </div>
                <div className="text-[24px]">{totalUnverifedNFTsCount}</div>
              </div>
              <div className="p-[16px] rounded-[8px] shadow-md flex items-center justify-between">
                <div className="flex items-center gap-[10px]">
                  <img alt="" src={upcoming}></img>
                  <div className="text-[#8C8C8C]">Upcoming</div>
                </div>
                <div className="text-[24px]">{totalUpcomingCount}</div>
              </div>
              <div className="p-[16px] rounded-[8px] shadow-md flex items-center justify-between">
                <div className="flex items-center gap-[10px]">
                  <img alt="" src={rent}></img>
                  <div className="text-[#8C8C8C]">Recent Active Rent</div>
                </div>
                <div className="text-[24px]">{totalCompletedCount}</div>
              </div>
            </div>
          </div>
          <div className="p-[16px] border-y-[1px] border-[#EDEDED] flex justify-between">
            <div>
              <SelectionGroup
                defaultItem={NFTsFlag}
                className="p-[6px] shadow-md gap-[8px] rounded-[16px] flex w-max text-[#959595] items-center"
                SelectedItemMask="shadow-md rounded-[16px]"
              >
                <SelectionItem
                  SelectedItem={
                    <div className="w-[160px] rounded-[10px] flex items-center justify-between gap-[10px] px-[10px] py-[4px] text-[#202020]">
                      <div>Verified NFTs</div>
                      <img alt="" src={light}></img>
                    </div>
                  }
                  UnselectedItem={
                    <div
                      className="w-[160px] rounded-[10px] flex items-center justify-between gap-[10px] px-[10px] py-[4px]"
                      onClick={() => setNFTsFlag(0)}
                    >
                      <div>Verified NFTs</div>
                    </div>
                  }
                />
                <SelectionItem
                  SelectedItem={
                    <div className="w-[160px] rounded-[10px] flex items-center justify-between gap-[10px] px-[10px] py-[4px] text-[#202020]">
                      <div>Unverified NFTs</div>
                      <img alt="" src={light}></img>
                    </div>
                  }
                  UnselectedItem={
                    <div
                      className="w-[160px] rounded-[10px] flex items-center justify-between gap-[10px] px-[10px] py-[4px]"
                      onClick={() => setNFTsFlag(1)}
                    >
                      <div>Unverified NFTs</div>
                    </div>
                  }
                />
                <SelectionItem
                  SelectedItem={
                    <div className="w-[160px] rounded-[10px] flex items-center justify-between gap-[10px] px-[10px] py-[4px] text-[#202020]">
                      <div>My Listings</div>
                      <img alt="" src={light}></img>
                    </div>
                  }
                  UnselectedItem={
                    <div
                      className="w-[160px] rounded-[10px] flex items-center justify-between gap-[10px] px-[10px] py-[4px]"
                      onClick={() => setNFTsFlag(2)}
                    >
                      <div>My Listings</div>
                    </div>
                  }
                />
              </SelectionGroup>
            </div>

            <div className="flex items-center gap-[20px]">
              <div className="flex items-center shadow-md rounded-[4px] px-[12px] py-[6px] gap-[12px]">
                <img alt="" src={search}></img>
                <input placeholder="Search" className="w-full" />
                <img alt="" src={slash}></img>
              </div>

              <div className="items-center flex">
                <div className="p-[8px] bg-[#F2F2F2]">
                  <img alt="" src={windowIcon}></img>
                </div>
                <div className="p-[8px]">
                  <img alt="" src={list}></img>
                </div>
              </div>
            </div>
          </div>
          <div className="p-[20px] h-full">
            <div className="grid grid-cols-4 gap-[20px]">
              {/* <PropertyItem /> */}

              {assets.map((item) => {
                if (item.shorttermrental_info.islisted && NFTsFlag === 2)
                  return (
                    <CarouselCompo
                      mode={"short"}
                      nftInfo={item.nft_info}
                      metaData={item.metaData}
                      price={item.shorttermrental_info.price_per_day}
                      totalPriceHide={true}
                      imageHeight={"200px"}
                      btn={
                        <div
                          className="bg-[#5B1DEE] py-[10px] text-center mt-[10px] cursor-pointer px-[18px]  text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] group-hover:shadow-[-1px_6px_10px_0_rgba(93,0,207,0.5)] group-hover:-translate-y-[3px]"
                          onClick={() => {
                            dispatch(setNFT(item.token_id));
                            setCurrentToken(item);
                            setActionMode(true);
                          }}
                        >
                          Unlist
                        </div>
                      }
                    />
                  );
                if (
                  !item.shorttermrental_info.islisted &&
                  item.metaData?.Images &&
                  NFTsFlag === 0
                )
                  return (
                    <CarouselCompo
                      nftInfo={item.nft_info}
                      metaData={item.metaData}
                      totalPriceHide={true}
                      PriceHide={true}
                      emptyImage={uploadimage}
                      imageHeight={"200px"}
                      btn={
                        <div
                          className="bg-[#5B1DEE] py-[10px] text-center mt-[10px] cursor-pointer px-[18px]  text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] group-hover:shadow-[-1px_6px_10px_0_rgba(93,0,207,0.5)] group-hover:-translate-y-[3px]"
                          onClick={() => {
                            dispatch(setNFT(item.token_id));
                            setCurrentToken(item);
                            setActionMode(true);
                          }}
                        >
                          List
                        </div>
                      }
                    />
                  );
                if (
                  !item.shorttermrental_info.islisted &&
                  !item.metaData?.Images &&
                  NFTsFlag === 1
                )
                  return (
                    <CarouselCompo
                      nftInfo={item.nft_info}
                      metaData={item.metaData}
                      totalPriceHide={true}
                      PriceHide={true}
                      emptyImage={uploadimage}
                      imageHeight={"200px"}
                      btn={
                        <div
                          className="bg-[#5B1DEE] py-[10px] text-center mt-[10px] cursor-pointer px-[18px] text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-full group-hover:shadow-[-1px_6px_10px_0_rgba(93,0,207,0.5)] group-hover:-translate-y-[3px]"
                          onClick={() => {
                            setActionMode(false);
                            dispatch(setNFT(item.token_id));
                            setCurrentToken(item);
                          }}
                        >
                          Verify
                        </div>
                      }
                    />
                  );
                return "";
              })}
            </div>
            {/* <div className="w-max shadow-md rounded-[10px] p-[10px] h-max">
<img alt=""  src={image} className="rounded-[10px] w-full h-[200px]"></img>

<div className="flex items-center ml-[10px] my-[10px] font-semibold text-[14px] gap-[10px]">
  <img alt=""  src={NUSD}></img>
  <div>10000 NUSD</div>
</div>
<div className="flex items-center text-[#959595] ml-[10px] gap-[10px]">
  <img alt=""  src={LocationPin}></img>
  <div>Los Angeles, CA</div>
</div>
<div className="flex items-center gap-[10px] text-[#959595]">
  <img alt=""  src={icon}></img>
  <div>350</div>

  <div className="w-[2px] h-[15px] bg-[#D9D9D9]"></div>
  <img alt=""  src={icon1}></img>
  <div>5</div>
  <div className="w-[2px] h-[15px] bg-[#D9D9D9]"></div>

  <img alt=""  src={icon2}></img>
  <div>1</div>
</div>

<div className="bg-[#5B1DEE] px-[16px] py-[10px] rounded-[16px] text-white text-center">
  Verify
</div>
</div> */}
            {/* <div className="w-full shadow-md bg-[#F6F6F6] h-full flex flex-col">
<div className="m-auto flex flex-col">
  <img alt=""  src={empty} className="m-auto"></img>
  <div>You don´t have any verified NFTs minted yet.</div>
  <div className="m-auto flex gap-[30px] text-[#6B349A]">
    <div className="underline">Listing page</div>
    <div className="underline">Mint</div>
  </div>
</div>
</div> */}
          </div>
        </>
      ) : (
        <></>
      )}
      {actionMode === false ? (
        <>
          <div className="p-[16px]">
            <div className="w-full flex justify-between items-center">
              <img alt="" src={BackIcon} onClick={() => setActionMode(true)} />
              <div
                className="px-[14px] py-[6px] rounded-[100px] shadow-md cursor-pointer text-white bg-[#4C37C3]"
                onClick={() => handleUpdateMetadata()}
              >
                Save Metadata
              </div>
            </div>
            <div className="w-full flex flex-col items-center">
              <div className="text-[24px] font-semibold">Edit Metadata</div>
              {/* <div className="text-[#595959]">
                Help your place stand out to get booked faster and earn your
                first reviews.
              </div>
              <div className="flex items-center mt-[40px]">
                <img alt=""  src={circlechecked}  />
                <div className="bg-[#EFE8FD] w-[160px] h-[4px]" />
                <img alt=""  src={circlechecked}  />
                <div className="bg-[#EFE8FD] w-[160px] h-[4px]" />
                <img alt=""  src={circlechecked}  />
              </div>
              <div className="grid grid-cols-3 justify-items-center gap-[60px]">
                <div>Autofill Metadata</div>
                <div>Manual Metadata</div>
                <div>Finished</div>
              </div> */}
              <div className="p-[16px] rounded-[12px] shadow-md w-[600px] h-[400px] space-y-[12px] flex flex-col my-[20px] select-none">
                <ImageUploading
                  multiple
                  value={images}
                  onChange={onUploadingImagesChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                  acceptType={[
                    "jpg",
                    "jpeg",
                    "png",
                    "gif",
                    "bmp",
                    "tiff",
                    "tif",
                    "svg",
                    "webp",
                    "heic",
                    "heif",
                  ]}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    <div
                      className={
                        isDragging
                          ? "border-[2px] border-dashed rounded-[12px] shadow-md flex w-full h-full bg-[#dddddd]"
                          : "border-[2px] border-dashed rounded-[12px] shadow-md flex w-full h-full"
                      }
                      {...dragProps}
                    >
                      <div className="m-auto flex flex-col items-center space-y-[20px]">
                        {imageList.length || currentImages?.length ? (
                          <div className="flex gap-[10px] w-full overflow-auto">
                            {currentImages?.map((hash, index) => {
                              return (
                                <div className="mx-auto flex flex-col items-center group">
                                  <img
                                    alt=""
                                    src={hash}
                                    className="w-[120px] h-[100px] rounded-[5px]"
                                  />

                                  <img
                                    alt=""
                                    className="cursor-pointer w-[20px] hidden group-hover:block"
                                    src={deleteIcon}
                                    onClick={() => {
                                      onImageRemoveFromCurrent(index);
                                      onImageRemove(-1);
                                    }}
                                  />
                                </div>
                              );
                            })}
                            {imageList.map((im, index) => {
                              return (
                                <div className="mx-auto flex flex-col items-center group">
                                  <img
                                    alt=""
                                    src={im.data_url}
                                    className="w-[120px] h-[100px] rounded-[5px]"
                                  />

                                  <img
                                    alt=""
                                    className="cursor-pointer w-[20px] hidden group-hover:block"
                                    src={deleteIcon}
                                    onClick={() => onImageRemove(index)}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <img alt="" src={uploadimage}></img>
                        )}
                        <div
                          className="text-white px-[16px] py-[9px] rounded-[40px] bg-[#4C37C3] cursor-pointer"
                          style={isDragging ? { color: "red" } : null}
                          onClick={onImageUpload}
                        >
                          Upload file
                        </div>
                        <div className="text-[#595959]">
                          ... or drag and drop files
                        </div>
                      </div>
                    </div>
                  )}
                </ImageUploading>

                {/* <div className="w-full flex justify-end">
                  <div
                    className="underline cursor-pointer"
                  >
                    Edit
                  </div>
                </div> */}
              </div>
              {/* <div className="w-[600px]">
                <PropertyDetail editable={true} />
              </div> */}
              <Modal
                centered
                show={showModal}
                onHide={() => setShowModal(false)}
              >
                <Modal.Body>
                  <div className="w-[400px] h-max py-[10px] px-[20px]">
                    <div className="w-full flex justify-between mb-[10px] items-center">
                      <div className="text-[20px] font-semibold">
                        {modalTitle}
                      </div>
                      <img
                        alt=""
                        onClick={() => setShowModal(false)}
                        src={cross}
                        className="w-[40px] cursor-pointer"
                      />
                    </div>
                    <div className="globalInputForm p-[16px] rounded-[8px]">
                      <textarea
                        className="w-full h-[200px]"
                        value={modalValue}
                        onChange={(e) => setModalValue(e.target.value)}
                      />
                    </div>
                    <div className="flex justify-end">
                      <div
                        className="text-white cursor-pointer w-max rounded-[16px] mt-[10px] py-[8px] px-[20px] bg-[#5b1dee]"
                        onClick={() => {
                          if (modalTitle === "Building Name") {
                            setName(modalValue);
                            setShowModal(false);
                          }
                          if (modalTitle === "Ground Rules") {
                            setGroundRule(modalValue);
                            setShowModal(false);
                          }
                          if (modalTitle === "Wifi Password") {
                            setWifiPass(modalValue);
                            setShowModal(false);
                          }
                          if (modalTitle === "Description") {
                            setDescription(modalValue);
                            setShowModal(false);
                          }
                        }}
                      >
                        Save
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              <div className="w-[600px] h-max shadow-md rounded-[8px] p-[20px] space-y-[10px]">
                <div>
                  <div className="flex justify-between items-center">
                    <div className="text-[18px]">Building Name</div>
                    <div
                      className="underline cursor-pointer"
                      onClick={() => {
                        setModalTitle("Building Name");
                        setModalValue(propertyName);
                        setShowModal(true);
                      }}
                    >
                      Edit
                    </div>
                  </div>
                  <div className="text-[#8d8d8d]">{propertyName}</div>
                </div>
                {/* <div>
                  <div className="flex justify-between items-center">
                    <div className="text-[18px]">Property ID *</div>
                    <div
                      className="underline cursor-pointer"
                      onClick={() => {
                        setModalTitle("Property ID");
                        setModalValue(propertyID);
                        setShowModal(true);
                      }}
                    >
                      Edit
                    </div>
                  </div>
                  <div className="text-[#8d8d8d]">{propertyID}</div>
                </div> */}
                {/* <div>
                    <div className="flex justify-between items-center">
                      <div className="text-[18px]">Address</div>
                      <div
                        className="underline cursor-pointer"
                        onClick={() => {
                          setFlow(1);
                        }}
                      >
                        Edit
                      </div>
                    </div>
                    <div className="text-[#8d8d8d]">{address}</div>
                    <div className="text-[#8d8d8d]">
                      {Object.keys(addressDetails).map((item) => {
                        return (
                          <div>
                            {item} {addressDetails[item]}
                          </div>
                        );
                      })}
                    </div>
                  </div> */}
                {/* <div>
                    <div className="flex justify-between items-center">
                      <div className="text-[18px]">Amenities</div>
                      <div
                        className="underline cursor-pointer"
                        onClick={() => {
                          setFlow(3);
                        }}
                      >
                        Edit
                      </div>
                    </div>
                    <div className="text-[#8d8d8d]">
                      {Object.keys(amenties).map((item) => {
                        return (
                          <div>
                            {item} {amenties[item]}
                          </div>
                        );
                      })}
                    </div>
                  </div> */}
                <div>
                  <div className="flex justify-between items-center">
                    <div className="text-[18px]">Description</div>
                    <div
                      className="underline cursor-pointer"
                      onClick={() => {
                        setModalTitle("Description");
                        setModalValue(description);
                        setShowModal(true);
                      }}
                    >
                      Edit
                    </div>
                  </div>
                  <div className="text-[#8d8d8d]">{description}</div>
                </div>
                <div>
                  <div className="flex justify-between items-center">
                    <div className="text-[18px]">Ground Rules</div>
                    <div
                      className="underline cursor-pointer"
                      onClick={() => {
                        setModalTitle("Ground Rules");
                        setModalValue(groundRule);
                        setShowModal(true);
                      }}
                    >
                      Edit
                    </div>
                  </div>
                  <div className="text-[#8d8d8d]">{groundRule}</div>
                </div>
                <div>
                  <div className="flex justify-between items-center">
                    <div className="text-[18px]">Wifi Password</div>
                    <div
                      className="underline cursor-pointer"
                      onClick={() => {
                        setModalTitle("Wifi Password");
                        setModalValue(wifiPass);
                        setShowModal(true);
                      }}
                    >
                      Edit
                    </div>
                  </div>
                  <div className="text-[#8d8d8d]">{wifiPass}</div>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-end h-[50px]">
              {/* <div className="px-[20px] py-[12px] rounded-[16px] bg-[#5B1DEE] text-white shadow-md">
                Next
              </div> */}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {actionMode === true ? (
        <>
          <div className="w-full bg-[#f6f6f6] p-[16px]">
            <div className="w-[60vw] flex flex-col space-y-[16px] mx-auto">
              <div
                className="bg-white w-full flex items-center rounded-[16px] p-[16px] shadow-md cursor-pointer"
                onClick={() => setActionMode(null)}
              >
                <img alt="" src={backArrow} />
                <div>Back to NFTs</div>
              </div>
              <PropertyAddress />
              <ImageView />

              <div className="grid grid-cols-2 gap-[16px]">
                <PropertyDetail />
                <PropertyListingDetail
                  mode={"short"}
                  token_id={currentToken.token_id}
                  setListNFT={setListNFT}
                  editMetaData={editMetaData}
                  setUnlistNFT={setUnlistNFT}
                />
              </div>
              <Reviews tokenId={currentToken.token_id} note />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
