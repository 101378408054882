import { useSelector, useDispatch } from "react-redux";
import close from "../../assets/images/gallery/close-mini.png";
import share from "../../assets/images/gallery/Send message, Send, Share.png";
import save from "../../assets/images/gallery/heart-round-circle.png";
import previous from "../../assets/images/gallery/Frame 1000005411.png";
import next from "../../assets/images/gallery/Frame 1000005412.png";
import { hideGallery } from "../../Actions/GallerySlice";
import { Carousel } from "react-bootstrap";
import { useState } from "react";
import backIcon from "../../assets/images/claim/Back.svg";

export const Gallery = () => {
  const show = useSelector((state) => state.gallery.show);
  const images = useSelector((state) => state.gallery.images);
  const dispatch = useDispatch();

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const [flag, setFlag] = useState(false);

  return (
    <div hidden={!show}>
      {flag ? (
        <div className="absolute top-0 right-0 w-[100vw] h-screen bg-[#202020] z-[1210] p-[50px] flex flex-col">
          <div className="flex justify-between w-full text-white">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => {
                setFlag(false);
                // dispatch(hideGallery());
              }}
            >
              <img alt="" src={close}></img>
              <div>Close</div>
            </div>
            <div>
              {index + 1}/{images.length}
            </div>
            <div className="flex gap-[10px]">
              <div className="flex items-center">
                <img alt="" src={share}></img>
                <div>Share</div>
              </div>
              <div className="flex items-center">
                <img alt="" src={save}></img>
                <div>Save</div>
              </div>
            </div>
          </div>

          <div className="flex justify-center w-full h-full items-center except">
            <Carousel
              className="w-full flex"
              interval={null}
              prevIcon={<img alt="" src={previous}></img>}
              nextIcon={<img alt="" src={next}></img>}
              activeIndex={index}
              onSelect={handleSelect}
            >
              {images.map((image) => {
                return (
                  <Carousel.Item>
                    <div className="flex">
                      <img
                        alt=""
                        src={image}
                        className="mx-auto w-[600px]"
                      ></img>
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        </div>
      ) : (
        <div className="w-full flex relative bg-white">
          <img
            alt=""
            className="absolute top-[100px] left-[100px] cursor-pointer"
            onClick={() => dispatch(hideGallery())}
            src={backIcon}
          ></img>
          <div className="w-[600px] mx-auto my-[100px] shadow-md p-[4px] space-y-[4px]">
            <div class="relative w-full group overflow-hidden bg-cover bg-[50%] bg-no-repeat">
              <img
                alt=""
                src={images[0]}
                className="hover:bg-blend-darken w-full transition duration-500"
              ></img>
              <div
                class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden except group-hover:bg-[#2c2c2c88] bg-fixed opacity-50"
                onClick={() => {
                  setIndex(0);
                  setFlag(true);
                }}
              ></div>
            </div>

            <div className="grid grid-cols-2 gap-[4px] w-full">
              {images.map((image, i) => {
                if (i > 0)
                  return (
                    <div class="relative w-full group overflow-hidden bg-cover bg-[50%] bg-no-repeat">
                      <img
                        alt=""
                        src={image}
                        className="hover:bg-blend-darken w-full transition duration-500"
                      ></img>
                      <div
                        class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden except group-hover:bg-[#2c2c2c88] bg-fixed opacity-50"
                        onClick={() => {
                          setIndex(i);
                          setFlag(true);
                        }}
                      ></div>
                    </div>
                  );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
