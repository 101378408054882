import shop from "../../assets/images/Shop.png";
import rent from "../../assets/images/Group 1000004856.png";

import arrow from "../../assets/images/Icon color.png";
import iconFilter from "../../assets/images/iconFilter.png";
import { SearchOptionBar } from "../PartialPage/SearchOptionBar";
import { CarouselCompo } from "../Global/CarouselCompo";

import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import "../../css/MainPageComponent.css";

import checked from "../../assets/images/checkbox/checked.png";
import unchecked from "../../assets/images/checkbox/unchecked.png";
import closeIcon from "../../assets/images/close-mini.svg";
import arrowToLeft from "../../assets/images/ArrowToLeft.svg";
import arrowToRight from "../../assets/images/ArrowToRight.svg";

import { useSelector } from "react-redux";
import { CustomChain, NibiruQuerier, Testnet } from "@nibiruchain/nibijs";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setFilteredNFTs, setNFT } from "../../Actions/NFTSlice";
import { setPage } from "../../Actions/PageSlice";
import { getAllNFTsInfo } from "../functional/InteractToIPFS";
import { Checkbox } from "../Global/Checkbox";
import { SelectionGroup, SelectionItem } from "../Global/Selection";
import { Toggle } from "../Global/Toggle";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { CustomChart } from "../Global/CustomChart";
import { Fade, Zoom } from "react-awesome-reveal";
import { setChatReceiver } from "../../Actions/ChatSlice";
import {
  setAmenities,
  setAmenitiesEssentials,
  setFilter,
  setPriceRange,
  setPropertyType,
} from "../../Actions/SearchSlice";
import Skeleton from "react-loading-skeleton";
import { GoogleMap, OverlayView, useJsApiLoader } from "@react-google-maps/api";
import { Popover } from "react-tiny-popover";
import NUSDIcon from "../../assets/images/NUSD.svg";
import arrowIcon from "../../assets/images/map/Arrow, Location, Map, Direction.svg";
import addIcon from "../../assets/images/map/add.svg";
import removeIcon from "../../assets/images/map/remove.svg";
import backForthIcon from "../../assets/images/map/Arrange, Filter, Sort.svg";
import gridIcon from "../../assets/images/map/grid-layout-14-square.svg";
import mapLineIcon from "../../assets/images/map/map-line-small-sqaure.svg";
import textEditorIcon from "../../assets/images/map/textEditor.svg";
import pinLocationIcon from "../../assets/images/map/Pin, Location, Direction.svg";
import viewHalfListIcon from "../../assets/images/map/justify-left.svg";
import viewListIcon from "../../assets/images/map/grid-layout-5.svg";
import { mapStyles } from "../Global/GoogleMapComponent";

export const MainPageComponent = () => {
  const [show, setShow] = useState(false);
  const [mapFullScreen, setMapFullScreen] = useState(null);
  const assets = useSelector((state) => state.nft.allNFTs);
  const filteredAssets = useSelector((state) => state.nft.filteredNFTs);
  const [hoveredAsset, setHoveredAsset] = useState(null);
  const [sortedAssets, setSortedAssets] = useState([]);
  const [showPop, setShowPop] = useState(null);
  const [viewMapPopOver, setViewMapPopOver] = useState(false);
  const [viewListPopOver, setViewListPopOver] = useState(false);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ["places"],
  });

  useEffect(() => {
    if (Object.keys(filteredAssets).length) {
      let newArray = Object.entries(filteredAssets).map(([key, value]) => ({
        token_id: key,
        ...value,
      }));
      setSortedAssets(
        newArray.sort((a, b) => {
          if (a.period !== b.period) return b.period - a.period;
          if (a.price !== b.price) return b.price - a.price;
          if (a.guests !== b.guests) return b.guests - a.guests;

          if (a.bedroom !== b.bedroom) return b.bedroom - a.bedroom;
          if (a.bed !== b.bed) return b.bed - a.bed;
          if (a.bathroom !== b.bathroom) return b.bathroom - a.bathroom;
          if (a.propertyType !== b.propertyType)
            return b.propertyType - a.propertyType;
          if (a.amenitiesEssentials !== b.amenitiesEssentials)
            return b.amenitiesEssentials - a.amenitiesEssentials;
          if (typeof a.where === "boolean") return b.where - a.where;
          else return a.where - b.where;
        })
      );

      dispatch(setFilter(true));
    }
  }, [filteredAssets]);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (e) => {
    setShow(true);
  };

  const handleCheck = (e) => {
    if (e.target.src == checked) e.target.src = unchecked;
    else e.target.src = checked;
  };

  const mode = useSelector((state) => state.header.mode);
  const submode = useSelector((state) => state.header.submode);
  const account = useSelector((state) => state.auth.account);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [checkedAdults, setCheckedAdults] = useState(false);
  const [checkedRoom, setCheckedRoom] = useState(false);
  const [checkedSharedRoom, setCheckedSharedRoom] = useState(false);

  const range = useSelector((state) => state.search.priceRange);
  const [mapZoom, setMapZoom] = useState(12);
  const amenities = useSelector((state) => state.search.amenities);
  const filter = useSelector((state) => state.search.filter);
  const propertyType = useSelector((state) => state.search.propertyType);
  const amenitiesEssentials = useSelector(
    (state) => state.search.amenitiesEssentials
  );

  useEffect(() => {
    if (filter) setMapFullScreen(false);
    else {
      setMapFullScreen(null);
    }
  }, [filter]);

  const defaultEssentials = {
    wifi: true,
    kitchen: false,
    washer: false,
    airConditioning: false,
    dryer: false,
    heating: false,
  };

  useEffect(() => {
    if (amenitiesEssentials === null)
      dispatch(setAmenitiesEssentials(defaultEssentials));
  }, []);

  useEffect(() => {
    if (!range?.max)
      dispatch(
        setPriceRange({
          min: range?.min,
          max: 1000,
        })
      );
    if (!range?.min)
      dispatch(
        setPriceRange({
          min: 1,
          max: range?.max,
        })
      );
  }, [range]);

  const handleOnRangeChange = (value) => {
    // setRange(value);
    dispatch(setPriceRange(value));
  };

  function generateRandomArray(min, max, length) {
    const randomArray = [];
    for (let i = 0; i < length; i++) {
      const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
      randomArray.push(randomNumber);
    }
    return randomArray;
  }

  const chartData = [7, 22, 1, 93, 48, 8, 67, 9, 55];

  const handleFilter = () => {
    let filteredAssets = {};
    const assetsArray = Object.values(assets);
    for (let i = 0; i < assetsArray.length; i++) {
      // price check
      let price = false;
      if (
        assetsArray[i].shorttermrental_info.price_per_day >= range.min &&
        assetsArray[i].shorttermrental_info.price_per_day <= range.max
      ) {
        price = true;
      }
      // bedrooms
      let bedroom = false;
      if (assetsArray[i].metaData.Amenities.Bedrooms >= amenities?.bedroom) {
        bedroom = true;
      }
      //beds
      let bed = false;
      if (assetsArray[i].metaData.Amenities.Beds >= amenities?.bed) {
        bed = true;
      }
      //bathrooms
      let bathroom = false;
      if (assetsArray[i].metaData.Amenities.Bathrooms >= amenities?.bathroom) {
        bathroom = true;
      }
      // propertyType
      let propertyType = true;
      // amenitiesEssentials
      let amenitiesEssentials = true;

      filteredAssets[assetsArray[i].token_id] = {
        price: price,
        bedroom: bedroom,
        bed: bed,
        bathroom: bathroom,
        propertyType: propertyType,
        amenitiesEssentials: amenitiesEssentials,
      };
    }
    dispatch(setFilteredNFTs(filteredAssets));
    handleClose();
  };

  return (
    <div className="bg-white w-full rounded-[8px] relative">
      <div className="grid grid-cols-3 px-[20px] h-[70px] items-center mb-[10px]">
        <div className="flex items-center gap-[10px] justify-self-start">
          {mode === 1 && submode === 0 ? (
            <>
              <img alt="" src={rent}></img>
              <div className="text-[28px] font-semibold">Long-Term Rental</div>
            </>
          ) : (
            <></>
          )}

          {mode === 1 && submode === 1 ? (
            <>
              <Fade cascade direction="up">
                <img alt="" src={rent}></img>
                <div className="2xl:text-[28px] xl:text-[24px] lg:text-[20px] sm:text-[16px] font-semibold">
                  Short-Term Rental
                </div>
              </Fade>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="justify-self-center">
          <SearchOptionBar />
        </div>
        <div className="justify-self-end flex items-center gap-[6vw]">
          <div
            className="hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)] flex items-center gap-[8px] shadow-md bg-white rounded-[20px] px-[20px] py-[10px] cursor-pointer"
            onClick={handleShow}
          >
            <img alt="" src={iconFilter}></img>
            <div className="">Filter</div>
          </div>
          {/* <div className="flex items-center gap-[10px]">
            <div>Show All</div>
            <Toggle
              status={!filter}
              onChange={() => dispatch(setFilter(!filter))}
            />
          </div> */}
        </div>
      </div>

      {mapFullScreen === null && (
        <div className="flex justify-center w-full">
          <Popover
            isOpen={viewMapPopOver}
            onClickOutside={() => setViewMapPopOver(false)}
            positions={["bottom"]}
            content={
              <div className="bg-white shadow-md mt-[10px] p-[4px] rounded-[4px]">
                <div
                  onClick={() => setMapFullScreen(false)}
                  className="flex items-center gap-[10px] p-[6px] hover:bg-[#f0f0f0] cursor-pointer"
                >
                  <img alt="" src={textEditorIcon} />
                  <div>View Half Map</div>
                </div>
                <div
                  onClick={() => setMapFullScreen(true)}
                  className="flex items-center gap-[10px] p-[6px] hover:bg-[#f0f0f0] cursor-pointer"
                >
                  <img alt="" src={pinLocationIcon} />
                  <div>View Full Map</div>
                </div>
              </div>
            }
          >
            <div
              onClick={() => setViewMapPopOver(true)}
              className="bg-white cursor-pointer flex items-center gap-[4px] px-[16px] py-[8px] rounded-full shadow-md mb-[20px]"
            >
              <img alt="" src={mapLineIcon} />
              <div>View Map</div>
            </div>
          </Popover>
        </div>
      )}
      <div
        className={
          mapFullScreen !== true
            ? "sm:grid 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 gap-[20px] h-[calc(100vh-200px)]"
            : "w-full h-[calc(100vh-140px)] overflow-y-auto hiddenscrollbar"
        }
      >
        {mapFullScreen === false && (
          <div className="h-[calc(100vh-140px)] overflow-y-auto hiddenscrollbar 2xl:col-span-3 xl:col-span-2 lg:col-span-1 sm:col-span-1">
            <div className="grid gap-[10px] 2xl:grid-cols-3 xl:grid-cols-2 lg:grid-cols-1 sm:grid-cols-1">
              <div className="2xl:col-span-3 xl:col-span-2 lg:col-span-1 sm:col-span-1 flex justify-center">
                <Popover
                  isOpen={viewMapPopOver}
                  onClickOutside={() => setViewMapPopOver(false)}
                  positions={["bottom"]}
                  content={
                    <div className="bg-white shadow-md mt-[10px] p-[4px] rounded-[4px]">
                      <div
                        onClick={() => setMapFullScreen(false)}
                        className="flex items-center gap-[10px] p-[6px] hover:bg-[#f0f0f0] cursor-pointer"
                      >
                        <img alt="" src={textEditorIcon} />
                        <div>View Half Map</div>
                      </div>
                      <div
                        onClick={() => setMapFullScreen(true)}
                        className="flex items-center gap-[10px] p-[6px] hover:bg-[#f0f0f0] cursor-pointer"
                      >
                        <img alt="" src={pinLocationIcon} />
                        <div>View Full Map</div>
                      </div>
                    </div>
                  }
                >
                  <div
                    onClick={() => setViewMapPopOver(true)}
                    className="bg-white cursor-pointer flex items-center gap-[4px] px-[16px] py-[8px] rounded-full shadow-md mb-[20px]"
                  >
                    <img alt="" src={mapLineIcon} />
                    <div>View Map</div>
                  </div>
                </Popover>
              </div>
              <Fade cascade damping={0.2}>
                {sortedAssets.length === 0 &&
                  Object.values(assets).map((item) => {
                    const temp = [];
                    if (
                      (item.longtermrental_info.islisted &&
                        item.nft_info.access.owner !== account &&
                        !item.longtermrental_info.renting_flag &&
                        mode === 1 &&
                        submode === 0) ||
                      (item.shorttermrental_info.islisted &&
                        item.nft_info.access.owner !== account &&
                        mode === 1 &&
                        submode === 1)
                    )
                      temp.push(
                        <CarouselCompo
                          mode={
                            mode === 1 && submode === 0
                              ? "long"
                              : mode === 1 && submode === 1
                              ? "short"
                              : null
                          }
                          nftInfo={item.nft_info}
                          metaData={item.metaData}
                          price={
                            mode === 1 && submode === 0
                              ? item.longtermrental_info.landlord
                                  .price_per_month
                              : mode === 1 && submode === 1
                              ? item.shorttermrental_info.price_per_day
                              : null
                          }
                          totalPriceHide={true}
                          imageHeight={"200px"}
                          btn={
                            <div className="bg-[#5B1DEE] py-[10px] text-center mt-[10px] cursor-pointer px-[18px]  text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] group-hover:shadow-[-1px_6px_10px_0_rgba(93,0,207,0.5)] group-hover:-translate-y-[3px]">
                              View Details
                            </div>
                          }
                          onClick={() => {
                            dispatch(setNFT(item.token_id));
                            dispatch(
                              setChatReceiver(item.nft_info.access.owner)
                            );
                            dispatch(setPage("detail"));
                          }}
                          onMouseEnter={() => setHoveredAsset(item.token_id)}
                          onMouseLeave={() => setHoveredAsset(null)}
                        />
                      );
                    return temp;
                  })}
                {sortedAssets.length > 0 &&
                  sortedAssets.map((item) => {
                    let temp = [];
                    if (
                      // Object.values(item).every((value) => value !== false) &&
                      assets[item.token_id].shorttermrental_info.islisted &&
                      assets[item.token_id].nft_info.access.owner !== account
                    ) {
                      temp.push(
                        <>
                          <CarouselCompo
                            mode={
                              mode === 1 && submode === 0
                                ? "long"
                                : mode === 1 && submode === 1
                                ? "short"
                                : null
                            }
                            nftInfo={assets[item.token_id].nft_info}
                            metaData={assets[item.token_id].metaData}
                            price={
                              mode === 1 && submode === 0
                                ? assets[item.token_id].longtermrental_info
                                    .landlord?.price_per_month
                                : mode === 1 && submode === 1
                                ? assets[item.token_id].shorttermrental_info
                                    .price_per_day
                                : null
                            }
                            totalPriceHide={true}
                            imageHeight={"200px"}
                            btn={
                              <div className="bg-[#5B1DEE] py-[10px] text-center mt-[10px] cursor-pointer px-[18px]  text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] group-hover:shadow-[-1px_6px_10px_0_rgba(93,0,207,0.5)] group-hover:-translate-y-[3px]">
                                View Details
                              </div>
                            }
                            onClick={() => {
                              dispatch(setNFT(assets[item.token_id].token_id));
                              dispatch(
                                setChatReceiver(
                                  assets[item.token_id].nft_info.access.owner
                                )
                              );
                              dispatch(setPage("detail"));
                            }}
                            onMouseEnter={() => setHoveredAsset(item.token_id)}
                            onMouseLeave={() => setHoveredAsset(null)}
                          />
                          <div className="text-[30px]">{item.where}</div>
                        </>
                      );
                    }
                    return temp;
                  })}
              </Fade>
            </div>
          </div>
        )}
        {mapFullScreen === null && (
          <Fade cascade damping={0.2}>
            {sortedAssets.length === 0 &&
              Object.values(assets).map((item) => {
                const temp = [];
                if (
                  (item.longtermrental_info.islisted &&
                    item.nft_info.access.owner !== account &&
                    !item.longtermrental_info.renting_flag &&
                    mode === 1 &&
                    submode === 0) ||
                  (item.shorttermrental_info.islisted &&
                    item.nft_info.access.owner !== account &&
                    mode === 1 &&
                    submode === 1)
                )
                  temp.push(
                    <CarouselCompo
                      mode={
                        mode === 1 && submode === 0
                          ? "long"
                          : mode === 1 && submode === 1
                          ? "short"
                          : null
                      }
                      nftInfo={item.nft_info}
                      metaData={item.metaData}
                      price={
                        mode === 1 && submode === 0
                          ? item.longtermrental_info.landlord.price_per_month
                          : mode === 1 && submode === 1
                          ? item.shorttermrental_info.price_per_day
                          : null
                      }
                      totalPriceHide={true}
                      imageHeight={"200px"}
                      btn={
                        <div className="bg-[#5B1DEE] py-[10px] text-center mt-[10px] cursor-pointer px-[18px]  text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] group-hover:shadow-[-1px_6px_10px_0_rgba(93,0,207,0.5)] group-hover:-translate-y-[3px]">
                          View Details
                        </div>
                      }
                      onClick={() => {
                        dispatch(setNFT(item.token_id));
                        dispatch(setChatReceiver(item.nft_info.access.owner));
                        dispatch(setPage("detail"));
                      }}
                      onMouseEnter={() => setHoveredAsset(item.token_id)}
                      onMouseLeave={() => setHoveredAsset(null)}
                    />
                  );
                return temp;
              })}
            {sortedAssets.length > 0 &&
              sortedAssets.map((item) => {
                let temp = [];
                if (
                  // Object.values(item).every((value) => value !== false) &&
                  assets[item.token_id].shorttermrental_info.islisted &&
                  assets[item.token_id].nft_info.access.owner !== account
                ) {
                  temp.push(
                    <>
                      <CarouselCompo
                        mode={
                          mode === 1 && submode === 0
                            ? "long"
                            : mode === 1 && submode === 1
                            ? "short"
                            : null
                        }
                        nftInfo={assets[item.token_id].nft_info}
                        metaData={assets[item.token_id].metaData}
                        price={
                          mode === 1 && submode === 0
                            ? assets[item.token_id].longtermrental_info.landlord
                                ?.price_per_month
                            : mode === 1 && submode === 1
                            ? assets[item.token_id].shorttermrental_info
                                .price_per_day
                            : null
                        }
                        totalPriceHide={true}
                        imageHeight={"200px"}
                        btn={
                          <div className="bg-[#5B1DEE] py-[10px] text-center mt-[10px] cursor-pointer px-[18px]  text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] group-hover:shadow-[-1px_6px_10px_0_rgba(93,0,207,0.5)] group-hover:-translate-y-[3px]">
                            View Details
                          </div>
                        }
                        onClick={() => {
                          dispatch(setNFT(assets[item.token_id].token_id));
                          dispatch(
                            setChatReceiver(
                              assets[item.token_id].nft_info.access.owner
                            )
                          );
                          dispatch(setPage("detail"));
                        }}
                        onMouseEnter={() => setHoveredAsset(item.token_id)}
                        onMouseLeave={() => setHoveredAsset(null)}
                      />
                      <div className="text-[30px]">{item.where}</div>
                    </>
                  );
                }
                return temp;
              })}
          </Fade>
        )}

        {isLoaded && (
          <GoogleMap
            center={
              // hoveredAsset
              //   ? assets[hoveredAsset].metaData.Location
              //   : assets[sortedAssets[0].token_id].metaData.Location
              sortedAssets.length === 0
                ? Object.values(assets)[0]?.metaData.Location
                : assets[sortedAssets[0]?.token_id]?.metaData.Location
            }
            zoom={mapZoom}
            mapContainerClassName={
              mapFullScreen === false
                ? "rounded-[10px] 2xl:col-span-2 xl:col-span-2 lg:col-span-2 sm:col-span-1 relative h-[calc(100vh-150px)]"
                : mapFullScreen === true
                ? "rounded-[10px] w-full h-[calc(100vh-150px)] relative"
                : "hidden"
            }
            options={{
              mapTypeControl: false,
              streetViewControl: false,
              fullscreenControl: false,
              zoomControl: false,
              styles: mapStyles,
            }}
          >
            {mapFullScreen && (
              <div className="absolute top-[20px] w-full justify-center flex">
                <Popover
                  isOpen={viewListPopOver}
                  onClickOutside={() => setViewListPopOver(false)}
                  positions={["bottom"]}
                  content={
                    <div className="bg-white shadow-md mt-[10px] p-[4px] rounded-[4px]">
                      <div
                        onClick={() => setMapFullScreen(false)}
                        className="flex items-center gap-[10px] p-[6px] hover:bg-[#f0f0f0] cursor-pointer"
                      >
                        <img alt="" src={textEditorIcon} />
                        <div>View Half List</div>
                      </div>
                      <div
                        onClick={() => setMapFullScreen(null)}
                        className="flex items-center gap-[10px] p-[6px] hover:bg-[#f0f0f0] cursor-pointer"
                      >
                        <img alt="" src={viewListIcon} />
                        <div>View Full List</div>
                      </div>
                    </div>
                  }
                >
                  <div
                    onClick={() => setViewListPopOver(true)}
                    className="bg-white cursor-pointer flex items-center gap-[4px] px-[16px] py-[8px] rounded-full shadow-md mb-[20px]"
                  >
                    <img alt="" src={viewHalfListIcon} />
                    <div>View List</div>
                  </div>
                </Popover>
              </div>
            )}

            {(mapFullScreen === true || mapFullScreen === false) && (
              <div className="flex items-center cursor-pointer absolute left-[20px] top-[20px] gap-[10px]">
                <div
                  className="p-[4px] rounded-full shadow-md bg-white hover:translate-y-[-4px]"
                  onClick={() => setMapFullScreen(null)}
                >
                  <img alt="" src={backForthIcon} />
                </div>
                <div
                  className="p-[4px] rounded-full shadow-md bg-white"
                  onClick={() => setMapFullScreen(false)}
                >
                  <img alt="" src={gridIcon} />
                </div>
              </div>
            )}

            <div className="absolute top-[20px] right-[20px] flex items-center gap-[10px]">
              <div className="p-[4px] rounded-full shadow-md bg-[#202020] hover:translate-y-[-4px]">
                <img alt="" src={arrowIcon} />
              </div>
              <div
                className="p-[4px] rounded-full shadow-md bg-white cursor-pointer hover:translate-y-[-4px]"
                onClick={() => setMapZoom(mapZoom + 1)}
              >
                <img alt="" src={addIcon} />
              </div>
              <div
                className="p-[4px] rounded-full shadow-md bg-white cursor-pointer hover:translate-y-[-4px]"
                onClick={() => setMapZoom(mapZoom - 1)}
              >
                <img alt="" src={removeIcon} />
              </div>
            </div>

            <>
              {sortedAssets.length === 0
                ? Object.values(assets).map((item) => {
                    const temp = [];
                    if (
                      // Object.values(item).every((value) => value !== false) &&
                      assets[item.token_id].shorttermrental_info.islisted &&
                      assets[item.token_id].nft_info.access.owner !== account
                    )
                      temp.push(
                        <OverlayView
                          position={assets[item.token_id].metaData.Location}
                          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        >
                          <Popover
                            isOpen={item.token_id === showPop}
                            positions={["bottom", "left", "right", "top"]}
                            onClickOutside={() => setShowPop(null)}
                            content={
                              <div className="w-[300px] bg-white rounded-[10px] relative">
                                <div
                                  onClick={() => setShowPop(null)}
                                  className="absolute cursor-pointer z-[9999] top-[10px] right-[10px] bg-white rounded-full shadow-md"
                                >
                                  <img alt="" src={closeIcon} />
                                </div>
                                <CarouselCompo
                                  padding={0}
                                  mode={
                                    mode === 1 && submode === 0
                                      ? "long"
                                      : mode === 1 && submode === 1
                                      ? "short"
                                      : null
                                  }
                                  nftInfo={assets[item.token_id].nft_info}
                                  metaData={assets[item.token_id].metaData}
                                  price={
                                    mode === 1 && submode === 0
                                      ? assets[item.token_id]
                                          .longtermrental_info.landlord
                                          ?.price_per_month
                                      : mode === 1 && submode === 1
                                      ? assets[item.token_id]
                                          .shorttermrental_info.price_per_day
                                      : null
                                  }
                                  totalPriceHide={true}
                                  imageHeight={"150px"}
                                  onClick={() => {
                                    dispatch(
                                      setNFT(assets[item.token_id].token_id)
                                    );
                                    dispatch(
                                      setChatReceiver(
                                        assets[item.token_id].nft_info.access
                                          .owner
                                      )
                                    );
                                    dispatch(setPage("detail"));
                                  }}
                                />
                              </div>
                            }
                          >
                            <div
                              className={
                                hoveredAsset === item.token_id ||
                                item.token_id === showPop
                                  ? "bg-black items-center gap-[6px] z-[1300] absolute text-white shadow-md text-[14px] font-bold cursor-pointer scale-110 w-max rounded-full p-[4px] pr-[8px] flex"
                                  : "to-[#5B1DEE] bg-gradient-to-b from-[#7440ee] text-white items-center gap-[6px] hover:z-[1300] hover:absolute shadow-md text-[14px] font-bold cursor-pointer hover:scale-110 w-max rounded-full p-[4px] pr-[8px] flex"
                              }
                              onClick={() => {
                                setShowPop(item.token_id);
                              }}
                            >
                              <img alt="" src={NUSDIcon} className="w-[20px]" />
                              ${" "}
                              {
                                assets[item.token_id].shorttermrental_info
                                  .price_per_day
                              }
                            </div>
                          </Popover>
                        </OverlayView>
                      );
                    return temp;
                  })
                : sortedAssets.map((item) => {
                    const temp = [];
                    if (
                      // Object.values(item).every((value) => value !== false) &&
                      assets[item.token_id].shorttermrental_info.islisted &&
                      assets[item.token_id].nft_info.access.owner !== account
                    )
                      temp.push(
                        <OverlayView
                          position={assets[item.token_id].metaData.Location}
                          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        >
                          <Popover
                            isOpen={item.token_id === showPop}
                            positions={["bottom", "left", "right", "top"]}
                            onClickOutside={() => setShowPop(null)}
                            content={
                              <div className="w-[300px] bg-white rounded-[10px] relative">
                                <div
                                  onClick={() => setShowPop(null)}
                                  className="absolute cursor-pointer z-[9999] top-[10px] right-[10px] bg-white rounded-full shadow-md"
                                >
                                  <img alt="" src={closeIcon} />
                                </div>
                                <CarouselCompo
                                  padding={0}
                                  mode={
                                    mode === 1 && submode === 0
                                      ? "long"
                                      : mode === 1 && submode === 1
                                      ? "short"
                                      : null
                                  }
                                  nftInfo={assets[item.token_id].nft_info}
                                  metaData={assets[item.token_id].metaData}
                                  price={
                                    mode === 1 && submode === 0
                                      ? assets[item.token_id]
                                          .longtermrental_info.landlord
                                          ?.price_per_month
                                      : mode === 1 && submode === 1
                                      ? assets[item.token_id]
                                          .shorttermrental_info.price_per_day
                                      : null
                                  }
                                  totalPriceHide={true}
                                  imageHeight={"150px"}
                                  onClick={() => {
                                    dispatch(
                                      setNFT(assets[item.token_id].token_id)
                                    );
                                    dispatch(
                                      setChatReceiver(
                                        assets[item.token_id].nft_info.access
                                          .owner
                                      )
                                    );
                                    dispatch(setPage("detail"));
                                  }}
                                />
                              </div>
                            }
                          >
                            <div
                              className={
                                hoveredAsset === item.token_id ||
                                item.token_id === showPop
                                  ? "bg-black items-center gap-[6px] z-[1300] absolute text-white shadow-md text-[14px] font-bold cursor-pointer scale-110 w-max rounded-full p-[4px] pr-[8px] flex"
                                  : "to-[#5B1DEE] bg-gradient-to-b from-[#7440ee] text-white items-center gap-[6px] hover:z-[1300] hover:absolute shadow-md text-[14px] font-bold cursor-pointer hover:scale-110 w-max rounded-full p-[4px] pr-[8px] flex"
                              }
                              onClick={() => {
                                setShowPop(item.token_id);
                              }}
                            >
                              <img alt="" src={NUSDIcon} className="w-[20px]" />
                              ${" "}
                              {
                                assets[item.token_id].shorttermrental_info
                                  .price_per_day
                              }
                            </div>
                          </Popover>
                        </OverlayView>
                      );
                    return temp;
                  })}
            </>
          </GoogleMap>
        )}
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="w-[600px] p-[20px] relative">
            <img
              alt=""
              src={closeIcon}
              className="absolute cursor-pointer top-[10px] right-[10px] w-[30px]"
              onClick={handleClose}
            />

            <div className="flex items-center w-full justify-between mb-[20px]">
              <div className="text-[20px] font-semibold">Filters</div>
            </div>
            <div className="h-[50vh] overflow-auto scrollbarwidth p-[10px]">
              <div className="mt-[20px]">
                <div className="text-[18px] font-semibold">Price range</div>
                <div>Cats, dogs, iguanas?!</div>
              </div>
              <div className="w-full">
                <div className="w-[80%] mx-auto my-[20px]">
                  <CustomChart
                    data={chartData}
                    start={range?.min}
                    end={range?.max}
                    min={1}
                    max={1000}
                  />
                  <InputRange
                    draggableTrack
                    maxValue={1000}
                    minValue={1}
                    onChange={handleOnRangeChange}
                    value={range}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-[20px] my-[16px] font-semibold">
                <div>
                  <div className="font-semibold">Minimum</div>
                  <div className="globalInputForm rounded-[8px] p-[8px] flex gap-[4px]">
                    <div>NUSD</div>
                    <input
                      value={range?.min}
                      onChange={(e) =>
                        dispatch(
                          setPriceRange({
                            ...range,
                            min: e.target.value,
                          })
                        )
                      }
                    />
                  </div>
                </div>
                <div className="">
                  <div>Maximum</div>
                  <div className="globalInputForm rounded-[8px] p-[8px] flex gap-[4px]">
                    <div>NUSD</div>

                    <input
                      value={range?.max}
                      onChange={(e) =>
                        dispatch(
                          setPriceRange({
                            ...range,
                            max: e.target.value,
                          })
                        )
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="w-full h-[2px] bg-[#eeeeee] my-[12px]" />
              {/* <div className="space-y-[12px] select-none">
                  <div className="text-[18px] font-semibold">Type of place</div>
                  <div className="flex justify-between">
                    <div
                      className="flex items-center gap-[8px] cursor-pointer"
                      onClick={() => setCheckedAdults(!checkedAdults)}
                    >
                      <Checkbox checked={checkedAdults} />
                      <div>
                        <div className="font-semibold">Adults</div>
                        <div className="text-[14px] text-[#959595]">
                          age 13 or above
                        </div>
                      </div>
                    </div>
                    <div
                      className="flex items-center gap-[8px] cursor-pointer"
                      onClick={() => setCheckedRoom(!checkedRoom)}
                    >
                      <Checkbox checked={checkedRoom} />
                      <div>
                        <div className="font-semibold">Room</div>
                        <div className="text-[14px] text-[#959595]">
                          Your own room, plus access to shared spaces
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div
                      className="flex items-center gap-[8px] cursor-pointer"
                      onClick={() => setCheckedSharedRoom(!checkedSharedRoom)}
                    >
                      <Checkbox checked={checkedSharedRoom} />
                      <div>
                        <div className="font-semibold">Shared room</div>
                        <div className="text-[14px] text-[#959595]">
                          A sleeping space and common areas that may be shared
                          with others
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full h-[2px] bg-[#eeeeee] my-[12px]" /> */}
              <div className="space-y-[12px]">
                <div className="text-[18px] font-semibold">Rooms</div>
                <div>
                  <div>Bedrooms</div>
                  <SelectionGroup
                    defaultItem={amenities.bedroom}
                    className="flex gap-[8px] text-[#D5D5D5] my-[12px]"
                    // SelectedItemMask="border-[#5B1DEE] text-[#5B1DEE]"
                  >
                    <SelectionItem
                      SelectedItem={
                        <div className="w-full py-[8px] border-[1px] rounded-[16px] text-center border-[#5B1DEE] text-[#5B1DEE]">
                          Any
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-full py-[8px] border-[1px] rounded-[16px] text-center"
                          onClick={() =>
                            dispatch(setAmenities({ ...amenities, bedroom: 0 }))
                          }
                        >
                          Any
                        </div>
                      }
                    />
                    <SelectionItem
                      SelectedItem={
                        <div className="w-full py-[8px] border-[1px] rounded-[16px] text-center border-[#5B1DEE] text-[#5B1DEE]">
                          1
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-full py-[8px] border-[1px] rounded-[16px] text-center"
                          onClick={() =>
                            dispatch(setAmenities({ ...amenities, bedroom: 1 }))
                          }
                        >
                          1
                        </div>
                      }
                    />
                    <SelectionItem
                      SelectedItem={
                        <div className="w-full py-[8px] border-[1px] rounded-[16px] text-center border-[#5B1DEE] text-[#5B1DEE]">
                          2
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-full py-[8px] border-[1px] rounded-[16px] text-center"
                          onClick={() =>
                            dispatch(setAmenities({ ...amenities, bedroom: 2 }))
                          }
                        >
                          2
                        </div>
                      }
                    />
                    <SelectionItem
                      SelectedItem={
                        <div className="w-full py-[8px] border-[1px] rounded-[16px] text-center border-[#5B1DEE] text-[#5B1DEE]">
                          3
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-full py-[8px] border-[1px] rounded-[16px] text-center"
                          onClick={() =>
                            dispatch(setAmenities({ ...amenities, bedroom: 3 }))
                          }
                        >
                          3
                        </div>
                      }
                    />
                    <SelectionItem
                      SelectedItem={
                        <div className="w-full py-[8px] border-[1px] rounded-[16px] text-center border-[#5B1DEE] text-[#5B1DEE]">
                          4
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-full py-[8px] border-[1px] rounded-[16px] text-center"
                          onClick={() =>
                            dispatch(setAmenities({ ...amenities, bedroom: 4 }))
                          }
                        >
                          4
                        </div>
                      }
                    />
                    <SelectionItem
                      SelectedItem={
                        <div className="w-full py-[8px] border-[1px] rounded-[16px] text-center border-[#5B1DEE] text-[#5B1DEE]">
                          5
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-full py-[8px] border-[1px] rounded-[16px] text-center"
                          onClick={() =>
                            dispatch(setAmenities({ ...amenities, bedroom: 5 }))
                          }
                        >
                          5
                        </div>
                      }
                    />
                    <SelectionItem
                      SelectedItem={
                        <div className="w-full py-[8px] border-[1px] rounded-[16px] text-center border-[#5B1DEE] text-[#5B1DEE]">
                          6
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-full py-[8px] border-[1px] rounded-[16px] text-center"
                          onClick={() =>
                            dispatch(setAmenities({ ...amenities, bedroom: 6 }))
                          }
                        >
                          6
                        </div>
                      }
                    />
                    <SelectionItem
                      SelectedItem={
                        <div className="w-full py-[8px] border-[1px] rounded-[16px] text-center border-[#5B1DEE] text-[#5B1DEE]">
                          7+
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-full py-[8px] border-[1px] rounded-[16px] text-center"
                          onClick={() =>
                            dispatch(setAmenities({ ...amenities, bedroom: 7 }))
                          }
                        >
                          7+
                        </div>
                      }
                    />
                  </SelectionGroup>

                  <div>Beds</div>
                  <SelectionGroup
                    defaultItem={amenities.bed}
                    className="flex gap-[8px] text-[#D5D5D5] my-[12px]"
                  >
                    <SelectionItem
                      SelectedItem={
                        <div className="w-full py-[8px] border-[1px] rounded-[16px] border-[#5B1DEE] text-[#5B1DEE] text-center">
                          Any
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-full py-[8px] border-[1px] rounded-[16px] text-center"
                          onClick={() =>
                            dispatch(setAmenities({ ...amenities, bed: 0 }))
                          }
                        >
                          Any
                        </div>
                      }
                    />
                    <SelectionItem
                      SelectedItem={
                        <div className="w-full py-[8px] border-[1px] rounded-[16px] border-[#5B1DEE] text-[#5B1DEE] text-center">
                          1
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-full py-[8px] border-[1px] rounded-[16px] text-center"
                          onClick={() =>
                            dispatch(setAmenities({ ...amenities, bed: 1 }))
                          }
                        >
                          1
                        </div>
                      }
                    />
                    <SelectionItem
                      SelectedItem={
                        <div className="w-full py-[8px] border-[1px] rounded-[16px] border-[#5B1DEE] text-[#5B1DEE] text-center">
                          2
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-full py-[8px] border-[1px] rounded-[16px] text-center"
                          onClick={() =>
                            dispatch(setAmenities({ ...amenities, bed: 2 }))
                          }
                        >
                          2
                        </div>
                      }
                    />
                    <SelectionItem
                      SelectedItem={
                        <div className="w-full py-[8px] border-[1px] rounded-[16px] border-[#5B1DEE] text-[#5B1DEE] text-center">
                          3
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-full py-[8px] border-[1px] rounded-[16px] text-center"
                          onClick={() =>
                            dispatch(setAmenities({ ...amenities, bed: 3 }))
                          }
                        >
                          3
                        </div>
                      }
                    />
                    <SelectionItem
                      SelectedItem={
                        <div className="w-full py-[8px] border-[1px] rounded-[16px] border-[#5B1DEE] text-[#5B1DEE] text-center">
                          4
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-full py-[8px] border-[1px] rounded-[16px] text-center"
                          onClick={() =>
                            dispatch(setAmenities({ ...amenities, bed: 4 }))
                          }
                        >
                          4
                        </div>
                      }
                    />
                    <SelectionItem
                      SelectedItem={
                        <div className="w-full py-[8px] border-[1px] rounded-[16px] border-[#5B1DEE] text-[#5B1DEE] text-center">
                          5
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-full py-[8px] border-[1px] rounded-[16px] text-center"
                          onClick={() =>
                            dispatch(setAmenities({ ...amenities, bed: 5 }))
                          }
                        >
                          5
                        </div>
                      }
                    />
                    <SelectionItem
                      SelectedItem={
                        <div className="w-full py-[8px] border-[1px] rounded-[16px] border-[#5B1DEE] text-[#5B1DEE] text-center">
                          6
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-full py-[8px] border-[1px] rounded-[16px] text-center"
                          onClick={() =>
                            dispatch(setAmenities({ ...amenities, bed: 6 }))
                          }
                        >
                          6
                        </div>
                      }
                    />
                    <SelectionItem
                      SelectedItem={
                        <div className="w-full py-[8px] border-[1px] rounded-[16px] border-[#5B1DEE] text-[#5B1DEE] text-center">
                          7+
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-full py-[8px] border-[1px] rounded-[16px] text-center"
                          onClick={() =>
                            dispatch(setAmenities({ ...amenities, bed: 7 }))
                          }
                        >
                          7+
                        </div>
                      }
                    />
                  </SelectionGroup>

                  <div>Bathrooms</div>
                  <SelectionGroup
                    defaultItem={amenities.bathroom}
                    className="flex gap-[8px] text-[#D5D5D5] my-[12px]"
                    SelectedItemMask="border-[#5B1DEE] text-[#5B1DEE]"
                  >
                    <SelectionItem
                      SelectedItem={
                        <div className="w-full py-[8px] border-[1px] rounded-[16px] text-center border-[#5B1DEE] text-[#5B1DEE]">
                          Any
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-full py-[8px] border-[1px] rounded-[16px] text-center"
                          onClick={() =>
                            dispatch(
                              setAmenities({ ...amenities, bathroom: 0 })
                            )
                          }
                        >
                          Any
                        </div>
                      }
                    />
                    <SelectionItem
                      SelectedItem={
                        <div className="w-full py-[8px] border-[1px] rounded-[16px] text-center border-[#5B1DEE] text-[#5B1DEE]">
                          1
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-full py-[8px] border-[1px] rounded-[16px] text-center"
                          onClick={() =>
                            dispatch(
                              setAmenities({ ...amenities, bathroom: 1 })
                            )
                          }
                        >
                          1
                        </div>
                      }
                    />
                    <SelectionItem
                      SelectedItem={
                        <div className="w-full py-[8px] border-[1px] rounded-[16px] text-center border-[#5B1DEE] text-[#5B1DEE]">
                          2
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-full py-[8px] border-[1px] rounded-[16px] text-center"
                          onClick={() =>
                            dispatch(
                              setAmenities({ ...amenities, bathroom: 2 })
                            )
                          }
                        >
                          2
                        </div>
                      }
                    />
                    <SelectionItem
                      SelectedItem={
                        <div className="w-full py-[8px] border-[1px] rounded-[16px] text-center border-[#5B1DEE] text-[#5B1DEE]">
                          3
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-full py-[8px] border-[1px] rounded-[16px] text-center"
                          onClick={() =>
                            dispatch(
                              setAmenities({ ...amenities, bathroom: 3 })
                            )
                          }
                        >
                          3
                        </div>
                      }
                    />
                    <SelectionItem
                      SelectedItem={
                        <div className="w-full py-[8px] border-[1px] rounded-[16px] text-center border-[#5B1DEE] text-[#5B1DEE]">
                          4
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-full py-[8px] border-[1px] rounded-[16px] text-center"
                          onClick={() =>
                            dispatch(
                              setAmenities({ ...amenities, bathroom: 4 })
                            )
                          }
                        >
                          4
                        </div>
                      }
                    />
                    <SelectionItem
                      SelectedItem={
                        <div className="w-full py-[8px] border-[1px] rounded-[16px] text-center border-[#5B1DEE] text-[#5B1DEE]">
                          5
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-full py-[8px] border-[1px] rounded-[16px] text-center"
                          onClick={() =>
                            dispatch(
                              setAmenities({ ...amenities, bathroom: 5 })
                            )
                          }
                        >
                          5
                        </div>
                      }
                    />
                    <SelectionItem
                      SelectedItem={
                        <div className="w-full py-[8px] border-[1px] rounded-[16px] text-center border-[#5B1DEE] text-[#5B1DEE]">
                          6
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-full py-[8px] border-[1px] rounded-[16px] text-center"
                          onClick={() =>
                            dispatch(
                              setAmenities({ ...amenities, bathroom: 6 })
                            )
                          }
                        >
                          6
                        </div>
                      }
                    />
                    <SelectionItem
                      SelectedItem={
                        <div className="w-full py-[8px] border-[1px] rounded-[16px] text-center border-[#5B1DEE] text-[#5B1DEE]">
                          7+
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-full py-[8px] border-[1px] rounded-[16px] text-center"
                          onClick={() =>
                            dispatch(
                              setAmenities({ ...amenities, bathroom: 7 })
                            )
                          }
                        >
                          7+
                        </div>
                      }
                    />
                  </SelectionGroup>
                </div>
              </div>
              <div className="w-full h-[2px] bg-[#eeeeee] my-[12px]" />
              <div>
                <div className="text-[18px] font-semibold">Property type</div>
                <SelectionGroup
                  defaultItem={propertyType}
                  className="flex gap-[8px] text-[#D5D5D5] my-[12px]"
                >
                  <SelectionItem
                    SelectedItem={
                      <div className="w-full py-[30px] border-[1px] rounded-[16px] text-center border-[#5B1DEE] text-[#5B1DEE]">
                        House
                      </div>
                    }
                    UnselectedItem={
                      <div
                        className="w-full py-[30px] border-[1px] rounded-[16px] text-center"
                        onClick={() => dispatch(setPropertyType(0))}
                      >
                        House
                      </div>
                    }
                  />
                  <SelectionItem
                    SelectedItem={
                      <div className="w-full py-[30px] border-[1px] rounded-[16px] text-center border-[#5B1DEE] text-[#5B1DEE]">
                        Apartment
                      </div>
                    }
                    UnselectedItem={
                      <div
                        className="w-full py-[30px] border-[1px] rounded-[16px] text-center"
                        onClick={() => dispatch(setPropertyType(1))}
                      >
                        Apartment
                      </div>
                    }
                  />
                  <SelectionItem
                    SelectedItem={
                      <div className="w-full py-[30px] border-[1px] rounded-[16px] text-center border-[#5B1DEE] text-[#5B1DEE]">
                        Guesthouse
                      </div>
                    }
                    UnselectedItem={
                      <div
                        className="w-full py-[30px] border-[1px] rounded-[16px] text-center"
                        onClick={() => dispatch(setPropertyType(2))}
                      >
                        Guesthouse
                      </div>
                    }
                  />
                  <SelectionItem
                    SelectedItem={
                      <div className="w-full py-[30px] border-[1px] rounded-[16px] text-center border-[#5B1DEE] text-[#5B1DEE]">
                        Hotel
                      </div>
                    }
                    UnselectedItem={
                      <div
                        className="w-full py-[30px] border-[1px] rounded-[16px] text-center"
                        onClick={() => dispatch(setPropertyType(3))}
                      >
                        Hotel
                      </div>
                    }
                  />
                </SelectionGroup>
              </div>
              <div className="w-full h-[2px] bg-[#eeeeee] my-[12px]" />
              <div className="space-y-[12px]">
                <div className="text-[18px] font-semibold">Amenities</div>
                <div className="font-semibold">Essentials</div>
                <div className="grid grid-cols-2 font-semibold">
                  <div
                    className="flex items-center gap-[8px] cursor-pointer"
                    onClick={() =>
                      dispatch(
                        setAmenitiesEssentials({
                          ...amenitiesEssentials,
                          wifi: !amenitiesEssentials?.wifi,
                        })
                      )
                    }
                  >
                    <Checkbox checked={amenitiesEssentials?.wifi} />
                    <div>Wifi</div>
                  </div>
                  <div
                    className="flex items-center gap-[8px] cursor-pointer"
                    onClick={() =>
                      // setEssentials({
                      //   ...essentials,
                      //   kitchen: !essentials.kitchen,
                      // })
                      dispatch(
                        setAmenitiesEssentials({
                          ...amenitiesEssentials,
                          kitchen: !amenitiesEssentials?.kitchen,
                        })
                      )
                    }
                  >
                    <Checkbox checked={amenitiesEssentials?.kitchen} />
                    <div>Kitchen</div>
                  </div>
                  <div
                    className="flex items-center gap-[8px] cursor-pointer"
                    onClick={() =>
                      // setEssentials({
                      //   ...essentials,
                      //   washer: !essentials.washer,
                      // })
                      dispatch(
                        setAmenitiesEssentials({
                          ...amenitiesEssentials,
                          washer: !amenitiesEssentials?.washer,
                        })
                      )
                    }
                  >
                    <Checkbox checked={amenitiesEssentials?.washer} />
                    <div>Washer</div>
                  </div>
                  <div
                    className="flex items-center gap-[8px] cursor-pointer"
                    onClick={() =>
                      // setEssentials({
                      //   ...essentials,
                      //   airConditioning: !essentials.airConditioning,
                      // })
                      dispatch(
                        setAmenitiesEssentials({
                          ...amenitiesEssentials,
                          airConditioning:
                            !amenitiesEssentials?.airConditioning,
                        })
                      )
                    }
                  >
                    <Checkbox checked={amenitiesEssentials?.airConditioning} />
                    <div>Air conditioning</div>
                  </div>
                  <div
                    className="flex items-center gap-[8px] cursor-pointer"
                    onClick={() =>
                      // setEssentials({
                      //   ...essentials,
                      //   dryer: !essentials.dryer,
                      // })
                      dispatch(
                        setAmenitiesEssentials({
                          ...amenitiesEssentials,
                          dryer: !amenitiesEssentials?.dryer,
                        })
                      )
                    }
                  >
                    <Checkbox checked={amenitiesEssentials?.dryer} />
                    <div>Dryer</div>
                  </div>
                  <div
                    className="flex items-center gap-[8px] cursor-pointer"
                    onClick={() =>
                      // setEssentials({
                      //   ...essentials,
                      //   heating: !essentials.heating,
                      // })
                      dispatch(
                        setAmenitiesEssentials({
                          ...amenitiesEssentials,
                          heating: !amenitiesEssentials?.heating,
                        })
                      )
                    }
                  >
                    <Checkbox checked={amenitiesEssentials?.heating} />
                    <div>Heating</div>
                  </div>
                </div>
                {/* <div className="font-semibold underline">Show more</div> */}
              </div>
              {/* <div className="w-full h-[2px] bg-[#eeeeee] my-[12px]" />
                <div className="space-y-[12px]">
                  <div className="font-semibold text-[18px]">
                    Booking options
                  </div>
                  <div className="flex justify-between items-center">
                    <div>
                      <div className="font-semibold">Instant Book</div>
                      <div className="text-[14px] text-[#959595]">
                        Listings you can book without for Host approval
                      </div>
                    </div>
                    <Toggle status={true} />
                  </div>
                  <div className="flex justify-between items-center">
                    <div>
                      <div className="font-semibold">Self Checking</div>
                      <div className="text-[14px] text-[#959595]">
                        Easy access to the property once you arrive
                      </div>
                    </div>
                    <Toggle status={true} />
                  </div>
                  <div className="flex justify-between items-center">
                    <div>
                      <div className="font-semibold">Allows pets</div>
                      <div className="text-[14px] text-[#959595] underline">
                        Bringing a service animal?
                      </div>
                    </div>
                    <Toggle status={true} />
                  </div>
                </div>
                <div className="w-full h-[2px] bg-[#eeeeee] my-[12px]" />
                <div className="space-y-[12px]">
                  <div className="font-semibold text-[18px]">
                    Top tier stays
                  </div>
                  <div className="flex justify-between items-center">
                    <div>
                      <div className="font-semibold">Superost</div>
                      <div className="text-[14px] text-[#959595]">
                        Stay with recognized Hosts
                      </div>
                      <div className="font-semibold text-[#959595] underline">
                        Learn more
                      </div>
                    </div>
                    <Toggle status={true} />
                  </div>
                </div>
                <div className="w-full h-[2px] bg-[#eeeeee] my-[12px]" />
                <div className="space-y-[12px]">
                  <div className="text-[18px] font-semibold">Host Language</div>
                  <div className="grid grid-cols-2 font-semibold">
                    <div className="flex items-center gap-[8px]">
                      <Checkbox />
                      <div>English</div>
                    </div>
                    <div className="flex items-center gap-[8px]">
                      <Checkbox />
                      <div>French</div>
                    </div>
                    <div className="flex items-center gap-[8px]">
                      <Checkbox />
                      <div>Duct</div>
                    </div>
                    <div className="flex items-center gap-[8px]">
                      <Checkbox />
                      <div>Japanese</div>
                    </div>
                  </div>
                  <div className="font-semibold underline">Show more</div>
                </div> */}
            </div>
            <div className="flex items-center justify-between mt-[32px]">
              <div className="px-[14px] py-[8px] border-[1px] border-[#959595] text-[#959595] rounded-[16px] w-max cursor-pointer">
                Clear all
              </div>
              <div
                className="shadow-lg px-[14px] py-[8px] bg-[#202020] text-white rounded-[16px] w-max cursor-pointer hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)] hover:-translate-y-[3px]"
                onClick={handleFilter}
              >
                Apply
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <div className="flex absolute w-full">
        <div className="bg-[#5B1DEE] mx-auto px-[50px] rounded-[15px] text-white mt-[-15px] py-[8px] z-[10]">
          Show map
        </div>
      </div> */}
    </div>
  );
};
