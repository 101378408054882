import { useSelector } from "react-redux";
import { Inbox } from "../PartialPage/Inbox/Inbox";
import { ImageView } from "../Global/ImageView";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setNFT } from "../../Actions/NFTSlice";
import { HostAction } from "./HostAction";
import { InboxProfile } from "../PartialPage/Inbox/InboxProfile";
import { InboxNFT } from "../PartialPage/Inbox/InboxNFT";

export const HostInbox = () => {
  const chatNFT = useSelector((state) => state.chat.nft);
  const assets = useSelector((state) => state.nft.allNFTs);
  const currentChatReceiver = useSelector((state) => state.chat.receiver);

  const chatMode = useSelector((state) => state.chat.mode);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNFT(null));
  }, []);

  return (
    <>
      <div className="bg-[#F6F6F6] rounded-[8px] w-full h-full flex gap-[5px]">
        <div className="w-full rounded-[8px] bg-white">
          <Inbox mode={"host"} />
        </div>
        <div className="w-[400px] rounded-[8px] bg-white p-[16px] space-y-[16px] overflow-auto sidebarheight hiddenscrollbar">
          {chatNFT && "host" === chatMode ? (
            <>
              <InboxNFT currentToken={assets[chatNFT]} />

              <InboxProfile />

              <div className="text-[#B6B6B6]">Rental</div>

              {assets[chatNFT]?.shorttermrental_info?.travelers?.map((item) => {
                const array = [];
                if (item.address === currentChatReceiver) {
                  array.push(
                    <HostAction
                      rentingPeriod={item.renting_period}
                      currentToken={assets[chatNFT]}
                      traveler={currentChatReceiver}
                      cancelled={item.cancelled}
                      fundedAmount={item.deposit_amount}
                      approved={item.approved}
                    />
                  );
                }
                return array;
              })}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
