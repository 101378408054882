import ProfileIcon from "../../../assets/images/Profile.svg";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { truncateWalletAddress } from "../../functional/Control";
import UserIcon from "../../../assets/images/User,Profile.svg";
import { useNavigate } from "react-router-dom";

export const InboxProfile = () => {
  const navigate = useNavigate();

  const [receiverAvatar, setReceiverAvatar] = useState();
  const receiverProfile = useSelector((state) => state.chat.receiverProfile);
  const currentChatReceiver = useSelector((state) => state.chat.receiver);

  useEffect(() => {
    setReceiverAvatar(receiverProfile?.Avatar);
  }, [receiverProfile]);

  return (
    <div className="space-y-[8px] border-b-[1px] border-[#B6B6B6] py-[10px]">
      <div className="flex items-center gap-[10px]">
        <div className="my-[10px] ml-[10px] p-[4px] rounded-full border-[1px] border-[#cdcdcd]">
          <img
            alt=""
            src={receiverAvatar || ProfileIcon}
            className="rounded-full w-[50px]"
          />
        </div>
        <div className="text-[#B6B6B6]">
          {truncateWalletAddress(currentChatReceiver, 5, 4)}
        </div>
      </div>
      <div
        onClick={() => navigate("/user")}
        className="bg-[#5b1dee] px-[12px] py-[8px] justify-center text-white cursor-pointer shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)] hover:-translate-y-[3px] flex items-center gap-[4px]"
      >
        <img alt="" src={UserIcon} />
        <div>View Profile</div>
      </div>
    </div>
  );
};
