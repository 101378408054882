import { Modal } from "react-bootstrap";
import joinIcon from "../../assets/images/claim/icon.svg";
import userIcon from "../../assets/images/claim/User,Profile.svg";
import emailIcon from "../../assets/images/claim/email-mail-style-4-big.svg";
import { useRef, useState } from "react";
import axios from "axios";
import { Bounce } from "react-awesome-reveal";
import plus from "../../assets/images/ConnectWallet/Close.svg";
import Lottie from "lottie-react";
import codedEstateLottie from "../../assets/animations/XA9XVQ3i9v.json";
import { api } from "../functional/Api";

// import testLottie from "../../assets/animations/JSON/time-bar.json";

export const Join = ({ show, handleClose = () => {} }) => {
  const [form, setForm] = useState({
    Email: null,
    Name: null,
  });
  const [joined, setJoined] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const [alreadyJoined, setAlreadyJoined] = useState(false);

  const emailRef = useRef(null);
  const join = async () => {
    if (!isValidEmail) return;

    const res = await api("waitlist/add", {
      Email: form.Email,
      Name: form.Name,
    });

    if (res) {
      setForm({
        Email: null,
        Name: null,
      });
      setAlreadyJoined(false);
      setJoined(true);
    }

    if (!res) {
      setAlreadyJoined(true);
    }
  };

  const [loading, setLoading] = useState(true);

  const handleEnterKey = async (e) => {
    if (e.key === "Enter") {
      join();
    }
  };

  const setFocusToEmail = async (e) => {
    if (e.key === "Enter") {
      emailRef.current.focus();
    }
  };

  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    setForm({ ...form, Email: inputEmail });
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputEmail);
    setIsValidEmail(isValid);
  };

  return (
    <>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="sm:w-[600px] w-full min-h-[400px] flex flex-col relative">
            <img
              alt=""
              src={plus}
              className="w-[40px] cursor-pointer absolute top-[10px] right-[10px]"
              onClick={handleClose}
            ></img>
            {/* <Lottie
              className="w-[200px] m-auto"
              animationData={testLottie}
              loop={true}
            /> */}

            {joined ? (
              <div className="w-full h-full bg-white my-auto flex flex-col items-center rounded-[8px] justify-center">
                <Lottie
                  className="w-[200px] m-auto"
                  animationData={codedEstateLottie}
                  loop={false}
                  onComplete={() => setLoading(false)}
                />
                <div hidden={loading}>
                  <Bounce
                    className="text-[#5B1DEE] sm:text-[40px] text-[24px] text-center"
                    duration={2000}
                  >
                    Successfully Joined!
                  </Bounce>
                </div>
              </div>
            ) : (
              <div className="p-[20px]">
                <div className="w-full flex flex-col items-center">
                  <img alt="" src={joinIcon} />
                  <div className="text-[#5B1DEE] sm:text-[40px] text-[32px] text-center">
                    Join the waitlist
                  </div>
                  <div className="text-center">
                    Get first access to the first homes on-chain and be notified
                    first for any future coders list benefits.
                  </div>
                </div>

                <div className="w-full flex flex-col items-center space-y-[20px] mt-[40px]">
                  <div className="globalInputForm py-[10px] flex items-center rounded-full px-[16px] gap-[8px] w-full sm:w-[400px]">
                    <img alt="" src={userIcon} />
                    <input
                      className="w-full"
                      placeholder="Full Name"
                      value={form.Name}
                      onChange={(e) =>
                        setForm({ ...form, Name: e.target.value })
                      }
                      onKeyDown={setFocusToEmail}
                      type="text"
                    />
                  </div>
                  <div className="globalInputForm py-[10px] flex items-center rounded-full px-[16px] gap-[8px] w-full sm:w-[400px]">
                    <img alt="" src={emailIcon} />
                    <input
                      className="w-full"
                      placeholder="Email"
                      value={form.Email}
                      onChange={handleEmailChange}
                      type="email"
                      onKeyDown={handleEnterKey}
                      ref={emailRef}
                    />
                  </div>
                  {!isValidEmail && (
                    <p style={{ color: "red" }}>
                      Please enter a valid email address.
                    </p>
                  )}
                  {alreadyJoined && (
                    <p style={{ color: "red" }}>
                      You already joined with using this email.
                    </p>
                  )}
                  <div
                    className="cursor-pointer px-[18px] py-[10px] bg-[#5B1DEE] active:scale-[0.95] text-white w-full sm:w-[400px] shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] text-center font-semibold hover:shadow-[-1px_6px_10px_0_rgba(93,0,207,0.5)] hover:-translate-y-[3px]"
                    onClick={() => {
                      join();
                    }}
                  >
                    Confirm
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
