import star from "../assets/images/review/Star.svg";
import { Dropdown, ProgressBar } from "react-bootstrap";
import star1 from "../assets/images/review/Star1.svg";
import check from "../assets/images/review/Check mark, Сertificate.svg";
import house from "../assets/images/review/house-key.svg";
import message from "../assets/images/review/Messages, Chat.svg";
import map from "../assets/images/review/map-pin-location.svg";
import sale from "../assets/images/review/Sale, Discount, Promotion, Label.svg";
import profile from "../assets/images/Profile.svg";
import search from "../assets/images/tabler-icon-search.png";
import slash from "../assets/images/slash.png";
import close from "../assets/images/Group 1000004790.png";
import { api } from "./functional/Api";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { StarRatingCompo } from "./Global/StarRatingCompo";
import { getCurrentUTCTime } from "./functional/GetCurrentUTCTime";

export const Reviews = ({ tokenId, note, reviewData }) => {
  const [showReviewModal, setShowReviewModal] = useState(false);
  const handleCloseReviewModal = () => setShowReviewModal(false);
  const handleShowReviewModal = () => setShowReviewModal(true);

  const [countReviews, setCountReviews] = useState(0);
  const [avgRate, setAvgReviews] = useState(0);
  const [avgRates, setAvgRates] = useState({
    cleanliness: 0,
    accuracy: 0,
    checkIn: 0,
    checkOut: 0,
    communication: 0,
    location: 0,
  });
  const [reviews, setReviews] = useState([]);
  const [overallRating, setOverallRating] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());

  // const setTime = async () => {
  //   setCurrentTime(new Date(await getCurrentUTCTime()));
  // };

  // useEffect(() => {
  //   setTime();
  // }, []);

  const calculateDiffDates = (date) => {
    // const time = await getCurrentUTCTime();
    const diffSec = Math.floor(currentTime?.getTime() / 1000) - date;
    return Math.round(diffSec / 86400) + " days ago";
  };

  const getProfile = async (address) => {
    const res = await api("profile/getProfile", {
      walletAddress: address,
    });
    return res;
  };

  const getReview = async () => {
    const res = await api("review/get", {
      token_id: tokenId,
    });
    // console.log(res);
    if (!res) return;
    setCountReviews(res.length);
    let avgRate = 0;
    let cleanliness = 0,
      accuracy = 0,
      checkIn = 0,
      communication = 0,
      location = 0;
    let reviews = [];
    let overallRating = [0, 0, 0, 0, 0];
    for (let i = 0; i < res.length; i++) {
      avgRate += res[i].rate;
      overallRating[Math.floor(res[i].rate / 1.0)]++;
      cleanliness += res[i].rates.cleanness;
      accuracy += res[i].rates.accuracy;
      checkIn += res[i].rates.checkIn;
      communication += res[i].rates.communication;
      location += res[i].rates.location;
      reviews.push({
        profile: await getProfile(res[i].renter),
        rate: res[i].rate,
        period: res[i].period,
        review: res[i].publicReview,
        note: res[i].privateNote,
      });
    }
    setOverallRating(overallRating);
    setReviews(reviews);
    setAvgReviews(avgRate / res.length);
    setAvgRates({
      cleanliness: cleanliness / res.length,
      accuracy: accuracy / res.length,
      checkIn: checkIn / res.length,
      communication: communication / res.length,
      location: location / res.length,
    });
  };

  const setReview = async (res) => {
    // console.log(res);
    if (!res?.length) return;
    setCountReviews(res.length);
    let avgRate = 0;
    let cleanliness = 0,
      accuracy = 0,
      checkIn = 0,
      communication = 0,
      checkOut = 0;
    let reviews = [];
    let overallRating = [0, 0, 0, 0, 0];
    for (let i = 0; i < res.length; i++) {
      avgRate += res[i].rate;
      overallRating[Math.floor(res[i].rate / 1.0)]++;
      cleanliness += res[i].rates.cleanness;
      accuracy += res[i].rates.accuracy;
      checkIn += res[i].rates.checkIn;
      communication += res[i].rates.communication;
      checkOut += res[i].rates.checkOut;
      reviews.push({
        profile: await getProfile(res[i].owner),
        rate: res[i].rate,
        period: res[i].period,
        review: res[i].publicReview,
        note: res[i].privateNote,
      });
    }
    setOverallRating(overallRating);
    setReviews(reviews);
    setAvgReviews(avgRate / res.length);
    setAvgRates({
      cleanliness: cleanliness / res.length,
      accuracy: accuracy / res.length,
      checkIn: checkIn / res.length,
      communication: communication / res.length,
      checkOut: checkOut / res.length,
    });
  };

  useEffect(() => {
    if (tokenId) getReview();
    else {
      setReview(reviewData);
    }
  }, [tokenId, reviewData]);

  return (
    <div className="shadow-md p-[20px] rounded-[10px] space-y-[20px] bg-white">
      <div className="shadow-md w-full p-[20px] rounded-[10px]">
        <div className="flex items-center gap-[10px] font-semibold">
          <img alt="" src={star}></img>
          <div>{avgRate}</div>
          <div>{countReviews} reviews</div>
        </div>
        <div className="grid grid-cols-7  mt-[20px] bg-[#B6B6B6] gap-[1px]">
          <div className="bg-white px-[10px]">
            <div className="mb-[5px]">Overall rating</div>
            {overallRating
              .slice()
              .reverse()
              .map((item, i) => {
                return (
                  <div className="flex items-center gap-[5px] text-[12px]">
                    <div>{5 - i}</div>
                    <ProgressBar
                      now={(item / countReviews) * 100}
                    ></ProgressBar>
                  </div>
                );
              })}
          </div>

          <div className="bg-white px-[4px] flex flex-col justify-between">
            <div>
              <div>Cleanliness</div>
              <div className="font-semibold">{avgRates.cleanliness}</div>
            </div>

            <img alt="" src={star1} className="self-start"></img>
          </div>
          <div className="bg-white px-[4px] flex flex-col justify-between">
            <div>
              <div>Accuracy</div>
              <div className="font-semibold">{avgRates.accuracy}</div>
            </div>

            <img alt="" src={check} className="self-start"></img>
          </div>
          <div className="bg-white px-[4px] flex flex-col justify-between">
            <div>
              <div>Check-in</div>
              <div className="font-semibold">{avgRates.checkIn}</div>
            </div>

            <img alt="" src={house} className="self-start"></img>
          </div>
          <div className="bg-white px-[4px] flex flex-col justify-between">
            <div>
              <div>Communication</div>
              <div className="font-semibold">{avgRates.communication}</div>
            </div>

            <img alt="" src={message} className="self-start"></img>
          </div>
          {!reviewData && (
            <div className="bg-white px-[4px] flex flex-col justify-between">
              <div>
                <div>Locaion</div>
                <div className="font-semibold">{avgRates.location}</div>
              </div>

              <img alt="" src={map} className="self-start"></img>
            </div>
          )}
          {reviewData && (
            <div className="bg-white px-[4px] flex flex-col justify-between">
              <div>
                <div>CheckOut</div>
                <div className="font-semibold">{avgRates.checkOut}</div>
              </div>

              <img alt="" src={house} className="self-start"></img>
            </div>
          )}

          <div className="bg-white px-[4px] flex flex-col justify-between">
            <div>
              <div>Value</div>
              <div className="font-semibold">{avgRate}</div>
            </div>

            <img alt="" src={sale} className="self-start"></img>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-[40px]">
        {reviews.slice(0, Math.min(2, reviews.length)).map((item) => {
          return (
            <div className="shadow-md w-full p-[20px] rounded-[10px] space-y-[20px]">
              <div className="flex items-center gap-[10px]">
                <div className="my-[10px] ml-[10px] p-[4px] rounded-full border-[1px] border-[#cdcdcd]">
                  <img
                    alt=""
                    src={item.profile.Avatar}
                    className="rounded-full w-[50px]"
                  />
                </div>
                <div>
                  <div className="font-semibold">
                    {item.profile.Name.split("/")[0]}
                  </div>
                  {/* <div>Vilnius, Lithuania</div> */}
                </div>
              </div>
              <div className="flex items-center gap-[10px]">
                <StarRatingCompo rating={item.rate} fixed />
                <div className="text-[#959595]">
                  {calculateDiffDates(item.period[0])}
                </div>
              </div>
              <div className="text-[#959595]">{note && item.note}</div>
              <div>{item.review}</div>
            </div>
          );
        })}

        {/* <div className="shadow-md w-full p-[20px] rounded-[10px] space-y-[20px]">
          <div className="flex items-center gap-[10px]">
            <img alt="" src={profile}></img>
            <div>
              <div className="font-semibold">Mantas</div>
              <div>Vilnius, Lithuania</div>
            </div>
          </div>
          <div className="flex items-center">
            <StarRatingCompo rating={3} fixed />
            <div className="text-[#959595]">1 week ago</div>
          </div>
          <div className="text-[#959595]">
            Great views on the road to the cabin. Nice suroundings. Not too
            remote, there are a few houses around. abin has electricity.
          </div>

          <div className="underline text-[#6b349a] ">Show more</div>
        </div> */}
      </div>

      {reviews.length > 1 && (
        <div
          className="bg-[#202020] text-white rounded-[20px] px-[20px] py-[10px] w-max"
          onClick={handleShowReviewModal}
        >
          Show All {reviews.length} reviews
        </div>
      )}

      <Modal show={showReviewModal} onHide={handleCloseReviewModal} centered>
        <Modal.Body>
          <div className="p-[16px] space-y-[24px]">
            <div className="flex justify-end">
              <img
                alt=""
                src={close}
                className="w-[30px] cursor-pointer"
                onClick={handleCloseReviewModal}
              ></img>
            </div>

            <div className="flex gap-[24px]">
              {/* <div className="p-[16px] shadow-md rounded-[12px] h-max w-[250px]">
                <div className="flex items-center gap-[10px] font-semibold">
                  <img alt="" src={star}></img>
                  <div>4.48</div>
                </div>
                <div className="space-y-[4px] mb-[10px]">
                  <div className="mb-[5px]">Overall rating</div>
                  <div className="flex items-center gap-[5px] text-[12px]">
                    <div>5</div>
                    <ProgressBar now={60}></ProgressBar>
                  </div>
                  <div className="flex items-center gap-[5px] text-[12px]">
                    <div>4</div>
                    <ProgressBar now={60}></ProgressBar>
                  </div>
                  <div className="flex items-center gap-[5px] text-[12px]">
                    <div>3</div>
                    <ProgressBar now={60}></ProgressBar>
                  </div>
                  <div className="flex items-center gap-[5px] text-[12px]">
                    <div>2</div>
                    <ProgressBar now={60}></ProgressBar>
                  </div>
                  <div className="flex items-center gap-[5px] text-[12px]">
                    <div>1</div>
                    <ProgressBar now={60}></ProgressBar>
                  </div>
                </div>
                <div className="items-center flex justify-between border-b-[1px] border-[#B6B6B6] py-[8px]">
                  <div className="items-center flex">
                    <img alt="" src={star1}></img>
                    <div>Cleanliness</div>
                  </div>
                  <div className="font-semibold">3.9</div>
                </div>
                <div className="items-center flex justify-between border-b-[1px] border-[#B6B6B6] py-[8px]">
                  <div className="items-center flex">
                    <img alt="" src={check}></img>
                    <div>Accuracy</div>
                  </div>
                  <div className="font-semibold">3.9</div>
                </div>
                <div className="items-center flex justify-between border-b-[1px] border-[#B6B6B6] py-[8px]">
                  <div className="items-center flex">
                    <img alt="" src={house}></img>
                    <div>Check-in</div>
                  </div>
                  <div className="font-semibold">3.9</div>
                </div>
                <div className="items-center flex justify-between border-b-[1px] border-[#B6B6B6] py-[8px]">
                  <div className="items-center flex">
                    <img alt="" src={message}></img>
                    <div>Communication</div>
                  </div>
                  <div className="font-semibold">3.9</div>
                </div>
                <div className="items-center flex justify-between border-b-[1px] border-[#B6B6B6] py-[8px]">
                  <div className="items-center flex">
                    <img alt="" src={map}></img>
                    <div>Location</div>
                  </div>
                  <div className="font-semibold">3.9</div>
                </div>
                <div className="items-center flex justify-between border-b-[1px] border-[#B6B6B6] py-[8px]">
                  <div className="items-center flex">
                    <img alt="" src={sale}></img>
                    <div>Value</div>
                  </div>
                  <div className="font-semibold">3.9</div>
                </div>
              </div> */}
              <div className="p-[16px] shadow-md rounded-[12px] max-w-[600px] max-h-[550px] space-y-[12px] flex flex-col">
                <div className="flex justify-between">
                  <div className="font-semibold text-[20px]">
                    {countReviews} reviews
                  </div>
                  {/* <Dropdown>
                    <Dropdown.Toggle>Most recent</Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>asdfasdf</Dropdown.Item>
                      <Dropdown.Item>asdfadf</Dropdown.Item>
                      <Dropdown.Item>asdfadsfa</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                </div>

                <div className="flex items-center shadow-md rounded-[4px] px-[12px] py-[6px] gap-[12px]">
                  <img alt="" src={search}></img>
                  <input placeholder="Search" className="w-full" />
                  <img alt="" src={slash}></img>
                </div>

                <div className="overflow-auto h-full min-w-[500px]">
                  {reviews.map((item) => {
                    return (
                      <div className="shadow-md w-full p-[20px] rounded-[10px] space-y-[20px]">
                        <div className="flex items-center gap-[10px]">
                          <div className="my-[10px] ml-[10px] p-[4px] rounded-full border-[1px] border-[#cdcdcd]">
                            <img
                              alt=""
                              src={item.profile.Avatar}
                              className="rounded-full w-[50px]"
                            />
                          </div>
                          <div>
                            <div className="font-semibold">
                              {item.profile.Name.split("/")[0]}
                            </div>
                            {/* <div>Vilnius, Lithuania</div> */}
                          </div>
                        </div>
                        <div className="flex items-center gap-[10px]">
                          <StarRatingCompo rating={item.rate} fixed />
                          <div className="text-[#959595]">
                            {calculateDiffDates(item.period[0])}
                          </div>
                        </div>
                        <div className="text-[#959595]">{item.review}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
