// import profile from "../assets/images/Social.png";
import camera from "../../assets/images/camera-front-style-3.png";
// import homeuser from "../assets/images/home-user.png";
// import locked from "../assets/images/Frame 1000005254.png";
// import traveler from "../assets/images/airplane-plane-big.png";
// import money from "../assets/images/Money.png";
// import home from "../assets/images/store-modern.png";
// import location from "../assets/images/Earth, Pin, Location, Direction.png";
// import arrow from "../assets/images/PropertyAddress/Group.png";
// import school from "../assets/images/school-building-2.png";
// import mywork from "../assets/images/buyer-bag-buy.png";
// import language from "../assets/images/Language, Translate.png";
// import decade from "../assets/images/User,Profile2.png";
// import song from "../assets/images/music-square.png";
// import heart from "../assets/images/PropertyAddress/heart-round-circle.png";
// import question from "../assets/images/question-loading.png";
// import puzzle from "../assets/images/puzzle-right.png";
// import book from "../assets/images/book-open-text.png";
// import clock from "../assets/images/clock-time-coins-deposit.png";
// import restaurant from "../assets/images/Knife, Fork, Restaurant, Food.png";
// import reception from "../assets/images/reception-bell.png";
// import star from "../assets/images/star-favorite-send.png";
// import btn from "../assets/images/Button.png";
import { useEffect, useState, useSyncExternalStore } from "react";
import { useRef } from "react";
import { Modal } from "react-bootstrap";
import InputSlider from "react-input-slider";
import AvatarEditor from "react-avatar-editor";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import close from "../../assets/images/Close.png";
import { useNavigate } from "react-router-dom";
import { Reclaim } from "../../components/Reclaim";
import { ReclaimProtocol } from "../../components/ZKP/ReclaimProtocol";
import { setMyProfile } from "../../Actions/AuthSlice";
import { api, setAuthToken } from "../../components/functional/Api";
import { Reviews } from "../../components/Reviews";
const CropperModal = ({
  src,
  modalOpen,
  setModalOpen,
  setPreview,
  setFile,
}) => {
  const [slideValue, setSlideValue] = useState(10);
  const cropRef = useRef(null);
  //handle save
  const handleSave = async () => {
    if (cropRef) {
      const dataUrl = cropRef.current.getImage().toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();
      setFile(result.url);

      setPreview(URL.createObjectURL(blob));
      setModalOpen(false);
    }
  };

  return (
    <Modal show={modalOpen} onHide={() => setModalOpen(false)} centered>
      <div className="p-[16px] w-[360px] ">
        <div className="mx-auto text-center my-[20px] text-[20px] font-semibold">
          Crop your avatar
        </div>
        <AvatarEditor
          ref={cropRef}
          image={src}
          border={50}
          borderRadius={150}
          color={[0, 0, 0, 0.72]}
          scale={slideValue / 10}
          rotate={0}
          className="mx-auto rounded-[16px]"
        />

        <div className="my-[20px] flex justify-center">
          <InputSlider
            styles={{
              track: {
                height: "5px",
                background: "#e9ecef",
              },
              active: {
                background: "#5b1dee",
              },
            }}
            xmin={10}
            xmax={50}
            // sx={{
            //   margin: "0 auto",
            //   width: "80%",
            //   color: "cyan",
            // }}
            // size="medium"
            x={slideValue}
            axis="x"
            // value={slideValue}
            onChange={({ x }) => setSlideValue(x)}
          />
        </div>
        <div className="flex w-[90%] mx-auto justify-between gap-[60px]">
          <div
            className="bg-[#5B1DEE] cursor-pointer text-center w-full py-[10px] text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] font-semibold hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)] hover:-translate-y-[3px]"
            onClick={handleSave}
          >
            Save
          </div>
          <div
            // size="small"
            // sx={{ marginRight: "10px", color: "white", borderColor: "white" }}
            // variant="outlined"
            className="bg-[#202020] cursor-pointer text-center w-full py-[10px] text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] font-semibold hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)] hover:-translate-y-[3px]"
            onClick={(e) => setModalOpen(false)}
          >
            Cancel
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const Profile = () => {
  const [preview, setPreview] = useState(null);
  const inputRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [src, setSrc] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [bio, setBio] = useState("");
  const [name, setName] = useState({
    firstName: "",
    lastName: "",
  });
  const [company, setCompany] = useState("");
  const [emiratesId, setEmiratesId] = useState("");
  const account = useSelector((state) => state.auth.account);
  const [file, setFile] = useState(null);

  const profile = useSelector((state) => state.auth.profile);
  const [reviewData, setReviewData] = useState();

  useEffect(() => {
    if (profile) {
      setPreview(profile.Avatar);
      setName({
        firstName: profile.Name.split("/")[0],
        lastName: profile.Name.split("/")[1],
      });
      setBio(profile.Bio);
      setEmiratesId(profile.ID);
      setCompany(profile.Company);
      setReviewData(profile.Reviews);
    }
  }, [profile]);

  const handleImgChange = (e) => {
    try {
      setSrc(URL.createObjectURL(e.target.files[0]));
      setModalOpen(true);
    } catch (error) {
      console.log(error);
    }
  };
  const handleInputClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  const setCurrentProfile = async () => {
    const profile = await api("profile/getProfile", {
      walletAddress: account,
    });
    dispatch(setMyProfile(profile));
  };

  const submitChanges = async () => {
    toast.loading("Uploading your profile..");

    let data = new FormData();
    data.append("walletAddress", account);
    if (file) data.append("Avatar", file);
    data.append("Bio", bio);
    data.append("Name", name.firstName + "/" + name.lastName);
    data.append("Company", company);
    data.append("ID", emiratesId);
    const res = (
      await axios.post(
        process.env.REACT_APP_BACKEND_SERVER + "profile/uploadProfile",
        data,
        {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
            accept: "application/json",
            Authorization: setAuthToken({ name: "Avatar" }),
          },
        }
      )
    ).data;
    if (res) {
      toast.dismiss();
      await setCurrentProfile();
      toast.success("Profile updated successfully!", {
        autoClose: 2000,
      });
    }
  };

  return (
    <div className="pt-[6px] pb-[6px] bg-[#F2F2F2] w-full h-max">
      <div className="w-[60%] max-w-[1200px] mx-auto bg-white shadow-md min-h-[calc(100vh-72px)] rounded-[6px] p-[24px] ">
        <div className="flex gap-[20px] items-center">
          <img
            alt=""
            src={close}
            onClick={() => navigate(-1)}
            className="cursor-pointer"
          ></img>

          <div className="text-[24px]">Your Profile</div>
        </div>
        <div className="flex gap-[40px]">
          <div className="min-w-[30%] flex flex-col justify-center items-center h-max">
            <CropperModal
              modalOpen={modalOpen}
              src={src}
              setPreview={setPreview}
              setModalOpen={setModalOpen}
              setFile={setFile}
            />
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              onChange={handleImgChange}
              hidden
            />
            <img
              alt=""
              src={
                preview ||
                "https://www.signivis.com/img/custom/avatars/member-avatar-01.png"
              }
              className="mt-[50px] rounded-full w-[160px] border-[2px] border-[#dddddd] shadow-md"
            />

            <div
              onClick={handleInputClick}
              className="shadow-lg hover:translate-y-[-6px] cursor-pointer mt-[-20px] flex gap-[6px] items-center text-white px-[12px] bg-[#5B1DEE] rounded-[400px] py-[2px] w-max mx-auto"
            >
              <img alt="" src={camera}></img>
              <div className="text-[14px]">Upload</div>
            </div>
            {/* <div className="mt-[40px] py-[8px] px-[100px] bg-[#5B1DEE] rounded-[16px] text-white text-center">
            Verify
          </div> */}
            {/* <div className="p-[24px] shadow-md rounded-[6px] space-y-[16px]">
            <div className="font-semibold text-[16px]">Mode</div>
            <div className="">
              If there any lock on these switch mode, you should
              <span className="underline ml-[5px]">verify</span>
            </div>
            <div className="flex justify-between py-[6px] px-[10px]">
              <div className="flex items-center gap-[10px]">
                <img alt=""  src={homeuser}></img>
                <div className="text-[#959595]">Host</div>
              </div>
              <img alt=""  src={locked}></img>
            </div>
            <div className="flex justify-between shadow-md rounded-[24px] py-[6px] px-[10px]">
              <div className="flex items-center gap-[10px]">
                <img alt=""  src={traveler}></img>
                <div>Traveler</div>
              </div>
              <img alt=""  src={locked}></img>
            </div>
            <div className="flex justify-between py-[6px] px-[10px]">
              <div className="flex items-center gap-[10px]">
                <img alt=""  src={money}></img>
                <div className="text-[#959595]">Buyer</div>
              </div>
              <img alt=""  src={locked}></img>
            </div>
            <div className="flex justify-between py-[6px] px-[10px]">
              <div className="flex items-center gap-[10px]">
                <img alt=""  src={home}></img>
                <div className="text-[#959595]">Seller</div>
              </div>
              <img alt=""  src={locked}></img>
            </div>
          </div> */}
          </div>

          <div className="space-y-[20px] bg-blur mt-[50px]">
            {/* <div className="font-semibold text-[24px]">Your profile</div>
          <div className="text-[#5A5A5A]">
            The information you share will be used across Airbnb to help other
            guests and Hosts get to know you
            <span className="underline text-[#6B349A] ml-[5px]">
              Learn more
            </span>
          </div>

          <div className="grid grid-cols-2 space-y-[0px] gap-[20px]">
            <div className="py-[20px] border-b-[1px] border-[#E3E3E3] gap-[12px] flex items-center w-full">
              <img alt=""  src={location}></img>
              <div>Where llive: Bergen, Norway</div>
              <img alt=""  src={arrow}></img>
            </div>
            <div className="py-[20px] border-b-[1px] border-[#E3E3E3] gap-[12px] flex items-center w-full">
              <img alt=""  src={school}></img>
              <div className="text-[#959595]">Where l went to school</div>
            </div>
            <div className="py-[20px] border-b-[1px] border-[#E3E3E3] gap-[12px] flex items-center w-full">
              <img alt=""  src={mywork}></img>
              <div className="text-[#959595]">My work</div>
            </div>
            <div className="py-[20px] border-b-[1px] border-[#E3E3E3] gap-[12px] flex items-center w-full">
              <img alt=""  src={language}></img>
              <div className="text-[#959595]">Languages I speak</div>
            </div>
            <div className="py-[20px] border-b-[1px] border-[#E3E3E3] gap-[12px] flex items-center w-full">
              <img alt=""  src={decade}></img>
              <div className="text-[#959595]">Decade l was born</div>
            </div>

            <div className="py-[20px] border-b-[1px] border-[#E3E3E3] gap-[12px] flex items-center w-full">
              <img alt=""  src={song}></img>
              <div className="text-[#959595]">
                My favorite song in high school
              </div>
            </div>
            <div className="py-[20px] border-b-[1px] border-[#E3E3E3] gap-[12px] flex items-center w-full">
              <img alt=""  src={heart}></img>
              <div className="text-[#959595]">I'm obsessed with</div>
            </div>
            <div className="py-[20px] border-b-[1px] border-[#E3E3E3] gap-[12px] flex items-center w-full">
              <img alt=""  src={question}></img>
              <div className="text-[#959595]">My fun fact</div>
            </div>
            <div className="py-[20px] border-b-[1px] border-[#E3E3E3] gap-[12px] flex items-center w-full">
              <img alt=""  src={puzzle}></img>
              <div className="text-[#959595]">My most useless skill</div>
            </div>
            <div className="py-[20px] border-b-[1px] border-[#E3E3E3] gap-[12px] flex items-center w-full">
              <img alt=""  src={book}></img>
              <div className="text-[#959595]">My biography title would be</div>
            </div>
            <div className="py-[20px] border-b-[1px] border-[#E3E3E3] gap-[12px] flex items-center w-full">
              <img alt=""  src={clock}></img>
              <div className="text-[#959595]">Ispend too much time</div>
            </div>
            <div className="py-[20px] border-b-[1px] border-[#E3E3E3] gap-[12px] flex items-center w-full">
              <img alt=""  src={restaurant}></img>
              <div className="text-[#959595]">Pets</div>
            </div>
            <div className="py-[20px] border-b-[1px] border-[#E3E3E3] gap-[12px] flex items-center w-full">
              <img alt=""  src={restaurant}></img>
              <div className="text-[#959595]">What's for breakfast</div>
            </div>
            <div className="py-[20px] border-b-[1px] border-[#E3E3E3] gap-[12px] flex items-center w-full">
              <img alt=""  src={reception}></img>
              <div className="text-[#959595]">For guests, l always</div>
            </div>
            <div className="py-[20px] border-b-[1px] border-[#E3E3E3] gap-[12px] flex items-center w-full">
              <img alt=""  src={star}></img>
              <div className="text-[#959595]">What makes my home unique</div>
            </div>
          </div> */}
            <div className="flex items-start gap-[20px] min-w-[500px]">
              <div className="w-full space-y-[20px]">
                <div className="font-semibold text-[18px]">First name</div>
                <div className="globalInputForm rounded-[8px] p-[10px] ">
                  <input
                    type="text"
                    className="w-full"
                    value={name.firstName}
                    onChange={(e) =>
                      setName({ ...name, firstName: e.target.value })
                    }
                    placeholder="First Name"
                  />
                </div>
              </div>
              <div className="w-full space-y-[20px]">
                <div className="font-semibold text-[18px]">Last name</div>
                <div className="globalInputForm rounded-[8px] p-[10px]">
                  <input
                    type="text"
                    className="w-full"
                    value={name.lastName}
                    onChange={(e) =>
                      setName({ ...name, lastName: e.target.value })
                    }
                    placeholder="Last Name"
                  />
                </div>
              </div>
            </div>

            <div className="flex items-center gap-[20px]">
              <div className="w-[120px]">Emirates ID</div>
              <div className="globalInputForm rounded-[8px] p-[10px] w-full">
                <input
                  disabled
                  type="text"
                  className="w-full"
                  value={emiratesId}
                  placeholder="Your emirates ID number"
                />
              </div>
            </div>

            <div className="flex justify-end">
              <ReclaimProtocol
                setReturn={setEmiratesId}
                txt={"Verify Emirates ID"}
              />
            </div>
            <div className="w-full space-y-[20px]">
              <div className="font-semibold text-[18px]">Company</div>
              <div className="globalInputForm rounded-[8px] p-[10px] ">
                <input
                  type="text"
                  className="w-full"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  placeholder="Your company"
                />
              </div>
            </div>
            <div className="font-semibold text-[18px]">About you</div>
            <div className="globalInputForm rounded-[8px] p-[10px] min-w-[500px]">
              <textarea
                className="w-full min-h-[100px]"
                placeholder="Write something fun and punchy."
                value={bio}
                onChange={(e) => setBio(e.target.value)}
              ></textarea>
            </div>
            <div className="flex justify-end">
              <div
                onClick={() => submitChanges()}
                className="hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)] cursor-pointer hover:-translate-y-[3px] mt-[10px] w-[300px] py-[8px] bg-[#5B1DEE] rounded-[16px] text-white text-center"
              >
                Save Changes
              </div>
            </div>
            {/* <div className="underline text-[#6B349A]">Add intro</div>

          <div className="w-full h-[1px] bg-[#E3E3E3]"></div>
          <div className="space-y-[10px]">
            <div className="font-semibold text-[18px]">What you're into</div>
            <div className="font-semibold text-[#959595]">
              Find common ground with other guests and Hosts by adding interests
              to your profile.
            </div>
            <div className="flex gap-[10px]">
              <img alt=""  src={btn}></img>
              <img alt=""  src={btn}></img>
              <img alt=""  src={btn}></img>
            </div>
            <div className="underline text-[#6B349A]">
              Add interests and sports
            </div>
          </div>
          <div className="w-full h-[1px] bg-[#E3E3E3]"></div>
          <div>
            <div className="font-semibold text-[18px]">Your past trips</div>
            <div className="font-semibold text-[#959595]">
              Show the destinations I've traveled to.
            </div>
            <div className="grid grid-cols-4 gap-[20px]">
              <div className="p-[24px] rounded-[6px] shadow-md">
                <div>2022</div>
                <div className="font-semibold text-[18px]">
                  Tingstadsvas... Sweden
                </div>
              </div>
              <div className="p-[24px] rounded-[6px] shadow-md">
                <div>2022</div>
                <div className="font-semibold text-[18px]">
                  Tingstadsvas... Sweden
                </div>
              </div>
              <div className="p-[24px] rounded-[6px] shadow-md">
                <div>2022</div>
                <div className="font-semibold text-[18px]">
                  Tingstadsvas... Sweden
                </div>
              </div>
              <div className="p-[24px] rounded-[6px] shadow-md">
                <div>2022</div>
                <div className="font-semibold text-[18px]">
                  Tingstadsvas... Sweden
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-[1px] bg-[#E3E3E3]"></div>

          <div className="flex justify-end">
            <div className="rounded-[16px] px-[18px] py-[10px] bg-[#5B1DEE] text-center w-max text-white">
              Done
            </div>
          </div> */}
          </div>
        </div>
        {reviewData?.length > 0 && <Reviews reviewData={reviewData} note />}
      </div>
    </div>
  );
};
