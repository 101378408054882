import NUSD from "../../assets/images/NUSD.svg";
import { Testnet } from "@nibiruchain/nibijs";
import { useDispatch, useSelector } from "react-redux";
import { executeContract } from "../functional/InteractToContract";
import { useState, useEffect } from "react";
import { diffToUTC } from "../functional/GetCurrentUTCTime";
import { setDashboardMode } from "../../Actions/DashboardSlice";

export const HostAction = ({
  currentToken,
  traveler,
  cancelled,
  fundedAmount,
  rentingPeriod,
  approved,
  setViewDetailFlag = () => {},
}) => {
  const dispatch = useDispatch();
  const testNet = Testnet(1);
  const account = useSelector((state) => state.auth.account);
  const walletEx = useSelector((state) => state.auth.wallet);
  const [currentTime, setCurrentTime] = useState(new Date());

  const [diff, setDiffToUTC] = useState(0);

  const getDiffToUTC = async () => {
    setDiffToUTC(await diffToUTC());
  };
  useEffect(() => {
    getDiffToUTC();
  }, []);

  const approveReservation = async () => {
    const message = {
      set_approve_for_short_term: {
        token_id: currentToken.token_id,
        traveler: traveler,
        renting_period: [
          rentingPeriod[0].toString(),
          rentingPeriod[1].toString(),
        ],
      },
    };
    const res = await executeContract(
      testNet.chainId,
      testNet.endptTm,
      process.env.REACT_APP_RENTAL_SMART_CONTRACT,
      dispatch,
      currentToken.token_id,
      traveler,
      message,
      account,
      walletEx
    );
    // if (res) setViewDetailFlag(null);
  };
  const finalizeRental = async () => {
    const message = {
      finalize_short_term_rental: {
        token_id: currentToken.token_id,
        traveler: traveler,
        renting_period: [
          rentingPeriod[0].toString(),
          rentingPeriod[1].toString(),
        ],
      },
    };
    const res = await executeContract(
      testNet.chainId,
      testNet.endptTm,
      process.env.REACT_APP_RENTAL_SMART_CONTRACT,
      dispatch,
      currentToken.token_id,
      traveler,
      message,
      account,
      walletEx
    );
    // if (res) setViewDetailFlag(null);
  };

  const rejectReservation = async () => {
    const message = {
      reject_reservation_for_shortterm: {
        token_id: currentToken.token_id,
        traveler: traveler,
        renting_period: [
          rentingPeriod[0].toString(),
          rentingPeriod[1].toString(),
        ],
      },
    };
    const res = await executeContract(
      testNet.chainId,
      testNet.endptTm,
      process.env.REACT_APP_RENTAL_SMART_CONTRACT,
      dispatch,
      currentToken.token_id,
      traveler,
      message,
      account,
      walletEx
    );
    // if (res) setViewDetailFlag(null);
  };

  return (
    <div className="space-y-[10px] border-b-[1px] border-[#B6B6B6] py-[10px]">
      {approved &&
        !cancelled &&
        new Date((rentingPeriod[1] - diff) * 1000) > currentTime && (
          <div className="text-[18px] font-semibold text-[#38A569]">
            Approved
          </div>
        )}
      {approved &&
        !cancelled &&
        new Date((rentingPeriod[1] - diff) * 1000) < currentTime && (
          <div className="text-[18px] font-semibold text-[#38A569]">
            Completed
          </div>
        )}

      {cancelled && (
        <div className="text-[18px] font-semibold text-[#EB4245]">Canceled</div>
      )}

      {!approved &&
        new Date((rentingPeriod[0] - diff) * 1000) > currentTime && (
          <div className="text-[18px] font-semibold text-[#F1B71C]">
            Waiting for approval
          </div>
        )}
      {!approved &&
        new Date((rentingPeriod[0] - diff) * 1000) < currentTime && (
          <div className="text-[18px] font-semibold text-[#EB4245]">
            Expired
          </div>
        )}

      <div className="flex justify-between items-center">
        <div>Period</div>
        <div className="">
          {rentingPeriod?.length &&
            rentingPeriod[0] !== "" &&
            rentingPeriod[1] !== "" &&
            new Intl.DateTimeFormat("en-US", {
              month: "short",
              day: "numeric",
            }).format(new Date((rentingPeriod[0] - diff) * 1000)) +
              "~" +
              new Intl.DateTimeFormat("en-US", {
                month: "short",
                day: "numeric",
              }).format(new Date((rentingPeriod[1] - diff) * 1000))}
        </div>
      </div>
      <div className="flex justify-between">
        <div>Funded</div>
        <div className="flex items-center gap-[10px]">
          <img alt="" src={NUSD} />
          <div className="py-[2px] px-[4px] rounded-[4px] text-[#5b1dee] shadow-md">
            {fundedAmount}
          </div>
          <div>NUSD</div>
        </div>
      </div>
      <div className="flex justify-between">
        <div>Pending</div>
        <div className="flex items-center gap-[10px]">
          <img alt="" src={NUSD} />
          <div className="py-[2px] px-[4px] rounded-[4px] text-[#5b1dee] shadow-md">
            {new Date((rentingPeriod[1] - diff) * 1000) < currentTime ||
            cancelled
              ? 0
              : approved
              ? cancelled
                ? fundedAmount
                : Math.floor(fundedAmount * 0.95)
              : 0}
          </div>
          <div>NUSD</div>
        </div>
      </div>
      <div className="flex justify-between">
        <div>Claimable</div>
        <div className="flex items-center gap-[10px]">
          <img alt="" src={NUSD} />
          <div className="py-[2px] px-[4px] rounded-[4px] text-[#5b1dee] shadow-md">
            {new Date((rentingPeriod[1] - diff) * 1000) < currentTime ||
            cancelled
              ? approved
                ? cancelled
                  ? fundedAmount
                  : Math.floor(fundedAmount * 0.95)
                : 0
              : 0}
          </div>
          <div>NUSD</div>
        </div>
      </div>
      {new Date((rentingPeriod[0] - diff) * 1000) > currentTime &&
        !approved && (
          <div className="flex w-full gap-[20px]">
            <>
              <div
                className="bg-[#5b1dee] py-[10px] text-center w-full text-white cursor-pointer shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] hover:shadow-[-1px_6px_10px_0_rgba(80,80,80,0.5)] hover:-translate-y-[3px]"
                onClick={() => {
                  approveReservation();
                }}
              >
                Approve
              </div>
              <div
                className="bg-[#202020] py-[10px] text-center w-full text-white cursor-pointer shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] hover:shadow-[-1px_6px_10px_0_rgba(80,80,80,0.5)] hover:-translate-y-[3px]"
                onClick={() => {
                  rejectReservation();
                }}
              >
                Reject
              </div>
            </>
          </div>
        )}
      {new Date((rentingPeriod[0] - diff) * 1000) < currentTime && (
        <div
          className="bg-[#202020] py-[10px] text-center w-full text-white cursor-pointer shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] hover:shadow-[-1px_6px_10px_0_rgba(80,80,80,0.5)] hover:-translate-y-[3px]"
          onClick={() => {
            rejectReservation();
          }}
        >
          Reject Rental
        </div>
      )}
      {new Date((rentingPeriod[1] - diff) * 1000) < currentTime &&
        approved &&
        !cancelled && (
          <>
            <div
              className="bg-[#5b1dee] py-[10px] text-center w-full text-white cursor-pointer shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] hover:shadow-[-1px_6px_10px_0_rgba(80,80,80,0.5)] hover:-translate-y-[3px]"
              onClick={() => {
                setViewDetailFlag(true);
                dispatch(setDashboardMode(1));
              }}
            >
              Write a Review
            </div>
          </>
        )}

      {(new Date((rentingPeriod[1] - diff) * 1000) < currentTime ||
        cancelled) &&
        approved && (
          <div
            className="bg-[#202020] py-[10px] text-center w-full text-white cursor-pointer shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] hover:shadow-[-1px_6px_10px_0_rgba(80,80,80,0.5)] hover:-translate-y-[3px]"
            onClick={() => finalizeRental()}
          >
            Finalize Rental
          </div>
        )}
    </div>
  );
};
