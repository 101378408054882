import search from "../../../assets/images/tabler-icon-search.png";
import slash from "../../../assets/images/slash.png";
import avatar from "../../../assets/images/Profile.svg";
import { InboxItem } from "./InboxItem";
import { SelectionGroup, SelectionItem } from "../../Global/Selection";
import { ReceivedMessage, SentMessage } from "../../Global/Messages";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setEmpty,
  setMaintainSomeoneToContact,
  setReadingNow,
} from "../../../Actions/MessageSlice";
import { setMessagesToSend } from "../../../Actions/MessageSlice";
import { removeItem } from "../../../Actions/NotificationSlice";
import {
  setChat,
  setChatNft,
  setChatMode,
  setChatReceiver,
  setHoldFlag,
} from "../../../Actions/ChatSlice";
import { setNFT } from "../../../Actions/NFTSlice";
import { api } from "../../functional/Api";
import { diffToUTC } from "../../functional/GetCurrentUTCTime";

export const Inbox = ({ mode }) => {
  const ref = useRef(null);
  const [text, setText] = useState(null);
  const [messages, setMessages] = useState([]);
  const [chats, setChats] = useState([]);
  const account = useSelector((state) => state.auth.account);

  // const [searchValue, setSearchValue] = useState(null);
  // const isNewMessage = useSelector((state) => state.messages.isNewMessage);
  const newMessages = useSelector((state) => state.messages.messages);

  const notificationItems = useSelector((state) => state.notification.items);

  const chatId = useSelector((state) => state.chat.chatId);
  const receiver = useSelector((state) => state.chat.receiver);
  const chatMode = useSelector((state) => state.chat.mode);
  const nftId = useSelector((state) => state.chat.nft);
  const hold = useSelector((state) => state.chat.holdFlag);

  // const maintain = useSelector((state) => state.messages.maintain);
  const allNFTs = useSelector((state) => state.nft.allNFTs);

  const receiverReadingChat = useSelector(
    (state) => state.messages.receiverReadingChat
  );

  const [showSearchResult, setShowResult] = useState(false);
  const [SearchResult, setResult] = useState([]);
  const [readingState, setReceiverReadingState] = useState(0);
  const dispatch = useDispatch();
  const [initiated, setInitiated] = useState(false);

  // const [currentChatId, setCurrentChat] = useState(null);
  const [latestChatId, setLatestChat] = useState(null);
  const [defaultSelected, setDefaultSelected] = useState(null);
  const [time, setTime] = useState(null);

  const [receiverAvatar, setReceiverAvatar] = useState();
  const [receiverName, setReceiverName] = useState();

  const [diff, setDiffToUTC] = useState(0);

  const getDiffToUTC = async () => {
    setDiffToUTC(await diffToUTC());
  };
  useEffect(() => {
    getDiffToUTC();
  }, []);

  const receiverProfile = useSelector((state) => state.chat.receiverProfile);

  useEffect(() => {
    setReceiverAvatar(receiverProfile?.Avatar);
    setReceiverName(receiverProfile?.Name);
  }, [receiverProfile]);

  useEffect(() => {
    dispatch(setChatNft(nftId));
  }, [nftId]);

  const sendMessage = async () => {
    if (!receiver) return;
    if (!text || text === "") return;
    if (!nftId) return;
    let textTosend = text;
    const generatedTime = new Date(
      (Math.floor(new Date().getTime() / 1000) + diff) * 1000
    );
    const MessageObj = {
      sender: account,
      generatedTime: generatedTime,
      text: textTosend,
    };
    const sendingData = {
      id: chatId,
      newMessage: MessageObj,
      sender: account,
      receiver: receiver,
      nftId: nftId,
      mode: mode === "traveler" || mode === "host" ? "short" : "long",
    };
    dispatch(setMessagesToSend(sendingData));
    setMessages([...messages, MessageObj]);
    setTime(generatedTime);
    setText("");

    const res = await api("chat/sendMessage", sendingData);
    if (res.isCreat) {
      // getChats();
      setChats([{ chatId: res.id, unreadMessages: 0 }, ...chats]);
      dispatch(setChat(res.id));
      dispatch(setChatMode(mode));
    }
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  const getChats = async () => {
    const res = await api("user/inbox", {
      account: account,
    });
    setChats(res);
  };

  const getMessages = async () => {
    const res = await api("chat/getMessages", {
      id: chatId,
      reader: account,
    });
    if (res) {
      if (
        res.chattingSession.mode === "short" &&
        (mode === "traveler" || mode === "host")
      ) {
        setMessages(res.chattingSession.messages);
        // setReceiverReadingState(res.data.readingState);
        // if (res.data.chattingSession.personOne === account)
        //   setReceiver(res.data.chattingSession.personTwo);
        // else setReceiver(res.data.chattingSession.personOne);
      } else {
      }
    }
  };

  useEffect(() => {
    if (chatId && initiated) {
      for (let i = 0; i < notificationItems.length; i++) {
        if (
          notificationItems[i].type === "message" &&
          notificationItems[i].data.id === chatId
        ) {
          dispatch(removeItem(i));
        }
      }
    } else {
    }
  }, [notificationItems, chatId, initiated]);

  useEffect(() => {
    for (let i = 0; i < newMessages.length; i++) {
      const isMatching = chats.some(
        (item) => item.chatId === newMessages[i].id
      );
      if (isMatching) {
        if (newMessages[i].id === chatId) {
          if (newMessages[i].message.sender !== account)
            setMessages([...messages, newMessages[i].message]);
        }
      } else {
        setChats([{ chatId: newMessages[i].id, unreadMessages: 1 }, ...chats]);
      }
    }
    dispatch(setEmpty());
    // setLatestChat(newMessages[newMessages.length - 1].id);
  }, [newMessages]);

  useEffect(() => {
    if (ref) ref.current.scrollTop = ref.current.scrollHeight;
  }, [messages.length]);

  // const checkIfWalletAddress = (str) => {
  //   let regex = /^nibi\w{39}$/i;
  //   return regex.test(str);
  // };

  // useEffect(() => {
  //   if (checkIfWalletAddress(searchValue)) searchByAddress();
  // }, [searchValue]);

  const getTime = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const timeString = hours + ":" + minutes + " " + amOrPm;
    return timeString;
  };

  const getDay = (date) => {
    const monthString = new Intl.DateTimeFormat("en-US", {
      month: "long",
    }).format(date);

    const day = ("0" + date.getDate()).slice(-2);
    let formattedDate = monthString + " " + day;
    const currentTime = new Date();
    if (
      currentTime.getFullYear() +
        currentTime.getMonth() +
        currentTime.getDate() ==
      date.getFullYear() + date.getMonth() + date.getDate()
    )
      return "Today";

    return formattedDate;
  };

  const initiateChats = () => {
    if (hold) {
      dispatch(setHoldFlag(false));
      return;
    }
    dispatch(setChat(null));
    dispatch(setChatReceiver(null));
    dispatch(setChatNft(null));
    dispatch(setChatMode(null));
  };

  useEffect(() => {
    initiateChats();
    setInitiated(true);
    getChats();
  }, []);

  useEffect(() => {
    if (chatId && mode === chatMode && initiated) {
      // alert(chatId);
      getMessages();
    }
  }, [chatId, mode, chatMode, initiated]);

  return (
    <div className="w-full flex">
      <div className="min-w-[330px] h-[calc(100vh-70px)] px-[16px]">
        <div className="bg-white pt-[16px] ml-[-2px]">
          <div className="text-[20px] my-[15px]">All Message</div>
          {/* <div className="relative">
            <div className="flex items-center shadow-md rounded-[4px] px-[12px] py-[6px] gap-[12px] my-[20px]">
              <img alt="" src={search}></img>
              <input
                placeholder="Search"
                className="w-full"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
              <img alt="" src={slash}></img>
            </div>
            <div
              className="absolute w-full bg-white shadow-md p-[10px] space-y-[10px] rounded-[5px]"
              hidden={!showSearchResult}
            >
              {showSearchResult ? (
                <>
                  {SearchResult.map((item) => {
                    return (
                      <div
                        className="flex items-center hover:bg-[#eeeeee] rounded-[5px] cursor-pointer"
                        onClick={() => {
                          setShowResult(false);
                          setSearchValue("");
                          checkIfExistingChat(item);
                        }}
                      >
                        <img alt="" src={avatar} />
                        <div>
                          {item?.substring(0, 10) +
                            "..." +
                            item?.substring(item?.length - 10)}
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </div>
          </div> */}
        </div>

        <div className="h-[calc(100vh-160px)] overflow-auto scrollbarwidth">
          {/* <SelectionGroup defaultItem={defaultSelected} className="chats">
            {chats.map((chat) => {
              return (
                <SelectionItem
                  SelectedItem={
                    <InboxItem
                      selected={true}
                      chatId={chat.chatId}
                      time={chat.chatId == currentChatId ? time : null}
                    />
                  }
                  UnselectedItem={
                    <div
                      onClick={() => {
                        setCurrentChat(chat.chatId);
                      }}
                    >
                      <InboxItem chatId={chat.chatId} />
                    </div>
                  }
                />
              );
            })}
          </SelectionGroup> */}
          {chats?.map((chat) => {
            return (
              <div
                onClick={() => {
                  setTime(null);
                  // setCurrentChat(chat.chatId);
                }}
              >
                <InboxItem
                  // selected={chat.chatId === chatId}
                  time={chat.chatId === chatId ? time : null}
                  myChatId={chat.chatId}
                  mode={mode}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-full h-[calc(100vh-70px)] bg-white p-[8px] ">
        <div className="bg-[#F6F6F6] border-[1px] border-[#E3E3E3] rounded-[8px] w-full h-full relative">
          <div className="flex h-[80px] items-center bg-white w-full rounded-t-[8px] gap-[10px] border-b-[1px] border-[#E3E3E3]">
            {receiver && (
              <div className="my-[10px] ml-[10px] p-[4px] rounded-full border-[1px] border-[#cdcdcd]">
                <img
                  alt=""
                  src={receiverAvatar || avatar}
                  className="rounded-full w-[50px]"
                />
              </div>
            )}
            {receiver && (
              <div className="w-full overflow-hidden">
                {receiverName ? receiverName?.replace("/", " ") : receiver}
              </div>
            )}
          </div>

          <div
            className="p-[8px] h-[calc(100vh-230px)] overflow-auto scrollbarwidth"
            ref={ref}
          >
            {messages.map((message, i) => {
              return (
                <>
                  <div className="w-full text-center text-[#B6B6B6] my-[10px]">
                    {i === 0 ? (
                      getDay(
                        new Date(
                          (Math.floor(
                            new Date(message.generatedTime).getTime() / 1000
                          ) -
                            diff) *
                            1000
                        )
                      )
                    ) : (
                      <></>
                    )}
                    {i > 0 &&
                    getDay(
                      new Date(
                        (Math.floor(
                          new Date(message.generatedTime).getTime() / 1000
                        ) -
                          diff) *
                          1000
                      )
                    ) !==
                      getDay(
                        new Date(
                          (Math.floor(
                            new Date(messages[i - 1].generatedTime).getTime() /
                              1000
                          ) -
                            diff) *
                            1000
                        )
                      ) ? (
                      getDay(
                        new Date(
                          (Math.floor(
                            new Date(message.generatedTime).getTime() / 1000
                          ) -
                            diff) *
                            1000
                        )
                      )
                    ) : (
                      <></>
                    )}
                  </div>

                  {message.sender === account ? (
                    <SentMessage
                      text={message.text}
                      time={getTime(
                        new Date(
                          (Math.floor(
                            new Date(message.generatedTime).getTime() / 1000
                          ) -
                            diff) *
                            1000
                        )
                      )}

                      // isRead={i == messages.length - 1 - readingState}
                    />
                  ) : (
                    <ReceivedMessage
                      text={message.text}
                      time={getTime(
                        new Date(
                          (Math.floor(
                            new Date(message.generatedTime).getTime() / 1000
                          ) -
                            diff) *
                            1000
                        )
                      )}

                      // isRead={i == messages.length - 1 - readingState}
                    />
                  )}
                </>
              );
            })}
          </div>

          <div className="flex w-full px-[20px] gap-[16px] absolute bottom-[20px]">
            <div className="rounded-[5px] px-[12px] py-[9px] w-full bg-white shadow-md">
              <input
                placeholder="Type Message.."
                className="w-full"
                onChange={(e) => setText(e.target.value)}
                value={text}
                onKeyDown={handleEnterKey}
              />
            </div>
            <div
              className="bg-[#5B1DEE] w-[150px] text-center py-[8px] cursor-pointer px-[18px] text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-full font-semibold hover:shadow-[-1px_6px_10px_0_rgba(93,0,207,0.5)] hover:-translate-y-[3px]"
              onClick={() => {
                sendMessage();
              }}
            >
              Send
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
