import NUSD from "../../assets/images/NUSD.svg";

import calendar from "../../assets/images/Calendar,Schedule.png";
import cross from "../../assets/images/close-mini.png";
// import DatePicker, { Calendar } from "react-multi-date-picker";
import { Modal } from "react-bootstrap";
import { StrictMode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setPage } from "../../Actions/PageSlice";
import {
  setAmountToPay,
  setRentDetail,
  setRentPeriod,
} from "../../Actions/RentSlice";
import { Toggle } from "../Global/Toggle";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Calendar, DateRangePicker } from "react-date-range";
import addDays from "date-fns/addDays";
import {
  queryContract,
  setFeeOnContract,
} from "../functional/InteractToContract";
import { Testnet } from "@nibiruchain/nibijs";
import { diffToUTC } from "../functional/GetCurrentUTCTime";

export const PropertyReserve = ({ mode, action, hide }) => {
  // const options = {
  //   year: "numeric",
  //   month: "2-digit",
  //   day: "2-digit",
  // };

  const setRangeFromStartToEnd = (start, end) => {
    const range = [];
    range.push(new Date(start));
    let diff = 1;
    while (addDays(new Date(start), diff) <= new Date(end)) {
      range.push(addDays(new Date(start), diff));
      diff++;
    }
    return range;
  };

  const dates = useSelector((state) => state.rent.period);
  const options = { year: "numeric", month: "short", day: "numeric" };
  // const [value, setValue] = useState(dates);

  const defaultPeriod = [
    {
      startDate: dates[0] ? new Date(dates[0]) : new Date(),
      endDate: dates[1] ? new Date(dates[1]) : new Date(),
      key: "selection",
      color: "#5b1deeaa",
    },
  ];
  const [period, setPeriod] = useState(defaultPeriod);

  const [showReserveModal, setShowReserveModal] = useState(false);
  const handleCloseReserveModal = () => setShowReserveModal(false);
  const handleShowReserveModal = () => {
    setPeriod(defaultPeriod);
    setShowReserveModal(true);
  };
  const dispatch = useDispatch();
  const [months, setMonths] = useState();
  const [days, setDays] = useState();

  const nft = useSelector((state) => state.nft.currentNFT);

  const currentNFT = useSelector((state) => state.nft.allNFTs[nft]);

  const [disabledDates, setDisabledDates] = useState([]);

  const [autoApproved, setAutoApproved] = useState();
  const [firstDay, setFirstDay] = useState();
  const [diff, setDiffToUTC] = useState(0);

  const getDiffToUTC = async () => {
    setDiffToUTC(await diffToUTC());
  };
  useEffect(() => {
    getDiffToUTC();
  }, []);

  // useEffect(() => {
  //   setCurrentNFT(allNFTs[nft]);
  // }, [nft]);

  const setFirstAvailableDay = () => {
    const today = new Date();
    if (disabledDates.length === 0) return today;
    for (let i = 0; i < 10000; i++) {
      if (addDays(today, i + 1) < disabledDates[0]) return addDays(today, i);
      for (let j = 0; j < disabledDates.length - 1; j++) {
        if (
          disabledDates[j] < addDays(today, i) &&
          addDays(today, i + 1) < disabledDates[j + 1]
        )
          return addDays(today, i + 1);
      }
      if (addDays(today, i) > disabledDates[disabledDates.length - 1])
        return addDays(today, i);
    }
  };

  const getDisabledDays = async () => {
    let disabledDays = [];
    for (
      let i = 0;
      i < currentNFT?.shorttermrental_info.travelers.length;
      i++
    ) {
      // disabledDays.conc(currentNFT.shorttermrental_info.travelers[i].renting_period);
      disabledDays = disabledDays.concat(
        setRangeFromStartToEnd(
          (currentNFT?.shorttermrental_info.travelers[i].renting_period[0] -
            diff) *
            1000,
          (currentNFT?.shorttermrental_info.travelers[i].renting_period[1] -
            diff) *
            1000
        )
      );
    }
    setDisabledDates(disabledDays);
  };

  useEffect(() => {
    getDisabledDays();
    setAutoApproved(currentNFT?.shorttermrental_info?.auto_approve);
  }, [currentNFT, diff]);

  useEffect(() => {
    setFirstDay(setFirstAvailableDay());
  }, [disabledDates]);

  useEffect(() => {
    if (dates.length === 0)
      dispatch(setRentPeriod([new Date(), addDays(new Date(), 1)]));
    if (dates[0] < firstDay)
      dispatch(
        setRentPeriod([
          addDays(new Date(firstDay), 1),
          addDays(
            new Date(firstDay),
            1 + currentNFT?.shorttermrental_info?.minimum_stay
          ),
        ])
      );
  }, [firstDay, dates]);

  const pricePerMonth = useSelector(
    (state) =>
      state.nft.allNFTs[nft]?.longtermrental_info?.landlord?.price_per_month
  );
  const pricePerDay = useSelector(
    (state) => state.nft.allNFTs[nft]?.shorttermrental_info?.price_per_day
  );

  const [price, setPrice] = useState(null);

  useEffect(() => {
    if (mode === "short") setPrice(pricePerDay);
    if (mode === "long") setPrice(pricePerMonth);
  }, [mode, pricePerDay, pricePerMonth]);

  const [totalPayment, setTotalPayment] = useState();
  // const fee = 300;
  const [fee, setFee] = useState(100);

  const getFeePercentage = async () => {
    const res = await queryContract(
      process.env.REACT_APP_RENTAL_SMART_CONTRACT,
      {
        get_fee: {},
      },
      Testnet(1).endptTm
    );
    setFee((days * price * res) / 10000);
  };

  useEffect(() => {
    console.log(days);
    getFeePercentage();
  }, [days, price]);

  const refundableDeposit = useSelector(
    (state) =>
      state.nft.allNFTs[nft]?.longtermrental_info?.landlord?.refundable_deposit
  );

  const deposit_amount_short = useSelector(
    (state) =>
      state.nft.allNFTs[nft]?.shorttermrental_info?.traveler?.deposit_amount
  );
  const deposit_amount_long = useSelector(
    (state) =>
      state.nft.allNFTs[nft]?.longtermrental_info?.tenant?.deposit_amount
  );

  function differenceInDays(date1, date2) {
    const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds

    // Convert the dates to timestamps
    const timestamp1 = new Date(date1).getTime();
    const timestamp2 = new Date(date2).getTime();

    // Calculate the difference in milliseconds
    const differenceInMs = Math.abs(timestamp1 - timestamp2);

    // Convert the difference to days
    const differenceInDays = Math.round(differenceInMs / oneDay);

    return differenceInDays;
  }

  useEffect(() => {
    // if (mode === "long") {
    //   if (!deposit_amount_long)
    //     setTotalPayment(months * price + fee + refundableDeposit);
    //   dispatch(
    //     setRentDetail({
    //       period: [period[0].startDate, period[0].endDate],
    //       totalPrice: months * price + fee + refundableDeposit,
    //       guest: 1,
    //     })
    //   );
    // }
    if (mode === "short") {
      if (!deposit_amount_short) setTotalPayment(days * price + fee);
      // dispatch(
      //   setRentDetail({
      //     period: [period[0].startDate, period[0].endDate],
      //     totalPrice: days * price + fee,
      //     guest: 1,
      //   })
      // );
    }
  }, [days, price, fee, deposit_amount_short]);

  useEffect(() => {
    // console.log(value[0]);
    // if (mode === "long") {
    //   const diffInMonths =
    //     (value[1]?.year - value[0]?.year) * 12 +
    //     (value[1]?.monthIndex - value[0]?.monthIndex) +
    //     1;
    //   setMonths(diffInMonths);
    // }
    if (mode === "short") {
      const diffInDays = differenceInDays(dates[1], dates[0]);
      setDays(diffInDays);
    }
  }, [dates, mode]);

  useEffect(() => {
    if (mode === "short") setTotalPayment(deposit_amount_short);
    else setTotalPayment(deposit_amount_long);
  }, [deposit_amount_long, deposit_amount_short, mode]);

  useEffect(() => {
    dispatch(setAmountToPay(totalPayment));
  }, [totalPayment]);

  return (
    <div>
      {!hide ? (
        <>
          <div className="flex items-center justify-between">
            <div>
              <div className="font-semibold text-[18px]">Price</div>

              <div className="flex items-center text-[20px] gap-[5px]">
                <img alt="" src={NUSD}></img>
                <div className="text-[#5B1DEE] px-[4px] py-[2px] rounded-[8px] shadow-md">
                  {price}
                </div>
                <div>NUSD</div>
                <div className="text-[#959595]">
                  {mode === "short" ? "/day" : "/month"}
                </div>
              </div>
              {/* <div className="text-[#5B1DEE] underline">
                How do I get the best deal?
              </div> */}
            </div>
            <div>
              <div>Available from</div>
              <div className="text-[20px]">
                {firstDay?.toLocaleDateString("en-US", options)}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-end mt-[10px]">
            <div className="flex items-center gap-[20px]">
              <div className="text-[20px]">Auto approval</div>
              <Toggle status={autoApproved} />
              {/* <div className="text-[20px]">{autoApproved ? "On" : "Off"}</div> */}
            </div>
          </div>
          {autoApproved ? (
            <div className="mt-[10px] text-[#b6b6b6] text-end">
              Your rental will be activated immediately once you make
              reservation
            </div>
          ) : (
            <div className="mt-[10px] text-[#b6b6b6] text-end">
              Your rental will be activated once host approve your reservation
            </div>
          )}

          <div className="grid grid-cols-2 gap-[16px] mt-[30px]">
            <div className="space-y-[10px]">
              <div className="text-[20px]">Check in</div>
              <div
                className="globalInputForm rounded-[8px] radius40 flex w-full px-[24px] py-[13px] gap-[6px] items-center text-[14px]"
                onClick={handleShowReserveModal}
              >
                <input
                  className="w-full text-[14px]"
                  placeholder="Select date"
                  value={new Date(dates[0])?.toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
                ></input>
                <img alt="" src={calendar}></img>
              </div>
            </div>
            <div className="space-y-[10px]">
              <div className="text-[20px]">Check out</div>
              <div
                className="globalInputForm rounded-[8px] radius40 flex w-full px-[24px] py-[13px] gap-[6px] items-center text-[14px]"
                onClick={handleShowReserveModal}
              >
                <input
                  className="w-full text-[14px]"
                  placeholder="Select date"
                  value={new Date(dates[1])?.toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
                ></input>
                <img alt="" src={calendar}></img>
              </div>
            </div>
          </div>
          <div className="mt-[20px] flex w-full justify-between">
            <div className="text-black">Minimum Stay</div>
            <div className="text-[18px]">
              {currentNFT?.shorttermrental_info?.minimum_stay} Nights
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {/* <div className="flex items-center px-[14px] py-[6px] rounded-[100px] shadow-md mt-[20px] gap-[10px] w-max">
            <img alt=""  src={warning}></img>
            <div className="text-[#5B1DEE]">Available from 11 Nov 2023</div>
          </div> */}

      {/* <div className="space-y-[10px] mt-[30px]">
            <div>Guests</div>
            <div className="globalInputForm rounded-[8px] radius40 flex w-full px-[24px] py-[13px] gap-[6px] items-center text-[14px]">
              <input
                className="w-full text-[14px]"
                placeholder="1 guest"
              ></input>
            </div>
          </div> */}

      <div className="space-y-[16px] mt-[30px]">
        <div className="flex justify-between">
          <div className="text-[#B6B6B6]">
            Rent per {mode === "short" ? "day" : "month"}
          </div>
          <div className="flex items-center text-[18px] gap-[5px] font-normal">
            <img alt="" src={NUSD}></img>
            {/* <div className="text-[#5B1DEE] font-semibold"></div> */}
            <div>{price} NUSD</div>
          </div>
        </div>
        {/* <div className="flex justify-between">
              <div className="text-[#B6B6B6]">Rent VAT per month</div>
              <div className="flex items-center text-[18px] gap-[5px] font-normal">
                <img alt=""  src={NUSD}></img>
                <div className="text-[#5B1DEE] font-semibold"></div>
                <div>375 NUSD</div>
              </div>
            </div> */}
        {/* <div className="flex justify-between">
              <div className="text-[#B6B6B6]">Non-refundable deposit</div>
              <div className="flex items-center text-[18px] gap-[5px] font-normal">
                <img alt=""  src={NUSD}></img>
                <div className="text-[#5B1DEE] font-semibold"></div>
                <div>375 NUSD</div>
              </div>
            </div> */}
        {/* <div className="flex justify-between">
          <div className="text-[#202020]">
            {mode === "short" ? "Weekly" : "Monthly"} Subtotal
          </div>
          <div className="flex items-center text-[18px] gap-[5px] font-normal">
            <img alt=""  src={NUSD} ></img>
            <div className="text-[#5B1DEE] font-semibold">
              {mode === "short" ? price * 7 : price}
            </div>
            <div>NUSD</div>
          </div>
        </div> */}
        <div className="bg-[#E3E3E3] w-full h-[2px]"></div>

        <div className="flex justify-between">
          <div className="text-[#B6B6B6]">Fee</div>
          <div className="flex items-center text-[18px] gap-[5px] font-normal">
            <img alt="" src={NUSD}></img>
            {/* <div className="text-[#5B1DEE] font-semibold"></div> */}
            <div>{fee} NUSD</div>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-[#B6B6B6]">Total Payment</div>
          <div className="flex items-center text-[18px] gap-[5px] font-normal">
            <img alt="" src={NUSD}></img>
            <div className="text-[#5B1DEE] font-semibold">
              {mode === "short"
                ? totalPayment
                : totalPayment - refundableDeposit}
            </div>
            <div>
              ({mode === "short" ? days : months}{" "}
              {mode === "short" ? "days" : "months"})
            </div>
            <div>NUSD</div>
          </div>
        </div>
      </div>
      <div className="bg-[#E3E3E3] w-full h-[2px] mt-[10px]"></div>
      {mode === "long" && (
        <div className="flex justify-between font-normal items-center mt-[10px]">
          <div className="text-[#B6B6B6]">Refundable Deposit</div>
          <div className="flex items-center gap-[5px] text-[24px]">
            <img alt="" src={NUSD}></img>
            <div className="text-[#5B1DEE] px-[4px] py-[2px] rounded-[8px] shadow-md">
              {refundableDeposit}
            </div>
            <div>NUSD</div>
          </div>
        </div>
      )}

      <div className="flex justify-between font-normal items-center mt-[10px]">
        <div className="font-semibold">TOTAL</div>
        <div className="flex items-center gap-[5px] text-[24px]">
          <img alt="" src={NUSD}></img>
          <div className="text-[#5B1DEE] px-[4px] py-[2px] rounded-[8px] shadow-md">
            {totalPayment}
          </div>
          <div>NUSD</div>
        </div>
      </div>
      <Modal show={showReserveModal} onHide={handleCloseReserveModal} centered>
        <Modal.Body>
          <div className="w-full p-[20px] pt-[40px] font-semibold relative">
            <img
              alt=""
              src={cross}
              onClick={handleCloseReserveModal}
              className="absolute cursor-pointer top-[10px] right-[20px]"
            ></img>
            {/* <div className="flex justify-between relative">
              <div>
                <div className="text-[24px] font-semibold">{months} months</div>
                <div className="text-[#B6B6B6]">3 beds 0 baths</div>
              </div>
              <div className="p-[6px] rounded-[40px] shadow-md flex items-center w-[50%] gap-[20px]">
                <div className="px-[16px] rounded-[40px] shadow-md w-[50%] h-full">
                  <div className="font-semibold">Check-in</div>
                  <div className="text-[#B6B6B6]">{value[0]?.toString()}</div>
                </div>
                <div className="h-full">
                  <div className="font-semibold">Checkout</div>
                  <div className="text-[#B6B6B6]">{value[1]?.toString()}</div>
                </div>
              </div>
            </div> */}

            <div className="flex gap-[16px]">
              <DateRangePicker
                onChange={(item) => setPeriod([item.selection])}
                // showSelectionPreview={true}
                // moveRangeOnFirstSelection={true}
                months={2}
                ranges={period}
                disabledDates={disabledDates}
                direction="horizontal"
                minDate={new Date()}
                color="#5b1dee"
                startDatePlaceholder="Check In"
                endDatePlaceholder="Check Out"
              />
            </div>

            {/* <div className="">
                <div className="font-semibold">November 2023</div>
                <div className="grid grid-cols-3 mt-[20px] gap-[10px]">
                  <div className="flex flex-col items-center rounded-[16px] py-[12px] shadow-md space-y-[4px]">
                    <div className="text-[12px] font-semibold">Your Dates</div>
                    <div className="text-[#5B1DEE] text-[18px] font-semibold">
                      2000 NUSD
                    </div>
                    <div className="text-[#959595]">/month</div>
                    <div>8 months & 24 days</div>
                  </div>
                  <div className="flex flex-col items-center rounded-[16px] py-[12px] shadow-md space-y-[4px]">
                    <div className="text-[12px] font-semibold">Your Dates</div>
                    <div className="text-[#5B1DEE] text-[18px] font-semibold">
                      2000 NUSD
                    </div>
                    <div className="text-[#959595]">/month</div>
                    <div>8 months & 24 days</div>
                  </div>
                  <div className="flex flex-col items-center rounded-[16px] py-[12px] shadow-md space-y-[4px]">
                    <div className="text-[12px] font-semibold">Your Dates</div>
                    <div className="text-[#5B1DEE] text-[18px] font-semibold">
                      2000 NUSD
                    </div>
                    <div className="text-[#959595]">/month</div>
                    <div>8 months & 24 days</div>
                  </div>
                </div>
              </div> */}

            <div className="flex items-center justify-between">
              {/* <div
                className="text-[#6B349A] underline cursor-pointer"
                onClick={() => {
                  setPeriod(defaultPeriod);
                }}
              >
                Clear dates
              </div> */}
              <div></div>
              <div
                className="bg-[#5B1DEE] px-[16px] py-[10px] rounded-[16px] text-white cursor-pointer"
                onClick={() => {
                  dispatch(
                    setRentPeriod([period[0].startDate, period[0].endDate])
                  );
                  handleCloseReserveModal();
                }}
              >
                Save
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <>{action}</>
    </div>
  );
};
