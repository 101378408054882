import {
  NibiruTxClient,
  NibiruQuerier,
  Testnet,
  Mainnet,
} from "@nibiruchain/nibijs";
import { setAllNFTs, setMyNFTs, updateNFT } from "../../Actions/NFTSlice";
import { getAllNFTsInfo, getMyNFTsInfo } from "./InteractToIPFS";
import { updateProperty } from "../../Actions/PropertySlice";
import { getMetadata } from "./InteractToIPFS";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setModal } from "../../Actions/ModalSlice";
import { getCurrentUTCTime } from "./GetCurrentUTCTime";
import { setEventToEmit } from "../../Actions/NotificationSlice";
import { setTokenUpdated } from "../../Actions/MessageSlice";
import axios from "axios";
import { api } from "./Api";
const testNet = Testnet(1);
const mainNet = Mainnet();

export const updateAssets = async (contractAddr, token_id, dispatch) => {
  // const allnfts = await getAllNFTsInfo();
  // dispatch(setAllNFTs(allnfts));
  // const mynfts = await getMyNFTsInfo(account);
  // dispatch(setMyNFTs(mynfts));
  console.log(token_id);
  const updatedAsset = await getAllInfo(contractAddr, token_id);
  console.log("updated asset:", updatedAsset);
  dispatch(updateNFT(updatedAsset));
};

export const updateFractionalizedNFT = async (
  contractAddr,
  token_id,
  dispatch
) => {
  const messageForItem = {
    all_nft_info: {
      token_id: token_id,
    },
  };
  const res = await queryContract(
    contractAddr,
    messageForItem,
    mainNet.endptTm
  );
  let totalCounts = 0;
  for (let j = 0; j < res.investors.length; j++) {
    totalCounts += res.investors[j].allocations;
  }
  if (res.investors.length > 1750)
    dispatch(
      updateProperty({
        token_id: token_id,
        ...res,
        value: totalCounts * 100000000,
      })
    );
  else dispatch(updateProperty({ token_id: token_id, ...res }));
};

export const executeContract = async (
  chainId,
  endpt,
  contractAddr,
  dispatch,
  token_id,
  receiver,
  CONTRACT_MESSAGE,
  account,
  walletEx,
  tokenToSend
) => {
  try {
    toast.loading("Transaction is pending...");

    dispatch(
      setModal({
        modal: "waiting",
        data: true,
      })
    );
    dispatch(
      setModal({
        modal: "txState",
        data: null,
      })
    );

    await window[walletEx].enable(chainId);
    const signer = await window[walletEx].getOfflineSigner(chainId);
    const signingClient = await NibiruTxClient.connectWithSigner(endpt, signer);
    let hash;
    if (tokenToSend) {
      const tx = await signingClient.wasmClient.execute(
        account,
        contractAddr,
        CONTRACT_MESSAGE,
        "auto",
        "codedestate",
        tokenToSend
      );
      hash = tx.transactionHash;
      console.log(tx.transactionHash);
    } else {
      const tx = await signingClient.wasmClient.execute(
        account,
        contractAddr,
        CONTRACT_MESSAGE,
        "auto"
      );
      hash = tx.transactionHash;
      console.log(tx.transactionHash);
    }

    dispatch(
      setModal({
        modal: "txState",
        data: true,
      })
    );

    toast.dismiss();

    toast.success("Transaction is confirmed!", {
      autoClose: 1000,
    });
    toast.loading("Updating assets with new data..");
    if (token_id) {
      dispatch(
        setTokenUpdated({ token_id: token_id, contractAddr: contractAddr })
      );
      const currentTime = await getCurrentUTCTime();
      if (receiver !== account && hash) {
        dispatch(
          setEventToEmit({
            txHash: hash,
            action: Object.keys(CONTRACT_MESSAGE)[0],
            token_id: token_id,
            receiver: receiver,
            sender: account,
            timeUTC: currentTime,
          })
        );
        await api("user/addEventItem", {
          txHash: hash,
          action: Object.keys(CONTRACT_MESSAGE)[0],
          token_id: token_id,
          receiver: receiver,
          sender: account,
          timeUTC: currentTime,
        });
      }
    }
    toast.dismiss();
    toast.success("Assets updated with new data.", {
      autoClose: 2000,
    });
    return true;
  } catch (error) {
    dispatch(
      setModal({
        modal: "txState",
        data: false,
      })
    );

    const errorText = error.toString();
    console.log(error.toString());
    toast.dismiss();

    if (errorText.includes("does not exist on chain"))
      toast.error(`Not enough NIBI in Wallet to pay for gas fees`, {
        autoClose: 1000,
      });
    else {
      toast.error(
        `Transaction failed with following errors ${error.toString()}`,
        {
          autoClose: 3000,
        }
      );
    }
    return false;
  }
};

export const queryContract = async (contractAddr, CONTRACT_MESSAGE, endpt) => {
  const querier = await NibiruQuerier.connect(endpt);
  const res = await querier.nibiruExtensions.wasm.queryContractSmart(
    contractAddr,
    CONTRACT_MESSAGE
  );
  return res;
};

export const queryBalance = async (account, denom, endpt) => {
  const querier = await NibiruQuerier.connect(endpt);
  const balance = await querier.getBalance(account, denom);
  return balance;
};

export const getBlockTimeStamp = async (endpt) => {
  const querier = await NibiruQuerier.connect(endpt);
  return (await querier.getBlock()).header.time;
};

export const getAllInfo = async (contractAddr, token_id) => {
  const messageForItem = {
    all_nft_info: {
      token_id: token_id,
    },
  };
  const message = {
    nft_info_long_term_rental: {
      token_id: token_id,
    },
  };
  const messageshort = {
    nft_info_short_term_rental: {
      token_id: token_id,
    },
  };

  const nftInfo = await queryContract(
    contractAddr,
    messageForItem,
    testNet.endptTm
  );
  const longtermrentalInfo = await queryContract(
    contractAddr,
    message,
    testNet.endptTm
  );
  const shorttermrentalInfo = await queryContract(
    contractAddr,
    messageshort,
    testNet.endptTm
  );
  const metaData = await getMetadata(nftInfo.info.token_uri);
  return {
    token_id: token_id,
    metaData: metaData,
    longtermrental_info: longtermrentalInfo,
    shorttermrental_info: shorttermrentalInfo,
    nft_info: nftInfo,
  };
};

export const getAllTokenIds = async () => {
  const message = {
    all_tokens: { owner: "nibi1sadhrsdt40v8fajt77qqhk095n3krms5aqwq8u" },
  };

  const tokens = (
    await queryContract(
      process.env.REACT_APP_RENTAL_SMART_CONTRACT,
      message,
      testNet.endptTm
    )
  ).tokens;
  // const array = [];
  // for (let i = 0; i < tokens.length; i++) {
  //   const item = await getAllInfo(tokens[i]);
  //   array.push(item);
  // }
  // return array;
  return tokens;
};

export const checkOwner = async (tokenId, account) => {
  const message = {
    owner_of: {
      token_id: tokenId,
      include_expired: false,
    },
  };
  const ownerOfChat = (
    await queryContract(
      process.env.REACT_APP_RENTAL_SMART_CONTRACT,
      message,
      testNet.endptTm
    )
  ).owner;
  return ownerOfChat === account;
};

export const checkIfApproved = async (tokenId, account) => {
  const message = {
    approval: {
      token_id: tokenId,
      spender: account,
      include_expired: false,
    },
  };
  try {
    const res = await queryContract(
      process.env.REACT_APP_RENTAL_SMART_CONTRACT,
      message,
      testNet.endptTm
    );
    console.log(res);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const setFeeOnContract = async (
  contractAddr,
  fee,
  account,
  walletEx,
  dispatch
) => {
  const res = await executeContract(
    Testnet(1).chainId,
    Testnet(1).endptTm,
    contractAddr,
    dispatch,
    null,
    null,
    {
      set_fee_value: {
        fee: fee,
      },
    },
    account,
    walletEx,
    null
  );
  return res;
};

export const getFeeFromContract = async () => {
  return await queryContract(
    process.env.REACT_APP_RENTAL_SMART_CONTRACT,
    {
      get_fee: {},
    },
    Testnet(1).endptTm
  );
};
// export const getMyNFTsInfo = async (account) => {
//   const message = {
//     tokens: {
//       owner: account,
//     },
//   };
//   const tokens = (
//     await queryContract(process.env.REACT_APP_RENTAL_SMART_CONTRACT, message)
//   ).tokens;
//   const array = [];
//   for (let i = 0; i < tokens.length; i++) {
//     const item = await getAllInfo(tokens[i]);
//     array.push(item);
//   }
//   return array;
// };
