import { Outlet } from "react-router-dom";
import { Header } from "../components/PartialPage/Header";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";
import { useDispatch } from "react-redux";
import {
  setNewMessage,
  setReadingNow,
  setMessagesToSend,
  setTokenUpdated,
} from "../Actions/MessageSlice";
import { getMyNFTsInfo } from "../components/functional/InteractToIPFS";
import { setMyNFTs } from "../Actions/NFTSlice";
import {
  updateFractionalizedNFT,
  updateAssets,
} from "../components/functional/InteractToContract";
import {
  addItem,
  removeItem,
  setEventToEmit,
} from "../Actions/NotificationSlice";
import axios from "axios";
import { disconnect, setMyProfile } from "../Actions/AuthSlice";
import { setReceiverProfile } from "../Actions/ChatSlice";
import { api } from "../components/functional/Api";

export const MainLayout = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const account = useSelector((state) => state.auth.account);
  const messagesToSend = useSelector((state) => state.messages.messagesToSend);
  const tokenUpdated = useSelector((state) => state.messages.tokenUpdated);
  const eventToEmit = useSelector((state) => state.notification.eventToEmit);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [socket, setSocket] = useState(null);
  const receiver = useSelector((state) => state.chat.receiver);

  const [tryConnect, setTryConnect] = useState(true);
  // const allNFTs = useSelector((state) => state.nft.allNFTs);
  const notificationItems = useSelector((state) => state.notification.items);

  // const getMyAssets = async () => {
  //   const result = await getMyNFTsInfo(account);
  // };

  // useEffect(() => {
  //   const myNFTs = [];
  //   for (let i = 0; i < allNFTs.length; i++) {
  //     if (allNFTs[i]?.nft_info.access.owner === account)
  //       myNFTs.push(allNFTs[i]);
  //   }
  //   dispatch(setMyNFTs(myNFTs));
  // }, [allNFTs]);

  const updateToken = async (updatedToken) => {
    console.log("token update signal received!", updatedToken);

    try {
      if (
        updatedToken.contractAddr ===
        process.env.REACT_APP_FRACTIONALIZED_SMART_CONTRACT
      )
        await updateFractionalizedNFT(
          process.env.REACT_APP_FRACTIONALIZED_SMART_CONTRACT,
          updatedToken.tokenId,
          dispatch
        );
      else if (
        updatedToken.contractAddr ===
        process.env.REACT_APP_RENTAL_SMART_CONTRACT
      ) {
        await updateAssets(
          process.env.REACT_APP_RENTAL_SMART_CONTRACT,
          updatedToken.tokenId,
          dispatch
        );
      }
    } catch (error) {}
  };

  const setNotificationContent = async () => {
    const res = await api("user/inbox", {
      account: account,
    });
    for (let i = 0; i < res.length; i++) {
      if (res[i].unreadMessages) {
        const r = await api("chat/getChattingInfo", {
          id: res[i].chatId,
          reader: account,
        });

        dispatch(
          addItem({
            type: "message",
            data: {
              id: res[i].chatId,
              message: {
                sender: r.receiver,
                generatedTime: r.latestMessageTime,
                text: null,
              },
              nftId: r.nftId,
              mode: r.mode,
            },
          })
        );
      }
    }
    const events = await api("user/inboxEvents", {
      account: account,
    });
    for (let i = 0; i < events.length; i++) {
      dispatch(
        addItem({
          type: "event",
          data: events[i],
        })
      );
    }
  };

  const setProfile = async (account, setProfile = () => {}) => {
    const profile = await api("profile/getProfile", {
      walletAddress: account,
    });
    dispatch(setProfile(profile));
  };

  useEffect(() => {
    if (receiver) setProfile(receiver, setReceiverProfile);
  }, [receiver]);

  useEffect(() => {
    try {
      if (isAuthenticated && tryConnect) {
        const temp_socket = io(process.env.REACT_APP_BACKEND_SERVER);
        temp_socket.on("connect", () => {
          temp_socket.emit("connectedAccount", account);
          console.log("connected to server");
          setTryConnect(false);
        });
        temp_socket.on("disconnect", () => {
          console.log("disconnected from server, trying to reconnect..");
          setTryConnect(true);
          // alert("socket disconnected");
          // dispatch(disconnect());
        });
        temp_socket.on("messageReceived", (res) => {
          dispatch(setNewMessage(res));
          dispatch(
            addItem({
              type: "message",
              data: res,
            })
          );
        });
        temp_socket.on("event", (res) => {
          dispatch(
            addItem({
              type: "event",
              data: res,
            })
          );
        });
        temp_socket.on("readingNow", (res) => {
          dispatch(setReadingNow(res));
        });
        temp_socket.on("tokenUpdated", (res) => {
          updateToken(res);
        });
        setSocket(temp_socket);
        setNotificationContent();
        setProfile(account, setMyProfile);
      }
      if (!isAuthenticated) {
        if (socket !== null) socket.disconnect();
        navigate("/landing");
      }
    } catch (error) {
      console.log(error);
    }
  }, [isAuthenticated, tryConnect]);

  useEffect(() => {
    if (messagesToSend && socket?.active) {
      socket.emit("sendMessage", messagesToSend);
      dispatch(setMessagesToSend(null));
    }
  }, [messagesToSend, socket]);

  useEffect(() => {
    if (eventToEmit && socket?.active) {
      socket.emit("event", eventToEmit);
      dispatch(setEventToEmit(null));
    }
  }, [eventToEmit, socket]);

  useEffect(() => {
    if (tokenUpdated !== null && socket?.active) {
      socket.emit("tokenUpdated", {
        tokenId: tokenUpdated.token_id,
        contractAddr: tokenUpdated.contractAddr,
      });
      dispatch(setTokenUpdated(null));
      console.log("token update signal sent!", tokenUpdated);
    }
  }, [tokenUpdated, socket]);

  return (
    <div className="w-full">
      <Header />
      <div className="pt-[60px]">
        <Outlet />
      </div>
    </div>
  );
};
