import Carousel from "react-bootstrap/Carousel";
import TESTATE from "../../assets/images/logo.svg";
import { PieChartComponent } from "../Global/PieChart";
import { Suspense } from "react";
import { NumericFormat } from "react-number-format";
import { Testate } from "../Global/TestateLogo";
import Lottie from "lottie-react";
import openingSoon from "../../assets/animations/openingSoon.json";
import { Fade } from "react-awesome-reveal";

export const YieldEstateProperty = ({
  flag,
  value,
  nft_price,
  percentage,
  images,
  title,
  available,
  action = () => {},
}) => {
  return (
    <>
      {/* <div className="w-[360px] p-[10px] bg-white rounded-[8px] space-y-[4px] shadow-md h-max">
        <div className="relative">
          <Carousel controls={false}>
            {
              <Carousel.Item>
                <img alt="" 
                  src={image}
                  className={`rounded-[10px] w-full h-[180px] group-hover:scale-125 transition duration-500`}
                  
                ></img>
              </Carousel.Item>
            }
          </Carousel>
          {flag === 2 ? (
            <>
              <div className="absolute top-0 bg-green-500 rounded-t-[10px] w-full text-white text-center">
                Sold
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="text-[18px]">2 Beds In The Bridge (2604)</div>
        <div className="flex items-center gap-[20px]">
          <div className="flex items-center">
            <img alt=""  src={location}  />
            <div className="text-[#959595]">Dubai</div>
          </div>

          {!flag ? (
            <div className="flex items-center">
              <img alt=""  src={home}  />
              <div className="text-[#959595]">Townhouse</div>
            </div>
          ) : (
            <></>
          )}
          {flag === 1 ? (
            <div className="flex items-center">
              <img alt=""  src={rented}  />
              <div className="text-[#959595]">Rented</div>
            </div>
          ) : (
            <></>
          )}
          {flag === 2 ? (
            <div className="flex items-center">
              <img alt=""  src={sold}  />
              <div className="text-[#959595]">Sold</div>
            </div>
          ) : (
            <></>
          )}
        </div>
        {flag === 1 ? (
          <div className="flex items-center justify-between">
            <div className="flex items-center text-[#6B349A] font-semibold gap-2">
              <img alt=""  src={TESTATE}  />
              <div>TESTATE </div>
              <div>925,814</div>
            </div>
            <div className="text-[#959595]">322 investors</div>
          </div>
        ) : (
          <></>
        )}

        {flag === 2 ? (
          <div className="flex items-center justify-between">
            <div className="flex items-center text-[#6B349A] font-semibold gap-2">
              <img alt=""  src={TESTATE}  />
              <div>TESTATE </div>
              <div>925,814</div>
            </div>
            <div className="text-[#959595]">Sold in Aug 2023</div>
          </div>
        ) : (
          <></>
        )}

        {!flag ? (
          <div className="text-center text-white bg-[#5B1DEE] py-[10px] rounded-[16px]">
            Complete your onboarding
          </div>
        ) : (
          <></>
        )}
        {flag === 1 ? (
          <>
            <div className="shadow-md rounded-[4px] space-y-[8px] p-[8px]">
              <div className="flex items-center justify-between">
                <div className="text-[#959595]">Annualised return</div>
                <div className="font-semibold">9,30%</div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-[#959595]">Founded date</div>
                <div className="font-semibold">7 Oct 2023</div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-[#959595]">Current valuation</div>
                <div className="font-semibold">AED 1,950,000</div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {flag === 2 ? (
          <>
            <div className="shadow-md rounded-[4px] space-y-[8px] p-[8px]">
              <div className="flex items-center justify-between">
                <div className="text-[#959595]">Founded date</div>
                <div className="font-semibold">1 Feb 2023</div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-[#959595]">Purchase price</div>
                <div className="font-semibold">AED 660,000</div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-[#959595]">Total rental income</div>
                <div className="font-semibold">AED 90,000</div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div> */}
      <div
        className={`w-[330px] p-[10px] bg-white rounded-[8px] shadow-md h-max group cursor-pointer relative flex flex-col`}
        onClick={available ? action : () => {}}
      >
        {!available ? (
          <div className="absolute bg-[#f8f8f8d0] group-hover:flex hidden top-0 left-0 w-full h-full z-[100] rounded-[8px] flex-col items-center">
            <Fade>
              <Lottie animationData={openingSoon} />

              <div className="text-[24px] text-[#aaaaaa] font-semibold flex gap-[10px]">
                <Fade cascade>
                  {"Purchase terminated..".split(" ").map((item) => {
                    return <div>{item}</div>;
                  })}
                </Fade>
              </div>
            </Fade>
          </div>
        ) : (
          <></>
        )}

        <div
          className="relative cursor-default"
          onClick={(e) => e.stopPropagation()}
        >
          <Carousel controls={false} interval={null}>
            {images?.map((image) => (
              <Carousel.Item>
                <Suspense fallback={<div>Loading...</div>}>
                  <img
                    alt=""
                    src={image}
                    className={`rounded-[10px] w-full h-[180px] group-hover:scale-125 transition duration-500`}
                  ></img>
                </Suspense>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>

        <div className="flex items-center justify-between mt-[10px]">
          <div className="space-y-[10px]">
            <div className="text-[16px]">{title}</div>
            <div className="flex items-center text-[12px] gap-[10px]">
              <div>
                <div className="flex items-center gap-[4px]">
                  <div className="text-[#959595]">Property Value</div>
                </div>
                <div className="flex items-center gap-[2px]">
                  <Testate size={20} />
                  <div className="text-[#5B1DEE] rounded-[8px] p-[4px] shadow-md text-[12px]">
                    <NumericFormat
                      value={value}
                      thousandSeparator={true}
                      displayType="text"
                    />
                  </div>
                  <div className="">TESTATE</div>
                </div>
              </div>
              <div>
                <div className="flex items-center gap-[4px]">
                  <div className="text-[#959595]">Min. Allocation</div>
                </div>
                <div className="flex items-center gap-[2px]">
                  <Testate size={20} />

                  <div className="text-[#5B1DEE] rounded-[8px] p-[4px] shadow-md text-[12px]">
                    {nft_price}
                  </div>
                  <div className="">TESTATE</div>
                </div>
              </div>
            </div>
          </div>
          <PieChartComponent size={70} percentage={percentage} radius={50.3} />
        </div>
        <div className="text-center mt-[10px] text-white bg-[#5B1DEE] py-[8px] rounded-[16px] group-hover:shadow-[-1px_6px_10px_0_rgba(93,0,207,0.5)] group-hover:-translate-y-[3px] cursor-pointer">
          View Details
        </div>
      </div>
    </>
  );
};
