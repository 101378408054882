export const getCurrentUTCTime = async () => {
  try {
    const utcTimeStamp = await (
      await fetch("https://worldtimeapi.org/api/timezone/Etc/UTC")
    ).json();
    return utcTimeStamp?.utc_datetime;
  } catch (error) {
    console.log(error);
    return new Date();
  }
};

export const diffToUTC = async () => {
  const utc = new Date(await getCurrentUTCTime());
  const diffSec =
    Math.floor(utc.getTime() / 1000) - Math.floor(new Date().getTime() / 1000);
  return diffSec;
};

export const toUTCTime = async (timeSec) => {
  const utc = new Date(await getCurrentUTCTime());
  const diffSec =
    Math.floor(utc.getTime() / 1000) - Math.floor(new Date().getTime() / 1000);
  return timeSec + diffSec;
};

export const fromUTCTime = async (timeSec) => {
  const utc = new Date(await getCurrentUTCTime());
  const diffSec =
    Math.floor(new Date().getTime() / 1000) - Math.floor(utc.getTime() / 1000);
  return timeSec + diffSec;
};
