import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setHoldFlag } from "../../Actions/ChatSlice";
import { setChat } from "../../Actions/ChatSlice";
import { setChatReceiver } from "../../Actions/ChatSlice";
import { setChatNft } from "../../Actions/ChatSlice";
import { setChatMode } from "../../Actions/ChatSlice";
import { checkOwner } from "../functional/InteractToContract";
import { setHeaderMode } from "../../Actions/HeaderSlice";
import { setDashboardMode } from "../../Actions/DashboardSlice";
import messageIcon from "../../assets/images/notification/Messages, Chat.svg";

import reserveIcon from "../../assets/images/notification/Hand, Arm, Coins.svg";
import refundIcon from "../../assets/images/notification/Buyer-bag-refund.svg";
import approveIcon from "../../assets/images/notification/home-house-handshake-deal.svg";
import withdrawIcon from "../../assets/images/notification/Money, Banknote, Withdraw.svg";
import rejectIcon from "../../assets/images/notification/house-delete-reject.svg";
import noti from "../../assets/images/Header/notification-bing.svg";
import { truncateWalletAddress } from "../functional/Control";
import { removeItem } from "../../Actions/NotificationSlice";
import axios from "axios";
import { api } from "../functional/Api";
import { useState, useEffect } from "react";
import { diffToUTC } from "../functional/GetCurrentUTCTime";

export const Notification = ({ setShowNoti = () => {} }) => {
  const notificationContent = useSelector((state) => state.notification.items);
  const dispatch = useDispatch();
  const account = useSelector((state) => state.auth.account);
  const allNFTs = useSelector((state) => state.nft.allNFTs);

  const [diff, setDiffToUTC] = useState(0);

  const getDiffToUTC = async () => {
    setDiffToUTC(await diffToUTC());
  };
  useEffect(() => {
    getDiffToUTC();
  }, []);

  const removeEventFromDB = async (hash) => {
    await api("user/inboxEventRemove", {
      account: account,
      txHash: hash,
    });
  };

  return (
    <div className="w-full h-full mb-[20px]">
      {/* <div className="flex items-center text-[16px]">
      <img alt=""  src={noti1} className=""></img>
      <div className="text-[#202020]">
        Please confirm your email address by clicking on the link we
        just emailed you.
      </div>
      <div className="text-[#959595]">Now</div>
    </div> */}
      {notificationContent.length ? (
        <>
          {notificationContent
            .slice()
            .reverse()
            .map((item, i) => {
              return (
                <div className="w-full hover:bg-[#dadada] cursor-pointer rounded-[8px] p-[8px]">
                  {item.type === "message" ? (
                    <div
                      onClick={async () => {
                        setShowNoti(false);
                        dispatch(setHoldFlag(true));
                        dispatch(setChat(item.data.id));
                        dispatch(setChatReceiver(item.data.message.sender));
                        dispatch(setChatNft(item.data.nftId));
                        // alert(item.data.nftId);
                        if (await checkOwner(item.data.nftId, account)) {
                          if (item.data.mode === "short") {
                            dispatch(setChatMode("host"));
                            dispatch(
                              setHeaderMode({
                                mode: 4,
                                submode: 3,
                              })
                            );
                            dispatch(setDashboardMode(3));
                          }
                        } else {
                          if (item.data.mode === "short") {
                            dispatch(setChatMode("traveler"));
                            dispatch(
                              setHeaderMode({
                                mode: 4,
                                submode: 4,
                              })
                            );
                            dispatch(setDashboardMode(3));
                          }
                        }
                      }}
                      className="flex items-center text-[16px] justify-between"
                    >
                      <div className="flex items-center gap-[10px]">
                        <img alt="" src={messageIcon} className="ml-[-6px]" />
                        <div>
                          <div className="font-semibold">New message from</div>
                          <div>
                            {truncateWalletAddress(item.data.message.sender)}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="text-right">
                          {new Date(
                            (Math.floor(
                              new Date(
                                item.data.message.generatedTime
                              ).getTime() / 1000
                            ) -
                              diff) *
                              1000
                          ).toLocaleString("en-US", {
                            month: "short",
                            day: "numeric",
                          })}
                        </div>
                        <div className="text-right">
                          {new Date(
                            (Math.floor(
                              new Date(
                                item.data.message.generatedTime
                              ).getTime() / 1000
                            ) -
                              diff) *
                              1000
                          ).toLocaleString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {item.type === "event" ? (
                    <div
                      className="flex items-center text-[16px] justify-between"
                      onClick={async () => {
                        setShowNoti(false);

                        if (await checkOwner(item.data.token_id, account)) {
                          dispatch(setHeaderMode({ mode: 4, submode: 3 }));
                        } else {
                          dispatch(setHeaderMode({ mode: 4, submode: 4 }));
                        }

                        dispatch(setDashboardMode(1));
                        dispatch(
                          removeItem(notificationContent.length - i - 1)
                        );
                        await removeEventFromDB(item.data.txHash);
                      }}
                    >
                      <div className="flex items-center gap-[10px]">
                        {item.data.action ===
                          "set_reservation_for_short_term" && (
                          <img alt="" src={reserveIcon} className="ml-[-6px]" />
                        )}
                        {(item.data.action ===
                          "cancel_reservation_for_shortterm" ||
                          item.data.action ===
                            "cancel_rental_for_shortterm") && (
                          <img alt="" src={refundIcon} className="ml-[-6px]" />
                        )}
                        {item.data.action ===
                          "reject_reservation_for_shortterm" && (
                          <img alt="" src={rejectIcon} className="ml-[-6px]" />
                        )}
                        {item.data.action === "set_approve_for_short_term" && (
                          <img alt="" src={approveIcon} className="ml-[-6px]" />
                        )}
                        {item.data.action === "finalize_short_term_rental" && (
                          <img
                            alt=""
                            src={withdrawIcon}
                            className="ml-[-6px]"
                          />
                        )}

                        <div>
                          <div className="font-semibold">
                            {truncateWalletAddress(item.data.sender, 5, 4)}
                          </div>
                          <div className="truncate w-[150px]">
                            {item.data.action ===
                              "set_reservation_for_short_term" && (
                              <>Reserved {item.data.token_id}</>
                            )}
                            {(item.data.action ===
                              "cancel_reservation_for_shortterm" ||
                              item.data.action ===
                                "cancel_rental_for_shortterm") && (
                              <>Canceled Reservation {item.data.token_id}</>
                            )}
                            {item.data.action ===
                              "set_approve_for_short_term" && (
                              <>Approved Reservation {item.data.token_id}</>
                            )}
                            {item.data.action ===
                              "reject_reservation_for_shortterm" && (
                              <>Rejected Reservation {item.data.token_id}</>
                            )}
                            {item.data.action ===
                              "finalize_short_term_rental" && (
                              <>Completed Rental {item.data.token_id}</>
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="text-right">
                          {new Date(
                            (Math.floor(
                              new Date(item.data.timeUTC).getTime() / 1000
                            ) -
                              diff) *
                              1000
                          ).toLocaleString("en-US", {
                            month: "short",
                            day: "numeric",
                          })}
                        </div>
                        <div className="text-right">
                          {new Date(
                            (Math.floor(
                              new Date(item.data.timeUTC).getTime() / 1000
                            ) -
                              diff) *
                              1000
                          ).toLocaleString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
        </>
      ) : (
        <>
          <div className="flex w-full h-full">
            <div className="m-auto text-center">
              <img alt="" src={noti} className="m-auto"></img>
              <div>No notifications yet</div>
              <div>
                You've got a blank slate (for now). We'll let you know when
                updates arrive!
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
