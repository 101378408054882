import arrowtoLeft from "../../assets/images/ArrowToLeft.png";
import circleIcon from "../../assets/images/Frame 1000005306.png";
import clockAlarm from "../../assets/images/dashboard/Social (2).png";
import profileIcon from "../../assets/images/Profile.svg";
import { CarouselCompo } from "../Global/CarouselCompo";
import arrowIcon from "../../assets/images/arrowToRight1.svg";
import hourGlass from "../../assets/images/dashboard/hourglass-03.png";
import checkout from "../../assets/images/dashboard/check-circle.png";
import checkoutgray from "../../assets/images/dashboard/check-circle-gray.png";
import printout from "../../assets/images/dashboard/printer.png";
import { SelectionGroup, SelectionItem } from "../Global/Selection";
import light from "../../assets/images/light.svg";
import bookLet from "../../assets/images/dashboard/Hotel, Booklet.png";
import messageschat from "../../assets/images/dashboard/Messages, Chat.png";
import homehousebig from "../../assets/images/dashboard/home-house-big.png";
import NUSD from "../../assets/images/NUSD.svg";
import map from "../../assets/images/map.png";
import mapPin from "../../assets/images/mapPin.svg";
import search from "../../assets/images/tabler-icon-search.png";
import slash from "../../assets/images/slash.png";
import windowIcon from "../../assets/images/window.png";
import list from "../../assets/images/list.png";
import location from "../../assets/images/location.svg";
import wifi from "../../assets/images/wifiIcon.svg";

import { useState, useEffect } from "react";
import { getMyNFTsInfo } from "../functional/InteractToIPFS";
import { useSelector } from "react-redux";
import empty from "../../assets/images/Frame 1000005568.svg";
import { GoogleMapComponent } from "../Global/GoogleMapComponent";
import { ImageView } from "../Global/ImageView";
import { truncateWalletAddress } from "../functional/Control";
import { useDispatch } from "react-redux";
import {
  setChatMode,
  setChatNft,
  setChatReceiver,
  setHoldFlag,
} from "../../Actions/ChatSlice";
import { setDashboardMode } from "../../Actions/DashboardSlice";
import {
  ApprovedCheckMark,
  CancelledCheckMark,
  UnApprovedCheckMark,
} from "../CheckMark/CheckMark";
import { Modal } from "react-bootstrap";
import { setChat } from "../../Actions/ChatSlice";
import { api, getChatId } from "../functional/Api";
import { StarRatingCompo } from "../Global/StarRatingCompo";
import arrowtoleft from "../../assets/images/sidebar/arrowToLeft.svg";
import { toast } from "react-toastify";
import { TravelerAction } from "./TravelerAction";
import { diffToUTC } from "../functional/GetCurrentUTCTime";

export const TravelerTrips = () => {
  const [viewDetailFlag, setViewDetailFlag] = useState(null);
  const [currentToken, setCurrentToken] = useState();
  const [NFTsFlag, setNFTsFlag] = useState(0);
  const account = useSelector((state) => state.auth.account);
  const [rentingPeriod, setRentingPeriod] = useState([]);

  const allNFTs = useSelector((state) => state.nft.allNFTs);
  const [approved, setApproved] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [rentingAssets, setRentingAssets] = useState([]);
  const [fundedAmount, setFundedAmount] = useState();
  const [refundable, setRefundable] = useState(0);

  const [showModal, setShowModal] = useState();

  const [currentTime, setCurrentTime] = useState();

  const [diff, setDiffToUTC] = useState(0);

  const getDiffToUTC = async () => {
    setDiffToUTC(await diffToUTC());
  };
  useEffect(() => {
    getDiffToUTC();
  }, []);

  const setTime = async () => {
    setCurrentTime(
      new Date((Math.floor(new Date().getTime() / 1000) + diff) * 1000)
    );
  };

  useEffect(() => {
    setTime();
  }, [diff]);

  const dispatch = useDispatch();
  useEffect(() => {
    const tempArray = [];
    let assets = Object.values(allNFTs);
    for (let i = 0; i < assets.length; i++) {
      if (
        assets[i].shorttermrental_info.islisted &&
        assets[i].nft_info.access.owner !== account
      ) {
        for (
          let j = 0;
          j < assets[i].shorttermrental_info.travelers.length;
          j++
        ) {
          if (assets[i].shorttermrental_info.travelers[j].address === account) {
            tempArray.push(assets[i]);
            break;
          }
        }
      }
    }
    setRentingAssets(tempArray);
  }, [allNFTs]);

  // const [daysRentedOut, setDaysRentedOut] = useState(0);
  // const [daysLeftOfRental, setDaysLeftOfRental] = useState(0);

  // function calculateDifferenceDays(date1, date2) {
  //   // To calculate the time difference of two dates
  //   var timeDifference = date2.getTime() - date1.getTime();

  //   // To calculate the number of days between two dates
  //   var differenceInDays = timeDifference / (1000 * 3600 * 24);
  //   if (differenceInDays < 0) return 0;
  //   return Math.round(differenceInDays);
  // }

  // const setdays = () => {
  //   setDaysRentedOut(
  //     calculateDifferenceDays(
  //       new Date(currentToken.shorttermrental_info.traveler.renting_period[0]),
  //       currentTime
  //     )
  //   );
  //   setDaysLeftOfRental(
  //     calculateDifferenceDays(
  //       currentTime,
  //       new Date(currentToken.shorttermrental_info.traveler.renting_period[1])
  //     )
  //   );
  // };

  // useEffect(() => {
  //   if (currentToken) setdays();
  // }, [currentToken]);

  const handleMessage = async () => {
    dispatch(setChatNft(currentToken?.token_id));
    dispatch(setChatReceiver(currentToken?.nft_info?.access?.owner));
    dispatch(setChatMode("traveler"));
    dispatch(
      setChat(
        await getChatId(
          account,
          currentToken?.nft_info?.access?.owner,
          currentToken?.token_id,
          "traveler"
        )
      )
    );
    dispatch(setHoldFlag(true));
    dispatch(setDashboardMode(3));
  };

  // const [reviewData, setReviewData] = useState({
  //   rate:rates,

  // });

  const [rates, setRates] = useState({
    cleanness: 0,
    accuracy: 0,
    checkIn: 0,
    communication: 0,
    location: 0,
  });
  const [rate, setRate] = useState();
  const [note, setNote] = useState();
  const [review, setReview] = useState();
  useEffect(() => {
    let total = 0;
    Object.values(rates).forEach((item) => {
      total += item;
    });
    setRate(total / 5);
  }, [rates]);

  const handleSubmit = async () => {
    toast.loading("Uploading your review..");

    const res = await api("review/add", {
      token_id: currentToken?.token_id,
      data: {
        rates: rates,
        rate: rate,
        privateNote: note,
        publicReview: review,
        renter: account,
        period: rentingPeriod,
      },
    });
    if (res === null) {
      toast.dismiss();
      toast.error("You already placed review for this rental");
    }
    if (res === true) {
      toast.dismiss();
      toast.success("Your review has successfully uploaded", {
        autoClose: 1000,
      });
    }
    if (res === false) {
      toast.dismiss();
      toast.error("Uploading failed");
    }
    setViewDetailFlag(null);
  };

  // useEffect(() => {
  //   for (
  //     let i = 0;
  //     i < currentToken?.shorttermrental_info.travelers.length;
  //     i++
  //   ) {
  //     if (
  //       currentToken?.shorttermrental_info.travelers[i].renting_period[0] ===
  //         rentingPeriod[0] &&
  //       currentToken?.shorttermrental_info.travelers[i].renting_period[1] ===
  //         rentingPeriod[1]
  //     ) {
  //       setApproved(currentToken?.shorttermrental_info.travelers[i].approved);
  //       setFundedAmount(
  //         currentToken?.shorttermrental_info.travelers[i].deposit_amount
  //       );
  //       setCancelled(currentToken?.shorttermrental_info.travelers[i].cancelled);
  //     }
  //   }
  // }, [currentToken]);

  useEffect(() => {
    if (!approved) {
      setRefundable(fundedAmount);
      return;
    }
    let cancellation = currentToken?.shorttermrental_info?.cancellation;
    if (!cancellation || cancellation?.length === 0) return;
    cancellation.sort((a, b) => {
      return b.percentage - a.percentage;
    });
    const currentTimeSec = Math.floor(currentTime.getTime() / 1000);
    const diffDays = (rentingPeriod[0] - currentTimeSec) / 86400;
    for (let i = 0; i < cancellation.length; i++) {
      if (cancellation[i].deadline < diffDays) {
        setRefundable((fundedAmount * cancellation[i].percentage) / 100);
        break;
      }
    }
  }, [currentToken]);

  return (
    <>
      {viewDetailFlag === null && (
        <>
          <div className="p-[20px]">
            <div className="text-[#8C8C8C]">My Trips</div>
            <div className="my-[20px] text-[18px] font-semibold">Trips</div>
          </div>
          <div className="p-[16px] border-y-[1px] border-[#EDEDED] flex justify-between">
            <div>
              <SelectionGroup
                defaultItem={NFTsFlag}
                className="p-[6px] shadow-md gap-[8px] rounded-[16px] flex w-max text-[#959595] items-center"
                SelectedItemMask="shadow-md font-semibold text-[#202020] rounded-[16px]"
              >
                <SelectionItem
                  SelectedItem={
                    <div className="w-[120px] rounded-[10px] flex items-center justify-between gap-[10px] px-[10px] py-[4px]">
                      <div>Upcoming</div>
                      <img alt="" src={light}></img>
                    </div>
                  }
                  UnselectedItem={
                    <div
                      className="w-[120px] rounded-[10px] flex items-center justify-between gap-[10px] px-[10px] py-[4px]"
                      onClick={() => setNFTsFlag(0)}
                    >
                      <div>Upcoming</div>
                    </div>
                  }
                />
                <SelectionItem
                  SelectedItem={
                    <div className="w-[120px] rounded-[10px] flex items-center justify-between gap-[10px] px-[10px] py-[4px]">
                      <div>Current</div>
                      <img alt="" src={light}></img>
                    </div>
                  }
                  UnselectedItem={
                    <div
                      className="w-[120px] rounded-[10px] flex items-center justify-between gap-[10px] px-[10px] py-[4px]"
                      onClick={() => setNFTsFlag(1)}
                    >
                      <div>Current</div>
                    </div>
                  }
                />
                <SelectionItem
                  SelectedItem={
                    <div className="w-[120px] rounded-[10px] flex items-center justify-between gap-[10px] px-[10px] py-[4px]">
                      <div>Completed</div>
                      <img alt="" src={light}></img>
                    </div>
                  }
                  UnselectedItem={
                    <div
                      className="w-[120px] rounded-[10px] flex items-center justify-between gap-[10px] px-[10px] py-[4px]"
                      onClick={() => setNFTsFlag(2)}
                    >
                      <div>Completed</div>
                    </div>
                  }
                />
                <SelectionItem
                  SelectedItem={
                    <div className="w-[120px] rounded-[10px] flex items-center justify-between gap-[10px] px-[10px] py-[4px]">
                      <div>Canceled</div>
                      <img alt="" src={light}></img>
                    </div>
                  }
                  UnselectedItem={
                    <div
                      className="w-[120px] rounded-[10px] flex items-center justify-between gap-[10px] px-[10px] py-[4px]"
                      onClick={() => setNFTsFlag(3)}
                    >
                      <div>Canceled</div>
                    </div>
                  }
                />
              </SelectionGroup>
            </div>

            <div className="flex items-center gap-[20px]">
              <div className="flex items-center shadow-md rounded-[4px] px-[12px] py-[6px] gap-[12px]">
                <img alt="" src={search}></img>
                <input placeholder="Search" className="w-full" />
                <img alt="" src={slash}></img>
              </div>

              <div className="items-center flex">
                <div className="p-[8px] bg-[#F2F2F2]">
                  <img alt="" src={windowIcon}></img>
                </div>
                <div className="p-[8px]">
                  <img alt="" src={list}></img>
                </div>
              </div>
            </div>
          </div>
          <div className="p-[20px] h-full">
            {rentingAssets.length > 0 ? (
              <div className="flex flex-wrap gap-[20px]">
                {rentingAssets.map((item) => {
                  let temp = [];
                  for (
                    let i = 0;
                    i < item.shorttermrental_info.travelers.length;
                    i++
                  )
                    if (
                      item.shorttermrental_info.travelers[i].address === account
                    )
                      if (
                        (NFTsFlag === 0 &&
                          !item.shorttermrental_info.travelers[i].cancelled &&
                          currentTime <
                            new Date(
                              item.shorttermrental_info.travelers[i]
                                .renting_period[0] * 1000
                            )) ||
                        (NFTsFlag === 1 &&
                          !item.shorttermrental_info.travelers[i].cancelled &&
                          currentTime >
                            new Date(
                              item.shorttermrental_info.travelers[i]
                                .renting_period[0] * 1000
                            ) &&
                          currentTime <
                            new Date(
                              item.shorttermrental_info.travelers[i]
                                .renting_period[1] * 1000
                            )) ||
                        (NFTsFlag === 2 &&
                          !item.shorttermrental_info.travelers[i].cancelled &&
                          currentTime >
                            new Date(
                              item.shorttermrental_info.travelers[i]
                                .renting_period[1] * 1000
                            )) ||
                        (NFTsFlag === 3 &&
                          item.shorttermrental_info.travelers[i].cancelled)
                      )
                        temp.push(
                          <div className="w-[360px]">
                            <CarouselCompo
                              mode="short"
                              nftInfo={item.nft_info}
                              metaData={item.metaData}
                              price={item.shorttermrental_info?.price_per_day}
                              topRightIcon={
                                item.shorttermrental_info.travelers[i]
                                  .cancelled ? (
                                  <CancelledCheckMark />
                                ) : item.shorttermrental_info.travelers[i]
                                    .approved ? (
                                  <ApprovedCheckMark />
                                ) : (
                                  <UnApprovedCheckMark />
                                )
                              }
                              totalPriceHide={true}
                              imageHeight={"200px"}
                              btn={
                                <div
                                  className="bg-[#5B1DEE] py-[10px] text-center mt-[10px] cursor-pointer px-[18px]  text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] group-hover:shadow-[-1px_6px_10px_0_rgba(93,0,207,0.5)] group-hover:-translate-y-[3px]"
                                  onClick={() => {
                                    setCurrentToken(item);
                                    setViewDetailFlag(false);
                                    setRentingPeriod(
                                      item.shorttermrental_info.travelers[i]
                                        .renting_period
                                    );
                                    setApproved(
                                      item.shorttermrental_info.travelers[i]
                                        .approved
                                    );
                                    setFundedAmount(
                                      item.shorttermrental_info.travelers[i]
                                        .deposit_amount
                                    );
                                    setCancelled(
                                      item.shorttermrental_info.travelers[i]
                                        .cancelled
                                    );
                                  }}
                                >
                                  View Details
                                </div>
                              }
                            />
                          </div>
                        );
                  return temp;
                })}
              </div>
            ) : (
              <div className="w-full shadow-md bg-[#F6F6F6] h-full flex flex-col">
                <div className="m-auto flex flex-col">
                  <img alt="" src={empty} className="m-auto"></img>
                  <div className="max-w-[400px] text-center">
                    You don't have any upcoming reservations. Let's plan your
                    next getaway!
                  </div>
                  {/* <div className="m-auto flex gap-[30px] text-[#6B349A]">
                    <div className="underline">Listing page</div>
                    <div className="underline">Mint</div>
                  </div> */}
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {viewDetailFlag === false && (
        <>
          <div className="relative w-full h-full">
            {/* <img
              alt=""
              src={map}
              className="w-full h-full absolute top-0 left-0"
            />
            <img
              alt=""
              src={locationPin}
              className="absolute left-[10%] top-[20%]"
            /> */}
            <GoogleMapComponent
              center={currentToken?.metaData?.Location}
              className="w-full h-full absolute top-0 left-0"
              icon={
                <div className="relative">
                  <img
                    src={mapPin}
                    className="w-[80px] rounded-full mt-[-60px] ml-[-40px]"
                    alt=""
                  />
                  <div className="absolute w-max top-[24px] left-[30px] text-[14px] text-[#5b1dee] font-bold">
                    <div>Where you're staying</div>
                    <div>
                      {new Date(
                        (rentingPeriod[0] - diff) * 1000
                      ).toLocaleDateString("en-US", {
                        // weekday: "short",
                        month: "short",
                        day: "2-digit",
                      }) +
                        "-" +
                        new Date(
                          (rentingPeriod[1] - diff) * 1000
                        ).toLocaleDateString("en-US", {
                          // weekday: "short",
                          month: "short",
                          day: "2-digit",
                        })}
                    </div>
                  </div>
                </div>
              }
            />
            <div className="overflow-auto scrollbarwidth p-[16px] rounded-[8px] shadow-md w-[360px] absolute bg-white h-[calc(100%-16px)] top-[8px] right-[8px] space-y-[16px]">
              <img
                alt=""
                src={arrowtoLeft}
                onClick={() => setViewDetailFlag(null)}
                className="cursor-pointer"
              />
              <div className="text-[24px]">Your stay</div>
              <ImageView
                counts={1}
                imagesArray={currentToken?.metaData?.Images}
              />
              <div className="p-[12px] rounded-[8px] shadow-md space-y-[16px]">
                <div className="flex justify-between">
                  <div className="space-y-[2px]">
                    <div className="text-[18px]">Check-in</div>
                    <div className="text-[#959595]">
                      {new Date(
                        (rentingPeriod[0] - diff) * 1000
                      ).toLocaleDateString("en-US", {
                        weekday: "short",
                        month: "short",
                        day: "2-digit",
                      })}
                    </div>
                    {/* <div className="text-[#959595]">3:00 PM</div> */}
                    {/* <div className="text-white bg-[#5B1DEE] px-[14px] py-[6px] rounded-[16px] w-max">
                      Instruction
                    </div> */}
                  </div>
                  <div className="min-h-[40px] w-[2px] bg-[#E3E3E3]"></div>
                  <div className="flex-col items-end inline-flex space-y-[2px]">
                    <div className="text-[18px]">Check-out</div>
                    <div className="text-[#959595]">
                      {new Date(
                        (rentingPeriod[1] - diff) * 1000
                      ).toLocaleDateString("en-US", {
                        weekday: "short",
                        month: "short",
                        day: "2-digit",
                      })}
                    </div>
                    {/* <div className="text-white bg-[#5B1DEE] px-[14px] py-[6px] rounded-[16px] w-max">
                      Instruction
                    </div> */}
                  </div>
                </div>
                <div className="w-full h-[1px] bg-[#E3E3E3]" />

                <div className="flex items-start gap-[12px] py-[4px] px-[8px]">
                  <img alt="" src={location} />
                  <div>
                    <div>Location</div>
                    <div className="text-[#959595]">
                      Address: {currentToken?.metaData?.Address}
                    </div>
                  </div>
                </div>
                {approved && !cancelled && (
                  <div className="flex items-start gap-[12px] py-[4px] px-[8px]">
                    <img alt="" src={wifi} />
                    <div>
                      <div>Wifi Password</div>
                      <div className="text-[#959595]">
                        {currentToken?.metaData?.Wifi}
                      </div>
                    </div>
                  </div>
                )}

                <div
                  onClick={() => setShowModal(true)}
                  className="flex items-start gap-[12px] py-[4px] px-[8px] cursor-pointer hover:bg-[#dddddd] rounded-[4px]"
                >
                  <img alt="" src={bookLet} />
                  <div>
                    <div>Things to know</div>
                    <div className="text-[#959595]">
                      Intructions and house rules
                    </div>
                  </div>
                </div>
                <Modal
                  centered
                  show={showModal}
                  onHide={() => setShowModal(false)}
                >
                  <Modal.Body>
                    <div className="w-[400px] p-[16px]">
                      <div className="text-[20px]">Ground Rules</div>
                      <div className="font-normal">
                        {currentToken?.metaData?.GroundRules}
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
                <div
                  className="flex items-start gap-[12px] cursor-pointer hover:bg-[#dddddd] py-[4px] px-[8px] rounded-[4px]"
                  onClick={() => handleMessage()}
                >
                  <img alt="" src={messageschat} />
                  <div>
                    <div>Message your host</div>
                    <div className="text-[#959595]">
                      {truncateWalletAddress(
                        currentToken?.nft_info?.access?.owner,
                        5,
                        4
                      )}
                    </div>
                  </div>
                </div>

                <TravelerAction
                  fundedAmount={fundedAmount}
                  refundable={refundable}
                  currentToken={currentToken}
                  approved={approved}
                  cancelled={cancelled}
                  rentingPeriod={rentingPeriod}
                  setViewDetailFlag={setViewDetailFlag}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {viewDetailFlag === true && (
        <>
          <div className="w-full flex flex-col items-center space-y-[30px] py-[20px] relative">
            <img
              alt=""
              src={arrowtoleft}
              className="absolute top-[20px] left-[30px] w-[50px] cursor-pointer"
              onClick={() => setViewDetailFlag(false)}
            />
            <div className="flex flex-col items-center space-y-[10px]">
              <div className="text-[24px]">
                Rate your experience in these areas
              </div>
              <div className="text-[#959595] max-w-[55vw] text-center">
                You've given your host an overall rating. Let them know how they
                did in these areas too so they have a better idea of what went
                well and how they can improve.
              </div>
            </div>
            <div className="grid grid-cols-2 w-[50vw] gap-[20px]">
              <div className="flex flex-col items-center">
                <div>Cleanness</div>
                <StarRatingCompo
                  rating={rates.cleanness}
                  onChange={(value) => setRates({ ...rates, cleanness: value })}
                />
              </div>
              <div className="flex flex-col items-center">
                <div>Accuracy</div>
                <StarRatingCompo
                  rating={rates.accuracy}
                  onChange={(value) => setRates({ ...rates, accuracy: value })}
                />
              </div>
              <div className="flex flex-col items-center">
                <div>Check-in</div>
                <StarRatingCompo
                  rating={rates.checkIn}
                  onChange={(value) => setRates({ ...rates, checkIn: value })}
                />
              </div>
              <div className="flex flex-col items-center">
                <div>Communication</div>
                <StarRatingCompo
                  rating={rates.communication}
                  onChange={(value) =>
                    setRates({ ...rates, communication: value })
                  }
                />
              </div>
              <div className="flex flex-col items-center">
                <div>Locaion</div>
                <StarRatingCompo
                  rating={rates.location}
                  onChange={(value) => setRates({ ...rates, location: value })}
                />
              </div>
            </div>
            <div className="flex flex-col items-center">
              <div>Total Value</div>
              <StarRatingCompo rating={rate} fixed />
            </div>
            <div className="flex flex-col items-center space-y-[10px]">
              <div className="text-[20px]">Write a private note for Host</div>
              <div className="text-[#959595] max-w-[50vw] text-center">
                Say thanks or offer a few suggestions. This is just for
                Host-other guests won't be able to read it.
              </div>
              <div className="globalInputForm p-[10px] rounded-[10px] min-w-[500px] h-[100px]">
                <textarea
                  placeholder="Add a private note"
                  className="w-full h-full"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col items-center space-y-[10px]">
              <div className="text-[20px]">Write a public review for Host</div>
              <div className="text-[#959595] max-w-[40vw] text-center">
                Give other travelers a heads-up about what they can expect.
                After the review period ends, well publish this on your host's
                listing.
              </div>
              <div className="globalInputForm p-[10px] rounded-[10px] min-w-[500px] h-[100px]">
                <textarea
                  placeholder="Write a public review"
                  className="w-full h-full"
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                />
              </div>
            </div>
            <div
              onClick={() => handleSubmit()}
              className="hover:translate-y-[-4px] cursor-pointer flex gap-[4px] text-white bg-[#5b1dee] px-[16px] py-[10px] rounded-[16px]"
            >
              <div>Submit Review</div>
              <img alt="" src={arrowIcon} />
            </div>
          </div>
        </>
      )}
    </>
  );
};
