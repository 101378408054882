import {
  // LoadScript,
  GoogleMap,
  Marker,
  OverlayView,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useState } from "react";
import arrowIcon from "../../assets/images/map/Arrow, Location, Map, Direction.svg";
import addIcon from "../../assets/images/map/add.svg";
import removeIcon from "../../assets/images/map/remove.svg";

export const mapStyles = [
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      {
        visibility: "off", // Hide the Points of Interest (POI) labels
      },
    ],
  },

  {
    featureType: "landscape.natural",
    stylers: [{ color: "#AFF0C0" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#B3BAC7" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [{ color: "#CED1DB" }],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off", // Hide the Points of Interest (POI) labels
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "labels.text.fill",
    stylers: [{ color: "#A4A4A4" }],
  },
  {
    featureType: "water",
    stylers: [{ color: "#84E9FF" }],
  },
];

export const GoogleMapComponent = ({ center, className, icon }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ["places"],
  });
  const [mapZoom, setMapZoom] = useState(12);

  return isLoaded ? (
    <GoogleMap
      center={center}
      zoom={mapZoom}
      mapContainerClassName={className}
      options={{
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        zoomControl: false,
        styles: mapStyles,
      }}
    >
      {/* <Marker position={center} draggable={false}></Marker> */}
      <div className="absolute top-[20px] right-[20px] flex items-center gap-[10px]">
        <div className="p-[4px] rounded-full shadow-md bg-[#202020] hover:translate-y-[-4px]">
          <img alt="" src={arrowIcon} />
        </div>
        <div
          className="p-[4px] rounded-full shadow-md bg-white cursor-pointer hover:translate-y-[-4px]"
          onClick={() => setMapZoom(mapZoom + 1)}
        >
          <img alt="" src={addIcon} />
        </div>
        <div
          className="p-[4px] rounded-full shadow-md bg-white cursor-pointer hover:translate-y-[-4px]"
          onClick={() => setMapZoom(mapZoom - 1)}
        >
          <img alt="" src={removeIcon} />
        </div>
      </div>

      {icon && (
        <OverlayView
          position={center}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          {icon}
        </OverlayView>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
};
