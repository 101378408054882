import { useDispatch } from "react-redux";
import IDIcon from "../../assets/images/Social.png";
import { setDashboardMode } from "../../Actions/DashboardSlice";
import { useSelector } from "react-redux";
import { CarouselCompo } from "../Global/CarouselCompo";
import verifiedMark from "../../assets/images/dashboard/badges (1).png";
import uploadimage from "../../assets/images/dashboardListing/uploadimage.png";
import unverifiedMark from "../../assets/images/dashboard/badges.png";
import verifyIcon1 from "../../assets/images/dashboard/Frame 10000055681.png";
import sampleMetadata from "../../metadata.json";
import {
  executeContract,
  queryContract,
} from "../functional/InteractToContract";
import { pinMetadata } from "../functional/InteractToIPFS";
import { useState, useEffect } from "react";
import { getMyNFTsInfo } from "../functional/InteractToIPFS";
import { useNavigate } from "react-router-dom";
import verifyIcon from "../../assets/images/dashboard/Frame 1000005568.png";
import { setNFTsSection } from "../../Actions/NFTSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const LandlordDashboard = () => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.auth.account);
  const walletEx = useSelector((state) => state.auth.wallet);
  const [updateFlag, setUpdateFlag] = useState(false);

  const assets = useSelector((state) => state.nft.myNFTs);

  const navigate = useNavigate();

  // const [assets, setAssets] = useState([]);

  // const getMyAssets = async () => {
  //   const result = await getMyNFTsInfo(account);
  //   setAssets(result);
  // };

  // useEffect(() => {
  //   getMyAssets();
  // }, [updateFlag]);

  const mintNFT = async (token_uri) => {
    // const token_id = ((await countMintedNFTs()) + 1).toString();
    // const mintMessage = {
    //   mint: {
    //     token_id: token_id,
    //     owner: account,
    //     token_uri: token_uri,
    //     extension: {},
    //   },
    // };
    // await executeContract(
    //   process.env.REACT_APP_RENTAL_SMART_CONTRACT,
    //   dispatch,
    //   token_id,
    //   mintMessage,
    //   account,
    //   walletEx
    // );
    // setUpdateFlag(!updateFlag);
  };

  // const countMintedNFTs = async () => {
  //   const query = {
  //     num_tokens: {},
  //   };
  //   return (
  //     await queryContract(process.env.REACT_APP_RENTAL_SMART_CONTRACT, query)
  //   ).count;
  // };

  const handleMint = async () => {
    const hash = await pinMetadata(sampleMetadata);
    if (!hash) return;
    console.log("Metadata uploaded..");
    mintNFT(process.env.REACT_APP_PINATA_GATEWAY_2 + hash);
  };
  return (
    <>
      <div className="p-[20px] h-full flex flex-col">
        <div className="font-semibold text-[24px]">Dashboard</div>
        <div className="text-[#8C8C8C]">
          Welcome to the CodedEstate Dashboard
        </div>
        <div className="my-[40px] flex gap-[16px] w-full">
          <div>
            <div className="font-semibold">Verify ID</div>
            <div className="rounded-[8px] shadow-md px-[12px] py-[18px] space-y-[20px] w-[260px] flex flex-col items-center">
              <img alt="" src={IDIcon} className="w-[88px]"></img>
              <div
                className="py-[8px] bg-[#202020] text-white text-center w-full cursor-pointer shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-full font-semibold hover:shadow-[-1px_6px_10px_0_rgba(0,0,0,0.5)] hover:-translate-y-[3px]"
                onClick={() => {
                  navigate("/account");
                }}
              >
                Verify ID
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="font-semibold">Your Minted NFTs</div>
            <div className="rounded-[8px] shadow-md w-full h-[184px] flex flex-wrap p-[8px] gap-[16px] overflow-auto scrollbarwidth">
              {assets.length > 0 ? (
                <>
                  {assets.map((item) => {
                    if (item.nft_info.access.owner == account)
                      if (item.metaData?.Images && item.metaData?.offers)
                        return (
                          <div
                            className="min-w-[250px]"
                            onClick={() => {
                              dispatch(setNFTsSection(0));
                              dispatch(setDashboardMode(2));
                            }}
                          >
                            <CarouselCompo
                              nftInfo={item.nft_info}
                              metaData={item.metaData}
                              onlyImages={true}
                              topRightIcon={verifiedMark}
                              imageHeight={"146px"}
                              stopPropagation={true}
                            />
                          </div>
                        );
                      else
                        return (
                          <div
                            className="min-w-[250px]"
                            onClick={() => {
                              dispatch(setNFTsSection(1));

                              dispatch(setDashboardMode(2));
                            }}
                          >
                            <CarouselCompo
                              nftInfo={item.nft_info}
                              metaData={item.metaData}
                              emptyImage={uploadimage}
                              onlyImages={true}
                              topRightIcon={unverifiedMark}
                              imageHeight={"146px"}
                              stopPropagation={true}
                            />
                          </div>
                        );
                  })}
                </>
              ) : (
                <div className="rounded-[8px] m-auto flex flex-col items-center space-y-[20px]">
                  <img alt="" src={verifyIcon}></img>
                  <div className="">No Minted NFTs</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="font-semibold">Mint Your Real Estate NFT</div>

        <div className="w-full h-full shadow-md rounded-[8px] flex">
          <div className="m-auto flex flex-col items-center space-y-[40px]">
            <img alt="" src={verifyIcon1}></img>
            <div
              className="bg-[#5B1DEE] text-center w-[200px] py-[8px] cursor-pointer px-[18px] text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-full font-semibold hover:shadow-[-1px_6px_10px_0_rgba(93,0,207,0.5)] hover:-translate-y-[3px]"
              onClick={() => {
                handleMint();
              }}
            >
              Mint
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
