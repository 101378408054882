import { useEffect, useState } from "react";
import NUSD from "../../../assets/images/NUSD.svg";
import { useDispatch, useSelector } from "react-redux";
// import { setNFT } from "../../../../Actions/NFTSlice";
import {
  setChat,
  setChatMode,
  setChatNft,
  setChatReceiver,
} from "../../../Actions/ChatSlice";
import { api } from "../../functional/Api";
import { truncateWalletAddress } from "../../functional/Control";
import { diffToUTC } from "../../functional/GetCurrentUTCTime";

export const InboxItem = ({ myChatId, time, mode }) => {
  const account = useSelector((state) => state.auth.account);
  const [receiver, setReceiverAccount] = useState(null);
  const [latestMessageTime, setLatestTime] = useState(time);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const dispatch = useDispatch();
  // const isNewMessage = useSelector((state) => state.messages.isNewMessage);
  const newMessages = useSelector((state) => state.messages.messages);

  const [nftId, setNftId] = useState(null);

  const assets = useSelector((state) => state.nft.allNFTs);
  const [buildingName, setBuildingName] = useState(null);
  const [price, setPrice] = useState(null);
  const [ownerOfThisItem, setOwner] = useState(null);

  const chatId = useSelector((state) => state.chat.chatId);

  const chatReceiver = useSelector((state) => state.chat.receiver);
  const chatNFTId = useSelector((state) => state.chat.nft);

  const [diff, setDiffToUTC] = useState(0);

  const getDiffToUTC = async () => {
    setDiffToUTC(await diffToUTC());
  };
  useEffect(() => {
    getDiffToUTC();
  }, []);

  useEffect(() => {
    if (chatId === myChatId) {
      setUnreadMessages(0);
    }
  }, [chatId, myChatId, nftId]);

  let back, shadowBack;
  if (myChatId === chatId) {
    back = "bg-[#F6F6F6]";
    shadowBack = "shadow-md bg-white";
  } else {
    back = "bg-white";
    shadowBack = "";
  }
  const getTime = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const timeString = hours + ":" + minutes + " " + amOrPm;
    return timeString;
  };

  const getDay = (date) => {
    try {
      const monthString = new Intl.DateTimeFormat("en-US", {
        month: "long",
      }).format(date);

      const day = ("0" + date.getDate()).slice(-2);
      let formattedDate = monthString + " " + day;
      const currentTime = new Date();
      if (
        currentTime.getFullYear() +
          currentTime.getMonth() +
          currentTime.getDate() ===
        date.getFullYear() + date.getMonth() + date.getDate()
      )
        return "Today";

      return formattedDate;
    } catch (error) {}
  };
  const getChattingInfo = async () => {
    const res = await api("chat/getChattingInfo", {
      id: myChatId,
      reader: account,
    });
    setUnreadMessages(res.unreadMessages);
    setLatestTime(res.latestMessageTime);
    setReceiverAccount(res.receiver);
    setNftId(res.nftId);

    if (res.receiver === chatReceiver && res.nftId === chatNFTId) {
      dispatch(setChat(myChatId));
    }
  };
  useEffect(() => {
    getChattingInfo();
  }, [myChatId]);

  useEffect(() => {
    if (nftId) {
      const allNFTs = Object.values(assets);
      for (let i = 0; i < allNFTs.length; i++) {
        if (allNFTs[i].token_id === nftId) {
          setBuildingName(allNFTs[i].metaData?.Name);

          if (mode === "tenant" || mode === "landlord")
            setPrice(allNFTs[i].longtermrental_info.landlord.price_per_month);
          if (mode === "traveler" || mode === "host")
            setPrice(allNFTs[i].shorttermrental_info.price_per_day);

          setOwner(allNFTs[i].nft_info.access.owner);
          if (
            mode === "landlord" &&
            account !== allNFTs[i].nft_info.access.owner
          ) {
            // dispatch(setChat(null));
          }
          if (
            mode === "tenant" &&
            account === allNFTs[i].nft_info.access.owner
          ) {
            // dispatch(setChat(null));
          }
        }
      }
    }
  }, [nftId, assets]);

  useEffect(() => {
    if (time !== null) setLatestTime(time);
  }, [time]);

  useEffect(() => {
    newMessages.forEach((element) => {
      if (element.id === myChatId) {
        setLatestTime(element.message.generatedTime);
        if (chatId !== myChatId) setUnreadMessages(unreadMessages + 1);
      }
    });
  }, [newMessages]);

  const handleSetCurrentNFT = () => {
    dispatch(setChat(myChatId));
    dispatch(setChatReceiver(receiver));
    dispatch(setChatMode(mode));
    dispatch(setChatNft(nftId));
  };

  return (
    <>
      {((mode === "landlord" || mode === "host") &&
        account === ownerOfThisItem) ||
      ((mode === "tenant" || mode === "traveler") &&
        account !== ownerOfThisItem) ? (
        <>
          <div
            className={`w-full p-[16px] ${back} space-y-[12px] rounded-[4px] shadow-md cursor-pointer`}
            onClick={() => {
              handleSetCurrentNFT();
              // setUnreadMessages(0);
            }}
            // hidden = {ownerOfThisItem}
          >
            <div className="w-full flex items-center justify-between">
              <div className="px-[12px] py-[4px] rounded-[8px] border-[1px] border-[#38A569] text-[#38A569]">
                Inquiry
              </div>
              <div className="text-[#B6B6B6]">
                {getDay(
                  new Date(
                    (Math.floor(new Date(latestMessageTime).getTime() / 1000) -
                      diff) *
                      1000
                  )
                ) +
                  " " +
                  getTime(
                    new Date(
                      (Math.floor(
                        new Date(latestMessageTime).getTime() / 1000
                      ) -
                        diff) *
                        1000
                    )
                  )}
              </div>
            </div>
            <div className="flex w-full items-center justify-between">
              <div className="font-semibold">
                {truncateWalletAddress(receiver)}
              </div>
              {unreadMessages && myChatId !== chatId ? (
                <div className="px-[4px] rounded-[10px] bg-[#7C4AF1] text-white min-w-[26px] text-center">
                  {unreadMessages}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div>
              <div className="text-[#B6B6B6]">{buildingName}</div>
              {/* <div className="text-[#B6B6B6]">Feb 4 - Feb 6 (2 nights)</div> */}
              <div className="flex items-center gap-[10px]">
                <img alt="" src={NUSD} />
                <div
                  className={`p-[4px] rounded-[8px] font-semibold ${shadowBack}`}
                >
                  {price}
                </div>
                <div className="text-[#B6B6B6]">NUSD</div>
              </div>
            </div>
          </div>
          <div className="w-full h-[1px] bg-[#E3E3E3] my-[16px]" />
        </>
      ) : (
        <></>
      )}
    </>
  );
};
