import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentProperty: null,
  Properties: [],
};

export const PropertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    setCurrentProperty: (state, action) => {
      state.currentProperty = action.payload;
    },
    updateProperty: (state, action) => {
      let flag = false;
      for (let i = 0; i < state.Properties.length; i++) {
        if (state.Properties[i]?.token_id === action.payload.token_id) {
          const temp = {
            ...action.payload,
            metaData: state.Properties[i].metaData,
          };
          state.Properties[i] = temp;
          flag = true;
        }
      }
      if (!flag) state.Properties.push(action.payload);

      if (
        state.currentProperty &&
        state.currentProperty.token_id === action.payload.token_id
      ) {
        const temp = {
          ...action.payload,
          metaData: state.currentProperty.metaData,
        };
        state.currentProperty = temp;
      }
    },
  },
});

export const { setCurrentProperty, updateProperty } = PropertySlice.actions;

export default PropertySlice.reducer;
