import { SelectionGroup, SelectionItem } from "../components/Global/Selection";
import light from "../assets/images/light.svg";
import { YieldEstateProperty } from "../components/YieldEstate/YieldEstateProperty";
import { useEffect, useState } from "react";
import { YieldEstateDetails } from "../components/YieldEstate/YieldEstateDetails";

import { useDispatch, useSelector } from "react-redux";
import { setCurrentProperty } from "../Actions/PropertySlice";
import { getMetadata } from "../components/functional/InteractToIPFS";
import { TransitionCompo } from "../components/TransitionCompo";

export const YieldEstate = () => {
  const [flag, setFlag] = useState(0);
  const dispatch = useDispatch();
  const [pages, setPages] = useState(0);
  const [percentages, setPercentages] = useState([]);
  // const [metaDatas, setMetaDatas] = useState([]);
  const [filteredPercentages, setFilteredPercentages] = useState([]);
  const properties = useSelector((state) => state.property.Properties);

  const page = useSelector((state) => state.page.page);

  let tempMetadataArray = [];

  // const metadatas = async (token_uri) => {
  //   tempMetadataArray.push(await getMetadata(token_uri));
  // console.log(tempMetadataArray.length);
  //   if (tempMetadataArray.length === properties.length)
  //     setMetaDatas(tempMetadataArray);
  // };

  useEffect(() => {
    if (page === "detail") setPages(1);
  }, [page]);

  useEffect(() => {
    // console.log(properties);
    const array = [];
    tempMetadataArray = [];
    for (let i = 0; i < properties.length; i++) {
      let totalCounts = 0;
      for (let j = 0; j < properties[i].investors.length; j++) {
        totalCounts += properties[i].investors[j].allocations;
      }

      const percentage = (
        ((properties[i].min_allocation * totalCounts) / properties[i].value) *
        100
      ).toFixed(2);

      array.push(Math.min(percentage, 100));

      // metadatas(properties[i].token_uri);
    }
    setPercentages(array);
  }, [properties]);

  // useEffect(() => {
  //   let temp = percentages;
  //   temp[3] += temp[6];
  //   temp[3] = Math.min(100, temp[3]);
  //   setFilteredPercentages(temp);
  // }, [percentages]);

  return (
    <div className="">
      {pages === 0 ? (
        <div className="h-[calc(100vh-60px)] ">
          <TransitionCompo
            Component={
              <div className="w-full p-[8px]">
                <div className="w-full rounded-[8px] bg-gradient-to-b from-[#6B349A] to-[#4C37C3] py-[50px] flex flex-col items-center space-y-[20px]">
                  <div className="text-white text-[28px]">Properties</div>
                  <div className="text-white w-[1200px] text-center">
                    Find your perfect home in Dubai. Explore our curated
                    selection of for-sale properties, blending style and
                    comfort.
                  </div>
                </div>
              </div>
            }
          />
          <TransitionCompo
            Component={
              <>
                <div className="w-full flex">
                  <SelectionGroup
                    className="px-[6px] py-[4px] rounded-[14px] flex gap-[10px] text-[#959595] w-max mx-auto mt-[-20px] z-[300] bg-white shadow-[2px_0px_8px_3px_#eeeeee]"
                    defaultItem={flag}
                    SelectedItemMask="shadow-md rounded-[10px]"
                  >
                    <SelectionItem
                      SelectedItem={
                        <div className="w-[130px] flex justify-between py-[2px] px-[20px] items-center gap-[10px] rounded-[10px]">
                          <div className="text-[#202020]">Available</div>
                          <img alt="" src={light}></img>
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-[130px] flex justify-between py-[2px] px-[20px] items-center gap-[10px] hover:bg-[#efefef] rounded-[10px]"
                          onClick={() => setFlag(0)}
                        >
                          <div>Available</div>
                        </div>
                      }
                    />

                    <SelectionItem
                      SelectedItem={
                        <div className="w-[130px] flex justify-between py-[2px] px-[40px] items-center gap-[10px]">
                          <div className="text-[#202020]">Funded</div>
                          <img alt="" src={light}></img>
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-[130px] flex justify-between py-[2px] px-[40px] items-center gap-[10px] hover:bg-[#efefef] rounded-[10px]"
                          onClick={() => setFlag(1)}
                        >
                          <div>Funded</div>
                        </div>
                      }
                    />
                    <SelectionItem
                      SelectedItem={
                        <div className="w-[130px] flex justify-between py-[2px] px-[40px] items-center gap-[10px]">
                          <div className="text-[#202020]">Exited</div>
                          <img alt="" src={light}></img>
                        </div>
                      }
                      UnselectedItem={
                        <div
                          className="w-[130px] flex justify-between py-[2px] px-[40px] items-center gap-[10px] hover:bg-[#efefef] rounded-[10px]"
                          onClick={() => setFlag(2)}
                        >
                          <div>Exited</div>
                        </div>
                      }
                    />
                  </SelectionGroup>
                </div>
              </>
            }
            timeout={200}
          />
          <div className="w-full flex mt-[20px]">
            <div className="mx-auto gap-[20px] flex max-w-[1030px] flex-wrap">
              {properties.map((property, i) => {
                if (
                  (percentages[i] < 100 && flag === 0) ||
                  (percentages[i] >= 100 && flag === 1)
                )
                  return (
                    <TransitionCompo
                      Component={
                        <YieldEstateProperty
                          flag={flag}
                          value={property.value / 1000000}
                          nft_price={property.min_allocation / 1000000}
                          percentage={percentages[i]}
                          images={property.metaData?.Images}
                          title={
                            property.metaData
                              ? property.metaData["Tower Name"]
                              : ""
                          }
                          available={
                            // i === 0
                            //   ? true
                            //   : i > 0 && percentages[i - 1] >= 100
                            //   ? true
                            //   : false
                            // i > 2 && i < 7 ? false : true
                            true
                          }
                          action={() => {
                            // if (flag !== 2) {
                            dispatch(
                              setCurrentProperty({
                                ...property,
                              })
                            );
                            setPages(1);
                            // }
                          }}
                        />
                      }
                      timeout={400 + i * 200}
                    />
                  );
              })}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {pages === 1 ? (
        <YieldEstateDetails
          action1={() => setPages(0)}
          action2={() => setPages(3)}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
