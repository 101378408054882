import { useEffect, useState } from "react";
import NUSD from "../../assets/images/NUSD.png";
import arrowdown from "../../assets/images/arrowdown (2).png";
import { useSelector } from "react-redux";
import { Toggle } from "../Global/Toggle";
import { NumberSpin } from "../Global/NumberSpin";
import InputSlider from "react-input-slider";
import deleteIcon from "../../assets/images/dashboard/deleteIcon.svg";

export const PropertyListingDetail = ({
  mode,
  token_id,
  setListNFT = () => {},
  editMetaData = () => {},
  setUnlistNFT = () => {},
}) => {
  const price_per_month = useSelector(
    (state) =>
      state.nft.allNFTs[token_id]?.longtermrental_info?.landlord
        ?.price_per_month
  );
  const price_per_day = useSelector(
    (state) =>
      state.nft.allNFTs[token_id]?.shorttermrental_info?.host?.price_per_day
  );

  const [price, setPrice] = useState(null);
  const [autoApprove, setAutoApprove] = useState(false);

  const [refundable_deposit, setRefundableDeposit] = useState(0);

  const isListedForLong = useSelector(
    (state) => state.nft.allNFTs[token_id].longtermrental_info?.islisted
  );

  const isListedForShort = useSelector(
    (state) => state.nft.allNFTs[token_id].shorttermrental_info?.islisted
  );

  const [minimumStay, setMinimumStay] = useState(1);
  const [percentage, setPercentage] = useState(50);
  const [deadline, setDeadline] = useState(9);
  const [error, setError] = useState(false);
  const [cancellation, setCancellation] = useState([
    {
      deadline: 15,
      percentage: 90,
    },
    {
      deadline: 3,
      percentage: 10,
    },
  ]);

  useEffect(() => {
    if (mode === "short") setPrice(price_per_day);
    if (mode === "long") setPrice(price_per_month);
  }, [mode, price_per_day, price_per_month]);

  const handleAddItem = () => {
    const updatedCancellation = cancellation.slice();
    for (let i = 0; i < updatedCancellation.length; i++) {
      if (
        Number(updatedCancellation[i].deadline) === Number(deadline) ||
        Number(updatedCancellation[i].percentage) === Number(percentage)
      ) {
        setError(true);
        return;
      }
    }
    updatedCancellation.push({
      deadline: Number(deadline),
      percentage: Number(percentage),
    });
    const sortByDeadline = updatedCancellation.slice();
    const sortByPercentage = updatedCancellation.slice();

    sortByPercentage.sort((a, b) => b.percentage - a.percentage);
    sortByDeadline.sort((a, b) => b.deadline - a.deadline);

    if (JSON.stringify(sortByDeadline) === JSON.stringify(sortByPercentage)) {
      setCancellation(sortByDeadline);
      setError(false);
    } else {
      setError(true);
    }
  };

  // useEffect(() => {}, [cancellation]);

  return (
    <div className="p-[24px] rounded-[12px] shadow-md w-full bg-white h-max space-y-[24px]">
      <div className="font-semibold text-[18px]">Property Listing Details</div>
      <div className="p-[16px] rounded-[8px] shadow-md w-full space-y-[10px]">
        <div className="text-[18px]">Currency</div>
        <div className="px-[24px] py-[14px] rounded-[100px] shadow-md flex items-center justify-between">
          <div className="flex items-center gap-[8px]">
            <img alt="" src={NUSD}></img>
            <div>NUSD</div>
          </div>
          <img alt="" src={arrowdown}></img>
        </div>

        <div className="text-[18px]">
          {mode === "short" ? "Price per day" : "Price per month"}
        </div>
        <div className="globalInputForm px-[20px] py-[10px] rounded-[16px]">
          <input
            className="w-full"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            placeholder="12,000"
          />
        </div>

        <div className="flex items-start justify-between w-full">
          <div className="space-y-[10px]">
            <div className="text-[18px]">Auto approve</div>
            <Toggle
              status={autoApprove}
              onChange={() => setAutoApprove(!autoApprove)}
            />
          </div>
          <div className="space-y-[2px]">
            <div className="text-[18px]">Minimum stay</div>
            <NumberSpin
              min={0}
              max={1000}
              value={minimumStay}
              onChange={setMinimumStay}
            />
          </div>
        </div>
        <div className="text-[18px]">Cancellation policy</div>

        <div className="flex items-center">
          <div className="min-w-[130px]">Percentage</div>
          <div className="flex items-center w-full gap-[20px]">
            <div>{percentage}</div>
            <InputSlider
              axis="x"
              x={percentage}
              xmin={0}
              xmax={100}
              onChange={({ x }) => setPercentage(x)}
              styles={{
                track: {
                  height: "5px",
                  width: "100%",
                  background: "#e9ecef",
                },
                active: {
                  background: "#5b1dee",
                },
              }}
            />
          </div>
        </div>
        <div className="flex items-center">
          <div className="min-w-[130px]">Deadline</div>
          <div className="globalInputForm rounded-[4px] px-[8px] py-[4px] gap-[10px] w-full flex items-center">
            <input
              value={deadline}
              onChange={(e) => setDeadline(e.target.value)}
              className="w-full px-[4px] rounded-[4px]"
            />
            <div className="text-[10px]">days before check-in</div>
          </div>
        </div>
        <div className="flex items-start">
          <div className="min-w-[130px] space-y-[10px]">
            <div
              className="w-[110px] h-max py-[4px] rounded-[4px] text-center text-white bg-[#202020] cursor-pointer"
              onClick={() => handleAddItem()}
            >
              Add Item
            </div>
            <div
              className="text-[#ff0000] text-[14px] font-normal"
              hidden={!error}
            >
              Invalid input
            </div>
          </div>
          <div className="w-full h-[120px] overflow-auto scrollbarwidth bg-[#f0f0f0] p-[4px] space-y-[4px] rounded-[4px]">
            {cancellation.map((item, i) => {
              return (
                <div className="flex items-center justify-between text-[12px] p-[4px] bg-white rounded-[4px]">
                  <div>
                    {item.percentage} % refundable {item.deadline} days before
                  </div>
                  <img
                    alt=""
                    src={deleteIcon}
                    className="w-[16px] cursor-pointer"
                    onClick={() => {
                      // Create a copy of the cancellation array
                      const updatedCancellation = cancellation.slice();
                      updatedCancellation.splice(i, 1);
                      setCancellation(updatedCancellation);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
        {/* <div className="flex items-center justify-between">
          <div className="font-semibold text-[18px]">Allow Auction</div>
          <Toggle
            onChange={() => {
              setStatus(!status);
            }}
            status={status}
          />
        </div> */}
        {mode === "long" && (
          <>
            <div className="font-semibold text-[18px]">Refundable Deposit</div>
            <div className="globalInputForm rounded-[8px] px-[20px] py-[10px] rounded-[16px]">
              <input
                className="w-full"
                value={refundable_deposit}
                onChange={(e) => setRefundableDeposit(e.target.value)}
                placeholder="125,000,000"
              />
            </div>
            <div className="flex items-center justify-between">
              <div className="font-semibold text-[18px]">TOTAL AMOUNT</div>
              <div className="flex items-center gap-[4px]">
                <img alt="" src={NUSD}></img>
                <div className="text-[#4C37C3]">{price_per_month}</div>
                <div>NUSD / month </div>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div></div>
              <div className="flex items-center gap-[4px]">
                <img alt="" src={NUSD}></img>
                <div className="text-[#4C37C3]">{refundable_deposit}</div>
                <div>NUSD in refundable deposit</div>
              </div>
            </div>
          </>
        )}

        {/* <div className="flex justify-between">
          <div className="px-[12px] py-[4px] rounded-[100px] text-[#5B1DEE] shadow-md w-max">
            Manage Guest Info...
          </div>
          <div className="px-[12px] py-[4px] rounded-[100px] text-[#5B1DEE] shadow-md w-max">
            Longer Stay Discount
          </div>
          <div className="px-[12px] py-[4px] rounded-[100px] text-[#5B1DEE] shadow-md w-max">
            Manage Availability
          </div>
        </div> */}
        {/* <div className="px-[12px] py-[4px] rounded-[100px] text-[#5B1DEE] shadow-md w-max">
          Manage Availability
        </div> */}

        <div
          className="px-[20px] py-[12px] rounded-[16px] text-center text-white bg-[#5B1DEE] cursor-pointer"
          onClick={() => {
            if (Number(price))
              setListNFT(
                token_id,
                "unibi",
                Number(price),
                autoApprove,
                [],
                minimumStay,
                cancellation
              );
          }}
        >
          {(mode === "short" && isListedForShort) ||
          (mode === "long" && isListedForLong)
            ? "Update Listing"
            : "List For Rental"}
        </div>

        <div className="flex gap-[16px]">
          <div
            className="px-[20px] py-[12px] rounded-[16px] text-center text-white bg-[#202020] w-full cursor-pointer"
            onClick={editMetaData}
          >
            Edit Metadata
          </div>
          <div
            className={`px-[20px] py-[12px] rounded-[16px] text-center text-white w-full 
            ${
              (mode === "short" && isListedForShort) ||
              (mode === "long" && isListedForLong)
                ? "bg-[#202020] cursor-pointer"
                : "bg-[#E3E3E3] cursor-not-allowed"
            }`}
            onClick={() => {
              if (
                (mode === "short" && isListedForShort) ||
                (mode === "long" && isListedForLong)
              )
                setUnlistNFT(token_id);
            }}
          >
            Unlist Property
          </div>
        </div>
      </div>
    </div>
  );
};
