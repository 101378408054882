import logo from "../../assets/images/Logo Coded-Estate.svg";
import warning from "../../assets/images/ConnectWallet/Warning.svg";
import plus from "../../assets/images/ConnectWallet/Close.svg";
import xdefi from "../../assets/images/ConnectWallet/image 2226.svg";
import metamask from "../../assets/images/ConnectWallet/image 2227.svg";
import keplr from "../../assets/images/ConnectWallet/image 2228.svg";
import phantom from "../../assets/images/ConnectWallet/image 2229.svg";
import coinbase from "../../assets/images/ConnectWallet/image 2230.svg";
import leap from "../../assets/images/ConnectWallet/leap.svg";
// import walletconnect from "../../assets/images/ConnectWallet/image 2231.svg";

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { setConnecting, setHideModal } from "../../Actions/AuthSlice";
import { useDispatch } from "react-redux";
import { connected } from "../../Actions/AuthSlice";
import axios from "axios";
import Lottie from "lottie-react";
import codedEstateLottie from "../../assets/animations/codedEstateLottie.json";
import { setHeaderMode } from "../../Actions/HeaderSlice";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { api } from "../functional/Api";

export const ConnectWallet = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.auth.connectModal);
  const [wallets, setWallets] = useState({
    isKeplrInstalled: null,
    isLeapInstalled: null,
  });
  const connecting = useSelector((state) => state.auth.connecting);

  const [selectedWallet, setWallet] = useState(null);

  const connectToBackend = async (account) => {
    dispatch(setConnecting(true));
    const res = await api("user/login", {
      account: account,
    });

    return res;
  };

  // const chainId = "cataclysm-1";
  const chainId = "nibiru-testnet-1";

  const connect = async (wallet) => {
    if (selectedWallet === "xdefi") {
      console.log(window.xfi);
      if (!window.xfi) {
        alert("Please install xdefi extension");
      } else {
      }
    }

    if (wallet === "leap") {
      if (!window.leap) {
        alert("Please install leap extension");
      } else {
        try {
          await window.leap.enable(chainId);

          const offlineSigner = window.leap.getOfflineSigner(chainId);

          const accounts = await offlineSigner.getAccounts();

          // console.log(accounts[0].address);

          dispatch(setHideModal());
          if (await connectToBackend(accounts[0].address)) {
            dispatch(
              connected({
                account: accounts[0].address,
                wallet: "leap",
              })
            );
            dispatch(setHeaderMode({ mode: 3, submode: null }));
            dispatch(setConnecting(false));
          }
        } catch (error) {
          console.log(error);
        }
      }
    }

    if (wallet === "keplr") {
      if (!window.keplr) {
        alert("Please install keplr extension");
      } else {
        try {
          await window.keplr.enable(chainId);

          const offlineSigner = window.keplr.getOfflineSigner(chainId);

          const accounts = await offlineSigner.getAccounts();

          // console.log(accounts[0].address);

          dispatch(setHideModal());

          if (await connectToBackend(accounts[0].address)) {
            dispatch(
              connected({
                account: accounts[0].address,
                wallet: "keplr",
              })
            );
            dispatch(setHeaderMode({ mode: 3, submode: null }));
            dispatch(setConnecting(false));
          }
        } catch (error) {
          console.log(error.toString());
          if (
            error.toString().includes("There is no chain info for cataclysm-1")
          ) {
            toast.error("Please add Nibiru chain to your Keplr.", {
              autoClose: 1000,
            });
          }
        }
      }
    }
  };
  const checkInstalledWallets = () => {
    setWallets({
      isLeapInstalled: window.leap !== undefined,
      isKeplrInstalled: window.keplr !== undefined,
    });
  };

  useEffect(() => {
    checkInstalledWallets();
  }, []);

  return (
    <>
      <div className="w-full h-[100vh] flex" hidden={!connecting}>
        <div className="m-auto">
          <Lottie
            animationData={codedEstateLottie}
            className="w-[300px] mx-auto"
          />
        </div>
      </div>
      <Modal
        show={show}
        centered
        onHide={() => dispatch(setHideModal())}
        dialogClassName="modal-dialog-full-width"
        contentClassName="modal-content-full-width"
      >
        <Modal.Body>
          <div className="p-[18px] rounded-[16px] w-full fullheight flex flex-col">
            <div className="flex items-center justify-between">
              <img alt="" src={logo}></img>
              <div className="flex items-center gap-[12px]">
                {/* <div className="sm:flex hidden cursor-pointer px-[18px] py-[10px] text-5D00CF shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-full font-semibold hover:shadow-[-1px_6px_10px_0_rgba(120,60,20,0.5)] hover:-translate-y-[3px] items-center gap-[8px]">
                  <img alt=""  src={warning} ></img>
                  <div>I don't have wallet</div>
                </div> */}

                <img
                  alt=""
                  src={plus}
                  className="cursor-pointer"
                  onClick={() => dispatch(setHideModal())}
                ></img>
              </div>
            </div>

            <div className="m-auto h-max sm:max-w-[370px] max-w-[300px]">
              <div className="text-[32px] font-semibold text-center">
                Connect to Coded Estate
              </div>
              <div className="text-center text-[#595959] font-semibold leading-[100%] mt-[20px] w-full">
                Securely access the Web3 environment by choosing your preferred
                wallet from the available options.
              </div>

              <div className="grid grid-cols-2 gap-[12px] mt-[50px]">
                {/* <div
                  className="px-[8px] py-[12px] rounded-[8px] shadow-md flex gap-[12px] items-center cursor-pointer transition ease-in-out hover:bg-gray-300 duration-100"
                  // onClick={() => {
                  //   connect("xdefi");
                  // }}
                >
                  <img alt=""  src={xdefi} ></img>
                  <div>
                    <div className="font-semibold">Xdefi</div>
                    <div className="font-semibold text-[#4C37C3]">install</div>
                  </div>
                </div>
                <div className="px-[8px] py-[12px] rounded-[8px] shadow-md flex gap-[12px] items-center cursor-pointer transition ease-in-out hover:bg-gray-300 duration-100">
                  <img alt=""  src={metamask} ></img>
                  <div>
                    <div className="font-semibold">Metamask</div>
                    <div className="font-semibold text-[#4C37C3]">install</div>
                  </div>
                </div>
                <div className="px-[8px] py-[12px] rounded-[8px] shadow-md flex gap-[12px] items-center cursor-pointer transition ease-in-out hover:bg-gray-300 duration-100">
                  <img alt=""  src={phantom} ></img>
                  <div>
                    <div className="font-semibold">Phantom</div>
                    <div className="font-semibold text-[#4C37C3]">install</div>
                  </div>
                </div>
                <div className="px-[8px] py-[12px] rounded-[8px] shadow-md flex gap-[12px] items-center cursor-pointer transition ease-in-out hover:bg-gray-300 duration-100">
                  <img alt=""  src={coinbase} ></img>
                  <div>
                    <div className="font-semibold">Coinbase</div>
                    <div className="font-semibold text-[#4C37C3]">install</div>
                  </div>
                </div> */}
                <div
                  className={
                    // selectedWallet === "keplr"
                    // ? "px-[8px] py-[12px] rounded-[8px] shadow-md flex gap-[12px] items-center cursor-pointer transition ease-in-out bg-gray-300 duration-100"
                    // :
                    "px-[8px] py-[12px] rounded-[8px] shadow-md flex gap-[12px] items-center cursor-pointer transition ease-in-out hover:bg-gray-300 duration-100"
                  }
                  onClick={() => {
                    // setWallet("keplr");
                    connect("keplr");
                    // connect();
                  }}
                >
                  <img alt="" src={keplr} className="w-[40px]"></img>
                  <div>
                    <div className="font-semibold">Keplr</div>

                    {wallets.isKeplrInstalled ? (
                      <></>
                    ) : (
                      <div className="font-semibold text-[#4C37C3]">
                        install
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={
                    // selectedWallet === "leap"
                    // ? "px-[8px] py-[12px] rounded-[8px] shadow-md flex gap-[12px] items-center cursor-pointer transition ease-in-out bg-gray-300 duration-100"
                    // :
                    "px-[8px] py-[12px] rounded-[8px] shadow-md flex gap-[12px] items-center cursor-pointer transition ease-in-out hover:bg-gray-300 duration-100"
                  }
                  onClick={() => {
                    // setWallet("leap");
                    connect("leap");
                    // connect();
                  }}
                >
                  <img alt="" src={leap} className="w-[40px]"></img>
                  <div>
                    <div className="font-semibold">Leap</div>
                    {wallets.isLeapInstalled ? (
                      <></>
                    ) : (
                      <div className="font-semibold text-[#4C37C3]">
                        install
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* <div
                className="cursor-pointer px-[20px] py-[12px] mt-[20px] mx-auto bg-[#5B1DEE] text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] font-semibold hover:shadow-[-1px_6px_10px_0_rgba(120,60,20,0.5)] hover:-translate-y-[3px] max-w-max"
                // onClick={() => connect()}
              >
                Connect Wallet
              </div> */}
            </div>
            {/* <div
              className="sm:hidden block cursor-pointer py-[12px] mt-[20px] mx-auto bg-[#5B1DEE] text-white rounded-[16px] font-semibold 
                w-full text-center"
            >
              Connect Wallet
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
