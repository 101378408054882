import { ImageView } from "../../Global/ImageView";
import NUSDIcon from "../../../assets/images/NUSD.svg";

export const InboxNFT = ({ currentToken }) => {
  return (
    <>
      <ImageView counts={1} imagesArray={currentToken.metaData.Images} />

      <div className="p-[8px] rounded-[8px] shadow-md space-y-[8px] text-[#606060]">
        <div className="flex justify-between">
          <div>Name</div>
          <div className="text-[#B6B6B6]">{currentToken?.metaData?.Name}</div>
        </div>
        <div className="flex justify-between items-start gap-[20px]">
          <div>Address</div>
          <div className="text-[#B6B6B6] w-full truncate">
            {currentToken?.metaData?.Address}
          </div>
        </div>

        <div className="flex justify-between items-center">
          <div>Daily Cost</div>
          <div className="flex gap-[8px] items-center">
            <img alt="" src={NUSDIcon} />
            <div className="text-[#5b1dee] px-[6px] py-[2px] rounded-[4px] shadow-md">
              {currentToken?.shorttermrental_info?.price_per_day}
            </div>

            <div>NUSD</div>
          </div>
        </div>
      </div>
    </>
  );
};
