import { useSelector } from "react-redux";
import { CarouselCompo } from "../Global/CarouselCompo";
import { Inbox } from "../PartialPage/Inbox/Inbox";
import NUSD from "../../assets/images/NUSD.png";
import { ImageView } from "../Global/ImageView";
import NUSDIcon from "../../assets/images/NUSD.svg";
import ProfileIcon from "../../assets/images/Profile.svg";
import UserIcon from "../../assets/images/User,Profile.svg";
import {
  executeContract,
  getBlockTimeStamp,
} from "../functional/InteractToContract";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { checkIfApproved } from "../functional/InteractToContract";
import { setNFT } from "../../Actions/NFTSlice";

export const LandlordInbox = () => {
  const currentNFT = useSelector((state) => state.nft.currentNFT);
  const currentChatReceiver = useSelector((state) => state.chat.receiver);
  const account = useSelector((state) => state.auth.account);
  const walletEx = useSelector((state) => state.auth.wallet);
  const [reserved, setReserved] = useState(false);
  const [approved, setApproved] = useState(false);
  const dispatch = useDispatch();
  const rent = useSelector(
    (state) =>
      state.nft?.currentNFT?.longtermrental_info?.tenant?.renting_period
  );
  useEffect(() => {
    if (
      currentNFT?.longtermrental_info?.isreserved &&
      currentChatReceiver === currentNFT?.longtermrental_info?.tenant_address
    )
      setReserved(true);
    else setReserved(false);
  }, [currentNFT, currentChatReceiver]);

  const rejectReservation = async () => {
    const message = {
      reject_reservation_for_longterm: {
        token_id: currentNFT.token_id,
      },
    };
    await executeContract(
      process.env.REACT_APP_RENTAL_SMART_CONTRACT,
      dispatch,
      currentNFT.token_id,
      message,
      account,
      walletEx
    );
  };

  const checkApproved = async () => {
    if (currentNFT) {
      const res = await checkIfApproved(
        currentNFT?.token_id,
        currentNFT?.longtermrental_info?.tenant_address
      );
      setApproved(res);
    }
  };

  const revokeApprove = async () => {
    const message = {
      revoke: {
        token_id: currentNFT.token_id,
        spender: currentNFT?.longtermrental_info?.tenant_address,
      },
    };
    await executeContract(
      process.env.REACT_APP_RENTAL_SMART_CONTRACT,
      dispatch,
      currentNFT.token_id,
      message,
      account,
      walletEx
    );
  };

  const approveReservation = async () => {
    // const timestampInNs = new Date(rent[1]).getTime() * 1000000;
    // const utcTimeStamp = await (
    //   await fetch("https://worldtimeapi.org/api/timezone/Etc/UTC")
    // ).json();
    const blockTimeStamp = await getBlockTimeStamp();

    const timestampInNs =
      new Date(blockTimeStamp).getTime() * 1000000 + 60 * 1000 * 1000000;
    console.log(timestampInNs);

    const message = {
      approve: {
        spender: currentNFT.longtermrental_info.tenant_address,
        token_id: currentNFT.token_id,
        expires: {
          // at_time: timestampInNs.toString(),
          never: {},
        },
      },
    };
    await executeContract(
      process.env.REACT_APP_RENTAL_SMART_CONTRACT,
      dispatch,
      currentNFT.token_id,
      message,
      account,
      walletEx
    );
    checkApproved();
  };

  useEffect(() => {
    checkApproved();
  }, [currentNFT]);

  useEffect(() => {
    dispatch(setNFT(null));
  }, []);

  return (
    <>
      <div className="bg-[#F6F6F6] rounded-[8px] w-full h-full flex gap-[5px]">
        <div className="w-full rounded-[8px] bg-white">
          <Inbox mode={"landlord"} />
        </div>
        <div className="w-[400px] rounded-[8px] bg-white p-[16px] space-y-[16px] overflow-auto sidebarheight hiddenscrollbar">
          {currentNFT ? (
            <>
              <div className="text-[20px]">Rental Details</div>

              <ImageView counts={1} />

              <div className="p-[8px] rounded-[8px] shadow-md space-y-[8px] text-[#606060]">
                <div className="flex justify-between">
                  <div>Tower Name</div>
                  <div className="text-[#B6B6B6]">
                    {currentNFT?.metaData["Building Name"].buildingNameEn}
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div>Address</div>
                  <div className="text-[#B6B6B6] text-[14px]">
                    {currentNFT?.metaData?.Area.areaEn}
                  </div>
                </div>
                {reserved ? (
                  <div className="flex justify-between items-center">
                    <div>Rental Period</div>
                    <div className="text-[#B6B6B6] text-[14px]">
                      {rent?.length &&
                        rent[0] !== "" &&
                        rent[1] !== "" &&
                        new Intl.DateTimeFormat("en-US", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        }).format(new Date(rent[0])) +
                          "~" +
                          new Intl.DateTimeFormat("en-US", {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          }).format(new Date(rent[1]))}
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="flex justify-between items-center">
                  <div>Monthly Cost</div>
                  <div className="flex gap-[8px] items-center">
                    <img alt="" src={NUSDIcon} />
                    <div className="text-[#B6B6B6]">
                      {
                        currentNFT?.longtermrental_info?.landlord
                          ?.price_per_month
                      }
                    </div>

                    <div>NUSD</div>
                  </div>
                </div>

                {reserved && (
                  <div className="flex justify-between items-center">
                    <div className="">Refundable deposit</div>
                    <div className="flex gap-[8px] items-center">
                      <img alt="" src={NUSDIcon} />
                      <div className="text-[#B6B6B6]">
                        {
                          currentNFT?.longtermrental_info?.landlord
                            ?.refundable_deposit
                        }
                      </div>

                      <div>NUSD</div>
                    </div>
                  </div>
                )}
                {reserved && (
                  <div className="flex justify-between items-center">
                    <div className="">Total price</div>
                    <div className="flex gap-[8px] items-center">
                      <img alt="" src={NUSDIcon} />
                      <div className="text-[#5b1dee] px-[6px] py-[2px] rounded-[4px] shadow-md">
                        {currentNFT.longtermrental_info?.tenant?.deposit_amount}
                      </div>

                      <div>NUSD</div>
                    </div>
                  </div>
                )}
              </div>

              <div className="text-[#B6B6B6]">Rental</div>

              {!reserved ? <div>Not reserved yet</div> : <></>}
              <div className="p-[8px] rounded-[8px] shadow-md space-y-[8px]">
                <div className="flex items-center gap-[10px]">
                  <img alt="" src={ProfileIcon} />
                  <div className="space-y-[4px] flex flex-col items-start">
                    <div className="text-[#B6B6B6]">
                      {currentChatReceiver?.substring(0, 5) +
                        "..." +
                        currentChatReceiver?.substring(
                          currentChatReceiver?.length - 15
                        )}
                    </div>
                    <div className="bg-[#5b1dee] px-[12px] py-[4px] text-white cursor-pointer shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)] hover:-translate-y-[3px] flex items-center gap-[4px]">
                      <img alt="" src={UserIcon} />
                      <div>View Profile</div>
                    </div>
                  </div>
                </div>
              </div>
              {reserved ? (
                <div>
                  You have an offer from
                  {" " +
                    currentNFT.longtermrental_info?.tenant_address?.substring(
                      0,
                      5
                    ) +
                    "..." +
                    currentNFT.longtermrental_info?.tenant_address?.substring(
                      currentNFT.longtermrental_info?.tenant_address?.length - 4
                    )}
                </div>
              ) : (
                <></>
              )}

              {reserved ? (
                <div className="flex w-full gap-[20px]">
                  <>
                    <div
                      className="bg-[#5b1dee] py-[10px] text-center w-full text-white cursor-pointer shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] hover:shadow-[-1px_6px_10px_0_rgba(80,80,80,0.5)] hover:-translate-y-[3px]"
                      onClick={() => {
                        approveReservation();
                        // revokeApprove();
                      }}
                    >
                      Approve
                    </div>
                    <div
                      className="bg-[#202020] py-[10px] text-center w-full text-white cursor-pointer shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] hover:shadow-[-1px_6px_10px_0_rgba(80,80,80,0.5)] hover:-translate-y-[3px]"
                      onClick={() => {
                        rejectReservation();
                      }}
                    >
                      Reject
                    </div>
                  </>
                </div>
              ) : (
                <></>
              )}

              {/* <div className="shadow-md space-y-[8px] p-[12px]">
                <div className="flex justify-between">
                  <div>Tenant</div>
                  <div className="text-[#B6B6B6]">
                    {currentNFT.longtermrentalInfo?.tenant_address?.substring(
                      0,
                      5
                    ) +
                      "..." +
                      currentNFT.longtermrentalInfo?.tenant_address?.substring(
                        currentNFT.longtermrentalInfo?.tenant_address?.length -
                          4
                      )}
                  </div>
                </div>
                <div className="w-full h-[1px] bg-[#B6B6B6]"></div>
                <div className="flex justify-between items-center">
                  <div>Deposit Amount</div>
                  <div className="flex items-center gap-[4px]">
                    <img alt=""  src={NUSD} />
                    <div className="text-[#5B1DEE] p-[6px] rounded-[4px]">
                      {currentNFT.longtermrentalInfo?.tenant?.deposit_amount}
                    </div>
                    <div className="text-[#B6B6B6]">NUSD</div>
                  </div>
                </div>
              </div> */}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
