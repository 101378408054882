import { Outlet } from "react-router-dom";
import { SideBar } from "../components/PartialPage/SideBar";
import { Dashboard } from "../pages/Dashboard";
import { Fade } from "react-awesome-reveal";

export const DashboardLayout = () => {
  return (
    <div className="flex w-full p-[5px] gap-[5px]">
      <Fade delay={1e2} damping={1e-1} direction="left">
        <SideBar />
      </Fade>
      {/* <Zoom className="w-full"> */}

      <Dashboard />
      {/* </Zoom> */}
    </div>
  );
};
