import icon1 from "../../assets/images/YieldEstate/Group 1000004845.svg";
import money1 from "../../assets/images/YieldEstate/Hand, Arm, Coins.svg";
import money2 from "../../assets/images/YieldEstate/Money.svg";
import money3 from "../../assets/images/YieldEstate/Business, Chart.svg";
import light from "../../assets/images/light.svg";
import search from "../../assets/images/tabler-icon-search.svg";
import slash from "../../assets/images/slash.svg";
import windowIcon from "../../assets/images/window.svg";
import list from "../../assets/images/list.svg";

import Buy from "../../assets/images/YieldEstate/Buy.svg";
import NFTIcon from "../../assets/images/YieldEstate/Group 1000004856.svg";

import { SelectionGroup, SelectionItem } from "../Global/Selection";
import { Carousel } from "react-bootstrap";
import { useEffect, useState } from "react";
import { PieChartComponent } from "../Global/PieChart";
import { useSelector } from "react-redux";

import TESTATE from "../../assets/images/YieldEstate/NUSD.png";
import { useDispatch } from "react-redux";
import { setCurrentProperty } from "../../Actions/PropertySlice";
import { setHeaderMode } from "../../Actions/HeaderSlice";
import { setPage } from "../../Actions/PageSlice";
import { NumericFormat } from "react-number-format";
import { Testate } from "../Global/TestateLogo";

export const Portfolio = () => {
  const properties = useSelector((state) => state.property.Properties);
  const account = useSelector((state) => state.auth.account);
  const dispatch = useDispatch();

  const [propertyArray, setPropertyArray] = useState([]);
  const [filteredArray, setFilteredArray] = useState([]);
  const [flag, setFlag] = useState(0);

  const [portfolioValue, setPortfolioValue] = useState(0);

  useEffect(() => {
    const propertiesWithValue = [];
    let totalValue = 0;
    for (let j = 0; j < properties.length; j++) {
      const propertyWithValue = {
        myPercentage: 0,
        myNFTs: 0,
        totalAmount: 0,
        percentage: 0,
        property: null,
      };
      let totalCounts = 0;
      for (let i = 0; i < properties[j].investors.length; i++) {
        totalCounts += properties[j].investors[i].allocations;
        if (properties[j].investors[i].address === account) {
          propertyWithValue.myPercentage =
            ((properties[j].investors[i].allocations *
              properties[j].min_allocation) /
              properties[j].value) *
            100;
          propertyWithValue.myNFTs = properties[j].investors[i].allocations;
        }
      }

      const totalDeposit = properties[j].min_allocation * totalCounts;
      propertyWithValue.totalAmount = totalDeposit / 1000000;
      propertyWithValue.percentage = Math.min(
        ((totalDeposit / properties[j].value) * 100).toFixed(2),
        100
      );
      propertyWithValue.property = properties[j];
      // if (propertyWithValue.myPercentage > 0) {
      propertiesWithValue.push(propertyWithValue);

      totalValue +=
        (propertyWithValue.myNFTs * propertyWithValue.property.min_allocation) /
        1000000;
      // }
    }
    setPortfolioValue(totalValue);
    setPropertyArray(propertiesWithValue);
  }, [properties]);

  // useEffect(() => {
  //   const sameProperties = ["3", "6"];
  //   let propertyWithValue = {
  //     myPercentage: 0,
  //     myNFTs: 0,
  //     totalAmount: 0,
  //     percentage: 0,
  //     property: null,
  //   };
  //   for (let i = 0; i < properties.length; i++) {
  //     if (properties[i].token_id === sameProperties[0])
  //       propertyWithValue.property = properties[i];
  //   }
  //   for (let i = 0; i < sameProperties.length; i++) {
  //     for (let j = 0; j < propertyArray.length; j++) {
  //       if (propertyArray[j].property.token_id === sameProperties[i]) {
  //         propertyWithValue.myPercentage += propertyArray[j].myPercentage;
  //         propertyWithValue.myNFTs += propertyArray[j].myNFTs;
  //         propertyWithValue.totalAmount += propertyArray[j].totalAmount;
  //         propertyWithValue.percentage += propertyArray[j].percentage;
  //       }
  //     }
  //   }

  //   const temparray = [];
  //   for (let i = 0; i < propertyArray.length; i++) {
  //     if (propertyArray[i].property.token_id === sameProperties[0]) {
  //       temparray.push(propertyWithValue);
  //     } else {
  //       temparray.push(propertyArray[i]);
  //     }
  //   }
  //   console.log(temparray);
  //   setFilteredArray(temparray);
  // }, [propertyArray]);

  return (
    <div className="p-[16px]">
      <div className="text-[24px]">Portfolio</div>
      <div className="shadow-md rounded-[16px] p-[16px] min-h-[580px] flex flex-col">
        <div className="text-[#959595]">Portfolio value</div>
        {/* <div className="flex gap-[20px]">
          <div className="text-[24px] text-[#959595]">TESTATE </div>
          <div className="text-[24px]">0 </div>
        </div> */}

        <div className="flex items-center gap-[10px]">
          {/* <img alt=""  src={TESTATE} /> */}
          <Testate size={30} />

          <div className="text-[#5B1DEE] px-[12px] py-[4px] text-[24px] font-semibold shadow-md rounded-[8px]">
            <NumericFormat
              value={portfolioValue}
              thousandSeparator
              displayType="text"
            />
          </div>
          <div className="text-[20px]">TESTATE</div>
        </div>

        <div className="flex items-center justify-between my-[30px]">
          <SelectionGroup
            className="px-[6px] py-[4px] rounded-[14px] flex gap-[10px] text-[#959595] w-max z-[300] bg-white shadow-[2px_0px_8px_3px_#eeeeee]"
            defaultItem={flag}
            SelectedItemMask="shadow-md rounded-[10px]"
          >
            <SelectionItem
              SelectedItem={
                <div className="w-[100px] flex justify-between py-[2px] px-[8px] items-center gap-[10px] rounded-[10px]">
                  <div className="text-[#202020]">Available</div>
                  <img alt="" src={light}></img>
                </div>
              }
              UnselectedItem={
                <div
                  className="w-[100px] flex justify-between py-[2px] px-[8px] items-center gap-[10px] hover:bg-[#efefef] rounded-[10px]"
                  onClick={() => setFlag(0)}
                >
                  <div>Available</div>
                </div>
              }
            />

            <SelectionItem
              SelectedItem={
                <div className="w-[100px] flex justify-between py-[2px] px-[20px] items-center gap-[10px]">
                  <div className="text-[#202020]">Funded</div>
                  <img alt="" src={light}></img>
                </div>
              }
              UnselectedItem={
                <div
                  className="w-[100px] flex justify-between py-[2px] px-[20px] items-center gap-[10px] hover:bg-[#efefef] rounded-[10px]"
                  onClick={() => setFlag(1)}
                >
                  <div>Funded</div>
                </div>
              }
            />
            <SelectionItem
              SelectedItem={
                <div className="w-[100px] flex justify-between py-[2px] px-[20px] items-center gap-[10px]">
                  <div className="text-[#202020]">Exited</div>
                  <img alt="" src={light}></img>
                </div>
              }
              UnselectedItem={
                <div
                  className="w-[100px] flex justify-between py-[2px] px-[20px] items-center gap-[10px] hover:bg-[#efefef] rounded-[10px]"
                  onClick={() => setFlag(2)}
                >
                  <div>Exited</div>
                </div>
              }
            />
          </SelectionGroup>

          <div className="flex items-center gap-[20px]">
            <div className="flex items-center shadow-md rounded-[4px] px-[12px] py-[6px] gap-[12px] w-[400px]">
              <img alt="" src={search}></img>
              <input placeholder="Search" className="w-full" />
              <img alt="" src={slash}></img>
            </div>

            <div className="items-center flex">
              <div className="p-[8px] bg-[#F2F2F2]">
                <img alt="" src={windowIcon}></img>
              </div>
              <div className="p-[8px]">
                <img alt="" src={list}></img>
              </div>
            </div>
          </div>
        </div>

        {propertyArray.length ? (
          <div className="flex gap-[20px]">
            {propertyArray.map((item, i) => {
              if (item.myPercentage > 0)
                if (
                  (item.percentage >= 100 && flag === 1) ||
                  (item.percentage < 100 && flag === 0)
                )
                  return (
                    <div className="w-[360px] p-[10px] bg-white rounded-[8px] space-y-[10px] shadow-md h-max">
                      <div className="relative">
                        <Carousel controls={false} interval={null}>
                          {propertyArray[i].property.metaData?.Images.map(
                            (image) => (
                              <Carousel.Item>
                                <img
                                  alt=""
                                  src={image}
                                  className={`rounded-[10px] w-full h-[180px] hover:scale-125 transition duration-500`}
                                ></img>
                              </Carousel.Item>
                            )
                          )}
                        </Carousel>
                      </div>

                      <div className="flex items-center gap-3">
                        <div className="space-y-[10px]">
                          <div className="text-[18px]">
                            {propertyArray[i].property.metaData
                              ? propertyArray[i].property.metaData["Tower Name"]
                              : ""}
                          </div>
                          <div className="flex items-center text-[12px] gap-[10px]">
                            <div>
                              <div className="flex items-center gap-[4px]">
                                <div className="text-[#959595]">
                                  Number of Allocations
                                </div>
                              </div>
                              <div className="flex items-center gap-[4px]">
                                <img alt="" src={NFTIcon} />
                                <div className="">{item.myNFTs}</div>
                                <div className="text-[#959595] w-[80px] overflow-hidden truncate">
                                  {item.myPercentage}% ownership
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="flex items-center gap-[4px]">
                                <div className="text-[#959595]">
                                  Investment Amount
                                </div>
                              </div>
                              <div className="flex items-center gap-[4px]">
                                <img alt="" src={Buy} />
                                <div className="flex gap-[4px]">
                                  <NumericFormat
                                    value={
                                      (item.myNFTs *
                                        item.property.min_allocation) /
                                      1000000
                                    }
                                    thousandSeparator
                                    displayType="text"
                                  />
                                  TESTATE
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <PieChartComponent
                          size={70}
                          percentage={item.percentage}
                          // radius={50}
                        />
                      </div>
                      <div
                        className="text-center text-white bg-[#5B1DEE] py-[10px] rounded-[16px] hover:shadow-[-1px_6px_10px_0_rgba(93,0,207,0.5)] hover:-translate-y-[3px] cursor-pointer"
                        onClick={() => {
                          dispatch(
                            setCurrentProperty(propertyArray[i].property)
                          );
                          dispatch(setPage("detail"));
                          dispatch(setHeaderMode({ mode: 3, submode: null }));
                        }}
                      >
                        View Details
                      </div>
                    </div>
                  );
            })}
          </div>
        ) : (
          <div className="flex flex-col items-center space-y-[10px] h-full justify-center m-auto">
            <img alt="" src={icon1} />
            <div className="text-[#959595]">
              Invest in the first ever virtual fractionalized ownership NFTs
            </div>
            <div
              className="text-[#5D00CF] border-[#5D00CF] border-[1px] px-[16px] py-[10px] rounded-[16px] cursor-pointer hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)] hover:-translate-y-[3px]"
              onClick={() => {
                dispatch(setHeaderMode({ mode: 3, submode: null }));
              }}
            >
              Buy Properties
            </div>
          </div>
        )}
      </div>
      <div className="text-[20px] my-[20px]">Key financials</div>
      <div className="grid grid-cols-3 gap-[20px]">
        <div className="shadow-md rounded-[16px] p-[16px]">
          <div className="flex items-center gap-[10px]">
            <img alt="" src={money1} />
            <div className="text-[#959595]">Portfolio value</div>
          </div>

          <div className="flex justify-between items-center mt-[30px]">
            <div className="flex gap-[20px]">
              <div className="text-[24px] text-[#959595]">TESTATE </div>
              <div className="text-[24px]">
                <NumericFormat
                  value={portfolioValue}
                  thousandSeparator
                  displayType="text"
                />
              </div>
            </div>
            <div className="text-[#959595] font-normal">
              {new Intl.DateTimeFormat("en-US", {
                month: "short",
                year: "numeric",
              }).format(new Date())}
            </div>
          </div>
        </div>
        <div className="shadow-md rounded-[16px] p-[16px]">
          <div className="flex items-center gap-[10px]">
            <img alt="" src={money2} />
            <div className="text-[#959595]">Total income</div>
          </div>

          <div className="flex justify-between items-center mt-[30px]">
            <div className="flex gap-[20px]">
              <div className="text-[24px] text-[#959595]">TESTATE </div>
              <div className="text-[24px]">0 </div>
            </div>
            {/* <div className="text-[#959595] font-normal">as of Sep 2023</div> */}
          </div>
        </div>
        <div className="shadow-md rounded-[16px] p-[16px]">
          <div className="flex items-center gap-[10px]">
            <img alt="" src={money3} />
            <div className="text-[#959595]">Capital appreciation</div>
          </div>

          <div className="flex justify-between items-center mt-[30px]">
            <div className="flex gap-[20px]">
              <div className="text-[24px] text-[#959595]">TESTATE </div>
              <div className="text-[24px]">0 </div>
            </div>
            {/* <div className="text-[#959595] font-normal">as of Sep 2023</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
