import { useDispatch } from "react-redux";
import IDIcon from "../../assets/images/Social.png";
import { setDashboardMode } from "../../Actions/DashboardSlice";
import { useSelector } from "react-redux";
import { CarouselCompo } from "../Global/CarouselCompo";
import verifiedMark from "../../assets/images/dashboard/badges (1).png";
import uploadimage from "../../assets/images/dashboardListing/uploadimage.png";
import unverifiedMark from "../../assets/images/dashboard/badges.png";
import verifyIcon1 from "../../assets/images/dashboard/Frame 10000055681.png";
import sampleMetadata from "../../metadata.json";
import arrowIcon from "../../assets/images/map/Arrow, Location, Map, Direction.svg";
import addIcon from "../../assets/images/map/add.svg";
import removeIcon from "../../assets/images/map/remove.svg";
import social1 from "../../assets/images/dashboard/Social1.svg";
import social2 from "../../assets/images/dashboard/Social2.svg";
import social3 from "../../assets/images/dashboard/Social3.svg";
import pinIcon from "../../assets/images/dashboard/pin.svg";
import searchIcon from "../../assets/images/dashboard/tabler-icon-search.svg";
import Guests from "../../assets/images/dashboard/GuestsSocial.svg";
import Bedrooms from "../../assets/images/dashboard/BedroomsSocial.svg";
import Bathrooms from "../../assets/images/dashboard/BathroomsSocial.svg";
import Beds from "../../assets/images/dashboard/BedsSocial.svg";
import deleteIcon from "../../assets/images/dashboard/deleteIcon.svg";
import { PropertyDetail } from "../Rental/PropertyDetail";
import arrowtoleft from "../../assets/images/sidebar/arrowToLeft.svg";

import {
  executeContract,
  setFeeOnContract,
} from "../functional/InteractToContract";
import { pinFileToIPFS, pinMetadata } from "../functional/InteractToIPFS";
import { useState, useEffect, useRef } from "react";
import { getMyNFTsInfo } from "../functional/InteractToIPFS";
import { useNavigate } from "react-router-dom";
import verifyIcon from "../../assets/images/dashboard/Frame 1000005568.png";
import { setNFTsSection } from "../../Actions/NFTSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { NumberSpin } from "../Global/NumberSpin";
import {
  Autocomplete,
  GoogleMap,
  useJsApiLoader,
  // LoadScript,
  Marker,
} from "@react-google-maps/api";
import { Fade, Bounce, Zoom, Slide } from "react-awesome-reveal";
import ImageUploading from "react-images-uploading";
import { Modal } from "react-bootstrap";
import cross from "../../assets/images/Header/Group 1000004790.svg";
import { VerifiedCheckMark } from "../CheckMark/CheckMark";
import { Testnet } from "@nibiruchain/nibijs";
import { mapStyles } from "../Global/GoogleMapComponent";
import { ReclaimProtocol } from "../ZKP/ReclaimProtocol";

const testNet = Testnet(1);

export const HostDashboard = ({ assets = [] }) => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.auth.account);
  const walletEx = useSelector((state) => state.auth.wallet);
  const myAvatar = useSelector((state) => state.auth.profile?.Avatar);
  const [updateFlag, setUpdateFlag] = useState(false);

  // const assets = useSelector((state) => state.nft.allNFTs);

  const navigate = useNavigate();

  const [mintMode, setMintMode] = useState(false);
  const [flow, setFlow] = useState(0);

  const [scaledSize, setScaleSize] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [anchor, setAnchor] = useState(null);
  const [images, setImages] = useState([]);
  const maxNumber = 69;

  const [amenties, setAmenities] = useState({
    Guests: 2,
    Bedrooms: 1,
    Beds: 1,
    Bathrooms: 1,
  });

  const [propertyID, setPropertyID] = useState();
  const [propertyName, setPropertyName] = useState();
  const [description, setDescription] = useState();
  const [groundRule, setGroundRule] = useState();
  const [wifiPass, setWifiPass] = useState();
  const ID = useSelector((state) => state.auth.profile?.ID);
  const avatar = useSelector((state) => state.auth.profile?.Avatar);
  // const [assets, setAssets] = useState([]);

  // const getMyAssets = async () => {
  //   const result = await getMyNFTsInfo(account);
  //   setAssets(result);
  // };

  // useEffect(() => {
  //   getMyAssets();
  // }, [updateFlag]);

  const [pinLocation, setPinLocation] = useState({
    lat: 25,
    lng: 55,
  });
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ["places"],
  });

  // const [abuDhabi, setAbuDhabi] = useState([]);
  // const initializeMap = () => {
  //   const coordinates = [];
  //   for (let i = 0; i < AbuDhabi.coordinates.length; i++) {
  //     coordinates.push({
  //       lat: AbuDhabi.coordinates[i][1],
  //       lng: AbuDhabi.coordinates[i][0],
  //     });
  //   }
  //   setAbuDhabi(coordinates);
  // };

  // useEffect(() => {
  //   initializeMap();
  // }, []);
  const onUploadingImagesChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };
  const [mapZoom, setMapZoom] = useState(12);

  useEffect(() => {
    setScaleSize(new window.google.maps.Size(100, 100));
    setOrigin(new window.google.maps.Point(0, 0));
    setAnchor(new window.google.maps.Point(50, 60));
  }, []);

  const setFeePercentage = async () => {
    await setFeeOnContract(
      process.env.REACT_APP_RENTAL_SMART_CONTRACT,
      500,
      account,
      walletEx,
      dispatch
    );
  };

  const mintNFT = async (token_uri, token_id) => {
    // const token_id = ((await countMintedNFTs()) + 1).toString();

    // console.log(token_id);
    const mintMessage = {
      mint: {
        token_id: token_id,
        owner: account,
        token_uri: token_uri,
        extension: {},
      },
      // mint: {
      //   token_id: token_id,
      //   owner: account,
      //   token_uri:
      //     "https://ivory-causal-kite-248.mypinata.cloud/ipfs/QmausFTqTTarcKPJgTQVTKyGeX6rVUovbu1j5Ui9eQG9JJ",
      //   extension: {},
      // },
    };
    const r = await executeContract(
      testNet.chainId,
      testNet.endptTm,
      process.env.REACT_APP_RENTAL_SMART_CONTRACT,
      dispatch,
      token_id,
      account,
      mintMessage,
      account,
      walletEx
    );
    if (r) setMintMode(false);
    // setUpdateFlag(!updateFlag);
  };

  // const countMintedNFTs = async () => {
  //   const query = {
  //     num_tokens: {},
  //   };
  //   return (
  //     await queryContract(process.env.REACT_APP_RENTAL_SMART_CONTRACT, query)
  //   ).count;
  // };

  const checkID = () => {
    if (!ID) {
      toast.error("You have to verify your Identity to mint new NFT");
      return false;
    } else return true;
  };

  const handleMint = async () => {
    if (!propertyID) {
      toast.error("Please confirm your property ID from ZKP before minting");
      return;
    }
    const imgHashes = await pinFileToIPFS(
      images,
      process.env.REACT_APP_PINATA_JWT_2,
      process.env.REACT_APP_PINATA_GATEWAY_2
    );
    const hash = await pinMetadata(
      {
        ID: propertyID,
        Name: propertyName,
        Address: address,
        Location: pinLocation,
        Amenities: amenties,
        Description: description,
        Images: imgHashes,
        AddressDetails: addressDetails,
        GroundRules: groundRule,
        Wifi: wifiPass,
      },
      propertyID,
      process.env.REACT_APP_PINATA_JWT_2
    );
    if (!hash) return;
    console.log("Metadata uploaded..");

    mintNFT(
      process.env.REACT_APP_PINATA_GATEWAY_2 + hash,
      propertyID.toString()
    );
  };
  const [address, setAddress] = useState(null);
  const [addressDetails, setAddressDetails] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalValue, setModalValue] = useState();
  const [zoom, setZoom] = useState(10);
  const geocodeLocation = async () => {
    const response = await geocoder?.geocode({ location: pinLocation });
    setAddress(response?.results[0]?.formatted_address);
    // setAddress(response?.results[0]);
    const addressComponents = response?.results[0].address_components;
    let parsedAddress = {
      street: "",
      city: "",
      state: "",
      zipCode: "",
      county: "",
    };

    addressComponents?.forEach((component) => {
      if (component.types.includes("route")) {
        parsedAddress.street = component.long_name;
      } else if (component.types.includes("locality")) {
        parsedAddress.city = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        parsedAddress.state = component.short_name;
      } else if (component.types.includes("postal_code")) {
        parsedAddress.zipCode = component.long_name;
      } else if (component.types.includes("administrative_area_level_2")) {
        parsedAddress.county = component.long_name;
      }
    });

    setAddressDetails(parsedAddress);
  };

  useEffect(() => {
    geocodeLocation();
    // console.log(isLocationInArea(pinLocation, abuDhabi));
  }, [pinLocation]);
  const [autocomplete, setAutocomplete] = useState(null);
  const [geocoder, SetGeocoder] = useState(null);
  const [map, setMap] = useState(null);
  const onPlaceChanged = () => {
    try {
      if (autocomplete !== null) {
        setPinLocation({
          lat: autocomplete.getPlace().geometry.location.lat(),
          lng: autocomplete.getPlace().geometry.location.lng(),
        });
        setZoom(Math.max(zoom, 12));
      } else {
        console.log("Autocomplete is not loaded yet!");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
    SetGeocoder(new window.google.maps.Geocoder());
  };

  const onMapLoad = (map) => {
    setMap(map);
  };

  useEffect(() => {
    // if (address) parseAddress(address);
    // console.log(addressDetails);
  }, [addressDetails]);

  // const [onModalValueChange, setOnModalValueChange] = useState(() => {});

  return (
    <>
      {!mintMode && (
        <>
          <div className="p-[20px] h-full flex flex-col">
            <div className="font-semibold text-[24px]">Dashboard</div>
            <div className="text-[#8C8C8C]">
              Welcome to the CodedEstate Dashboard
            </div>
            <div className="my-[40px] flex gap-[16px] w-full">
              <div>
                <div className="font-semibold">Verify ID</div>
                <div className="rounded-[8px] shadow-md px-[12px] py-[18px] space-y-[20px] w-[260px] flex flex-col items-center">
                  <img
                    alt=""
                    src={
                      avatar ||
                      "https://www.signivis.com/img/custom/avatars/member-avatar-01.png"
                    }
                    className="w-[88px] rounded-full"
                  ></img>
                  <div
                    className="py-[8px] bg-[#202020] text-white text-center w-full cursor-pointer shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-full font-semibold hover:shadow-[-1px_6px_10px_0_rgba(0,0,0,0.5)] hover:-translate-y-[3px]"
                    onClick={() => {
                      navigate("/account");
                    }}
                  >
                    Verify ID
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className="font-semibold">Your Minted NFTs</div>
                <div className="rounded-[8px] shadow-md w-full h-[184px] flex flex-wrap p-[8px] gap-[8px] overflow-auto scrollbarwidth">
                  {assets.length > 0 ? (
                    <>
                      {assets.map((item) => {
                        let temp = [];

                        // if (item.metaData?.Images)
                        temp.push(
                          <div
                            className="w-[250px]"
                            onClick={() => {
                              dispatch(setNFTsSection(0));
                              dispatch(setDashboardMode(2));
                            }}
                          >
                            <CarouselCompo
                              // nftInfo={item.nft_info}
                              metaData={item.metaData}
                              onlyImages={true}
                              topRightIcon={<VerifiedCheckMark />}
                              imageHeight={"146px"}
                              stopPropagation={true}
                            />
                          </div>
                        );
                        // else
                        //   temp.push(
                        //     <div
                        //       className="w-[250px]"
                        //       onClick={() => {
                        //         dispatch(setNFTsSection(1));

                        //         dispatch(setDashboardMode(2));
                        //       }}
                        //     >
                        //       <CarouselCompo
                        //         nftInfo={item.nft_info}
                        //         metaData={item.metaData}
                        //         emptyImage={uploadimage}
                        //         onlyImages={true}
                        //         topRightIcon={unverifiedMark}
                        //         imageHeight={"146px"}
                        //         stopPropagation={true}
                        //       />
                        //     </div>
                        //   );
                        return temp;
                      })}
                    </>
                  ) : (
                    <div className="rounded-[8px] m-auto flex flex-col items-center space-y-[20px]">
                      <img alt="" src={verifyIcon}></img>
                      <div className="">No Minted NFTs</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="font-semibold">Mint Your Real Estate NFT</div>

            <div className="w-full h-full shadow-md rounded-[8px] flex">
              <div className="m-auto flex flex-col items-center space-y-[40px]">
                <img alt="" src={verifyIcon1}></img>
                <div
                  className="bg-[#5B1DEE] text-center w-[200px] py-[8px] cursor-pointer px-[18px] text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-full font-semibold hover:shadow-[-1px_6px_10px_0_rgba(93,0,207,0.5)] hover:-translate-y-[3px]"
                  onClick={() => {
                    // mintNFT("", "10261280");
                    if (checkID()) setMintMode(true);
                    else navigate("/account");
                  }}
                >
                  Mint
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {mintMode && (
        <>
          <div className="flex flex-col justify-between w-full px-[30px] h-full">
            {flow === 4 && (
              <div className="flex justify-between">
                <img
                  alt=""
                  className="cursor-pointer w-[60px]"
                  onClick={() => {
                    if (flow) setFlow(flow - 1);
                    else setMintMode(!mintMode);
                  }}
                  src={arrowtoleft}
                ></img>
              </div>
            )}

            {flow === 0 && (
              <div className="m-auto space-y-[20px] max-w-[440px]">
                <Fade cascade direction="left" damping={0.1} duration={1000}>
                  <div className="text-center text-[32px] mb-[20px]">
                    Get your NFTs ready for rental
                  </div>
                  <div className="p-[14px] gap-[14px] flex items-center shadow-md rounded-[8px] border-[2px] border-[#777777]">
                    <img alt="" src={social1}></img>
                    <div>
                      <div>Tell us about your place</div>
                      <div className="text-[#8C8C8C]">
                        Share some basic info, like where it is and how many
                        guests can stay
                      </div>
                    </div>
                  </div>
                  <div className="p-[14px] gap-[14px] flex items-center shadow-md rounded-[8px]">
                    <img alt="" src={social2}></img>
                    <div>
                      <div>Make it stand out</div>
                      <div className="text-[#8C8C8C]">
                        Add 5 or more photos plus a title and description. We’ll
                        help you out.
                      </div>
                    </div>
                  </div>
                  <div className="p-[14px] gap-[14px] flex items-center shadow-md rounded-[8px]">
                    <img alt="" src={social3}></img>
                    <div>
                      <div>Finish up and publish</div>
                      <div className="text-[#8C8C8C]">
                        Choose if you’d like to start with an experienced guest,
                        set a starting price, and publish your listing.
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            )}
            {flow === 1 && (
              <div className="m-auto w-[700px]">
                <div className="w-full text-center mb-[40px]">
                  <Zoom delay={1000}>
                    <div className="text-[24px]">Pin point your location</div>
                  </Zoom>
                  <Bounce delay={1000}>
                    <div className="text-[14px]">
                      Move the pin to your location
                    </div>
                  </Bounce>
                </div>
                <div className="flex items-center gap-[20px] mb-[20px]">
                  {/* <div className="globalInputForm flex px-[10px] py-[4px] rounded-[4px] w-max gap-[10px]">
                    <div>Latitude:</div>
                    <input
                      className="rounded-[4px] w-[50px]"
                      disabled
                      value={pinLocation.lat.toFixed(2)}
                    />
                  </div>
                  <div className="globalInputForm flex px-[10px] py-[4px] rounded-[4px] w-max gap-[10px]">
                    <div>Longitude:</div>
                    <input
                      className="rounded-[4px] w-[50px]"
                      disabled
                      value={pinLocation.lng.toFixed(2)}
                    />
                  </div> */}
                  {map && (
                    <div className="globalInputForm flex px-[10px] py-[4px] rounded-[4px] w-full gap-[10px]">
                      <div>Address:</div>
                      <input
                        className="rounded-[4px] w-full"
                        disabled
                        value={address}
                      />
                    </div>
                  )}
                </div>
                <Fade>
                  <div className="w-full h-[600px] relative">
                    {/* <LoadScript
                      libraries={["places"]}
                      googleMapsApiKey={
                        process.env.REACT_APP_GOOGLE_MAP_API_KEY
                      }
                    > */}
                    <div className="w-full flex items-center justify-center absolute top-[10px] z-[1200]">
                      <div className="bg-white mx-auto flex items-center py-[4px] gap-[6px] px-[10px] rounded-[4px]">
                        <img alt="" src={searchIcon} />

                        <Autocomplete
                          onLoad={onLoad}
                          onPlaceChanged={onPlaceChanged}
                        >
                          <input
                            type="text"
                            placeholder="Enter an address"
                            className="w-[200px]"
                          ></input>
                        </Autocomplete>
                      </div>
                    </div>
                    {isLoaded && (
                      <GoogleMap
                        center={pinLocation}
                        zoom={zoom}
                        mapContainerClassName="w-full h-full shadow-lg rounded-[8px]"
                        options={{
                          mapTypeControl: false,
                          streetViewControl: false,
                          fullscreenControl: false,
                          zoomControl: false,
                          styles: mapStyles,
                        }}
                        onLoad={onMapLoad}
                        onZoomChanged={() => {
                          if (map) setZoom(map.getZoom());
                        }}
                      >
                        <div className="absolute top-[20px] right-[20px] flex items-center gap-[10px]">
                          <div className="p-[4px] rounded-full shadow-md bg-[#202020] hover:translate-y-[-4px]">
                            <img alt="" src={arrowIcon} />
                          </div>
                          <div
                            className="p-[4px] rounded-full shadow-md bg-white cursor-pointer hover:translate-y-[-4px]"
                            onClick={() => setMapZoom(mapZoom + 1)}
                          >
                            <img alt="" src={addIcon} />
                          </div>
                          <div
                            className="p-[4px] rounded-full shadow-md bg-white cursor-pointer hover:translate-y-[-4px]"
                            onClick={() => setMapZoom(mapZoom - 1)}
                          >
                            <img alt="" src={removeIcon} />
                          </div>
                        </div>
                        {map && (
                          <Marker
                            position={pinLocation}
                            draggable
                            onDragEnd={(e) => {
                              setPinLocation({
                                lat: e.latLng.lat(),
                                lng: e.latLng.lng(),
                              });
                              setZoom(Math.max(zoom, 12));
                            }}
                            onDragStart={() => setZoom(zoom - 0.1)}
                            icon={{
                              url: pinIcon,
                              scaledSize: scaledSize,
                              origin: origin,
                              anchor: anchor,
                            }}
                          ></Marker>
                        )}
                      </GoogleMap>
                    )}
                    {/* </LoadScript> */}
                  </div>
                </Fade>
              </div>
            )}

            {flow === 2 && (
              <>
                <div className="m-auto space-y-[20px] flex flex-col items-center">
                  <div className="text-[32px]">Confirm your address</div>
                  <div className="text-[#595959] w-[400px] text-center">
                    Your address is only shared with guest after they’ve made a
                    reservation
                  </div>
                  <div className="w-[400px] space-y-[10px]">
                    <Fade cascade>
                      <div className="p-[14px] rounded-[8px] shadow-md w-full flex justify-between">
                        <div>Address</div>
                        <div className="text-[#9d9d9d] w-[60%]">{address}</div>
                      </div>
                      <div className="p-[14px] rounded-[8px] shadow-md w-full flex justify-between">
                        <div>Street</div>
                        <div className="text-[#9d9d9d]">
                          {addressDetails.street}
                        </div>
                      </div>
                      <div className="p-[14px] rounded-[8px] shadow-md w-full flex justify-between">
                        <div>City</div>
                        <div className="text-[#9d9d9d]">
                          {addressDetails.city}
                        </div>
                      </div>
                      <div className="p-[14px] rounded-[8px] shadow-md w-full flex justify-between">
                        <div>State (optional)</div>
                        <div className="text-[#9d9d9d]">
                          {addressDetails.state}
                        </div>
                      </div>
                      <div className="p-[14px] rounded-[8px] shadow-md w-full flex justify-between">
                        <div>Zip code (optional)</div>
                        <div className="text-[#9d9d9d]">
                          {addressDetails.zipCode}
                        </div>
                      </div>
                      <div className="p-[14px] rounded-[8px] shadow-md w-full flex justify-between">
                        <div>County / Regional</div>
                        <div className="text-[#9d9d9d]">
                          {addressDetails.county}
                        </div>
                      </div>
                    </Fade>
                  </div>
                </div>
              </>
            )}

            {flow === 3 && (
              <div className="m-auto flex flex-col items-center w-max">
                <div className="text-[32px] text-center">
                  Share some basic about your place
                </div>
                <div className="text-[#595959]">
                  You’ll add more details later
                </div>
                <div className="w-[500px]">
                  <Slide cascade direction="up">
                    <div className="px-[8px] py-[12px] rounded-[8px] shadow-md flex items-center w-full justify-between">
                      <div className="flex items-center gap-[5px]">
                        <img alt="" src={Guests}></img>
                        <div>Guests</div>
                      </div>
                      <NumberSpin
                        value={amenties.Guests}
                        min={0}
                        onChange={(e) => {
                          setAmenities({ ...amenties, Guests: e });
                        }}
                      />
                    </div>
                    <div className="px-[8px] py-[12px] rounded-[8px] shadow-md flex items-center w-full justify-between">
                      <div className="flex items-center gap-[5px]">
                        <img alt="" src={Bedrooms}></img>
                        <div>Bedrooms</div>
                      </div>
                      <NumberSpin
                        value={amenties.Bedrooms}
                        min={0}
                        onChange={(e) => {
                          setAmenities({ ...amenties, Bedrooms: e });
                        }}
                      />
                    </div>
                    <div className="px-[8px] py-[12px] rounded-[8px] shadow-md flex items-center w-full justify-between">
                      <div className="flex items-center gap-[5px]">
                        <img alt="" src={Beds}></img>
                        <div>Beds</div>
                      </div>
                      <NumberSpin
                        value={amenties.Beds}
                        min={0}
                        onChange={(e) => {
                          setAmenities({ ...amenties, Beds: e });
                        }}
                      />
                    </div>
                    <div className="px-[8px] py-[12px] rounded-[8px] shadow-md flex items-center w-full justify-between">
                      <div className="flex items-center gap-[5px]">
                        <img alt="" src={Bathrooms}></img>
                        <div>Bathrooms</div>
                      </div>
                      <NumberSpin
                        value={amenties.Bathrooms}
                        min={0}
                        onChange={(e) => {
                          setAmenities({ ...amenties, Bathrooms: e });
                        }}
                      />
                    </div>
                  </Slide>
                </div>
              </div>
            )}
            {flow === 4 && (
              <div className="m-auto flex flex-col items-center">
                <div className="text-[24px]">Manual Metadata</div>
                <div className="p-[16px] rounded-[12px] shadow-md w-[600px] h-[400px] space-y-[12px] flex flex-col my-[20px] select-none">
                  <ImageUploading
                    multiple
                    value={images}
                    onChange={onUploadingImagesChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={[
                      "jpg",
                      "jpeg",
                      "png",
                      "gif",
                      "bmp",
                      "tiff",
                      "tif",
                      "svg",
                      "webp",
                      "heic",
                      "heif",
                    ]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      <div
                        className={
                          isDragging
                            ? "border-[2px] border-dashed rounded-[12px] shadow-md flex w-full h-full bg-[#dddddd]"
                            : "border-[2px] border-dashed rounded-[12px] shadow-md flex w-full h-full"
                        }
                        {...dragProps}
                      >
                        <div className="m-auto flex flex-col items-center space-y-[20px]">
                          {imageList.length ? (
                            <div className="flex gap-[10px] w-full overflow-auto">
                              {/* {currentImages?.map((hash, index) => {
                              return (
                                <div className="mx-auto flex flex-col items-center">
                                  <img alt="" 
                                    src={gateWay + hash}
                                    className="w-[120px] h-[100px] rounded-[5px]"
                                    
                                  />
                                  <img alt="" 
                                    className="cursor-pointer"
                                    src={deleteIcon}
                                    onClick={() => {
                                      onImageRemoveFromCurrent(index);
                                      onImageRemove(-1);
                                    }}
                                    
                                  />
                                </div>
                              );
                            })} */}
                              {imageList.map((im, index) => {
                                return (
                                  <div className="mx-auto flex flex-col items-center group">
                                    <img
                                      alt=""
                                      src={im.data_url}
                                      className="w-[120px] h-[100px] rounded-[5px]"
                                    />
                                    <img
                                      alt=""
                                      className="cursor-pointer w-[20px] hidden group-hover:block"
                                      src={deleteIcon}
                                      onClick={() => onImageRemove(index)}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <img alt="" src={uploadimage}></img>
                          )}
                          <div
                            className="text-white px-[16px] py-[9px] rounded-[40px] bg-[#4C37C3] cursor-pointer"
                            style={isDragging ? { color: "red" } : null}
                            onClick={onImageUpload}
                          >
                            Upload file
                          </div>
                          <div className="text-[#595959]">
                            ... or drag and drop files
                          </div>
                        </div>
                      </div>
                    )}
                  </ImageUploading>

                  {/* <div className="w-full flex justify-end">
                  <div
                    className="underline cursor-pointer"
                  >
                    Edit
                  </div>
                </div> */}
                </div>
                <div className="w-[600px] h-max shadow-md rounded-[8px] p-[20px] space-y-[10px]">
                  <div>
                    <div className="flex justify-between items-center">
                      <div className="text-[18px]">
                        Property ID *{" "}
                        <span className="text-[#8d8d8d] italic text-[14px]">
                          your property ID from DLD
                        </span>
                      </div>
                      {/* <div
                        className="underline cursor-pointer"
                        onClick={() => {
                          setModalTitle("Property ID");
                          setModalValue(propertyID);
                          setShowModal(true);
                        }}
                      >
                        Add
                      </div> */}
                      <ReclaimProtocol
                        setReturn={setPropertyID}
                        providerId={1}
                        txt="Add"
                      />
                    </div>
                    <div className="text-[#8d8d8d]">{propertyID}</div>
                  </div>
                  <div>
                    <div className="flex justify-between items-center">
                      <div className="text-[18px]">Building Name</div>
                      <div
                        className="underline cursor-pointer"
                        onClick={() => {
                          setModalTitle("Building Name");
                          setModalValue(propertyName);
                          setShowModal(true);
                        }}
                      >
                        Add
                      </div>
                    </div>
                    <div className="text-[#8d8d8d]">{propertyName}</div>
                  </div>
                  <div>
                    <div className="flex justify-between items-center">
                      <div className="text-[18px]">Ground Rules</div>
                      <div
                        className="underline cursor-pointer"
                        onClick={() => {
                          setModalTitle("Ground Rules");
                          setModalValue(groundRule);
                          setShowModal(true);
                        }}
                      >
                        Add
                      </div>
                    </div>
                    <div className="text-[#8d8d8d]">{groundRule}</div>
                  </div>
                  <div>
                    <div className="flex justify-between items-center">
                      <div className="text-[18px]">Wifi Password</div>
                      <div
                        className="underline cursor-pointer"
                        onClick={() => {
                          setModalTitle("Wifi Password");
                          setModalValue(wifiPass);
                          setShowModal(true);
                        }}
                      >
                        Add
                      </div>
                    </div>
                    <div className="text-[#8d8d8d]">{wifiPass}</div>
                  </div>
                  <div>
                    <div className="flex justify-between items-center">
                      <div className="text-[18px]">Description</div>
                      <div
                        className="underline cursor-pointer"
                        onClick={() => {
                          setModalTitle("Description");
                          setModalValue(description);
                          setShowModal(true);
                        }}
                      >
                        Add
                      </div>
                    </div>
                    <div className="text-[#8d8d8d]">{description}</div>
                  </div>
                  <div>
                    <div className="flex justify-between items-center">
                      <div className="text-[18px]">Address</div>
                      <div
                        className="underline cursor-pointer"
                        onClick={() => {
                          setFlow(1);
                        }}
                      >
                        Edit
                      </div>
                    </div>
                    <div className="text-[#8d8d8d]">{address}</div>
                    <div className="text-[#8d8d8d]">
                      {Object.keys(addressDetails).map((item) => {
                        return (
                          <div>
                            {item} {addressDetails[item]}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-between items-center">
                      <div className="text-[18px]">Amenities</div>
                      <div
                        className="underline cursor-pointer"
                        onClick={() => {
                          setFlow(3);
                        }}
                      >
                        Edit
                      </div>
                    </div>
                    <div className="text-[#8d8d8d]">
                      {Object.keys(amenties).map((item) => {
                        return (
                          <div>
                            {item} {amenties[item]}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div
                  className="text-white cursor-pointer my-[20px] rounded-[16px] py-[8px] px-[20px] bg-[#5b1dee]"
                  onClick={() => handleMint()}
                >
                  Mint Now
                </div>
              </div>
            )}

            <div className="w-full flex justify-between mb-[20px]">
              {flow < 4 && (
                <div
                  className="cursor-pointer shadow-md rounded-[16px] py-[12px] px-[20px]"
                  onClick={() => {
                    if (flow) setFlow(flow - 1);
                    else setMintMode(!mintMode);
                  }}
                >
                  back
                </div>
              )}
              {flow < 4 && (
                <div
                  className="text-white cursor-pointer rounded-[16px] py-[12px] px-[20px] bg-[#5b1dee]"
                  onClick={() => setFlow(flow + 1)}
                >
                  {flow === 0 ? "Get Started" : "Next"}
                </div>
              )}
            </div>
            <Modal centered show={showModal} onHide={() => setShowModal(false)}>
              <Modal.Body>
                <div className="w-[400px] h-max py-[10px] px-[20px]">
                  <div className="w-full flex justify-between mb-[10px] items-center">
                    <div className="text-[20px] font-semibold">
                      {modalTitle}
                    </div>
                    <img
                      alt=""
                      onClick={() => setShowModal(false)}
                      src={cross}
                      className="w-[40px] cursor-pointer"
                    />
                  </div>
                  <div className="globalInputForm p-[16px] rounded-[8px]">
                    <textarea
                      className="w-full h-[250px]"
                      value={modalValue}
                      onChange={(e) => setModalValue(e.target.value)}
                    />
                  </div>
                  <div className="flex justify-end">
                    <div
                      className="text-white cursor-pointer w-max rounded-[16px] mt-[10px] py-[8px] px-[20px] bg-[#5b1dee]"
                      onClick={() => {
                        if (modalTitle === "Building Name") {
                          setPropertyName(modalValue);
                          setShowModal(false);
                        }
                        // if (modalTitle === "Property ID") {
                        //   setPropertyID(modalValue);
                        //   setShowModal(false);
                        // }
                        if (modalTitle === "Description") {
                          setDescription(modalValue);
                          setShowModal(false);
                        }
                        if (modalTitle === "Ground Rules") {
                          setGroundRule(modalValue);
                          setShowModal(false);
                        }
                        if (modalTitle === "Wifi Password") {
                          setWifiPass(modalValue);
                          setShowModal(false);
                        }
                      }}
                    >
                      Save
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </>
      )}
    </>
  );
};
