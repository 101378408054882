import { useDispatch, useSelector } from "react-redux";
import { Inbox } from "../PartialPage/Inbox/Inbox";
import { setPage } from "../../Actions/PageSlice";
import { setHeaderMode } from "../../Actions/HeaderSlice";
import { ImageView } from "../Global/ImageView";
import NUSDIcon from "../../assets/images/NUSD.svg";
import { setNFT } from "../../Actions/NFTSlice";
import { setDashboardMode } from "../../Actions/DashboardSlice";
import { TravelerAction } from "./TravelerAction";
import { InboxProfile } from "../PartialPage/Inbox/InboxProfile";
import { InboxNFT } from "../PartialPage/Inbox/InboxNFT";

export const TravelerInbox = () => {
  const chatNFT = useSelector((state) => state.chat.nft);

  const account = useSelector((state) => state.auth.account);
  const assets = useSelector((state) => state.nft.allNFTs);
  const chatMode = useSelector((state) => state.chat.mode);
  const dispatch = useDispatch();

  return (
    <>
      <div className="bg-[#F6F6F6] rounded-[8px] w-full h-full flex gap-[5px]">
        <div className="w-full rounded-[8px] bg-white">
          <Inbox mode={"traveler"} />
        </div>
        <div className="w-[400px] rounded-[8px] bg-white p-[12px] space-y-[16px] overflow-auto sidebarheight hiddenscrollbar">
          {chatNFT && "traveler" === chatMode ? (
            <>
              <InboxNFT currentToken={assets[chatNFT]} />
              <InboxProfile />
              <div
                className="bg-[#202020] py-[10px] text-center mt-[10px] text-white cursor-pointer shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)] hover:-translate-y-[3px]"
                onClick={() => {
                  // dispatch(setNFT(chatNFT));
                  // dispatch(
                  //   setHeaderMode({
                  //     mode: 1,
                  //     submode: 1,
                  //   })
                  // );
                  // dispatch(setPage("detail"));
                  dispatch(setDashboardMode(1));
                }}
              >
                View Details
              </div>
              <div
                className="bg-[#5b1dee] py-[10px] text-center mt-[10px] text-white cursor-pointer shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)] hover:-translate-y-[3px]"
                onClick={() => {
                  dispatch(setNFT(chatNFT));
                  dispatch(
                    setHeaderMode({
                      mode: 1,
                      submode: 1,
                    })
                  );
                  dispatch(setPage("detail"));
                }}
              >
                Go to Reservation
              </div>

              <div className="text-[#B6B6B6]">Rental</div>

              {assets[chatNFT]?.shorttermrental_info?.travelers?.map((item) => {
                const array = [];
                if (item.address === account) {
                  array.push(
                    <TravelerAction
                      rentingPeriod={item.renting_period}
                      currentToken={assets[chatNFT]}
                      cancelled={item.cancelled}
                      fundedAmount={item.deposit_amount}
                      approved={item.approved}
                    />
                  );
                }
                return array;
              })}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
