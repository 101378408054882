import "bootstrap/dist/css/bootstrap.min.css";
import { LandingPage } from "./pages/LandingPage";

import { Navigater } from "./Navigater";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { MainLayout } from "./Layout/MainLayout";
import { Gallery } from "./components/Page/Gallery";
import { DashboardLayout } from "./Layout/DashboardLayout";
import { Swap } from "./pages/Swap";
import { Rent } from "./pages/Rent";
import { useSelector } from "react-redux";
import { Account } from "./pages/Account";
import { YieldEstate } from "./pages/YieldEstate";
import { Claim } from "./pages/Claim";
import { User } from "./pages/Profile/User";
// import "./font/GeneralSans_Complete/Fonts/WEB/css/general-sans.css";
// import "./font/Trueno/style.css";
import { ConnectWallet } from "./components/Page/ConnectWallet";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loading } from "./components/Page/Loading";
import ReactAudioPlayer from "react-audio-player";
import successSound from "./assets/sounds/SUCCESS LUx2x.ogg";
import failSound from "./assets/sounds/fail.m4a";
import { useEffect, useState } from "react";
import { Chart } from "./components/Perps/Chart";
import ReactTooltip from "react-tooltip";
// Load the content of the .env file
// const loadEnv = () => {
//   if (process.env.NODE_ENV !== "production") {
//     const env = require("./.env");
//     for (let key in env) {
//       process.env[key] = env[key];
//     }
//   }
// };

// // Invoke the function to load environment variables
// loadEnv();
// import "react-tooltip/dist/react-tooltip.css";

function App() {
  const show = useSelector((state) => state.gallery.show);
  const txState = useSelector((state) => state.modal.txState);

  const [audioPlay, setAudioPlay] = useState(false);
  const [sound, setSound] = useState(failSound);

  useEffect(() => {
    if (txState === false) {
      setSound(failSound);
      setAudioPlay(true);
    }
    if (txState === true) {
      setSound(successSound);
      setAudioPlay(true);
    }
  }, [txState]);

  return (
    <div
      className="w-full bg-white font-medium flex flex-col font-sans text-[#202020]"
      style={{ fontFamily: "General Sans" }}
    >
      <ConnectWallet />
      <Loading />

      <div hidden={show}>
        <Router>
          <Navigater />

          <Routes>
            <Route path="landing" element={<LandingPage />}></Route>

            {/* <Route path="join" element={<Claim />}></Route> */}

            <Route element={<MainLayout />}>
              <Route path="account" element={<Account />}></Route>
              <Route path="user" element={<User />}></Route>
              <Route path="swap" element={<Swap />}></Route>
              <Route path="perps" element={<Chart />}></Route>
              <Route path="yieldestate" element={<YieldEstate />}></Route>
              <Route path="rent" element={<Rent />}></Route>
              <Route path="buy" element={<></>}></Route>
              <Route path="dashboard" element={<DashboardLayout />}></Route>
              <Route path="claim" element={<Claim />}></Route>
            </Route>
            <Route path="*" element={<Navigater />}></Route>
          </Routes>
        </Router>
      </div>
      <Gallery />

      <ToastContainer
        position="bottom-right"
        // autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
      />
      <ReactTooltip
        id="comingsoon"
        className="comingsoon"
        effect="solid"
        padding="2px"
        offset={{ top: "4px" }}
      >
        <span>Coming Soon</span>
      </ReactTooltip>
      {/* <Tooltip
        id="comingsoon"
        place="bottom"
        content="Coming Soon"
        style={{
          padding: "4px",
          paddingLeft: "8px",
          paddingRight: "8px",
          zIndex: "9999",
          color: "#dddddd",
          fontSize: "12px",
        }}
      /> */}

      {audioPlay && (
        <ReactAudioPlayer
          style={{
            display: "none",
          }}
          autoPlay={true}
          onEnded={() => setAudioPlay(false)}
          src={sound}
        />
      )}
    </div>
  );
}

export default App;
