import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  region: null,
  address: null,
  checkIn: null,
  checkOut: null,
  guests: {
    adults: 1,
    children: 0,
    infants: 0,
    pets: 0,
  },
  amenities: {
    bathroom: 0,
    bedroom: 0,
    bed: 0,
    squareFeet: "",
  },
  priceRange: null,
  propertyType: null,
  amenitiesEssentials: null,
  filter: null,
};

export const SearchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setRegion: (state, action) => {
      state.region = action.payload;
    },
    setSearchAddress: (state, action) => {
      state.address = action.payload;
    },
    setCheckIn: (state, action) => {
      state.checkIn = action.payload;
    },
    setCheckOut: (state, action) => {
      state.checkOut = action.payload;
    },
    setGuests: (state, action) => {
      state.guests = action.payload;
    },
    setAmenities: (state, action) => {
      state.amenities = action.payload;
    },
    setPriceRange: (state, action) => {
      state.priceRange = action.payload;
    },
    setPropertyType: (state, action) => {
      state.propertyType = action.payload;
    },
    setAmenitiesEssentials: (state, action) => {
      state.amenitiesEssentials = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
});

export const {
  setPriceRange,
  setPropertyType,
  setAmenitiesEssentials,
  setRegion,
  setSearchAddress,
  setCheckIn,
  setCheckOut,
  setGuests,
  setAmenities,
  setFilter,
} = SearchSlice.actions;

export default SearchSlice.reducer;
