import logo from "../../assets/images/Logo Coded-Estate.svg";

import group from "../../assets/images/Header/Group 1000004852.svg";
import frame from "../../assets/images/Header/Frame 1000001444.svg";
import frame1 from "../../assets/images/Header/Frame 1000001445.svg";
import light from "../../assets/images/light.svg";

// import Metamask from "../../assets/images/Group 10000048071.png";
import copy from "../../assets/images/Header/copy-03.svg";
import wallet1 from "../../assets/images/Header/Wallet.svg";
import user from "../../assets/images/Header/Group.svg";
import logout from "../../assets/images/Header/log-out-03.svg";
import cross from "../../assets/images/Header/Group 1000004790.svg";
import noti from "../../assets/images/Header/notification-bing.svg";
import noti1 from "../../assets/images/Header/Group 1000004810.svg";
import messageIcon from "../../assets/images/Header/messageIcon.svg";

import arrowdown from "../../assets/images/Header/arrowToDown.svg";
import arrowtop from "../../assets/images/Header/arrowToTop.svg";

import keplrIcon from "../../assets/images/ConnectWallet/image 2228.svg";
import leapIcon from "../../assets/images/ConnectWallet/leap.svg";

import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";

import { disconnect } from "../../Actions/AuthSlice";
import { setHeaderMode } from "../../Actions/HeaderSlice";

import { Link } from "react-router-dom";

import { SelectionGroup, SelectionItem } from "../Global/Selection";
import { TransitionCompo } from "../TransitionCompo";
import { setPage } from "../../Actions/PageSlice";
import { setDashboardMode } from "../../Actions/DashboardSlice";
import {
  setChat,
  setChatMode,
  setChatReceiver,
  setChatNft,
  setHoldFlag,
} from "../../Actions/ChatSlice";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Testate } from "../Global/TestateLogo";
import { NumericFormat } from "react-number-format";
import { queryBalance } from "../functional/InteractToContract";
import { checkOwner } from "../functional/InteractToIPFS";
import { truncateWalletAddress } from "../functional/Control";
import { Notification } from "./Notification";
import { Popover } from "react-tiny-popover";
import { Fade } from "react-awesome-reveal";
import { Mainnet } from "@nibiruchain/nibijs";

const mainNet = Mainnet();

export const Header = () => {
  const [show, setShow] = useState(false);
  const [showNoti, setShowNoti] = useState(false);
  const [showDash, setShowDash] = useState(false);

  const handleShowNoti = () => setShowNoti(true);
  const handleCloseNoti = () => setShowNoti(false);

  const handleShowDash = () => setShowDash(true);
  const handleCloseDash = () => setShowDash(false);

  const [walletIcon, setWalletIcon] = useState();
  const [tokenBalance, setTokenBalance] = useState(0);

  // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const account = useSelector((state) => state.auth.account);
  const wallet = useSelector((state) => state.auth.wallet);

  const mode = useSelector((state) => state.header.mode);
  const submode = useSelector((state) => state.header.submode);
  const allNFTs = useSelector((state) => state.nft.allNFTs);

  const notificationContent = useSelector((state) => state.notification.items);

  const [placeholderText, setPlaceholderText] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setCurrentWalletIcon = () => {
    if (wallet == "keplr") setWalletIcon(keplrIcon);
    if (wallet == "leap") setWalletIcon(leapIcon);
  };

  const [showDashDropdown, setShowDashDropdown] = useState(false);
  const [showRentDropdown, setShowRentDropdown] = useState(false);

  const getBalance = async () => {
    try {
      const res = await queryBalance(
        account,
        process.env.REACT_APP_TESTATE_DENOM,
        mainNet.endptTm
      );
      setTokenBalance((res.amount / 1000000).toFixed(2));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // if (!isAuthenticated) navigate("/landing");
    getBalance();
    setCurrentWalletIcon();
  }, []);

  useEffect(() => {
    if (account)
      setPlaceholderText(
        account?.substring(0, 10) +
          "..." +
          account?.substring(account?.length - 10)
      );
  }, [account]);

  useEffect(() => {
    if (placeholderText === "Copied to clipboard") {
      setTimeout(() => {
        setPlaceholderText(
          account?.substring(0, 10) +
            "..." +
            account?.substring(account?.length - 10)
        );
      }, 1000);
    }
  }, [placeholderText]);

  const handleClose = (e) => {
    for (
      let i = 0;
      i < document.getElementsByClassName("z-[120]").length;
      i++
    ) {
      document
        .getElementsByClassName("z-[120]")
        [i].classList.replace("z-[120]", "z-[1200]");
    }

    setShow(false);
  };
  const handleShow = (e) => {
    for (
      let i = 0;
      i < document.getElementsByClassName("z-[1200]").length;
      i++
    ) {
      document
        .getElementsByClassName("z-[1200]")
        [i].classList.replace("z-[1200]", "z-[120]");
    }

    setShow(true);
  };

  return (
    <div className="relative w-full">
      <div className="w-full h-[60px] items-center grid grid-cols-3 justify-items-center px-[20px] bg-white rounded-t-lg fixed top-0 font-semibold z-[1200]">
        <img alt="" src={logo} width={130} className="justify-self-start"></img>
        <div className="relative">
          <div
            className="bg-[#5B1DEE] absolute top-[-4px] left-[-13vw] cursor-pointer px-[18px] py-[10px] text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] font-semibold hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)] hover:-translate-y-[3px]"
            onClick={() => {
              // dispatch(setPage("claim"));
              // dispatch(setHeaderMode({ mode: 4, submode: null }));
              navigate("/claim");
            }}
          >
            <div>Claim $TESTATE</div>
          </div>
          <SelectionGroup
            className="px-[6px] py-[4px] rounded-[14px] shadow-md flex gap-[10px] text-[#959595]"
            defaultItem={mode}
            SelectedItemMask="shadow-md rounded-[10px]"
          >
            <SelectionItem
              disabled={true}
              SelectedItem={
                <div className="w-[130px] flex justify-between py-[2px] px-[40px] items-center gap-[10px]">
                  <div className="text-[#202020]">Buy</div>
                  <img alt="" src={light}></img>
                </div>
              }
              UnselectedItem={
                <div
                  className="w-[130px] flex justify-between py-[2px] px-[50px] items-center gap-[10px] hover:bg-[#efefef] rounded-[10px]"
                  // onClick={() =>
                  //   dispatch(setHeaderMode({ mode: 2, submode: null }))
                  // }
                  // data-tip
                  // data-for="comingsoon"
                >
                  <div>Buy</div>
                </div>
              }
            />
            <SelectionItem
              disabled={true}
              SelectedItem={
                <div
                  onMouseEnter={() => setShowRentDropdown(true)}
                  onMouseLeave={() => setShowRentDropdown(false)}
                  className="relative text-[#202020]"
                >
                  <div className="w-[130px] flex justify-between pr-[15px] pl-[46px] py-[2px] items-center gap-[10px]">
                    <div className="">Rent</div>
                    <img alt="" src={light}></img>
                  </div>

                  <div
                    className="absolute top-[0px] w-[130px] bg-white shadow-md rounded-[10px] font-normal"
                    hidden={!showRentDropdown}
                    // hidden
                  >
                    <div className="w-[130px] flex justify-between px-[15px] py-[2px] items-center gap-[10px]">
                      <div className="font-semibold">Rent</div>
                      <img alt="" src={arrowtop}></img>
                    </div>

                    <div
                      className="pl-[15px] py-[3px] cursor-pointer hover:bg-[#efefef]"
                      // onClick={() => {
                      //   dispatch(setHeaderMode({ mode: 1, submode: 0 }));
                      //   setShowRentDropdown(false);
                      //   dispatch(setPage(null));
                      // }}
                    >
                      Long-term
                    </div>
                    <div
                      className="pl-[15px] py-[3px] cursor-pointer hover:bg-[#efefef] rounded-b-[10px]"
                      // onClick={() => {
                      //   dispatch(setHeaderMode({ mode: 1, submode: 1 }));
                      //   dispatch(setPage(null));
                      //   setShowRentDropdown(false);
                      // }}
                    >
                      Short-term
                    </div>
                  </div>
                </div>
              }
              UnselectedItem={
                <div
                  onMouseEnter={() => setShowRentDropdown(true)}
                  onMouseLeave={() => setShowRentDropdown(false)}
                  className="relative text-[#202020]"
                >
                  <div className="w-[130px] flex justify-between pr-[15px] pl-[46px] py-[2px] items-center gap-[10px] hover:bg-[#efefef] rounded-[10px]">
                    <div className="text-[#959595]">Rent</div>
                    <img alt="" src={arrowdown}></img>
                  </div>

                  <div
                    className="absolute top-[0px] w-[130px] bg-white shadow-md rounded-[10px] font-normal"
                    hidden={!showRentDropdown}
                    // hidden
                  >
                    <div className="w-[130px] flex justify-between px-[15px] py-[2px] items-center gap-[10px]">
                      <div className="font-semibold">Rent</div>
                      <img alt="" src={arrowtop}></img>
                    </div>

                    <div
                      className="pl-[15px] py-[3px] cursor-pointer hover:bg-[#efefef]"
                      // onClick={() => {
                      //   dispatch(setHeaderMode({ mode: 1, submode: 0 }));
                      //   setShowRentDropdown(false);
                      //   dispatch(setPage(null));
                      // }}
                    >
                      Long-term
                    </div>
                    <div
                      className="pl-[15px] py-[3px] cursor-pointer hover:bg-[#efefef] rounded-b-[10px]"
                      // onClick={() => {
                      //   dispatch(setHeaderMode({ mode: 1, submode: 1 }));
                      //   dispatch(setPage(null));
                      //   setShowRentDropdown(false);
                      // }}
                    >
                      Short-term
                    </div>
                  </div>
                </div>
              }
            />

            <SelectionItem
              disabled={true}
              SelectedItem={
                <div className="w-[130px] flex justify-between py-[2px] px-[4px] items-center gap-[10px]">
                  <div className="text-[#202020]">Trade</div>
                  <img alt="" src={light}></img>
                </div>
              }
              UnselectedItem={
                <div
                  className="w-[130px] flex justify-between py-[2px] px-[40px] items-center gap-[10px] hover:bg-[#efefef] rounded-[10px]"
                  // onClick={() =>
                  //   dispatch(setHeaderMode({ mode: 2, submode: null }))
                  // }
                >
                  <div className="">Trade</div>
                </div>
              }
            />
            <SelectionItem
              SelectedItem={
                <div className="w-[130px] flex justify-between py-[2px] px-[20px] items-center gap-[10px] rounded-[10px]">
                  <div className="text-[#202020]">YieldEstate</div>
                  <img alt="" src={light}></img>
                </div>
              }
              UnselectedItem={
                <div
                  className="w-[130px] flex justify-between py-[2px] px-[20px] items-center gap-[10px] hover:bg-[#efefef] rounded-[10px]"
                  onClick={() =>
                    dispatch(setHeaderMode({ mode: 3, submode: null }))
                  }
                >
                  <div>YieldEstate</div>
                </div>
              }
            />
            <SelectionItem
              disabled={true}
              SelectedItem={
                <div
                  onMouseEnter={() => setShowDashDropdown(true)}
                  onMouseLeave={() => setShowDashDropdown(false)}
                  // onClick={() => navigate("/dashboard")}
                  className="relative text-[#202020]"
                >
                  <div className="w-[130px] flex justify-between px-[12px] py-[2px] items-center gap-[10px]">
                    <div className="text-[#202020]">Dashboard</div>
                    <img alt="" src={light}></img>
                  </div>
                  <div
                    className="absolute top-[0px] w-[130px] bg-white shadow-md rounded-[10px] font-normal"
                    hidden={!showDashDropdown}
                  >
                    <div className="w-[130px] flex justify-between px-[12px] py-[2px] items-center gap-[10px]">
                      <div className="font-semibold">Dashboard</div>
                      <img alt="" src={arrowtop}></img>
                    </div>

                    <div
                      className="pl-[15px] py-[3px] hover:bg-[#efefef]"
                      onClick={() => {
                        dispatch(setHeaderMode({ mode: 4, submode: 0 }));
                        setShowDashDropdown(false);
                      }}
                      // onMouseEnter={(e) => {
                      //   e.target.innerText = "coming soon";
                      // }}
                      // onMouseLeave={(e) => {
                      //   e.target.innerText = "YieldEstate";
                      // }}
                    >
                      YieldEstate
                    </div>

                    <div
                      className="pl-[15px] py-[3px] cursor-not-allowed hover:bg-[#efefef]"
                      // onClick={() => {
                      //   dispatch(setHeaderMode({ mode: 4, submode: 1 }));
                      //   setShowDashDropdown(false);
                      // }}
                      onMouseEnter={(e) => {
                        e.target.innerText = "coming soon";
                      }}
                      onMouseLeave={(e) => {
                        e.target.innerText = "Landord Mode";
                      }}
                    >
                      Landlord Mode
                    </div>
                    <div
                      className="pl-[15px] py-[3px] cursor-not-allowed hover:bg-[#efefef]"
                      // onClick={() => {
                      //   dispatch(setHeaderMode({ mode: 4, submode: 2 }));
                      //   setShowDashDropdown(false);
                      // }}
                      onMouseEnter={(e) => {
                        e.target.innerText = "coming soon";
                      }}
                      onMouseLeave={(e) => {
                        e.target.innerText = "Tenant Mode";
                      }}
                    >
                      Tenant Mode
                    </div>
                    <div
                      className="pl-[15px] py-[3px] cursor-pointer hover:bg-[#efefef]"
                      // onClick={() => {
                      //   dispatch(setHeaderMode({ mode: 4, submode: 3 }));
                      //   setShowDashDropdown(false);
                      // }}
                      onMouseEnter={(e) => {
                        e.target.innerText = "coming soon";
                      }}
                      onMouseLeave={(e) => {
                        e.target.innerText = "Host Mode";
                      }}
                    >
                      Host Mode
                    </div>
                    <div
                      className="pl-[15px] py-[3px] cursor-pointer hover:bg-[#efefef]"
                      // onClick={() => {
                      //   dispatch(setHeaderMode({ mode: 4, submode: 4 }));
                      //   setShowDashDropdown(false);
                      // }}
                      onMouseEnter={(e) => {
                        e.target.innerText = "coming soon";
                      }}
                      onMouseLeave={(e) => {
                        e.target.innerText = "Traveler Mode";
                      }}
                    >
                      Traveler Mode
                    </div>
                    <div
                      className="pl-[15px] py-[3px] cursor-not-allowed hover:bg-[#efefef]"
                      onClick={() => {
                        // dispatch(setHeaderMode({ mode: 3, submode: 0 }));
                        // setShowDashDropdown(false);
                      }}
                      onMouseEnter={(e) => {
                        e.target.innerText = "coming soon";
                      }}
                      onMouseLeave={(e) => {
                        e.target.innerText = "Buy Mode";
                      }}
                    >
                      Buy Mode
                    </div>
                    <div
                      className="pl-[15px] py-[3px] cursor-not-allowed hover:bg-[#efefef] rounded-b-[10px]"
                      onClick={() => {
                        // dispatch(setHeaderMode({ mode: 3, submode: 1 }));
                        // setShowDashDropdown(false);
                      }}
                      onMouseEnter={(e) => {
                        e.target.innerText = "coming soon";
                      }}
                      onMouseLeave={(e) => {
                        e.target.innerText = "Sell Mode";
                      }}
                    >
                      Sell Mode
                    </div>
                  </div>
                </div>
              }
              UnselectedItem={
                <div
                  onMouseEnter={() => setShowDashDropdown(true)}
                  onMouseLeave={() => setShowDashDropdown(false)}
                  className="relative text-[#202020]"
                >
                  <div className="w-[130px] flex justify-between px-[12px] py-[2px] items-center gap-[10px]">
                    <div className="text-[#959595]">Dashboard</div>
                    <img alt="" src={arrowdown}></img>
                  </div>
                  <div
                    className="absolute top-[0px] w-[130px] bg-white shadow-md rounded-[10px] font-normal"
                    hidden={!showDashDropdown}
                  >
                    <div className="w-[130px] flex justify-between px-[12px] py-[2px] items-center gap-[10px]">
                      <div className="font-semibold">Dashboard</div>
                      <img alt="" src={arrowtop}></img>
                    </div>
                    {/* <div className="pl-[12px] text-[14px] text-[#B6B6B6]">
                          Short-term Rental
                        </div> */}
                    <div
                      className="pl-[15px] py-[3px] hover:bg-[#efefef]"
                      onClick={() => {
                        dispatch(setHeaderMode({ mode: 4, submode: 0 }));
                        // dispatch()
                        setShowDashDropdown(false);
                      }}
                      // onMouseEnter={(e) => {
                      //   e.target.innerText = "coming soon";
                      // }}
                      // onMouseLeave={(e) => {
                      //   e.target.innerText = "YieldEstate";
                      // }}
                    >
                      YieldEstate
                    </div>

                    <div
                      className="pl-[15px] py-[3px] cursor-not-allowed hover:bg-[#efefef]"
                      // onClick={() => {
                      //   dispatch(setHeaderMode({ mode: 4, submode: 1 }));
                      //   setShowDashDropdown(false);
                      // }}
                      onMouseEnter={(e) => {
                        e.target.innerText = "coming soon";
                      }}
                      onMouseLeave={(e) => {
                        e.target.innerText = "Landlord Mode";
                      }}
                    >
                      Landlord Mode
                    </div>
                    <div
                      className="pl-[15px] py-[3px] cursor-not-allowed hover:bg-[#efefef]"
                      // onClick={() => {
                      //   dispatch(setHeaderMode({ mode: 4, submode: 2 }));
                      //   setShowDashDropdown(false);
                      // }}
                      onMouseEnter={(e) => {
                        e.target.innerText = "coming soon";
                      }}
                      onMouseLeave={(e) => {
                        e.target.innerText = "Tenant Mode";
                      }}
                    >
                      Tenant Mode
                    </div>
                    <div
                      className="pl-[15px] py-[3px] cursor-pointer hover:bg-[#efefef]"
                      // onClick={() => {
                      //   dispatch(setHeaderMode({ mode: 4, submode: 3 }));
                      //   setShowDashDropdown(false);
                      // }}
                      onMouseEnter={(e) => {
                        e.target.innerText = "coming soon";
                      }}
                      onMouseLeave={(e) => {
                        e.target.innerText = "Host Mode";
                      }}
                    >
                      Host Mode
                    </div>
                    <div
                      className="pl-[15px] py-[3px] cursor-pointer hover:bg-[#efefef]"
                      // onClick={() => {
                      //   dispatch(setHeaderMode({ mode: 4, submode: 4 }));
                      //   setShowDashDropdown(false);
                      // }}
                      onMouseEnter={(e) => {
                        e.target.innerText = "coming soon";
                      }}
                      onMouseLeave={(e) => {
                        e.target.innerText = "Traveler Mode";
                      }}
                    >
                      Traveler Mode
                    </div>
                    <div
                      className="pl-[15px] py-[3px] cursor-not-allowed hover:bg-[#efefef]"
                      onClick={() => {
                        // dispatch(setHeaderMode({ mode: 3, submode: 0 }));
                        // setShowDashDropdown(false);
                      }}
                      onMouseEnter={(e) => {
                        e.target.innerText = "coming soon";
                      }}
                      onMouseLeave={(e) => {
                        e.target.innerText = "Buy Mode";
                      }}
                    >
                      Buy Mode
                    </div>
                    <div
                      className="pl-[15px] py-[3px] cursor-not-allowed hover:bg-[#efefef] rounded-b-[10px]"
                      onClick={() => {
                        // dispatch(setHeaderMode({ mode: 3, submode: 1 }));
                        // setShowDashDropdown(false);
                      }}
                      onMouseEnter={(e) => {
                        e.target.innerText = "coming soon";
                      }}
                      onMouseLeave={(e) => {
                        e.target.innerText = "Sell Mode";
                      }}
                    >
                      Sell Mode
                    </div>
                  </div>
                </div>
              }
            />
            {/* <SelectionItem
                SelectedItem={
                  <div className="w-[160px] flex justify-between py-[2px] px-[10px] items-center gap-[10px]">
                    <div className="text-[#202020]">Claim $TESTATE</div>
                    <img alt=""  src={light} ></img>
                  </div>
                }
                UnselectedItem={
                  <div
                    className="w-[160px] flex justify-between py-[2px] px-[10px] items-center gap-[10px] hover:bg-[#efefef] rounded-[10px]"
                    onClick={() => {
                      dispatch(setPage("claim"));
                      dispatch(setHeaderMode({ mode: 4, submode: null }));
                    }}
                  >
                    <div>Claim $TESTATE</div>
                  </div>
                }
              /> */}
          </SelectionGroup>
          <div className="flex items-center gap-[10px] absolute right-[-13vw] top-[-10px]">
            <img
              alt=""
              src={frame1}
              className="cursor-pointer hover:-translate-y-[3px] transition"
              data-tip
              data-for="comingsoon"
            ></img>

            <Popover
              isOpen={showNoti}
              positions={["bottom"]}
              onClickOutside={handleCloseNoti}
              content={
                <Fade direction="down" duration={500}>
                  <div className="bg-white shadow-lg rounded-[20px] w-[320px] h-[500px] scrollbarwidth p-[8px] flex flex-col overflow-auto">
                    <div className="font-semibold my-[10px] ml-[10px]">
                      Notifications
                    </div>

                    <Notification setShowNoti={setShowNoti} />
                  </div>
                </Fade>
              }
            >
              <div className="relative cursor-pointer hover:-translate-y-[3px] transition">
                {notificationContent.length ? (
                  <div className="absolute top-[10px] right-[10px] bg-red-500 rounded-full w-[10px] h-[10px]"></div>
                ) : (
                  <></>
                )}

                <img
                  alt=""
                  src={frame}
                  onClick={handleShowNoti}
                  className=""
                ></img>
              </div>
            </Popover>
          </div>
        </div>
        <div className="flex items-center gap-[10px] justify-self-end">
          <div
            className="bg-[#5B1DEE] cursor-pointer px-[18px] py-[10px] text-white shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-[16px] font-semibold hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)] hover:-translate-y-[3px]"
            onClick={handleShow}
          >
            {account ? truncateWalletAddress(account, 5, 4) : "Connect Wallet"}
          </div>
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          dialogClassName="_modal-dialog __modal-dialog"
        >
          <Modal.Body>
            <div className="relative h-max pt-[40px]">
              <img
                alt=""
                src={cross}
                onClick={handleClose}
                className="cursor-pointer top-[10px] right-[10px] w-[40px] absolute"
              ></img>
              <div className="space-y-[20px] px-[20px] pb-[20px] w-[350px]">
                <img
                  alt=""
                  src={walletIcon}
                  className="mx-auto w-[100px]"
                ></img>
                <div className="globalInputForm rounded-[8px] flex w-full px-[12px] py-[9px] gap-[6px]">
                  <input
                    placeholder={placeholderText}
                    className="w-full"
                    disabled
                  ></input>
                  <CopyToClipboard
                    text={account}
                    onCopy={() => setPlaceholderText("Copied to clipboard")}
                  >
                    <img alt="" src={copy} className="cursor-pointer"></img>
                  </CopyToClipboard>
                </div>

                <div className="globalInputForm rounded-full w-full py-[8px] px-[20px] flex items-center gap-[10px]">
                  {/* <div className="text-[#B6B6B6]">Wallet</div> */}
                  <div className="flex items-center gap-[10px] text-[18px] ">
                    <Testate size={24} />

                    <div className="text-[#5B1DEE] font-semibold">
                      <NumericFormat
                        value={tokenBalance}
                        thousandSeparator={true}
                        displayType="text"
                      />
                    </div>
                    <div className="text-[#959595]">TESTATE</div>
                  </div>
                </div>

                {/* <div className="grid grid-cols-2 gap-[20px]">
                  <Link to="/wallet">
                    <div
                      className="border-[1px] border-[#FFFFFF] cursor-pointer gap-[4px] px-[18px] py-[10px] shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-full font-semibold hover:shadow-[-1px_6px_10px_0_rgba(80,80,80,0.5)] hover:-translate-y-[3px] flex items-center"
                      onClick={handleClose}
                    >
                      <img alt="" src={wallet1}></img>
                      <div className="text-[#202020]">Wallet</div>
                    </div>
                  </Link>
                  <Link to="/account">
                    <div
                      className="border-[1px] border-[#FFFFFF]  cursor-pointer gap-[4px] px-[18px] py-[10px] shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-full font-semibold hover:shadow-[-1px_6px_10px_0_rgba(80,80,80,0.5)] hover:-translate-y-[3px] flex items-center"
                      onClick={handleClose}
                    >
                      <img alt="" src={user}></img>
                      <div className="text-[#202020]">Account</div>
                    </div>
                  </Link>
                </div> */}

                <div
                  className="bg-[#202020] gap-[10px] py-[12px]  text-white w-full text-center font-semibold flex items-center justify-center  cursor-pointer px-[18px] shadow-[-1px_3px_3px_0_rgba(80,80,80,0.2)] rounded-full  hover:shadow-[-1px_6px_10px_0_rgba(80,80,80,0.5)] hover:-translate-y-[3px]"
                  onClick={() => {
                    dispatch(setHeaderMode({ mode: null }));
                    dispatch(disconnect());
                  }}
                >
                  <img alt="" src={logout}></img>
                  <div>Disconnect</div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};
