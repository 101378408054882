import filter from "../../assets/images/Filter.png";
import expand from "../../assets/images/Frame 1000005236.png";
import shrink from "../../assets/images/Frame 1000005236 - Copy.png";

import arrowtoleft from "../../assets/images/sidebar/arrowToLeft.svg";

import dashboardactive from "../../assets/images/sidebar/dashboard-active.svg";
import dashboardinactive from "../../assets/images/sidebar/dashboard-inactive.svg";
import reservationactive from "../../assets/images/sidebar/reservations-active.svg";
import reservationinactive from "../../assets/images/sidebar/reservations-inactive.svg";
import listingactive from "../../assets/images/sidebar/listing-active.png";
import listinginactive from "../../assets/images/sidebar/listing-inactive.png";

import insightsactive from "../../assets/images/sidebar/insights-active.svg";

import insightsinactive from "../../assets/images/sidebar/insights-inactive.png";
import calendarinactive from "../../assets/images/sidebar/calendar-inactive.png";
import calendaractive from "../../assets/images/sidebar/calendar-active.png";
import inboxinactive from "../../assets/images/sidebar/inbox-inactive.svg";
import inboxactive from "../../assets/images/sidebar/inbox-active.svg";
import tenantactive from "../../assets/images/sidebar/tenant-active.png";
import tenantinactive from "../../assets/images/sidebar/tenant-inactive.png";
import nftinactive from "../../assets/images/sidebar/nft-inactive.svg";
import nftactive from "../../assets/images/sidebar/nft-active.svg";
import portfolioinactive from "../../assets/images/sidebar/portfolio-inactive.png";
import portfolioactive from "../../assets/images/sidebar/portfolio-active.png";
import tripsinactive from "../../assets/images/sidebar/trips-inactive.png";
import tripsactive from "../../assets/images/sidebar/trips-active.png";
import wishlistinactive from "../../assets/images/sidebar/wishlist-inactive.png";
import wishlistactive from "../../assets/images/sidebar/wishlist-active.png";
import rentalinactive from "../../assets/images/sidebar/rental-inactive.png";
import rentalactive from "../../assets/images/sidebar/rental-active.png";

import propertyactive from "../../assets/images/sidebar/property-active.png";
import propertyinactive from "../../assets/images/sidebar/property-inactive.png";
import walletactive from "../../assets/images/sidebar/wallet-active.png";
import walletinactive from "../../assets/images/sidebar/wallet-inactive.png";
import rewardactive from "../../assets/images/sidebar/reward-active.png";
import rewardinactive from "../../assets/images/sidebar/reward-inactive.png";
import cartactive from "../../assets/images/sidebar/cart-active.png";
import cartinactive from "../../assets/images/sidebar/cart-inactive.png";
import transactionactive from "../../assets/images/sidebar/transaction-active.png";
import transactioninactive from "../../assets/images/sidebar/transaction-inactive.png";

import light from "../../assets/images/light.svg";

import host from "../../assets/images/host.svg";
import traveler from "../../assets/images/airplane-plane-big.svg";

import { SelectionGroup, SelectionItem } from "../Global/Selection";

import landlord from "../../assets/images/sidebar/home-user.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setDashboardMode } from "../../Actions/DashboardSlice";
import { setHeaderMode } from "../../Actions/HeaderSlice";
import { useEffect, useState } from "react";

export const SideBar = () => {
  const mode = useSelector((state) => state.dashboard.mode);
  const dispatch = useDispatch();

  const headerSubmode = useSelector((state) => state.header.submode);

  const [width, setWidth] = useState(300);

  const [collapse, setCollapse] = useState(false);

  useEffect(() => {
    if (collapse) setWidth(90);
    else setWidth(300);
  }, [collapse]);

  return (
    <div className="h-[calc(100vh-70px)]">
      {/* <div className="w-[70px] bg-white rounded-[8px] grid justify-items-center h-full relative">
        <img alt=""  className="mt-[20px]" src={filter}></img>

        <img alt=""  className="absolute right-[-20px] top-[10px]" src={expand}></img>
      </div> */}
      {/* <div className="w-[300px] bg-white rounded-[8px] grid h-full p-[10px] relative">
        <img alt=""  className="absolute right-[-20px] top-[10px]" src={shrink}></img>

        <div className="">
          <div className="flex m-[10px] items-center">
            <img alt=""  src={filter}></img>
            <div>Filter</div>
          </div>
          <div className="w-full border-2 rounded-[10px] p-[10px]">
            <div className="rounded-[10px] w-full shadow-md p-[10px] text-[#5B1DEE]">
              Price
            </div>
            <div className="rounded-[10px] w-full shadow-md p-[10px]">
              <div>Min</div>
            </div>
          </div>
        </div>
      </div> */}

      <div
        className={`w-[${width}px] bg-white rounded-[8px] h-full p-[10px] flex flex-col justify-between `}
      >
        <div className="space-y-[10px] h-max">
          <div
            className={
              collapse
                ? "flex items-center justify-center"
                : "flex items-center justify-between"
            }
          >
            {collapse ? (
              <></>
            ) : (
              <>
                {headerSubmode === 1 ? (
                  <div className="flex items-center gap-[10px]">
                    <img alt="" src={landlord}></img>
                    <div className="text-[18px]">Landlord’s Dashboard</div>
                  </div>
                ) : (
                  <></>
                )}
                {headerSubmode === 0 ? (
                  <div className="flex items-center gap-[10px]">
                    <img alt="" src={landlord}></img>
                    <div className="text-[18px]">YieldEstate</div>
                  </div>
                ) : (
                  <></>
                )}
                {headerSubmode === 2 ? (
                  <div className="flex items-center gap-[10px]">
                    <img alt="" src={landlord}></img>
                    <div className="text-[18px]">Tenant's Dashboard</div>
                  </div>
                ) : (
                  <></>
                )}
                {headerSubmode === 3 ? (
                  <div className="flex items-center gap-[10px]">
                    <img alt="" src={host}></img>
                    <div className="text-[18px]">Host's Dashboard</div>
                  </div>
                ) : (
                  <></>
                )}
                {headerSubmode === 4 ? (
                  <div className="flex items-center gap-[10px]">
                    <img alt="" src={traveler}></img>
                    <div className="text-[18px]">Traveler's Dashboard</div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}

            <img
              alt=""
              src={arrowtoleft}
              onClick={() => setCollapse(!collapse)}
              className="cursor-pointer"
            ></img>
          </div>
          {headerSubmode === 1 ? (
            <SelectionGroup
              defaultItem={mode}
              className={""}
              SelectedItemMask="rounded-[16px] shadow-md bg-[#F6F6F6]"
            >
              <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px]">
                    <div className="flex items-center">
                      <img alt="" src={dashboardactive}></img>
                      {collapse ? <></> : <div>Dashboard</div>}
                    </div>
                    {collapse ? <></> : <img alt="" src={light}></img>}
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(0))}
                  >
                    <div className="flex items-center">
                      <img alt="" src={dashboardinactive}></img>
                      {collapse ? (
                        <></>
                      ) : (
                        <div className="text-[#959595]">Dashboard</div>
                      )}
                    </div>
                  </div>
                }
              />
              <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px] rounded-[16px] shadow-md bg-[#F6F6F6]">
                    <div className="flex items-center">
                      <img alt="" src={tenantactive}></img>
                      {collapse ? <></> : <div>Tenant Overview</div>}
                    </div>
                    {collapse ? <></> : <img alt="" src={light}></img>}
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(1))}
                  >
                    <div className="flex items-center">
                      <img alt="" src={tenantinactive}></img>
                      {collapse ? (
                        <></>
                      ) : (
                        <div className="text-[#959595]">Tenant Overview</div>
                      )}
                    </div>
                  </div>
                }
              />
              <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px] rounded-[16px] shadow-md bg-[#F6F6F6]">
                    <div className="flex items-center">
                      <img alt="" src={nftactive}></img>
                      {collapse ? <></> : <div>My Real Estate NFTs</div>}
                    </div>
                    {collapse ? <></> : <img alt="" src={light}></img>}
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(2))}
                  >
                    <div className="flex items-center">
                      <img alt="" src={nftinactive}></img>
                      {collapse ? (
                        <></>
                      ) : (
                        <div className="text-[#959595]">
                          My Real Estate NFTs
                        </div>
                      )}
                    </div>
                  </div>
                }
              />
              <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px] rounded-[16px] shadow-md bg-[#F6F6F6]">
                    <div className="flex items-center">
                      <img alt="" src={transactionactive}></img>

                      {collapse ? <></> : <div>Transaction</div>}
                    </div>
                    {collapse ? <></> : <img alt="" src={light}></img>}
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(3))}
                  >
                    <div className="flex items-center">
                      <img alt="" src={transactioninactive}></img>
                      {collapse ? (
                        <></>
                      ) : (
                        <div className="text-[#959595]">Transaction</div>
                      )}
                    </div>
                  </div>
                }
              />
              {/* <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px] rounded-[16px] shadow-md bg-[#F6F6F6]">
                    <div className="flex items-center">
                      <img alt=""  src={insightsactive}></img>
                      <div>Insights</div>
                    </div>

                    <img alt=""  src={light}></img>
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(4))}
                  >
                    <div className="flex items-center">
                      <img alt=""  src={insightsinactive}></img>
                      <div className="text-[#959595]">Insights</div>
                    </div>
                  </div>
                }
              />
              <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px] rounded-[16px] shadow-md bg-[#F6F6F6]">
                    <div className="flex items-center">
                      <img alt=""  src={calendaractive}></img>
                      <div>Calendar</div>
                    </div>

                    <img alt=""  src={light}></img>
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(5))}
                  >
                    <div className="flex items-center">
                      <img alt=""  src={calendarinactive}></img>
                      <div className="text-[#959595]">Calendar</div>
                    </div>
                  </div>
                }
              /> */}
              <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px]">
                    <div className="flex items-center">
                      <img alt="" src={inboxactive}></img>
                      {collapse ? <></> : <div>Inbox</div>}
                    </div>
                    {collapse ? <></> : <img alt="" src={light}></img>}
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(4))}
                  >
                    <div className="flex items-center">
                      <img alt="" src={inboxinactive}></img>
                      {collapse ? (
                        <></>
                      ) : (
                        <div className="text-[#959595]">Inbox</div>
                      )}
                    </div>
                  </div>
                }
              />
              {/* <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px] rounded-[16px] shadow-md bg-[#F6F6F6]">
                    <div className="flex items-center">
                      <img alt=""  src={portfolioactive}></img>
                      <div>Portfolio</div>
                    </div>

                    <img alt=""  src={light}></img>
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(7))}
                  >
                    <div className="flex items-center">
                      <img alt=""  src={portfolioinactive}></img>
                      <div className="text-[#959595]">Portfolio</div>
                    </div>
                  </div>
                }
              /> */}
            </SelectionGroup>
          ) : (
            <></>
          )}
          {headerSubmode === 2 ? (
            <SelectionGroup
              defaultItem={mode}
              SelectedItemMask="rounded-[16px] shadow-md bg-[#F6F6F6]"
            >
              <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px]">
                    <div className="flex items-center">
                      <img alt="" src={dashboardactive}></img>
                      {collapse ? <></> : <div>Dashboard</div>}
                    </div>
                    {collapse ? <></> : <img alt="" src={light}></img>}
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(0))}
                  >
                    <div className="flex items-center">
                      <img alt="" src={dashboardinactive}></img>
                      {collapse ? (
                        <></>
                      ) : (
                        <div className="text-[#959595]">Dashboard</div>
                      )}
                    </div>
                  </div>
                }
              />
              <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px]">
                    <div className="flex items-center">
                      <img alt="" src={rentalactive}></img>
                      {collapse ? <></> : <div>My Rental</div>}
                    </div>
                    {collapse ? <></> : <img alt="" src={light}></img>}
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(1))}
                  >
                    <div className="flex items-center">
                      <img alt="" src={rentalinactive}></img>
                      {collapse ? (
                        <></>
                      ) : (
                        <div className="text-[#959595]">My Rental</div>
                      )}
                    </div>
                  </div>
                }
              />
              <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px]">
                    <div className="flex items-center">
                      <img alt="" src={transactionactive}></img>
                      {collapse ? <></> : <div>Transaction</div>}
                    </div>
                    {collapse ? <></> : <img alt="" src={light}></img>}
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(2))}
                  >
                    <div className="flex items-center">
                      <img alt="" src={transactioninactive}></img>
                      {collapse ? (
                        <></>
                      ) : (
                        <div className="text-[#959595]">Transaction</div>
                      )}
                    </div>
                  </div>
                }
              />
              {/* <SelectionItem
              SelectedItem={
                <div className="flex items-center justify-between px-[14px] py-[8px] rounded-[16px] shadow-md bg-[#F6F6F6]">
                  <div className="flex items-center">
                    <img alt=""  src={tripsactive}></img>
                    <div>Trips</div>
                  </div>

                  <img alt=""  src={light}></img>
                </div>
              }
              UnselectedItem={
                <div
                  className="flex items-center justify-between px-[14px] py-[8px]"
                  onClick={() => dispatch(setDashboardMode(3))}
                >
                  <div className="flex items-center">
                    <img alt=""  src={tripsinactive}></img>
                    <div className="text-[#959595]">Trips</div>
                  </div>
                </div>
              }
            />
            <SelectionItem
              SelectedItem={
                <div className="flex items-center justify-between px-[14px] py-[8px] rounded-[16px] shadow-md bg-[#F6F6F6]">
                  <div className="flex items-center">
                    <img alt=""  src={wishlistactive}></img>
                    <div>Wishlist</div>
                  </div>

                  <img alt=""  src={light}></img>
                </div>
              }
              UnselectedItem={
                <div
                  className="flex items-center justify-between px-[14px] py-[8px]"
                  onClick={() => dispatch(setDashboardMode(4))}
                >
                  <div className="flex items-center">
                    <img alt=""  src={wishlistinactive}></img>
                    <div className="text-[#959595]">Wishlist</div>
                  </div>
                </div>
              }
            /> */}

              <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px]">
                    <div className="flex items-center">
                      <img alt="" src={inboxactive}></img>
                      {collapse ? <></> : <div>Inbox</div>}
                    </div>
                    {collapse ? <></> : <img alt="" src={light}></img>}
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(3))}
                  >
                    <div className="flex items-center">
                      <img alt="" src={inboxinactive}></img>
                      {collapse ? (
                        <></>
                      ) : (
                        <div className="text-[#959595]">Inbox</div>
                      )}
                    </div>
                  </div>
                }
              />
            </SelectionGroup>
          ) : (
            <></>
          )}
          {headerSubmode === 3 ? (
            <SelectionGroup
              defaultItem={mode}
              SelectedItemMask="rounded-[16px] shadow-md bg-[#F6F6F6]"
            >
              <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px]">
                    <div className="flex items-center">
                      <img alt="" src={dashboardactive}></img>
                      {collapse ? <></> : <div>Dashboard</div>}
                    </div>
                    {collapse ? <></> : <img alt="" src={light}></img>}
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(0))}
                  >
                    <div className="flex items-center">
                      <img alt="" src={dashboardinactive}></img>
                      {collapse ? (
                        <></>
                      ) : (
                        <div className="text-[#959595]">Dashboard</div>
                      )}
                    </div>
                  </div>
                }
              />
              <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px]">
                    <div className="flex items-center">
                      <img alt="" src={reservationactive}></img>
                      {collapse ? <></> : <div>Reservations</div>}
                    </div>
                    {collapse ? <></> : <img alt="" src={light}></img>}
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(1))}
                  >
                    <div className="flex items-center">
                      <img alt="" src={reservationinactive}></img>
                      {collapse ? (
                        <></>
                      ) : (
                        <div className="text-[#959595]">Reservations</div>
                      )}
                    </div>
                  </div>
                }
              />
              <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px]">
                    <div className="flex items-center">
                      <img alt="" src={nftactive}></img>
                      {collapse ? <></> : <div>My NFTs</div>}
                    </div>
                    {collapse ? <></> : <img alt="" src={light}></img>}
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(2))}
                  >
                    <div className="flex items-center">
                      <img alt="" src={nftinactive}></img>
                      {collapse ? (
                        <></>
                      ) : (
                        <div className="text-[#959595]">My NFTs</div>
                      )}
                    </div>
                  </div>
                }
              />
              <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px]">
                    <div className="flex items-center">
                      <img alt="" src={inboxactive}></img>
                      {collapse ? <></> : <div>Inbox</div>}
                    </div>
                    {collapse ? <></> : <img alt="" src={light}></img>}
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(3))}
                  >
                    <div className="flex items-center">
                      <img alt="" src={inboxinactive}></img>
                      {collapse ? (
                        <></>
                      ) : (
                        <div className="text-[#959595]">Inbox</div>
                      )}
                    </div>
                  </div>
                }
              />
            </SelectionGroup>
          ) : (
            <></>
          )}
          {headerSubmode === 4 ? (
            <SelectionGroup
              defaultItem={mode}
              SelectedItemMask="rounded-[16px] shadow-md bg-[#F6F6F6]"
            >
              <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px]">
                    <div className="flex items-center">
                      <img alt="" src={dashboardactive}></img>
                      {collapse ? <></> : <div>Dashboard</div>}
                    </div>
                    {collapse ? <></> : <img alt="" src={light}></img>}
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(0))}
                  >
                    <div className="flex items-center">
                      <img alt="" src={dashboardinactive}></img>
                      {collapse ? (
                        <></>
                      ) : (
                        <div className="text-[#959595]">Dashboard</div>
                      )}
                    </div>
                  </div>
                }
              />
              <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px]">
                    <div className="flex items-center">
                      <img alt="" src={tripsactive}></img>
                      {collapse ? <></> : <div>Trips</div>}
                    </div>

                    {collapse ? <></> : <img alt="" src={light}></img>}
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(1))}
                  >
                    <div className="flex items-center">
                      <img alt="" src={tripsinactive}></img>
                      {collapse ? (
                        <></>
                      ) : (
                        <div className="text-[#959595]">Trips</div>
                      )}
                    </div>
                  </div>
                }
              />
              <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px]">
                    <div className="flex items-center">
                      <img alt="" src={wishlistactive}></img>
                      {collapse ? <></> : <div>Wishlist</div>}
                    </div>
                    {collapse ? <></> : <img alt="" src={light}></img>}
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(2))}
                  >
                    <div className="flex items-center">
                      <img alt="" src={wishlistinactive}></img>
                      {collapse ? (
                        <></>
                      ) : (
                        <div className="text-[#959595]">Wishlist</div>
                      )}
                    </div>
                  </div>
                }
              />

              <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px]">
                    <div className="flex items-center">
                      <img alt="" src={inboxactive}></img>
                      {collapse ? <></> : <div>Inbox</div>}
                    </div>
                    {collapse ? <></> : <img alt="" src={light}></img>}
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(3))}
                  >
                    <div className="flex items-center">
                      <img alt="" src={inboxinactive}></img>
                      {collapse ? (
                        <></>
                      ) : (
                        <div className="text-[#959595]">Inbox</div>
                      )}
                    </div>
                  </div>
                }
              />
            </SelectionGroup>
          ) : (
            <></>
          )}
          {headerSubmode === 0 ? (
            <div className="flex items-center justify-between px-[14px] py-[8px] rounded-[16px] shadow-md bg-[#F6F6F6]">
              <div className="flex items-center">
                <img alt="" src={insightsactive}></img>
                {collapse ? <></> : <div>Portfolio</div>}
              </div>
              {collapse ? <></> : <img alt="" src={light}></img>}
            </div>
          ) : (
            <></>
          )}
        </div>

        {headerSubmode === 1 ? (
          <SelectionGroup
            className={
              collapse
                ? "p-[6px] rounded-[16px] shadow-md h-max"
                : "flex gap-[8px] p-[6px] rounded-[16px] shadow-md h-max"
            }
            defaultItem={0}
            SelectedItemMask="rounded-[10px] shadow-md"
          >
            <SelectionItem
              SelectedItem={
                <div className="flex justify-between items-center py-[6px] w-full px-[10px]">
                  <div className="flex items-center">
                    <img alt="" src={host}></img>
                    {collapse ? <></> : <div>Landlord</div>}
                  </div>

                  <img alt="" src={light}></img>
                </div>
              }
              UnselectedItem={
                <div
                  className="flex justify-between items-center py-[6px] w-full px-[10px]"
                  onClick={() => {
                    dispatch(setHeaderMode({ mode: 4, submode: 1 }));
                  }}
                >
                  <div className="flex items-center">
                    <img alt="" src={host}></img>
                    {collapse ? <></> : <div>Landlord</div>}
                  </div>
                </div>
              }
            />
            <SelectionItem
              SelectedItem={
                <div className="flex justify-between items-center py-[6px] w-full px-[10px]">
                  <div className="flex items-center">
                    <img alt="" src={traveler}></img>
                    {collapse ? <></> : <div>Tenant</div>}
                  </div>
                  <img alt="" src={light}></img>
                </div>
              }
              UnselectedItem={
                <div
                  className="flex justify-between items-center py-[6px] w-full px-[10px]"
                  onClick={() => {
                    dispatch(setHeaderMode({ mode: 4, submode: 2 }));
                  }}
                >
                  <div className="flex items-center">
                    <img alt="" src={traveler}></img>
                    {collapse ? <></> : <div>Tenant</div>}
                  </div>
                </div>
              }
            />
          </SelectionGroup>
        ) : (
          <></>
        )}
        {headerSubmode === 2 ? (
          <SelectionGroup
            className={
              collapse
                ? "p-[6px] rounded-[16px] shadow-md h-max"
                : "flex gap-[8px] p-[6px] rounded-[16px] shadow-md h-max"
            }
            defaultItem={1}
            SelectedItemMask="rounded-[10px] shadow-md"
          >
            <SelectionItem
              SelectedItem={
                <div className="flex justify-between items-center py-[6px] w-full px-[10px]">
                  <div className="flex items-center">
                    <img alt="" src={host}></img>
                    {collapse ? <></> : <div>Landlord</div>}
                  </div>

                  <img alt="" src={light}></img>
                </div>
              }
              UnselectedItem={
                <div
                  className="flex justify-between items-center py-[6px] w-full px-[10px]"
                  onClick={() => {
                    dispatch(setHeaderMode({ mode: 4, submode: 1 }));
                  }}
                >
                  <div className="flex items-center">
                    <img alt="" src={host}></img>
                    {collapse ? <></> : <div>Landlord</div>}
                  </div>
                </div>
              }
            />
            <SelectionItem
              SelectedItem={
                <div className="flex justify-between items-center py-[6px] w-full px-[10px]">
                  <div className="flex items-center">
                    <img alt="" src={traveler}></img>
                    {collapse ? <></> : <div>Tenant</div>}
                  </div>
                  <img alt="" src={light}></img>
                </div>
              }
              UnselectedItem={
                <div
                  className="flex justify-between items-center py-[6px] w-full px-[10px]"
                  onClick={() => {
                    dispatch(setHeaderMode({ mode: 4, submode: 2 }));
                  }}
                >
                  <div className="flex items-center">
                    <img alt="" src={traveler}></img>
                    {collapse ? <></> : <div>Tenant</div>}
                  </div>
                </div>
              }
            />
          </SelectionGroup>
        ) : (
          <></>
        )}
        {headerSubmode === 3 ? (
          <SelectionGroup
            className={
              collapse
                ? "p-[6px] rounded-[16px] shadow-md h-max"
                : "flex gap-[8px] p-[6px] rounded-[16px] shadow-md h-max"
            }
            defaultItem={0}
            SelectedItemMask="rounded-[10px] shadow-md"
          >
            <SelectionItem
              SelectedItem={
                <div className="flex justify-between items-center py-[6px] w-full px-[10px]">
                  <div className="flex items-center">
                    <img alt="" src={host}></img>
                    {collapse ? <></> : <div>Host</div>}
                  </div>

                  <img alt="" src={light}></img>
                </div>
              }
              UnselectedItem={
                <div
                  className="flex justify-between items-center py-[6px] w-full px-[10px]"
                  onClick={() => {
                    dispatch(setHeaderMode({ mode: 4, submode: 3 }));
                  }}
                >
                  <div className="flex items-center">
                    <img alt="" src={host}></img>
                    {collapse ? <></> : <div>Host</div>}
                  </div>
                </div>
              }
            />
            <SelectionItem
              SelectedItem={
                <div className="flex justify-between items-center py-[6px] w-full px-[10px]">
                  <div className="flex items-center">
                    <img alt="" src={traveler}></img>
                    {collapse ? <></> : <div>Traveler</div>}
                  </div>
                  <img alt="" src={light}></img>
                </div>
              }
              UnselectedItem={
                <div
                  className="flex justify-between items-center py-[6px] w-full px-[10px]"
                  onClick={() => {
                    dispatch(setHeaderMode({ mode: 4, submode: 4 }));
                  }}
                >
                  <div className="flex items-center">
                    <img alt="" src={traveler}></img>
                    {collapse ? <></> : <div>Traveler</div>}
                  </div>
                </div>
              }
            />
          </SelectionGroup>
        ) : (
          <></>
        )}
        {headerSubmode === 4 ? (
          <SelectionGroup
            className={
              collapse
                ? "p-[6px] rounded-[16px] shadow-md h-max"
                : "flex gap-[8px] p-[6px] rounded-[16px] shadow-md h-max"
            }
            defaultItem={1}
            SelectedItemMask="rounded-[10px] shadow-md"
          >
            <SelectionItem
              SelectedItem={
                <div className="flex justify-between items-center py-[6px] w-full px-[10px]">
                  <div className="flex items-center">
                    <img alt="" src={host}></img>
                    {collapse ? <></> : <div>Host</div>}
                  </div>

                  <img alt="" src={light}></img>
                </div>
              }
              UnselectedItem={
                <div
                  className="flex justify-between items-center py-[6px] w-full px-[10px]"
                  onClick={() => {
                    dispatch(setHeaderMode({ mode: 4, submode: 3 }));
                  }}
                >
                  <div className="flex items-center">
                    <img alt="" src={host}></img>
                    {collapse ? <></> : <div>Host</div>}
                  </div>
                </div>
              }
            />
            <SelectionItem
              SelectedItem={
                <div className="flex justify-between items-center py-[6px] w-full px-[10px]">
                  <div className="flex items-center">
                    <img alt="" src={traveler}></img>
                    {collapse ? <></> : <div>Traveler</div>}
                  </div>
                  <img alt="" src={light}></img>
                </div>
              }
              UnselectedItem={
                <div
                  className="flex justify-between items-center py-[6px] w-full px-[10px]"
                  onClick={() => {
                    dispatch(setHeaderMode({ mode: 4, submode: 4 }));
                  }}
                >
                  <div className="flex items-center">
                    <img alt="" src={traveler}></img>
                    {collapse ? <></> : <div>Traveler</div>}
                  </div>
                </div>
              }
            />
          </SelectionGroup>
        ) : (
          <></>
        )}
      </div>

      {/* {headerSubmode === 6 ? (
        <div className="w-[300px] bg-white rounded-[8px] h-full p-[10px] flex flex-col justify-between">
          <div className="space-y-[10px] h-max">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-[10px]">
                <img alt=""  src={landlord} ></img>
                <div className="text-[18px]">YieldEstate</div>
              </div>
              <img alt=""  src={arrowtoleft} ></img>
            </div>

            

            <SelectionGroup
              defaultItem={mode}
              SelectedItemMask="rounded-[16px] shadow-md bg-[#F6F6F6]"
            >
              <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px]">
                    <div className="flex items-center">
                      <img alt=""  src={propertyactive} ></img>
                      <div>Properties</div>
                    </div>

                    <img alt=""  src={light} ></img>
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(0))}
                  >
                    <div className="flex items-center">
                      <img alt=""  src={propertyinactive} ></img>
                      <div className="text-[#959595]">Properties</div>
                    </div>
                  </div>
                }
              />
              <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px]">
                    <div className="flex items-center">
                      <img alt=""  src={walletactive} ></img>
                      <div>Wallet</div>
                    </div>

                    <img alt=""  src={light} ></img>
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(1))}
                  >
                    <div className="flex items-center">
                      <img alt=""  src={walletinactive} ></img>
                      <div className="text-[#959595]">Wallet</div>
                    </div>
                  </div>
                }
              />
              <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px]">
                    <div className="flex items-center">
                      <img alt=""  src={insightsactive} ></img>
                      <div>Portfolio</div>
                    </div>

                    <img alt=""  src={light} ></img>
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(2))}
                  >
                    <div className="flex items-center">
                      <img alt=""  src={insightsinactive} ></img>
                      <div className="text-[#959595]">Portfolio</div>
                    </div>
                  </div>
                }
              />

              <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px]">
                    <div className="flex items-center">
                      <img alt=""  src={rentalactive} ></img>
                      <div>Reward</div>
                    </div>

                    <img alt=""  src={light} ></img>
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(3))}
                  >
                    <div className="flex items-center">
                      <img alt=""  src={rentalinactive} ></img>
                      <div className="text-[#959595]">Reward</div>
                    </div>
                  </div>
                }
              />
              <SelectionItem
                SelectedItem={
                  <div className="flex items-center justify-between px-[14px] py-[8px]">
                    <div className="flex items-center">
                      <img alt=""  src={cartactive} ></img>
                      <div>Cart</div>
                    </div>

                    <img alt=""  src={light} ></img>
                  </div>
                }
                UnselectedItem={
                  <div
                    className="flex items-center justify-between px-[14px] py-[8px]"
                    onClick={() => dispatch(setDashboardMode(4))}
                  >
                    <div className="flex items-center">
                      <img alt=""  src={cartinactive} ></img>
                      <div className="text-[#959595]">Cart</div>
                    </div>
                  </div>
                }
              />
            </SelectionGroup>
          </div>
        </div>
      ) : (
        <></>
      )} */}
    </div>
  );
};
